import { mainApi } from '../../HttpClient';

export const getCurrentDocRunning = async (documentType, documentBy, documentGenre = null) => {
    let params = '/api/v2/documents-register/preview-doc-no?documentType=' + documentType;
    if (documentBy.id !== null) {
        params += '&documentById=' + documentBy.id;
    }
    if (documentBy.type !== null && documentBy.type !== '') {
        params += '&documentByType=' + documentBy.type;
    }
    if (documentGenre !== null) {
        params += '&documentGenre=' + documentGenre;
    }
    let rs = await mainApi.get(params);
    return rs;
};