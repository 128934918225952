import { createSlice } from "@reduxjs/toolkit";

export const documentSlice = createSlice({
    name: "document",
    initialState: {
        receiverNo: null,
        sentNo: null,
        documentDate: null,
        isDigitalSignatureCheckSentNo: false,
        isDigitalSignatureCheckDocumentDate: false,
        value: {
            id: 0,
            type: "",
            genre: "",
            registerNo: "",
            documentDate: null,
        },
        documentBy: {
            id: null,
            name: '',
            type: '',
        },
    },
    reducers: {
        clearDocumentRunningNo: (state) => {
            state.receiverNo = null;
            state.sentNo = null;
            state.documentDate = null;
            state.isDigitalSignatureCheckSentNo = false;
            state.isDigitalSignatureCheckDocumentDate = false;
        },
        clearDocumentReceiverNo: (state) => {
            state.receiverNo = null;
        },
        clearDocumentSentNo: (state) => {
            state.sentNo = null;
        },
        clearDocumentDate: (state) => {
            state.documentDate = null;
        },
        setDocumentReceiverNo: (state, action) => {
            state.receiverNo = action.payload;
        },
        setDocumentSentNo: (state, action) => {
            state.sentNo = action.payload;
        },
        setDocumentDateRedux: (state, action) => {
            state.documentDate = action.payload;
        },
        setDocument: (state, action) => {
            state.value = {...state.value, ...action.payload};
        },
        setDocumentBy: (state, action) => {
            const documentById = action.payload.id;
            if(documentById !== null) {
                action.payload.id = documentById.toString();
            }
            state.documentBy = action.payload;
        },
        setDocumentByRole: (state, action) => {
            const documentById = action.payload.id;
            if(documentById !== null) {
                action.payload.id = documentById.toString();
            }
            state.documentBy = action.payload;
        },
        setDocumentByEmployee: (state, action) => {
            if(state.documentBy.id === null) {
                state.documentBy = action.payload;
            }
        },
        setDigitalSignatureCheckSentNo: (state, action) => {
            state.isDigitalSignatureCheckSentNo = action.payload;
        },
        setDigitalSignatureCheckDocumentDate: (state, action) => {
            state.isDigitalSignatureCheckDocumentDate = action.payload;
        },
    },
});

export const {
    clearDocumentRunningNo,
    clearDocumentReceiverNo,
    clearDocumentSentNo,
    clearDocumentDate,
    setDocumentReceiverNo,
    setDocumentSentNo,
    setDocumentDateRedux,
    setDocument,
    setDocumentBy,
    setDocumentByEmployee,
    setDocumentByRole,
    setDigitalSignatureCheckSentNo,
    setDigitalSignatureCheckDocumentDate,
} = documentSlice.actions;

export default documentSlice.reducer;
