export const prefixList = [
    {label: "นาย", value: "นาย"},
    {label: "นาง", value: "นาง"},
    {label: "นางสาว", value: "นางสาว"},
    {label: "หม่อม", value: "หม่อม"},
    {label: "หม่อมเจ้า", value: "หม่อมเจ้า"},
    {label: "หม่อมเจ้าหญิง", value: "หม่อมเจ้าหญิง"},
    {label: "หม่อมราชวงศ์", value: "หม่อมราชวงศ์"},
    {label: "หม่อมหลวง", value: "หม่อมหลวง"},
];
