import React from "react";
import { Button, Container } from "react-bootstrap";
import { useKeycloak } from '@react-keycloak/web';
import DocViewer, { DocViewerRenderers, PDFRenderer } from "@cyntler/react-doc-viewer";
import { Document, Page } from "react-pdf";

export default function Test() {
    const [keycloak] = useKeycloak();

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `bearer ${keycloak.token}`,
    };

    const docs = [
        {
            uri: "https://file.shin.homes/api/v1/file/99ddfa1f-a226-4da9-8a37-125fbb5bf49f" ,
            fileName: "File Name อย่าลืม",
        },
        {
            uri: "https://file.shin.homes/api/v1/file/5b9e6471-aed2-4079-8901-1092de1a1599" ,
            fileName: "File Name อย่าลืม",
        },
    ];

    const MyCustomPDFRenderer: PDFRenderer = ({ mainState: { currentDocument } }) => {
        if (!currentDocument) return null;

        let numPages = null;
        let pageNumber = 1;
        let page = {width: 0, height: 0};

        const onDocumentLoadSuccess = (pdf) => {
            numPages = pdf.numPages;
        }

        const onPageLoadSuccess = async (pdf) => {
            const pdfWidth = pdf.originalWidth;
            const pdfHeight = pdf.originalHeight;

            page = {width: pdfWidth, height: pdfHeight};
        }

        const changePage = (offset) => {
            pageNumber += offset;
        }

        const previousPage = () => {
            changePage(-1);
        }

        const nextPage = () => {
            changePage(1);
        }

        return (
            <Container className="pdf-sign-viewer">
                <Document file={currentDocument.fileData} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page
                        width={page.width}
                        height={page.height}
                        pageNumber={pageNumber}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        onLoadSuccess={onPageLoadSuccess}>
                    </Page>
                </Document>
                <div className="row m-4">
                    <div className="col-12 text-center">
                        <Button type="button" disabled={pageNumber <= 1} onClick={previousPage}>‹</Button>
                        <span className="m-2">หน้า {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}</span>
                        <Button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>›</Button>
                    </div>
                </div>
            </Container>
        );
    };

    MyCustomPDFRenderer.fileTypes = ["pdf", "application/pdf"];
    MyCustomPDFRenderer.weight = 1;

    const MyHeader: IHeaderOverride = (state, previousDocument, nextDocument) => {
        if (!state.currentDocument || state.config?.header?.disableFileName) {
            return null;
        }

        return (
            <>
                <Container className="pdf-sign-viewer mt-3 mb-3">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h5><b>{state.currentDocument.fileName || ""}</b></h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <Button type="button" onClick={previousDocument} disabled={state.currentFileNo === 0}>‹</Button>
                            <span className="m-2">ไฟล์ {state.currentFileNo + 1 || (state.currentFileNo + 1 ? 1 : "--")} / {state.documents.length || "--"}</span>
                            <Button type="button" onClick={nextDocument} disabled={state.currentFileNo >= state.documents.length - 1}>›</Button>
                        </div>
                    </div>
                </Container>
            </>
        );
    };

    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>

                        <DocViewer
                            documents={docs}
                            pluginRenderers={[...DocViewerRenderers, MyCustomPDFRenderer]}
                            prefetchMethod="GET"
                            requestHeaders={headers}
                            config={{
                                header: {overrideComponent: MyHeader,},
                            }}
                        />
                    </Container>
                </section>
            </div>
        </div>
    );
}