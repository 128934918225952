import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { mainApi } from '../HttpClient';
import config from 'react-global-configuration';
import Swal from 'sweetalert2';
import Select from 'react-select';
import 'datatables.mark.js/dist/datatables.mark.es6.min.js';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import 'datatables.net-responsive/js/dataTables.responsive.min';

const MasOrganization = () => {
    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => setModalShow(false);
    const handleModalShow = () => setModalShow(true);
    const selectInputRef1 = useRef();
    const selectInputRef2 = useRef();
    const selectInputRef3 = useRef();
    const selectInputRef4 = useRef();
    const {register, errors, handleSubmit, setValue} = useForm();
    const {register: modalRegister, handleSubmit: modalHandleSubmit} = useForm();

    const [editData, setEditData] = useState({});

    const [isHideOrg1, setIsHideOrg1] = useState('none');
    const [isHideOrg2, setIsHideOrg2] = useState('none');
    const [isHideOrg3, setIsHideOrg3] = useState('none');
    const [isHideOrg4, setIsHideOrg4] = useState('none');

    const [selectOrgType, setSelectOrgType] = useState('');

    const [listOrg1, setListOrg1] = useState([]);
    const [selectValueListOrg1, setSelectValueListOrg1] = useState('');
    const [selectTextListOrg1, setSelectTextListOrg1] = useState('');

    const [listOrg2, setListOrg2] = useState([]);
    const [selectValueListOrg2, setSelectValueListOrg2] = useState('');
    const [selectTextListOrg2, setSelectTextListOrg2] = useState('');

    const [listOrg3, setListOrg3] = useState([]);
    const [selectValueListOrg3, setSelectValueListOrg3] = useState('');
    const [selectTextListOrg3, setSelectTextListOrg3] = useState('');

    const [listOrg4, setListOrg4] = useState([]);
    const [selectValueListOrg4, setSelectValueListOrg4] = useState('');
    const [selectTextListOrg4, setSelectTextListOrg4] = useState('');

    const [dataSourceOrganization, setDataSourceOrganization] = useState([]);

    const clearSelect1 = useCallback(() => {
        selectInputRef1.current.select.clearValue();
        setListOrg1([]);
    }, []);

    const clearSelect2 = useCallback(() => {
        selectInputRef2.current.select.clearValue();
        setListOrg2([]);
    }, []);

    const clearSelect3 = useCallback(() => {
        selectInputRef3.current.select.clearValue();
        setListOrg3([]);
    }, []);

    const clearSelect4 = useCallback(() => {
        selectInputRef4.current.select.clearValue();
        setListOrg4([]);
    }, []);

    //Hide Show.
    const setVisibleOrg = useCallback(() => {
        // Clear Display.
        setIsHideOrg1('none');
        setIsHideOrg2('none');
        setIsHideOrg3('none');
        setIsHideOrg4('none');

        if (selectOrgType === '1') {
            setIsHideOrg1('');
        } else if (selectOrgType === '2') {
            setIsHideOrg1('');
            setIsHideOrg2('');
        } else if (selectOrgType === '3') {
            setIsHideOrg1('');
            setIsHideOrg2('');
            setIsHideOrg3('');
        } else if (selectOrgType === '4') {
            setIsHideOrg1('');
            setIsHideOrg2('');
            setIsHideOrg3('');
            setIsHideOrg4('');
        }
    }, [selectOrgType]);

    const getListOrg1 = useCallback(() => {
        mainApi.get('/api/organization/react-select/list/1').then((res) => {
            setListOrg1(res.data);
        });
    }, []);

    const getListOrg2 = useCallback(() => {
        if (selectValueListOrg1 !== '') {
            mainApi.get('/api/organization/react-select/list/2?parent_id=' + selectValueListOrg1).then((res) => {
                setListOrg2(res.data);
            });
        }
    }, [selectValueListOrg1]);

    const getListOrg3 = useCallback(() => {
        if (selectValueListOrg2 !== '') {
            mainApi.get('/api/organization/react-select/list/3?parent_id=' + selectValueListOrg2).then((res) => {
                setListOrg3(res.data);
            });
        }
    }, [selectValueListOrg2]);

    const getListOrg4 = useCallback(() => {
        if (selectValueListOrg3 !== '') {
            mainApi.get('/api/organization/react-select/list/4?parent_id=' + selectValueListOrg3).then((res) => {
                setListOrg4(res.data);
            });
        }
    }, [selectValueListOrg3]);

    //Load Data.
    const loadDataListOrg = useCallback(() => {
        // let level = 0;
        // let parent_id = 0;
        //
        // if (selectOrgType === '1') {
        //     level = selectOrgType;
        //     parent_id = '1001';
        // } else if (selectOrgType === '2') {
        //     level = selectOrgType;
        //     parent_id = selectValueListOrg1;
        // } else if (selectOrgType === '3') {
        //     level = selectOrgType;
        //     parent_id = selectValueListOrg2;
        // } else if (selectOrgType === '4') {
        //     level = selectOrgType;
        //     parent_id = selectValueListOrg3;
        // }
        //
        // if (level && parent_id) {
        //     mainApi.get('/api/tbl-organization/list/' + level + '/' + parent_id).then((res) => {
        //         setDataSourceOrganization(res.data);
        //     });
        // }

        mainApi.get('/api/tbl-organization/data-table-list').then((res) => {
            setDataSourceOrganization(res.data);
            setValue('num_order', res.data.length + 1);
        });
    }, [setValue]);

    useEffect(() => {
        loadDataListOrg();
    }, [loadDataListOrg]);

    useEffect(() => {
        setVisibleOrg();
        clearSelect1();
        if (selectOrgType !== '') {
            getListOrg1();
        } else {
            clearSelect1();
        }
    }, [setVisibleOrg, selectOrgType, clearSelect1, getListOrg1]);

    useEffect(() => {
        clearSelect2();
        if (selectValueListOrg1 !== '') {
            getListOrg2();
        } else {
            clearSelect2();
        }
    }, [selectValueListOrg1, clearSelect2, getListOrg2]);

    useEffect(() => {
        clearSelect3();
        if (selectValueListOrg2 !== '') {
            getListOrg3();
        } else {
            clearSelect3();
        }
    }, [selectValueListOrg2, clearSelect3, getListOrg3]);

    useEffect(() => {
        clearSelect4();
        if (selectValueListOrg3 !== '') {
            getListOrg4();
        } else {
            clearSelect4();
        }
    }, [selectValueListOrg3, clearSelect4, getListOrg4]);

    //Search.
    // const handleSearch = async () => {
    //     loadDataListOrg();
    // };

    //Save.
    const handleSave = (data) => {
        let err_count = 0;
        let err_message = '';
        //Check Data
        if (selectOrgType === '') {
            showWarningMsg("ไม่สามารถบันทึกข้อมูลได้", "- กรุณาเลือกประเภทโครงสร้างหน่วยงาน");
            return false;
        } else if (selectOrgType === '1') {
            if (selectValueListOrg1 === '') {
                showWarningMsg("ไม่สามารถบันทึกข้อมูลได้", "- กรุณาเลือกสำนัก/กอง");
                return false;
            }
        } else if (selectOrgType === '2') {
            if (selectValueListOrg1 === '') {
                err_count += 1;
                err_message += '- กรุณาเลือกสำนัก/กอง<br>';
            }
            if (selectValueListOrg2 === '') {
                err_count += 1;
                err_message += '- กรุณาเลือกหน่วยงานที่ต่ำกว่าสำนัก/กอง 1 ระดับ';
            }
            if (err_count > 0) {
                showWarningMsg("ไม่สามารถบันทึกข้อมูลได้", err_message);
                return false;
            }
        } else if (selectOrgType === '3') {
            if (selectValueListOrg1 === '') {
                err_count += 1;
                err_message += '- กรุณาเลือกสำนัก/กอง<br>';
            }
            if (selectValueListOrg2 === '') {
                err_count += 1;
                err_message += '- กรุณาเลือกหน่วยงานที่ต่ำกว่าสำนัก/กอง 1 ระดับ<br>';
            }
            if (selectValueListOrg3 === '') {
                err_count += 1;
                err_message += '- กรุณาเลือกหน่วยงานที่ต่ำกว่าสำนัก/กอง 2 ระดับ';
            }
            if (err_count > 0) {
                showWarningMsg("ไม่สามารถบันทึกข้อมูลได้", err_message);
                return false;
            }
        } else if (selectOrgType === '4') {
            if (selectValueListOrg1 === '') {
                err_count += 1;
                err_message += '- กรุณาเลือกสำนัก/กอง<br>';
            }
            if (selectValueListOrg2 === '') {
                err_count += 1;
                err_message += '- กรุณาเลือกหน่วยงานที่ต่ำกว่าสำนัก/กอง 1 ระดับ<br>';
            }
            if (selectValueListOrg3 === '') {
                err_count += 1;
                err_message += '- กรุณาเลือกหน่วยงานที่ต่ำกว่าสำนัก/กอง 2 ระดับ<br>';
            }
            if (selectValueListOrg4 === '') {
                err_count += 1;
                err_message += '- กรุณาเลือกหน่วยงานที่ต่ำกว่าสำนัก/กอง 3 ระดับ';
            }
            if (err_count > 0) {
                showWarningMsg("ไม่สามารถบันทึกข้อมูลได้", err_message);
                return false;
            }
        }


        let count_data = 0;
        let org_id = 0;
        let parent_id = 0;
        let name = '';

        if (selectOrgType === '1') {
            org_id = selectValueListOrg1;
            parent_id = '1001';
            name = selectTextListOrg1;
        } else if (selectOrgType === '2') {
            org_id = selectValueListOrg2;
            parent_id = selectValueListOrg1;
            name = selectTextListOrg2;
        } else if (selectOrgType === '3') {
            org_id = selectValueListOrg3;
            parent_id = selectValueListOrg2;
            name = selectTextListOrg3;
        } else if (selectOrgType === '4') {
            org_id = selectValueListOrg4;
            parent_id = selectValueListOrg3;
            name = selectTextListOrg4;
        }

        let params = {
            orgId: org_id,
            parentId: parent_id,
            name: name,
            level: data.level,
            numOrder: data.num_order,
            isActive: '1',
        };
        //console.log(params);
        //console.log(data);

        mainApi.get('/api/tbl-organization/check_duplicate/' + params.orgId + '/' + params.parentId).then((rsCheck) => {
            count_data = parseInt(rsCheck.data);
            if (count_data > 0) {
                Swal.fire({
                    title: 'โครงสร้างหน่วยงานซ้ำ',
                    text: 'ไม่สามารถเพิ่มข้อมูลได้ เนื่องจากโครงสร้างหน่วยงานซ้ำในระบบ',
                    icon: 'warning',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonColor: '#3085d6',
                    cancelButtonText: 'ยกเลิก',
                });
            } else {
                mainApi.post('/api/tbl-organization/save', params).then((rsSave) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'สำเร็จ',
                        text: `บันทึกข้อมูลสำเร็จ`,
                        timer: config.get('alertTimer'),
                        timerProgressBar: true,
                    });
                    //Refresh Show Data.
                    loadDataListOrg();
                });
            }
        });
    };

    //showEdit
    const showEdit = (data) => {
        setEditData({});
        setEditData(data);
        handleModalShow();
    };


    //showDelete
    const showDelete = (data) => {
        Swal.fire({
            title: 'คุณแน่ใจหรือไม่',
            text: 'ต้องการลบข้อมูลโครงสร้างหน่วยงานใช่ไหม',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'ลบข้อมูล',
            cancelButtonText: 'ยกเลิก',
        }).then((result) => {
            if (result.isConfirmed) {
                //console.log('Delete : ', data)
                deleteData(data.orgId);
            }
        });
    }

    //Delete
    const deleteData = (orgId) => {
        mainApi.delete('/api/tbl-organization/delete/' + orgId).then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'ลบข้อมูลโครงสร้างหน่วยงานสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });
            loadDataListOrg();
        });
    };

    //handleEdit
    const handleEdit = (data) => {
        let tmp_num_order = parseInt(data.edit_num_order) || 1;
        let valid_number = 1;
        if (Number.isInteger(tmp_num_order) === true) {
            valid_number = tmp_num_order;
        }

        let params = {
            orgId: data.edit_org_id,
            name: data.edit_name,
            level: data.edit_level,
            parentId: data.edit_parent_id,
            numOrder: valid_number,
        };
        //console.log('Params ! ', params);
        mainApi.put('/api/tbl-organization/update-order', params).then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'แก้ไขข้อมูลสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });
            loadDataListOrg();
            handleModalClose();
        });
    };

    //Render
    const renderDataOrganization = (data) => {
        // console.log('render : ', data);
        if (data.length === 0) {
            return (
                <tr className="text-center">
                    <td colSpan="3">ไม่พบข้อมูล</td>
                </tr>
            );
        } else {
            return data.map((item, index) => (
                <tr key={index}>
                    <td>
                        <div className="d-flex justify-content-around align-items-center">
                            <button
                                type="button"
                                className="btn btn-sm btn-order-up btn-primary"
                                disabled={item.numOrder === 1}
                                onClick={() => {
                                reorder(item, "UP")
                            }}>
                                <i className="fas fa-angle-up"></i>
                            </button>
                            <div>{item.numOrder}</div>
                            <button
                                type="button"
                                className="btn btn-sm btn-order-down btn-secondary"
                                disabled={data.length === index + 1}
                                onClick={() => {
                                reorder(item, "DOWN")
                            }}>
                                <i className="fas fa-angle-down"></i>
                            </button>
                        </div>
                    </td>
                    <td>{item.name}</td>
                    <td className="text-center">
                        <div>
                            <button
                                type="button"
                                className="btn btn-secondary btn-sm"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i className="fas fa-ellipsis-h"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-right">
                                <button
                                    className="dropdown-item btn-edit"
                                    type="button"
                                    onClick={() => {
                                        showEdit(item);
                                    }}
                                >
                                    <i className="fas fa-undo-alt mr-2"></i>แก้ไข
                                </button>
                                <button
                                    className="dropdown-item btn-delete text-danger"
                                    type="button"
                                    onClick={() => {
                                        showDelete(item);
                                    }}
                                >
                                    <i className="fas fa-times-circle mr-2"></i>ลบข้อมูล
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            ));
        }
    };

    const reorder = (data, action) => {
        mainApi.get('/api/tbl-organization/re-order?orgId=' + data.orgId
            + '&parentId=' + data.parentId + '&level=' + data.level
            + '&curNumOrder=' + data.numOrder + '&action=' + action).then((res) => {
            loadDataListOrg();
        });
    }

    //Show Warning Message.
    const showWarningMsg = (title, msg) => {
        Swal.fire({
            title: title,
            html: msg,
            icon: 'warning',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonColor: '#3085d6',
            cancelButtonText: 'ยกเลิก',
        });
    }

    //Clear Form Data.
    // const clearFormData = async () => {
    //     $("#selectOrg").val("");
    //     setSelectOrgType('');
    //     $("#control_num_order").val(1);
    // }

    return (
        <div>
            <div
                className="content-wrapper text-normal"
                style={{minHeight: '100vh'}}
            >
                <section className="content">
                    <Container>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        ข้อมูลโครงสร้างหน่วยงาน
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            ข้อมูลโครงสร้างหน่วยงาน
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="table-header-MasOrganization card-header text-dark">
                                        เพิ่มข้อมูลโครงสร้างหน่วยงาน
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className="form-group row">
                                                <div className="col-12 col-md-3 pt-2">
                                                    <span className="text-color-form align-middle">ประเภทโครงสร้าง</span>
                                                    <span className="text-danger"> *</span>
                                                </div>

                                                <div className="col-12 col-md-9 pt-1">
                                                    <select
                                                        className="form-control shadow-none"
                                                        id="selectOrg"
                                                        name="level"
                                                        ref={register}
                                                        defaultValue={''}
                                                        onChange={(e) => {
                                                            setSelectOrgType(e.target.value);
                                                        }}
                                                    >
                                                        <option value="" tag="">
                                                            กรุณาเลือก
                                                        </option>
                                                        <option value="1" tag="สำนัก/กอง">
                                                            สำนัก/กอง
                                                        </option>
                                                        <option value="2" tag="ต่ำกว่าสำนัก/กอง 1 ระดับ">
                                                            ต่ำกว่าสำนัก/กอง 1 ระดับ
                                                        </option>
                                                        <option value="3" tag="ต่ำกว่าสำนัก/กอง 2 ระดับ">
                                                            ต่ำกว่าสำนัก/กอง 2 ระดับ
                                                        </option>
                                                        <option value="4" tag="ต่ำกว่าสำนัก/กอง 3 ระดับ">
                                                            ต่ำกว่าสำนัก/กอง 3 ระดับ
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div
                                                className="form-group row"
                                                style={{display: isHideOrg1}}
                                            >
                                                <div className="col-12 col-md-3 pt-2">
                                                    <span className="text-color-form align-middle">สำนัก/กอง</span>
                                                    <span className="text-danger"> *</span>
                                                </div>

                                                <div className="col-12 col-md-9 pt-1">
                                                    <Select
                                                        isClearable
                                                        ref={selectInputRef1}
                                                        options={listOrg1}
                                                        onChange={(select) => {
                                                            if (select) {
                                                                setSelectValueListOrg1(select.value);
                                                                setSelectTextListOrg1(select.label);
                                                            } else {
                                                                setSelectValueListOrg1('');
                                                                setSelectTextListOrg1('');
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="form-group row"
                                                style={{display: isHideOrg2}}
                                            >
                                                <div className="col-12 col-md-3 pt-2">
                                                    <span className="text-color-form align-middle">ต่ำกว่าสำนักกอง 1 ระดับ</span>
                                                    <span className="text-danger"> *</span>
                                                </div>

                                                <div className="col-12 col-md-9 pt-1">
                                                    <Select
                                                        isClearable
                                                        ref={selectInputRef2}
                                                        options={listOrg2}
                                                        onChange={(select) => {
                                                            if (select) {
                                                                setSelectValueListOrg2(select.value);
                                                                setSelectTextListOrg2(select.label);
                                                            } else {
                                                                setSelectValueListOrg2('');
                                                                setSelectTextListOrg2();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="form-group row"
                                                style={{display: isHideOrg3}}
                                            >
                                                <div className="col-12 col-md-3 pt-2">
                                                    <span className="text-color-form align-middle">ต่ำกว่าสำนักกอง 2 ระดับ</span>
                                                    <span className="text-danger"> *</span>
                                                </div>

                                                <div className="col-12 col-md-9 pt-1">
                                                    <Select
                                                        isClearable
                                                        ref={selectInputRef3}
                                                        options={listOrg3}
                                                        onChange={(select) => {
                                                            if (select) {
                                                                setSelectValueListOrg3(select.value);
                                                                setSelectTextListOrg3(select.label);
                                                            } else {
                                                                setSelectValueListOrg3('');
                                                                setSelectTextListOrg3('');
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="form-group row"
                                                style={{display: isHideOrg4}}
                                            >
                                                <div className="col-12 col-md-3 pt-2">
                                                    <span className="text-color-form align-middle">ต่ำกว่าสำนักกอง 3 ระดับ</span>
                                                    <span className="text-danger"> *</span>
                                                </div>

                                                <div className="col-12 col-md-9 pt-1">
                                                    <Select
                                                        isClearable
                                                        ref={selectInputRef4}
                                                        options={listOrg4}
                                                        onChange={(select) => {
                                                            if (select) {
                                                                setSelectValueListOrg4(select.value);
                                                                setSelectTextListOrg4(select.label);
                                                            } else {
                                                                setSelectValueListOrg4('');
                                                                setSelectTextListOrg4('');
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12 col-md-3 pt-2">
                                                    <span className="text-color-form align-middle">ลำดับ</span>
                                                </div>
                                                <div className="col-12 col-md-9 pt-1">
                                                    <input
                                                        className="form-control shadow-none"
                                                        id="control_num_order"
                                                        type="number"
                                                        name="num_order"
                                                        min={1}
                                                        step={1}
                                                        defaultValue={1}
                                                        ref={register()}
                                                    />
                                                    {errors.num_order && (
                                                        <div className="text-left">
                                                            <span className="text-danger">{errors.num_order.message}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            {/*<div className="col-sm-12 col-md-3 pt-2">*/}
                                            {/*    <Button*/}
                                            {/*        type="submit"*/}
                                            {/*        id="btn-search-organization"*/}
                                            {/*        className="btn-color-submit btn-block btn-primary"*/}
                                            {/*        onClick={handleSearch}*/}
                                            {/*    >*/}
                                            {/*        <i className="fas fa-search pr-2"></i>ค้นหา*/}
                                            {/*    </Button>*/}
                                            {/*</div>*/}

                                            <div className="col-sm-12 col-md-4 offset-md-8 col-xl-3 offset-xl-9 pt-2">
                                                <Button
                                                    type="submit"
                                                    id="btn-submit-organization"
                                                    className="btn-color-add-small btn-block"
                                                    onClick={handleSubmit(handleSave)}
                                                >
                                                    <i className="far fa-save pr-2"></i>บันทึก
                                                </Button>
                                            </div>
                                            {/*<div className="col-sm-12 col-md-3 pt-2">*/}
                                            {/*    <Button*/}
                                            {/*        type="reset"*/}
                                            {/*        className="btn-block btn-color-delete-small"*/}
                                            {/*        id="btn_reset"*/}
                                            {/*        onClick={() => {*/}
                                            {/*            clearFormData()*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        <i className="fas fa-ban pr-2"></i>*/}
                                            {/*        ยกเลิก*/}
                                            {/*    </Button>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mt-2">
                                    <div className="table-header-MasOrganization card-header text-dark">
                                        รายชื่อหน่วยงาน
                                    </div>
                                    <div className="card-body">
                                        <table
                                            id="data-table"
                                            className="table table-striped table-bordered display w-100 margin-bottom-30"
                                        >
                                            <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">ชื่อหน่วยงาน</th>
                                                <th className="text-center">จัดการ</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderDataOrganization(dataSourceOrganization)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>

            <Modal show={modalShow} onHide={handleModalClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        แก้ไขลำดับหน่วยงาน
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="form-group row">
                                        <div className="col-12 col-md-4 pt-2">
                                            <span className="text-color-form align-middle">ชื่อหน่วยงาน</span>
                                            <span className="text-danger"> *</span>
                                        </div>
                                        <div className="col-12 col-md-8 pt-1">
                                            <input
                                                className="form-control shadow-none"
                                                type="text"
                                                name="edit_name"
                                                ref={modalRegister()}
                                                defaultValue={editData.name}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{display: 'none'}}>
                                        <div className="col-12 col-md-4 pt-2">
                                            <span className="text-color-form align-middle">orgId</span>
                                            <span className="text-danger"> *</span>
                                        </div>
                                        <div className="col-12 col-md-8 pt-1">
                                            <input
                                                className="form-control shadow-none"
                                                type="text"
                                                name="edit_org_id"
                                                ref={modalRegister()}
                                                defaultValue={editData.orgId}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{display: 'none'}}>
                                        <div className="col-12 col-md-4 pt-2">
                                            <span className="text-color-form align-middle">parentId</span>
                                            <span className="text-danger"> *</span>
                                        </div>
                                        <div className="col-12 col-md-8 pt-1">
                                            <input
                                                className="form-control shadow-none"
                                                type="text"
                                                name="edit_parent_id"
                                                ref={modalRegister()}
                                                defaultValue={editData.parentId}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row" style={{display: 'none'}}>
                                        <div className="col-12 col-md-4 pt-2">
                                            <span className="text-color-form align-middle">level</span>
                                            <span className="text-danger"> *</span>
                                        </div>
                                        <div className="col-12 col-md-8 pt-1">
                                            <input
                                                className="form-control shadow-none"
                                                type="text"
                                                name="edit_level"
                                                ref={modalRegister()}
                                                defaultValue={editData.level}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12 col-md-4 pt-2">
                                            <span className="text-color-form align-middle">ลำดับ</span>
                                        </div>
                                        <div className="col-12 col-md-8 pt-1">
                                            <input
                                                className="form-control shadow-none"
                                                type="number"
                                                name="edit_num_order"
                                                min={0}
                                                step={1}
                                                ref={modalRegister()}
                                                defaultValue={editData.numOrder}
                                            />
                                            {errors.edit_num_order && (
                                                <div className="text-left">
                                                    <span className="text-danger">{errors.edit_num_order.message}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-submit mr-2"
                        onClick={modalHandleSubmit(handleEdit)}
                    >
                        <i className="fas fa-save pr-2"></i>บันทึก
                    </Button>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={handleModalClose}
                    >
                        <i className="fas fa-ban pr-2"></i>ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MasOrganization;
