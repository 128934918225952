import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FroalaEditor from "../share/FroalaEditor";
import UploadFiles from "../share/UploadFiles";
import { mainApi } from "../../HttpClient";
import DateInput from "../share/DateInput";
import moment from "moment";
import { getCurrentDocRunning } from "../util/DocRegister";
import Swal from "sweetalert2";
import config from "react-global-configuration";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import PdfSelectionViewModal from "../share/pdf-selection-view/PdfSelectionViewModal";
import { delay } from "../util/Delay";
import { unit } from "./util/Unit";
import DownloadFile from "../share/DownloadFile";
import { useSelector } from "react-redux";
import { urgencyLevel } from "./util/UrgencyLevel";

export default function FormAutoCopy() {
    const {register, handleSubmit, reset} = useForm();

    const [content, setContent] = useState('');
    const [fileList, setFileList] = useState([]);
    // const [autoFormDate, setAutoFormDate] = useState(null);
    const [autoFormDueDate, setAutoFormDueDate] = useState(null);
    const [docRunningNo, setDocRunningNo] = useState(1);
    const [isLoadingDocRunningNo, setIsLoadingDocRunningNo] = useState(true);

    const [autoFormName, setAutoFormName] = useState('');
    // const [autoFormNo, setAutoFormNo] = useState('');
    const [urgencyLevelCheck, setUrgencyLevelCheck] = useState(true);
    const [autoFormReferDocument, setAutoFormReferDocument] = useState({});

    const [referSelections, setReferSelections] = useState([]);
    const [referOptions, setReferOptions] = useState([]);
    const [isReferLoading, setIsReferLoading] = useState(false);
    const [pdfReferSignList, setPdfReferSignList] = useState([]);

    const tab1Ref = useRef(null);
    const tab2Ref = useRef(null);
    const pdfSelectionViewModalRef = useRef();
    const selectAttachFileButtonRef = useRef();

    const history = useHistory();
    const location = useLocation();

    const documentState = useSelector((state) => state.document);

    const [showSelectDepartment, setShowSelectDepartment] = useState(false);
    const [departmentNameList, setDepartmentNameList] = useState([]);

    const [referenceList, setReferenceList] = useState([]);
    const [attachList, setAttachList] = useState([]);

    useEffect(() => {
        setIsLoadingDocRunningNo(true);
        if (!location.state || !location.state.formId) {
            if (!documentState.documentBy || documentState.documentBy.type === 'employee' || documentState.documentBy.type === 'manager') {
                history.push('./dashbordV2');
                return;
            }
            history.push('./form-auto-menu-copy-list');
            return;
        }

        switch (location.state.formId) {
            case 1:
                setAutoFormName('ขอเชิญเป็นวิทยากร (ภายใน)');
                break;
            case 2:
                setAutoFormName('ขอเชิญเป็นวิทยากร (ภายนอก) - กรมควบคุมโรค');
                break;
            case 3:
                setAutoFormName('ขอเชิญเป็นวิทยากร (ภายนอก) - หน่วยงานเทียบเท่ากอง');
                break;
            case 4:
                setAutoFormName('ขอเชิญเป็นวิทยากร (ภายนอก)');
                break;
            case 5:
                setAutoFormName('ขอความอนุเคราะห์วิทยากร (ภายใน) - กรมควบคุมโรค');
                break;
            case 6:
                setAutoFormName('ขอความอนุเคราะห์วิทยากร (ภายใน) - หน่วยงานเทียบเท่ากอง');
                break;
            case 7:
                setAutoFormName('ขอความอนุเคราะห์วิทยากร (ภายนอก) - กรมควบคุมโรค');
                break;
            case 8:
                setAutoFormName('ขอความอนุเคราะห์วิทยากร (ภายนอก) - หน่วยงานเทียบเท่ากอง');
                break;
            case 9:
                setAutoFormName('ขอเชิญประชุม (ภายใน) - กรมควบคุมโรค');
                break;
            case 10:
                setAutoFormName('ขอเชิญประชุม (ภายใน) - หน่วยงานเทียบเท่ากอง');
                break;
            case 11:
                setAutoFormName('ขอเชิญประชุม (ภายใน-เวียน) - กรมควบคุมโรค');
                setShowSelectDepartment(true);
                break;
            case 12:
                setAutoFormName('ขอเชิญประชุม (ภายใน-เวียน) - หน่วยงานเทียบเท่ากอง');
                setShowSelectDepartment(true);
                break;
            case 13:
                setAutoFormName('ขอเชิญประชุม (ภายนอก) - กรมควบคุมโรค');
                break;
            case 14:
                setAutoFormName('ขอเชิญประชุม (ภายนอก) - หน่วยงานเทียบเท่ากอง');
                break;
            case 15:
                setAutoFormName('ขอความอนุเคราะห์ใช้ห้องประชุม');
                break;
            case 16:
                setAutoFormName('หนังสือเวียนทั่วไปแบบบันทึก');
                setShowSelectDepartment(true);
                break;
            case 17:
                setAutoFormName('หนังสือเวียนประทับตราลงนาม');
                break;
            default:
                break;
        }

        if (documentState.documentBy.id !== null) {
            getCurrentDocRunning(location.state.autoFormType, documentState.documentBy).then((res) => {
                let docCount = res.data;
                if (docCount > -1) {
                    docCount += 1;
                }
                setDocRunningNo(docCount);
                setIsLoadingDocRunningNo(false);
            });
        }
    }, [history, location.state, documentState.documentBy]);

    // useEffect(() => {
    //     mainApi.post('/api/v2/documents-register/register-name', documentState.documentBy).then((res) => {
    //         setAutoFormNo(res.data);
    //     });
    // }, [documentState.documentBy]);

    const handleSaveDraft = (data) => {
        if (referSelections.length === 0) {
            Swal.fire({
                icon: 'info',
                title: 'กรุณาค้นหาเอกสารแม่แบบ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
            });
            return;
        }

        if (getPdfReferSelectList().length === 0) {
            Swal.fire({
                icon: 'info',
                title: 'กรุณาค้นหาไฟล์',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
            });
            selectAttachFileButtonRef.current.click();
            return;
        }

        let bodyData = {
            formId: location.state.formId,
            autoFormName: autoFormName,
            autoFormType: location.state.autoFormType,
            autoFormGenre: location.state.autoFormGenre,

            government: autoFormReferDocument.government || '',
            tel: autoFormReferDocument.tel || '',
            fax: autoFormReferDocument.fax || '',
            autoFormNo: data.autoFormNo || '',
            subject: autoFormReferDocument.subject || '',
            inform: autoFormReferDocument.inform || '',
            prefixInform: autoFormReferDocument.prefixInform || '',
            postScript: autoFormReferDocument.postScript || '',
            command: autoFormReferDocument.command || '',
            urgencyLevel: autoFormReferDocument.urgencyLevel || '',
            content: autoFormReferDocument.content || '',

            governmentFt: autoFormReferDocument.governmentFt || '',
            telFt: autoFormReferDocument.telFt || '',
            faxFt: autoFormReferDocument.faxFt || '',
            emailFt: autoFormReferDocument.emailFt || '',
            referenceList: autoFormReferDocument.referenceList,
            attachList: autoFormReferDocument.attachList,


            createInstead: autoFormReferDocument.createInstead,

            pdfSignList: getPdfReferSelectList(),
            refer: referSelections[0].documentId,
            documentBy: documentState.documentBy,

            departmentNameList: autoFormReferDocument.departmentNameList,
        };

        // if (autoFormDate != null) {
        //     bodyData = {
        //         ...bodyData,
        //         autoFormDate: moment(autoFormDate).format('YYYY-MM-DD'),
        //     };
        // }

        if (autoFormDueDate != null) {
            bodyData = {
                ...bodyData,
                autoFormDueDate: moment(autoFormDueDate).format('YYYY-MM-DD'),
            };
        }

        let params = new FormData();
        params.append('data', JSON.stringify(bodyData));

        fileList.forEach((file) => {
            params.append('files', file);
        });

        mainApi.post('/api/form-auto/refer-save', params).then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'บันทึกข้อมูลสำเร็จ',
                // text: 'เลขที่เอกสาร : ' + res.data.autoFormNo,
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            }).then((result) => {
                if (
                    result.dismiss === Swal.DismissReason.timer ||
                    result.value === true
                ) {
                    history.push('/form-auto-box');
                }
            });
        });
    }

    const handleSubmitError = (errors) => {
        let firstKey = Object.keys(errors)[0];
        if (firstKey.indexOf('f2') > -1) {
            tab2Ref.current.click();
        } else {
            tab1Ref.current.click();
        }
    }

    const handleReferSelection = (value) => {
        reset();
        setContent('');
        // setAutoFormDate(null);
        setAutoFormDueDate(null);
        setPdfReferSignList([]);
        setReferSelections(value);

        if (value.length > 0) {
            let {documentId} = value[0];
            mainApi.get(`/api/form-auto/attach-file/pdf-signature-latest?documentId=${documentId}`).then((res) => {
                setPdfReferSignList(res.data);
            });

            mainApi.get(`/api/form-auto/refer-document?documentId=${documentId}`).then((res) => {
                setAutoFormReferDocument(res.data);
                // let specialChar = '';
                // if (location.state.autoFormType === "W") {
                //     specialChar = '/ว';
                // }
                let data = {
                    ...res.data,
                    // autoFormNo: autoFormNo + specialChar,
                }
                setContent(data.content);
                setDepartmentNameList(data.departmentNameList);
                setReferenceList(data.referenceList);
                setAttachList(data.attachList);

                if (data.urgencyLevel === '1') setUrgencyLevelCheck(false);
                else setUrgencyLevelCheck(true);
                // if (data.autoFormDate != null) setAutoFormDate(data.autoFormDate);
                if (data.autoFormDueDate != null) setAutoFormDueDate(data.autoFormDueDate);
                reset({...data});
            });
        }
    }

    const handleReferSearch = (query) => {
        setIsReferLoading(true);

        let params = {
            registerNo: query,
        };

        let filter = '?documentGenre=' + location.state.autoFormGenre;

        mainApi.post('/api/form-auto/search-refer-register-no' + filter, params).then((res) => {
            setReferOptions(res.data);
        }).finally(() => {
            setIsReferLoading(false);
        });
    };

    const handleAttachFileSearch = async () => {
        pdfSelectionViewModalRef.current.clearDataAndShowModal();
        await delay(100);
        pdfSelectionViewModalRef.current.initPdfList(pdfReferSignList);
    }

    const handleAttachFileAccept = (pdfList) => {
        setPdfReferSignList(pdfList);
    }

    const getPdfReferSelectList = () => {
        return pdfReferSignList.filter((pdf) => pdf.isSelected === true);
    }

    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>

                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        {autoFormName}
                                        <i className="nav-icon fas fa-angle-double-right sidebar-fa-size-header ml-2 mr-2 nvClick"></i>
                                        <button
                                            type="button"
                                            className="btn btn-primary page-heading mb-2"
                                        >
                                            {isLoadingDocRunningNo && (
                                                <div className="spinner-border spinner-border-sm mb-1" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            )}
                                            {!isLoadingDocRunningNo && (
                                                <div>{docRunningNo}</div>
                                            )}
                                        </button>
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            ทะเบียนแม่แบบ
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <Link to="./form-auto-menu-copy-list" className="text-breadcrumb">
                                                แม่แบบ (คัดลอกข้อมูล)
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            {autoFormName}
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <Form className="form-auto" onSubmit={handleSubmit(handleSaveDraft, handleSubmitError)}>
                            <Card>
                                <Card.Header className="form-auto-header">ส่วนค้นหาแม่แบบ</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col sm={12} md={2}>
                                            <span className="text-color-form align-middle">ค้นหา</span>
                                            <span className="text-danger"> *</span>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <InputGroup className="mb-3">
                                                <AsyncTypeahead
                                                    filterBy={() => true}
                                                    id="basic-typeahead-single"
                                                    isLoading={isReferLoading}
                                                    labelKey="registerNo"
                                                    onChange={handleReferSelection}
                                                    options={referOptions}
                                                    placeholder="กรุณาพิมพ์เลขที่หนังสือ..."
                                                    selected={referSelections}
                                                    minLength={2}
                                                    onSearch={handleReferSearch}
                                                />
                                                <InputGroup.Append>
                                                    <Button
                                                        variant="primary"
                                                        onClick={handleAttachFileSearch}
                                                        disabled={referSelections.length === 0}
                                                        ref={selectAttachFileButtonRef}
                                                    >ค้นหาไฟล์</Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Card.Body>

                                <Card.Footer className="form-auto-header">หัวหนังสือราชการ</Card.Footer>
                                <Card.Body>
                                    {location.state && location.state.formId !== 17 && (
                                        <>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">ส่วนราชการ</span>
                                                </Col>
                                                <Col>
                                                    <Form.Control type="text" name="government" ref={register} disabled/>
                                                </Col>
                                            </Row>
                                            {location.state && location.state.autoFormGenre !== "OUT" && (
                                                <Row className="mb-3">
                                                    <Col sm={12} md={2}>
                                                        <span className="text-color-form align-middle">โทร</span>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="text" name="tel" ref={register} disabled/>
                                                    </Col>
                                                    <Col sm={12} md={2}>
                                                        <span className="text-color-form align-middle">โทรสาร</span>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="text" name="fax" ref={register} disabled/>
                                                    </Col>
                                                </Row>
                                            )}
                                            {/*<Row className="mb-3">*/}
                                            {/*    <Col sm={12} md={2}>*/}
                                            {/*        <span className="text-color-form align-middle">ที่</span>*/}
                                            {/*    </Col>*/}
                                            {/*    <Col sm={12} md={4}>*/}
                                            {/*        <Form.Control type="text" name="autoFormNo" ref={register}/>*/}
                                            {/*    </Col>*/}
                                            {/*    <Col sm={12} md={2}>*/}
                                            {/*        <span className="text-color-form align-middle">ลงวันที่</span>*/}
                                            {/*        /!*<span className="text-danger"> *</span>*!/*/}
                                            {/*    </Col>*/}
                                            {/*    <Col sm={12} md={4}>*/}
                                            {/*        <DateInput*/}
                                            {/*            name="autoFormDate"*/}
                                            {/*            value={autoFormDate}*/}
                                            {/*            onChange={(date) => setAutoFormDate(date)}*/}
                                            {/*            inputRef={register}*/}
                                            {/*            disabled={true}*/}
                                            {/*        />*/}
                                            {/*        {errors.autoFormDate && !autoFormDate && (<span className="text-danger">กรุณากรอกวันที่</span>)}*/}
                                            {/*    </Col>*/}
                                            {/*</Row>*/}
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">เรื่อง</span>
                                                </Col>
                                                <Col>
                                                    <Form.Control type="text" name="subject" ref={register} disabled/>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <Form.Control type="text" name="prefixInform" ref={register} disabled/>
                                                </Col>
                                                <Col>
                                                    <Form.Control type="text" name="inform" ref={register} disabled/>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">คำลงท้าย</span>
                                                </Col>
                                                <Col>
                                                    <Form.Control type="text" name="postScript" ref={register} disabled/>
                                                </Col>
                                            </Row>
                                            {showSelectDepartment && (
                                                <>
                                                    <Row>
                                                        <Col sm={12} md={2}>

                                                        </Col>
                                                        <Col>
                                                            <div>
                                                                {departmentNameList.map((data, index) => {
                                                                    return <p className='mb-0' key={data}>- {data}</p>
                                                                })}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </>

                                    )}
                                    {location.state && location.state.formId === 17 && (
                                        <>
                                            {/*<Row className="mb-3">*/}
                                            {/*    <Col sm={12} md={2}>*/}
                                            {/*        <span className="text-color-form align-middle">ที่</span>*/}
                                            {/*    </Col>*/}
                                            {/*    <Col sm={12} md={4}>*/}
                                            {/*        <Form.Control type="text" name="autoFormNo" ref={register}/>*/}
                                            {/*    </Col>*/}
                                            {/*    <Col sm={12} md={2}>*/}
                                            {/*        <span className="text-color-form align-middle">ลงวันที่</span>*/}
                                            {/*        /!*<span className="text-danger"> *</span>*!/*/}
                                            {/*    </Col>*/}
                                            {/*    <Col sm={12} md={4}>*/}
                                            {/*        <DateInput*/}
                                            {/*            name="autoFormDate"*/}
                                            {/*            value={autoFormDate}*/}
                                            {/*            onChange={(date) => setAutoFormDate(date)}*/}
                                            {/*            inputRef={register}*/}
                                            {/*            disabled={true}*/}
                                            {/*        />*/}
                                            {/*        {errors.autoFormDate && !autoFormDate && (<span className="text-danger">กรุณากรอกวันที่</span>)}*/}
                                            {/*    </Col>*/}
                                            {/*</Row>*/}
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">เรื่อง</span>
                                                </Col>
                                                <Col>
                                                    <Form.Control type="text" name="subject" ref={register} disabled/>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">ถึง</span>
                                                </Col>
                                                <Col>
                                                    <Form.Control type="text" name="inform" ref={register} disabled/>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                    {location.state && location.state.autoFormGenre === "OUT" && (
                                        <>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">อ้างถึง</span>
                                                </Col>
                                                <Col sm={12} md={10}>
                                                    {referenceList.map((item, index) => (
                                                        <div key={index}>
                                                            <Row className="mb-1">
                                                                <Col>
                                                                    <Form.Control
                                                                        type="text"
                                                                        defaultValue={item.referenceId}
                                                                        hidden
                                                                    />
                                                                    <InputGroup className="mb-3">
                                                                        <Form.Control
                                                                            type="text"
                                                                            value={item.detail}
                                                                            onChange={(e) => {
                                                                                let dataList = referenceList.map((item, i) => i === index ? {
                                                                                    ...item,
                                                                                    detail: e.target.value
                                                                                } : item);
                                                                                setReferenceList(dataList);
                                                                            }}
                                                                            disabled
                                                                        />
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    ))}
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">สิ่งที่ส่งมาด้วย</span>
                                                </Col>
                                                <Col sm={12} md={10}>
                                                    {attachList.map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <Row className="mb-3">
                                                                    <Col sm={12} md={7}>
                                                                        <Form.Control
                                                                            type="text"
                                                                            defaultValue={item.attachId}
                                                                            hidden
                                                                        />
                                                                        <InputGroup>
                                                                            <InputGroup.Prepend>
                                                                                <InputGroup.Text>{index + 1}.</InputGroup.Text>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control
                                                                                type="text"
                                                                                value={item.detail}
                                                                                onChange={(e) => {
                                                                                    let detailDataList = attachList.map((item, i) => i === index ? {
                                                                                        ...item,
                                                                                        detail: e.target.value
                                                                                    } : item);
                                                                                    setAttachList(detailDataList);
                                                                                }}
                                                                                disabled
                                                                            />
                                                                        </InputGroup>
                                                                    </Col>
                                                                    <Col sm={12} md={2}>
                                                                        <InputGroup>
                                                                            <InputGroup.Prepend>
                                                                                <InputGroup.Text>จำนวน</InputGroup.Text>
                                                                            </InputGroup.Prepend>
                                                                            <Form.Control
                                                                                type="text"
                                                                                value={item.qty}
                                                                                onChange={(e) => {
                                                                                    let qtyDataList = attachList.map((item, i) => i === index ? {
                                                                                        ...item,
                                                                                        qty: e.target.value
                                                                                    } : item);
                                                                                    setAttachList(qtyDataList);
                                                                                }}
                                                                                disabled
                                                                            />
                                                                        </InputGroup>
                                                                    </Col>
                                                                    <Col sm={12} md={2}>
                                                                        <select
                                                                            className="form-control shadow-none"
                                                                            value={item.unit}
                                                                            onChange={(e) => {
                                                                                let unitDataList = attachList.map((item, i) => i === index ? {
                                                                                    ...item,
                                                                                    unit: e.target.value
                                                                                } : item);
                                                                                setAttachList(unitDataList);
                                                                            }}
                                                                            disabled
                                                                        >
                                                                            {unit.map((item) => {
                                                                                return (
                                                                                    <option
                                                                                        key={item.id}
                                                                                        value={item.id}>
                                                                                        {item.name}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </select>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        );
                                                    })}
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Card.Body>

                                <Card.Footer className="form-auto-header">ส่วนข้อความ</Card.Footer>
                                <Card.Body>
                                    <FroalaEditor model={content} onModelChange={setContent} readOnly={true}/>
                                </Card.Body>

                                {((location.state && location.state.autoFormGenre !== "OUT") && (location.state && location.state.formId !== 17)) && (
                                    <>
                                        <Card.Footer className="form-auto-header">ส่วนเสริม</Card.Footer>
                                        <Card.Body>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">ชั้นความเร็ว</span>
                                                </Col>
                                                <Col sm={12} md={4}>
                                                    <select className="form-control shadow-none" name="urgencyLevel" ref={register} disabled={urgencyLevelCheck}>
                                                        {urgencyLevel.map((item) => {
                                                            return (
                                                                <option
                                                                    key={item.id}
                                                                    value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">คำสั่งการ</span>
                                                    {/*<span className="text-danger"> *</span>*/}
                                                </Col>
                                                <Col>
                                                    <Form.Control as="textarea" rows={3} name="command" ref={register} disabled/>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">วันแจ้งเตือน</span>
                                                </Col>
                                                <Col sm={12} md={4}>
                                                    <DateInput
                                                        name="autoFormDueDate"
                                                        value={autoFormDueDate}
                                                        onChange={(date) => setAutoFormDueDate(date)}
                                                        inputRef={register}
                                                        disabled={true}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </>
                                )}

                                {((location.state && location.state.autoFormGenre === "OUT") || (location.state && location.state.formId === 17)) && (
                                    <>
                                        <Card.Footer className="form-auto-header">ข้อมูลส่วนที่ 4</Card.Footer>
                                        <Card.Body>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">ส่วนราชการเจ้าของเรื่อง</span>
                                                </Col>
                                                <Col>
                                                    <Form.Control type="text" name="governmentFt" ref={register} disabled/>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">โทร</span>
                                                </Col>
                                                <Col sm={12} md={4}>
                                                    <Form.Control type="text" name="telFt" ref={register} disabled/>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">โทรสาร</span>
                                                </Col>
                                                <Col sm={12} md={4}>
                                                    <Form.Control type="text" name="faxFt" ref={register} disabled/>
                                                </Col>
                                            </Row>
                                            {location.state && location.state.formId !== 4 && (
                                                <Row className="mb-3">
                                                    <Col sm={12} md={2}>
                                                        <span className="text-color-form align-middle">ไปรษณีย์อิเล็กทรอนิกส์</span><br/>
                                                        <span className="text-color-form align-middle">(ถ้ามี)</span>
                                                    </Col>
                                                    <Col sm={12} md={4}>
                                                        <Form.Control type="text" name="emailFt" ref={register} disabled/>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Card.Body>

                                        <Card.Footer className="form-auto-header">ส่วนเสริม</Card.Footer>
                                        <Card.Body>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">ชั้นความเร็ว</span>
                                                </Col>
                                                <Col sm={12} md={4}>
                                                    <select className="form-control shadow-none" name="urgencyLevel" ref={register} disabled={urgencyLevelCheck}>
                                                        {urgencyLevel.map((item) => {
                                                            return (
                                                                <option
                                                                    key={item.id}
                                                                    value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">คำสั่งการ</span>
                                                    {/*<span className="text-danger"> *</span>*/}
                                                </Col>
                                                <Col>
                                                    <Form.Control as="textarea" rows={3} name="command" ref={register} disabled/>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col sm={12} md={2}>
                                                    <span className="text-color-form align-middle">วันแจ้งเตือน</span>
                                                </Col>
                                                <Col sm={12} md={4}>
                                                    <DateInput
                                                        name="autoFormDueDate"
                                                        value={autoFormDueDate}
                                                        onChange={(date) => setAutoFormDueDate(date)}
                                                        inputRef={register}
                                                        disabled={true}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </>
                                )}

                                <Card.Footer className="form-auto-header">แนบไฟล์</Card.Footer>
                                <Card.Body>
                                    {getPdfReferSelectList().length > 0 && (
                                        <Row>
                                            <Col>
                                                <span className="text-color-form align-middle">
                                                    เอกสารลายเซ็นอิเล็กทรอนิกส์ {getPdfReferSelectList() ? getPdfReferSelectList().length : 0} ไฟล์
                                                </span>
                                            </Col>
                                        </Row>
                                    )}
                                    {getPdfReferSelectList().map((pdfRefer, pdfReferIndex) => {
                                        return (
                                            <Row key={pdfReferIndex}>
                                                <Col md={2} className="d-none d-sm-block">
                                                    <span className="text-color-form align-middle"></span>
                                                </Col>
                                                <Col md={10} className="text-normal">
                                                    <DownloadFile
                                                        filename={pdfRefer.filename}
                                                        realfilename={pdfReferIndex + 1 + '. ' + pdfRefer.realFilename}
                                                    />
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                    <Row className="mt-3 mb-3">
                                        <Col>
                                            <UploadFiles fileList={fileList} setFileList={setFileList}/>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <div>
                                <Button
                                    type="submit"
                                    className="btn-color-add-small mr-2"
                                >
                                    <i className="far fa-save pr-2"></i>บันทึกร่าง
                                </Button>
                                <Button
                                    type="button"
                                    className="btn-color-delete-small"
                                    onClick={() => history.goBack()}
                                >
                                    <i className="fas fa-ban pr-2"></i>ยกเลิก
                                </Button>
                            </div>
                        </Form>

                        <div className="p-4"/>
                    </Container>
                </section>
            </div>

            <PdfSelectionViewModal onAccept={handleAttachFileAccept} ref={pdfSelectionViewModalRef}/>
        </div>
    );
};
