import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import isRole from "./util/isRole";

const GenReportMenu = ({title, path}) => {
    return (
        <div className="col-12 col-sm-12 col-md-4 p-2">
            <Link
                to={path}
                className="btn btn-ddc-menu-small btn-block elevation-3"
            >
                <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                        <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                        {title}
                    </div>
                </div>
            </Link>
        </div>
    );
}

const Report = () => {
    return (
        <div>
            <div className="content-wrapper" style={{minHeight: '100vh'}}>
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>

                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">รายงาน</h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            รายงาน
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <GenReportMenu title="รายงานสรุปการ รับ-ส่ง หนังสือ" path="./report-summary-by-month"/>
                            {isRole(['secret-doc',]) && (
                                <GenReportMenu title="รายงานสรุปการ รับ-ส่ง หนังสือ (ลับ)" path="./report-secret-doc-summary-by-month"/>
                            )}
                            <GenReportMenu title="รายงานการรับเอกสาร" path="./rpt-1"/>
                            <GenReportMenu title="รายงานการส่งเอกสารภายในองค์กร" path="./rpt-2"/>
                            <GenReportMenu title="รายงานการส่งเอกสารเพื่อเซ็นต์รับ" path="./rpt-3"/>
                            <GenReportMenu title="รายงานการส่งออกนอกองค์กร" path="./rpt-4"/>
                            <GenReportMenu title="รายงานเลขทะเบียนส่งออก" path="./report-register-no"/>
                            <GenReportMenu title="รายงานจำนวนครั้งของการใช้งานระบบ" path="./report-chart"/>
                            <GenReportMenu title="รายงานหนังสือเพื่อทำลาย" path="./report-destruction"/>
                        </div>
                    </Container>
                </section>
            </div>
        </div>
    );
};

export default Report;
