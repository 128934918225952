import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { pdfjs } from "react-pdf";
import pdfJsWorker from "react-pdf/src/pdf.worker.entry";
import MyHeader from "./components/MyHeader";
import MyCustomPDFRenderer from "./components/MyCustomPDFRenderer";
import { useKeycloak } from "@react-keycloak/web";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker;

const DocPreview = forwardRef((
    {
        onAccept,
        labelSubmitSignature,
        iconSubmitSignature,
    }, ref) => {
    const [docsList, setDocsList] = useState([]);
    const [modalShow, setModalShow] = useState(false);

    const show = () => setModalShow(true);

    const close = () => {
        clear();
        setModalShow(false);
    };

    const clear = () => {
        setDocsList([]);
    };

    const docsPreviewData = (data) => {
        setDocsList(data);
    };

    const clearDataAndShowModal = () => {
        clear();
        show();
    };

    useImperativeHandle(ref, () => ({
        show,
        close,
        clear,
        docsPreviewData,
        clearDataAndShowModal,
    }));

    const MyLoadingRenderer = ({document, fileName}) => {
        const fileText = fileName || document?.fileType || "";

        if (fileText) {
            return <div className="preview-position">
                <i className="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span className="sr-only">Loading...</span>
            </div>;
        }

        return <div className="text-center">
            Loading Renderer...
        </div>;
    };

    const [keycloak] = useKeycloak();

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `bearer ${keycloak.token}`,
    };

    MyCustomPDFRenderer.fileTypes = ["pdf", "application/pdf"];
    MyCustomPDFRenderer.weight = 1;

    return (
        <>
            <Modal
                size="xl"
                show={modalShow}
                onHide={close}
                backdrop="static"
                keyboard={false}
                scrollable
            >
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="far fa-file-pdf mr-2"></i>ตัวอย่างเอกสาร
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <div className="row">
                            <div className="col">
                                <DocViewer
                                    style={{minHeight: 60 + 'vh'}}
                                    documents={docsList}
                                    pluginRenderers={[...DocViewerRenderers, MyCustomPDFRenderer]}
                                    prefetchMethod="GET"
                                    requestHeaders={headers}
                                    config={{
                                        header: {overrideComponent: MyHeader,},
                                        loadingRenderer: {
                                            overrideComponent: MyLoadingRenderer,
                                            showLoadingTimeout: false,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={close}
                    >
                        <i className="fas fa-ban mr-2"></i>ปิด
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
});

export default DocPreview;
