export const isOwnerCreateDocument = (doc_create, keycloak_user) => {
    let res = false;
    if (doc_create === keycloak_user) {
        res = true;
    }
    return res;
}

export const canSeeAttachmentFiles = (data_process, documentById) => {
    let display = 'none';
    if (data_process.senderId === documentById.toString() || data_process.receiverId === documentById.toString()) {
        display = '';
    }
    return display;
}