import React from 'react';
import { Nav } from "react-bootstrap";
import { Document, Page } from "react-pdf";

const MenuPdf = ({pdfList, previewPdf, onSelected}) => {
    const handleSelected = (eventKey) => {
        const index = eventKey;
        if (onSelected) onSelected(index, pdfList);
    }

    const generateMenu = () => {
        const result = [];
        pdfList.forEach(({base64Str, realFilename}, index) => {
            result.push(
                <Nav.Item key={index} className="text-center">
                    <Nav.Link eventKey={index}>
                        <Document file={base64Str}>
                            <Page
                                width={60}
                                pageNumber={1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        </Document>
                        <span style={{fontSize: "12px"}}><b>{realFilename.replaceAll("_", " ")}</b></span>
                    </Nav.Link>
                </Nav.Item>
            )
        })
        return result;
    }

    return (
        <Nav className="d-block"
             variant="pills"
             onSelect={selectedKey => handleSelected(selectedKey)}
             defaultActiveKey={previewPdf ? previewPdf.index : "0"}
        >
            <div className="sidebar-sticky"></div>
            {generateMenu()}
        </Nav>
    );
};

export default MenuPdf;
