import React, { useEffect, useState } from 'react';
import { Button, Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { mainApi } from "../HttpClient";
import Select from "react-select";
import $ from "jquery";
import config from "react-global-configuration";
import Swal from "sweetalert2";

const SpecialOrganization = () => {
    const [keycloak] = useKeycloak();
    const [comboboxOrg1Type1, setcomboboxOrg1Type1] = useState([]);
    const [comboboxOrg2, setcomboboxOrg2] = useState([]);
    const [organizeId, setOrganizeId] = useState('');
    const [organizeId2, setOrganizeId2] = useState('');
    const [addOrganizeData, setAddOrganizeData] = useState({});
    const [showModal, setShowModal] = useState(false);

    const initSetAddOrganize = (data) => {
        setAddOrganizeData({});
        setAddOrganizeData(data);
        setShowModal(true);
    };

    const fetchDataTableAddOrganizeList = () => {
        $('#data-table-add-special-organization').DataTable().ajax.reload();
    };

    // const fetchDataTable = () => {
    //     $('#data-table').DataTable().ajax.reload();
    // };

    const handleAddOrganizeSave = async () => {

        let mes_success = `บันทึกข้อมูลสำเร็จ`;
        let mes_error = `บันทึกไม่สำเร็จ`;

        let params = {
            employeeId: addOrganizeData.employeeId,
            orgId: organizeId2,
        };

        let rsSave = await mainApi.post('/api/special-organization/save', params);

        if (rsSave.data === 'ok') {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: mes_success,
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });
        } else if (rsSave.data === 'duplicate') {
            Swal.fire({
                icon: 'error',
                title: 'ข้อมูลบัญชีซ้ำ',
                text: 'หน่วยงานได้ทำการผูกข้อมูลอยู่แล้ว',
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: mes_error,
            });
        }
        fetchDataTableAddOrganizeList();
    };

    useEffect(() => {
        const fetchOrganizeId = async () => {
            const response = await mainApi.get('/api/employee/organization/1/' + keycloak.tokenParsed.preferred_username);
            setOrganizeId(response.data.orgId);
        };
        const getListOrg = async () => {
            const response = await mainApi.get('/api/organization/getOrgType1');
            setcomboboxOrg1Type1(response.data);
        };
        const getListOrgAll = async () => {
            const response = await mainApi.get('/api/organization/getOrgAll');
            setcomboboxOrg2(response.data);
        };

        const clearFormDataAddOrganization = () => {
            setcomboboxOrg1Type1([]);
        };

        const initDataTable = async () => {
            $.fn.dataTable.ext.errMode = 'none';
            $('#data-table').DataTable({
                order: [0, 'asc'],
                ajax: {
                    contentType: 'application/json',
                    url: config.get('apiUrl') + ('/api/special-organization/data-table'),
                    type: 'POST',
                    data: function (d) {
                        return JSON.stringify(d);
                    },
                    headers: {
                        Authorization: `bearer ${keycloak.token}`,
                    },
                },
                processing: true,
                serverSide: true,
                mark: true,
                columns: [
                    {
                        width: '50px', data: '', className: 'text-center',
                        render: function (data, type, row, meta) {
                            return meta.row + meta.settings._iDisplayStart + 1;
                        }
                    },
                    {
                        width: '50px',
                        data: 'idCard', className: 'text-center',
                    },
                    {
                        width: '100px',
                        data: 'fname', className: 'text-left'
                    },
                    {
                        width: '100px',
                        data: 'lname', className: 'text-left'
                    },
                    {
                        width: '100px',
                        data: 'orgName', className: 'text-left'
                    },
                    {
                        width: '50px',
                        className: 'text-center',
                        searchable: false,
                        orderable: false,
                        responsivePriority: 4,
                        targets: -3,
                        render: function (data, type, row) {
                            return `
                            <div>
                                <button type="button" class="btn btn-secondary btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-h"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <button class="dropdown-item btn-edit-document" type="button""><i class="fas fa-users-cog mr-2"></i>จัดการบัญชีผู้ใช้</button>
                                </div>
                            </div>
                        `;
                        },
                    },
                ],
                rowCallback: function (row, data) {
                    $('td', row).find('.btn-edit-document').on('click', function (e) {
                        // showEditModal(data);
                        initSetAddOrganize(data);
                    });
                },
                lengthChange: true,
                ordering: true,
                info: true,
                autoWidth: false,
                responsive: true,
                language: {
                    search: '_INPUT_',
                    searchPlaceholder: 'ค้นหา...',
                    lengthMenu: '_MENU_ รายการ/หน้า',
                    sLengthMenu: '_MENU_ รายการ/หน้า',
                    sProcessing: 'กำลังดำเนินการ...',
                    sZeroRecords: 'ไม่พบข้อมูล',
                    sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
                    sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
                    sInfoFiltered: '',
                    sSearch: '',
                    sUrl: '',
                    oPaginate: {
                        sFirst: 'หน้าแรก',
                        sPrevious: 'ก่อนหน้า',
                        sNext: 'ถัดไป',
                        sLast: 'หน้าสุดท้าย',
                    },
                    processing:
                        '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
                },
            });
        };
        fetchOrganizeId();
        getListOrg();
        getListOrgAll();
        initDataTable();
        clearFormDataAddOrganization();

    }, [keycloak]);

    const handleClose = () => {
        setShowModal(false);
    };

    const handleDelete = async (employeeId, orgId) => {
        Swal.fire({
            title: 'คุณแน่ใจหรือไม่',
            text: 'ต้องการลบบัญชีใช่ไหม',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'ลบข้อมูล',
            cancelButtonText: 'ยกเลิก',
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteOrganization(employeeId, orgId);
            }
        });
    };

    const DeleteOrganization = async (employeeId, orgId) => {
        const response = await mainApi.delete(
            '/api/special-organization/delete/' + employeeId + '/' + orgId
        );
        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'ลบข้อมูลบัญชีสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'ลบไม่สำเร็จ กรุณาลองใหม่ภายหลัง',
            });
        }
        fetchDataTableAddOrganizeList();
    };

    const handleSearch = () => {
        $('#data-table').DataTable().ajax.url(config.get('apiUrl') + '/api/special-organization/data-table?orgId=' + organizeId).load();
    };

    const loadDataTableAddOrganization = async () => {
        $('#data-table-add-special-organization').DataTable({
            order: [1, 'asc'],
            ajax: {
                contentType: 'application/json',
                url:
                    config.get('apiUrl') +
                    '/api/special-organization/list/data-table?employeeId=' +
                    addOrganizeData.employeeId,
                type: 'POST',
                data: function (d) {
                    return JSON.stringify(d);
                },
                headers: {
                    Authorization: `bearer ${keycloak.token}`,
                },
            },
            processing: true,
            serverSide: true,
            columnDefs: [{width: '100px'}],
            columns: [
                {
                    data: 'orgName',
                    className: 'text-center',
                    width: '20%',
                },
                {
                    className: 'text-center',
                    searchable: false,
                    orderable: false,
                    responsivePriority: 3,
                    targets: -2,
                    width: '15%',
                    render: function (data, type, row) {
                        return `
                      <button class="dropdown-item btn-delete-user text-danger" type="button"><i class="fas fa-times-circle mr-2"></i>ลบข้อมูล</button>
                   `;
                    },
                },
            ],
            rowCallback: function (row, data) {
                $('td', row)
                    .find('.btn-delete-user')
                    .on('click', function (e) {
                        handleDelete(data.employeeId, data.orgId);
                    });
            },
            lengthChange: true,
            ordering: true,
            info: true,
            responsive: true,
            dom:
                '<"row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>><"datatable-wrap"t><"row"<"col-sm-12 col-md-5"i><"col-sm-12 col-md-7"p>>',
            language: {
                search: '_INPUT_',
                searchPlaceholder: 'ค้นหา...',
                lengthMenu: '_MENU_ รายการ/หน้า',
                sLengthMenu: '_MENU_ รายการ/หน้า',
                sProcessing: 'กำลังดำเนินการ...',
                sZeroRecords: 'ไม่พบข้อมูล',
                sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
                sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
                sInfoFiltered: '',
                sSearch: '',
                sUrl: '',
                oPaginate: {
                    sFirst: 'หน้าแรก',
                    sPrevious: 'ก่อนหน้า',
                    sNext: 'ถัดไป',
                    sLast: 'หน้าสุดท้าย',
                },
            },
        });
    };

    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        ผูกหน่วยงานพิเศษกับผู้ใช้งาน
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            ผูกหน่วยงานพิเศษกับผู้ใช้งาน
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-wrapper text-normal">
                                        <div className="row">
                                            <div className="col-12 col-md-1 pt-2">
                                                <span className="text-color-form align-middle">
                                                    หน่วยงาน :
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-9 pt-2">
                                                <Select
                                                    options={comboboxOrg1Type1}
                                                    onChange={(ValueType) => setOrganizeId(ValueType.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-2 offset-1 pt-3">
                                                <Button className="btn btn-success btn-md"
                                                        type="button"
                                                        onClick={handleSearch}>
                                                    <i className="fas fa-search pr-2"></i>
                                                    ค้นหา
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-wrapper text-normal">
                                        <table
                                            id="data-table"
                                            className="table table-striped table-bordered display w-100 margin-bottom-30">
                                            <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">รหัสบัตรประชาชน</th>
                                                <th className="text-center">ชื่อ</th>
                                                <th className="text-center">นามสกุล</th>
                                                <th className="text-center">หน่วยงาน</th>
                                                <th className="text-center">จ้ดการ</th>
                                            </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
            <Modal
                show={showModal}
                onHide={handleClose}
                size="lg"
                onEntered={() => {
                    loadDataTableAddOrganization();
                }}
            >
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-users-cog mr-2"></i>จัดการบัญชีผู้ใช้
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row mt-3">
                                <div className="col">
                                    <div className="form-group row">
                                        <div className="col-12 col-md-4 pt-2">
                                            <span className="text-color-form align-middle">รหัสบัตรประชาชน</span>
                                            {/* <span className="text-danger"> *</span> */}
                                        </div>
                                        <div className="col-12 col-md-8 pt-1">
                                            <input
                                                className="form-control shadow-none"
                                                type="text"
                                                name="add_user_employeeId"
                                                defaultValue={addOrganizeData.idCard}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12 col-md-4 pt-2">
                                            <span className="text-color-form align-middle">ชื่อ</span>
                                            {/* <span className="text-danger"> *</span> */}
                                        </div>
                                        <div className="col-12 col-md-8 pt-1">
                                            <input
                                                className="form-control shadow-none"
                                                type="text"
                                                name="add_user_fname"
                                                defaultValue={addOrganizeData.fname}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12 col-md-4 pt-2">
                                            <span className="text-color-form align-middle">นามสกุล</span>
                                            {/* <span className="text-danger"> *</span> */}
                                        </div>
                                        <div className="col-12 col-md-8 pt-1">
                                            <input
                                                className="form-control shadow-none"
                                                type="text"
                                                name="add_user_lname"
                                                defaultValue={addOrganizeData.lname}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12 col-md-4 pt-2">
                                            <span className="text-color-form align-middle">หน่วยงาน</span>
                                            {/* <span className="text-danger"> *</span> */}
                                        </div>
                                        <div className="col-12 col-md-8 pt-1">
                                            <input
                                                className="form-control shadow-none"
                                                type="text"
                                                name="add_user_position"
                                                defaultValue={addOrganizeData.orgName}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12 col-md-4 pt-2">
                                            <span className="text-color-form align-middle">เลือกหน่วยงานพิเศษ</span>
                                            <span className="text-danger"> *</span>
                                        </div>
                                        <div className="col-12 col-md-8 pt-1">
                                            <Select
                                                options={comboboxOrg2}
                                                onChange={(ValueType) => setOrganizeId2(ValueType.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center mb-2">
                                    <Button
                                        type="button"
                                        className="btn-color-submit mr-2"
                                        onClick={handleAddOrganizeSave}
                                    >
                                        <i className="fas fa-save pr-2"></i>
                                        เพิ่มหน่วยงานพิเศษ
                                    </Button>
                                </div>
                            </div>
                        </form>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mt-2">
                                    <div className="table-header-masexpert card-header text-dark">
                                        ข้อมูลหน่วยงานพิเศษ
                                    </div>
                                    <div className="card-body">
                                        <table
                                            id="data-table-add-special-organization"
                                            className="table table-striped table-bordered display w-100 margin-bottom-30"
                                        >
                                            <thead>
                                            <tr>
                                                <th className="text-center">หน่วยงานพิเศษ</th>
                                                <th className="text-center">จัดการ</th>
                                            </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={handleClose}
                    >
                        <i className="fas fa-ban pr-2"></i>
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

};

export default SpecialOrganization;