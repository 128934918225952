import { createSlice } from '@reduxjs/toolkit'

export const rptSecretDocSummaryByMonthSlice = createSlice({
    name: 'rptSecretDocSummaryByMonth',
    initialState: {
        sMonth: "",
        sYear: "",
        sListSummary: [],
    },
    reducers: {
        setSelectMonth: (state, action) => {
            state.sMonth = action.payload
        },
        setSelectYear: (state, action) => {
            state.sYear = action.payload
        },
        setListSummary: (state, action) => {
            state.sListSummary = action.payload
        },

    },
})

// Action creators are generated for each case reducer function
export const {setSelectMonth, setSelectYear, setListSummary} = rptSecretDocSummaryByMonthSlice.actions
export const sMonth = state => state.rptSecretDocSummaryByMonth.sMonth;
export const sYear = state => state.rptSecretDocSummaryByMonth.sYear;
export const sListSummary = state => state.rptSecretDocSummaryByMonth.sListSummary;
export default rptSecretDocSummaryByMonthSlice.reducer