import { Button, Container } from "react-bootstrap";
import React from "react";


const MyHeader: IHeaderOverride = (state, previousDocument, nextDocument) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
        return null;
    }

    return (
        <>
            <Container className="pdf-sign-viewer mb-3 ">
                <div className="row d-block d-lg-flex justify-content-between ">
                    <div className="col">
                    </div>
                    <div className=" col col-lg-6 text-center">
                        <h5><b>{state.currentDocument.fileName || ""}</b></h5>
                    </div>
                    <div className="col text-center">
                        {state.documents.length > 1 && (
                            <div>
                                <Button type="button" onClick={previousDocument} disabled={state.currentFileNo === 0}>‹</Button>
                                <span className="m-2">ไฟล์ {state.currentFileNo + 1 || (state.currentFileNo + 1 ? 1 : "--")} / {state.documents.length || "--"}</span>
                                <Button type="button" onClick={nextDocument} disabled={state.currentFileNo >= state.documents.length - 1}>›</Button>
                            </div>
                        )}
                    </div>
                </div>
                <hr/>
            </Container>
        </>
    );
};

export default MyHeader;