/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { Button, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useKeycloak } from '@react-keycloak/web';
import { mainApi, hrApi } from '../../HttpClient';
import config from 'react-global-configuration';
import moment from 'moment';
import Swal from 'sweetalert2';

import UploadFiles from '../share/UploadFiles';
import OrgEmpList from '../share/OrgEmpList';
import fileDownload from 'js-file-download';

const Pre6 = () => {
  let history = useHistory();
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [meetingStartDate, setMeetingStartDate] = useState(new Date());
  const [meetingEndDate, setMeetingEndDate] = useState(new Date());
  const [meetingStartTime, setMeetingStartTime] = useState(
    new Date().setHours(0, 0, 0, 0)
  );
  const [meetingEndTime, setMeetingEndTime] = useState(
    new Date().setHours(0, 0, 0, 0)
  );
  const [trainingStartDate, setTrainingStartDate] = useState(new Date());
  const [trainingEndDate, setTrainingEndDate] = useState(new Date());
  const [trainingStartTime, setTrainingStartTime] = useState(
    new Date().setHours(0, 0, 0, 0)
  );
  const [trainingEndTime, setTrainingEndTime] = useState(
    new Date().setHours(0, 0, 0, 0)
  );
  const { register, errors, handleSubmit, reset, setValue } = useForm();
  const [keycloak] = useKeycloak();
  const [fileList, setFileList] = useState([]);
  const [meetingRoomList, setMeetingRoomList] = useState([]);
  const orgEmpListRef = useRef();
  const [isPlaceOther, setIsPlaceOther] = useState(false);
  const [userLogin, setUserLogin] = useState({});

  useEffect(() => {
    const fetchRegisterName = async () => {
      const response = await mainApi.get(
        '/api/v1/documents-register/register-name'
      );
      setValue('registerNo', response.data);
    };
    fetchRegisterName();
  }, []);

  useEffect(() => {
    const fetchMeetingRoom = async () => {
      const response = await mainApi.get('/api/v1/meeting-room');
      setMeetingRoomList(response.data);
    };

    fetchMeetingRoom();
  }, []);

  useEffect(() => {
    const getInfoLogin = async () => {
      let info = await hrApi.get(
        '/api/employee/' + keycloak.tokenParsed.preferred_username
      );

      if (info.status === 200) {
        setUserLogin(info.data.data);
      }
    };
    getInfoLogin();
  }, [keycloak]);

  useEffect(() => {
    const fetchWorkbu = async () => {
      let rsWorkbu = await hrApi.get('/api/master/org/1/1001');
      setWorkbu(rsWorkbu.data.data.records);
    };

    fetchWorkbu();
  }, []);

  const [workbu, setWorkbu] = useState([]);
  const [workbu2, setWorkbu2] = useState([]);
  const [workbu3, setWorkbu3] = useState([]);
  const [workbu4, setWorkbu4] = useState([]);
  const [workbu5, setWorkbu5] = useState([]);
  const [workbu6, setWorkbu6] = useState([]);
  const [empWorkBu1, setEmpWorkBu1] = useState('');
  const [empWorkBu2, setEmpWorkBu2] = useState('');
  const [empWorkBu3, setEmpWorkBu3] = useState('');
  const [empWorkBu4, setEmpWorkBu4] = useState('');
  const [empWorkBu5, setEmpWorkBu5] = useState('');
  const [empWorkBu6, setEmpWorkBu6] = useState('');
  const onChangeHandler = async (t, e) => {
    let v = e.target.value;
    if (v !== '') {
      let rsWorkbu = await hrApi.get('/api/master/org/1/' + v);
      if (t === '1') {
        setEmpWorkBu1(v);
        setWorkbu2(rsWorkbu.data.data.records);
      }
      if (t === '2') {
        setEmpWorkBu2(v);
        setWorkbu3(rsWorkbu.data.data.records);
      }
      if (t === '3') {
        setEmpWorkBu3(v);
        setWorkbu4(rsWorkbu.data.data.records);
      }
      if (t === '4') {
        setEmpWorkBu4(v);
        setWorkbu5(rsWorkbu.data.data.records);
      }
      if (t === '5') {
        setEmpWorkBu5(v);
        setWorkbu6(rsWorkbu.data.data.records);
      }
      if (t === '6') {
        setEmpWorkBu6(v);
      }
    }

    if (t === '1') {
      setEmpWorkBu1(v);
    }
    if (t === '2') {
      setEmpWorkBu2(v);
    }
    if (t === '3') {
      setEmpWorkBu3(v);
    }
    if (t === '4') {
      setEmpWorkBu4(v);
    }
    if (t === '5') {
      setEmpWorkBu5(v);
    }
    if (t === '6') {
      setEmpWorkBu6(v);
    }
  };

  const onSubmit = async (data) => {
    let place = data.place === 'other' ? data.placeOther : data.place;
    let params = {
      preformId: data.preformId || '0',
      documentId: data.documentId || '0',
      organize: data.organize || '',
      organizeName: workbu.find(item => item.org_id == empWorkBu1).name || '',
      organizeGroup: data.organizeGroup || '',
      organizeGroupName:workbu2.find(item => item.org_id == empWorkBu2).name || '',
      telephone: data.telephone || '',
      fax: data.fax || '',
      email: data.email || '',
      meetingName: data.meetingName || '',
      meetingType: data.meetingType || '',
      meetingStartDate:
        moment(meetingStartDate).format('YYYY-MM-DD ') +
        moment(meetingStartTime).format('hh:mm:ss'),
      meetingEndDate:
        moment(meetingEndDate).format('YYYY-MM-DD ') +
        moment(meetingEndTime).format('hh:mm:ss'),
      ordinalNumbers: data.ordinalNumbers || '',
      place: place || '',
      lecturerName: data.lecturerName || '',
      lecturerPosition: data.lecturerPosition || '',
      skill: data.skill || '',
      subject: data.subject || '',
      purpose: data.purpose || '',
      trainingStartDate:
        moment(trainingStartDate).format('YYYY-MM-DD ') +
        moment(trainingStartTime).format('hh:mm:ss'),
      trainingEndDate:
        moment(trainingEndDate).format('YYYY-MM-DD ') +
        moment(trainingEndTime).format('hh:mm:ss'),
      coordinator: data.coordinator || '',
      mobile: data.mobile || '',
      remark: data.remark || '',
      remark2: '',
      remark3: '',
      scheduleForm: data.scheduleForm || '',
      answerForm: data.answerForm || '',
    };

    let document = {
      documentId: data.documentId || '0',
      documentType: 'S',
      documentGenre: 'IN',
      registerNo: data.registerNo || '',
      documentDate: moment(startDate).format('YYYY-MM-DD'),
      subject: data.documentSubject || '',
      senderId: keycloak.tokenParsed.preferred_username,
      sender: userLogin.fname + ' ' + userLogin.lname,
      receiverId: data.receiverId || '0',
      receiver: data.receiver || '',
      owner: data.owner || '',
      refer: data.refer || '',
      remark: data.remark || '',
      command: data.command || '',
      status: 1,
      formId: 6,
      insideOrg: getSentList(),
    };

    const dataSend = new FormData();
    fileList.forEach((file) => {
      dataSend.append('files', file);
    });
    dataSend.append(
      'documentPreform',
      new Blob([JSON.stringify(params)], {
        type: 'application/json',
      })
    );
    dataSend.append(
      'document',
      new Blob([JSON.stringify(document)], {
        type: 'application/json',
      })
    );

    let rsSave = await mainApi.post('/api/v4/document-preform', dataSend, { responseType: 'blob' });

    if (rsSave.status === 200) {
      // setValue('preformId', rsSave.data.documentPreform.preformId);
      // setValue('documentId', rsSave.data.document.document.documentId);
      // setValue('registerNo', rsSave.data.document.document.registerNo);
      // Swal.fire({
      //   icon: 'success',
      //   title: 'ส่งหนังสือสำเร็จ',
      //   text: 'เลขที่เอกสาร : ' + rsSave.data.document.document.registerNo,
      //   timer: config.get('alertTimer'),
      //   timerProgressBar: true,
      // }).then((result) => {
      //   if (
      //     result.dismiss === Swal.DismissReason.timer ||
      //     result.value === true
      //   ) {
      //     history.push('/outbox');
      //   }
      // });
      fileDownload(rsSave.data, 'Preform' + document.formId + '.docx');
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ผิดพลาด',
        text: 'ส่งหนังสือไม่สำเร็จ',
      });
    }
  };

  const getSentList = () => {
    if (orgEmpListRef.current.selectList.length > 0) {
      return orgEmpListRef.current.selectList;
    } else {
      return orgEmpListRef.current.orgSelectList;
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="content-header">
              <div className="row">
                <div className="col-sm-12">
                  <h1 className="text-light-dark page-heading mt-2">
                    ขอเชิญประชุม (ภายใน) - หน่วยงานเทียบเท่ากอง
                  </h1>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12">
                  <ol className="breadcrumb float-sm-left bg-transparent p-0">
                    <li className="breadcrumb-item text-breadcrumb">
                      <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                      <Link to="./" className="text-breadcrumb">
                        หน้าหลัก
                      </Link>
                    </li>
                    <li className="breadcrumb-item text-breadcrumb">
                      <Link to="./all-form" className="text-breadcrumb">
                        แบบฟอร์ม
                      </Link>
                    </li>
                    <li className="breadcrumb-item text-breadcrumb-active">
                      ขอเชิญประชุม (ภายใน) - หน่วยงานเทียบเท่ากอง
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="section-wrapper text-normal">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              สำนัก / กอง
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <select
                              className="form-control shadow-none"
                              name="organize"
                              ref={register}
                              onChange={(e) => onChangeHandler('1', e)}
                              value={empWorkBu1}
                            >
                              <option value="">กรุณาเลือก</option>
                              {workbu.map((item) => (
                                <option key={item.org_id} value={item.org_id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              กลุ่ม
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <select
                              className="form-control shadow-none"
                              name="organizeGroup"
                              ref={register}
                              onChange={(e) => onChangeHandler('2', e)}
                              value={empWorkBu2}
                            >
                              <option value="">กรุณาเลือก</option>
                              {workbu2.map((item) => (
                                <option key={item.org_id} value={item.org_id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              โทร
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <input
                              className="form-control shadow-none"
                              type="text"
                              name="telephone"
                              ref={register({
                                maxLength: {
                                  value: 50,
                                  message: 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร',
                                },
                              })}
                            />
                            {errors.telephone && (
                              <span className="text-danger">
                                {errors.telephone.message}
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              โทรสาร
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <input
                              className="form-control shadow-none"
                              type="text"
                              name="fax"
                              ref={register({
                                maxLength: {
                                  value: 50,
                                  message: 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร',
                                },
                              })}
                            />
                            {errors.fax && (
                              <span className="text-danger">
                                {errors.fax.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              ที่
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <input
                              className="form-control shadow-none d-none"
                              type="text"
                              name="preformId"
                              ref={register}
                            />
                            <input
                              className="form-control shadow-none d-none"
                              type="text"
                              name="documentId"
                              ref={register}
                            />
                            <input
                              className="form-control shadow-none"
                              type="text"
                              name="registerNo"
                              ref={register}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              ลงวันที่
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              className="form-control shadow-none"
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              ประเภทประชุม
                            </span>
                          </div>
                          <div className="form-check form-check-inline pt-2 pb-2 ml-3">
                            <input
                              type="radio"
                              name="meetingType"
                              className="form-check-input"
                              ref={register}
                              value="การประชุมราชการ"
                            />{' '}
                            การประชุมราชการ
                          </div>
                          <div className="form-check form-check-inline pt-2 pb-2 ml-3">
                            <input
                              type="radio"
                              name="meetingType"
                              className="form-check-input"
                              ref={register}
                              value="การประชุมเชิงปฎิบัติการ"
                            />{' '}
                            การประชุมเชิงปฎิบัติการ
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              เรื่อง
                            </span>
                            <span className="text-danger"> *</span>
                          </div>
                          <div className="col-12 col-md-7 pt-1">
                            <input
                              className="form-control shadow-none"
                              type="text"
                              name="documentSubject"
                              ref={register({
                                required: {
                                  value: true,
                                  message: 'กรุณากรอกข้อมูล',
                                },
                                maxLength: {
                                  value: 500,
                                  message: 'ข้อมูลต้องไม่เกิน 500 ตัวอักษร',
                                },
                              })}
                            />
                            {errors.documentSubject && (
                              <span className="text-danger">
                                {errors.documentSubject.message}
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              ครั้งที่
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <input
                              className="form-control shadow-none"
                              type="text"
                              name="ordinalNumbers"
                              ref={register}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              เรียน
                            </span>
                            <span className="text-danger"> *</span>
                          </div>
                          <div className="col-12 col-md-10 pt-1">
                            <input
                              className="form-control shadow-none"
                              type="text"
                              name="receiver"
                              ref={register({ required: true })}
                            />
                            {errors.receiver && (
                              <span className="text-danger">
                                กรุณากรอกข้อมูล
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              วัตถุประสงค์ของการประชุม
                            </span>
                          </div>
                          <div className="col-12 col-md-10 pt-1">
                            <textarea
                              className="form-control shadow-none"
                              rows="5"
                              name="purpose"
                              ref={register}
                            ></textarea>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              ประชุมตั้งแต่วันที่
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              selected={meetingStartDate}
                              onChange={(date) => setMeetingStartDate(date)}
                              className="form-control shadow-none"
                            />
                          </div>
                          <div className="col-12 col-md-1 pt-2 text-center">
                            <span className="text-color-form align-middle">
                              ถึง
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              selected={meetingEndDate}
                              onChange={(date) => setMeetingEndDate(date)}
                              className="form-control shadow-none"
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              เวลาตั้งแต่
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <DatePicker
                              selected={meetingStartTime}
                              onChange={(date) => setMeetingStartTime(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              timeFormat="HH:mm"
                              dateFormat="HH:mm"
                              className="form-control shadow-none"
                            />
                          </div>
                          <div className="col-12 col-md-1 pt-2 text-center">
                            <span className="text-color-form align-middle">
                              ถึง
                            </span>
                          </div>
                          <div className="col-12 col-md-3 pt-1">
                            <DatePicker
                              selected={meetingEndTime}
                              onChange={(date) => setMeetingEndTime(date)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              timeFormat="HH:mm"
                              dateFormat="HH:mm"
                              className="form-control shadow-none"
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              สถานที่การประชุม
                            </span>
                          </div>
                          <div className="col-12 col-md-10 pt-1">
                            <select
                              className="form-control shadow-none"
                              name="place"
                              ref={register}
                              onChange={(event) => {
                                if (event.target.value === 'other') {
                                  setIsPlaceOther(true);
                                } else {
                                  setIsPlaceOther(false);
                                }
                              }}
                            >
                              <option value="">กรุณาเลือก</option>
                              {meetingRoomList.map((meetingRoom) => {
                                return (
                                  <option
                                    key={meetingRoom.id}
                                    value={meetingRoom.name}
                                  >
                                    {meetingRoom.name}
                                  </option>
                                );
                              })}
                              <option value="other">อื่นๆ</option>
                            </select>
                          </div>
                        </div>
                        {isPlaceOther && (
                          <div className="row">
                            <div className="col-12 col-md-2 pt-2">
                              <span className="text-color-form align-middle">
                                สถานที่การประชุม (อื่นๆ)
                              </span>
                            </div>
                            <div className="col-12 col-md-10 pt-1">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                name="placeOther"
                                ref={register}
                              />
                            </div>
                          </div>
                        )}
                        <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2">
                            <span className="text-color-form align-middle">
                              หมายเหตุ
                            </span>
                          </div>
                          <div className="col-12 col-md-10 pt-1">
                            <textarea
                              className="form-control shadow-none"
                              rows="4"
                              name="remark"
                              ref={register}
                            ></textarea>
                          </div>
                        </div>
                        {/* <div className="row mt-3">
                          <div className="col-12 col-md-2 pt-2"></div>
                          <div className="col-12 col-md-10 pt-1">
                            <UploadFiles
                              fileList={fileList}
                              setFileList={setFileList}
                            />
                          </div>
                        </div> */}
                        <div className="col-12 mt-3">
                          <hr style={{ margin: '6px -10px 10px' }} />
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-3 mt-2">
                            {/* <Button
                              type="button"
                              variant="contained"
                              className="btn-no-border btn-color-submit btn-block"
                              onClick={() => orgEmpListRef.current.show()}
                            >
                              <i className="fas fa-paper-plane pr-2"></i>
                              ส่งหน่วยงานอื่น
                            </Button> */}
                            <Button
                              type="button"
                              variant="contained"
                              className="btn-no-border btn-color-submit btn-block"
                              onClick={handleSubmit(onSubmit)}
                            >
                              <i className="fas fa-paper-plane pr-2"></i>
                              พิมพ์เอกสาร
                            </Button>
                          </div>
                          <div className="col-sm-12 col-md-3 mt-2">
                            <Button
                              type="reset"
                              variant="contained"
                              className="btn-color-delete-small btn-block"
                              onClick={reset}
                            >
                              <i className="fas fa-ban pr-2"></i>
                              ยกเลิก
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
      <OrgEmpList onAccept={handleSubmit(onSubmit)} ref={orgEmpListRef} />
    </div>
  );
};

export default Pre6;
