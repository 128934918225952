/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Container, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { mainApi } from '../../HttpClient';
import fileDownload from 'js-file-download';
import { useSelector } from "react-redux";

const RptRegisterNo = () => {
    const [keycloak] = useKeycloak();
    const [isPrintLoading, setIsPrintLoading] = useState(false);
    const [registerNo, setRegisterNo] = useState({});

    const documentState = useSelector((state) => state.document);

    useEffect(() => {
        mainApi.post('/api/report/excel/01/preview', documentState.documentBy).then((res) => {
            setRegisterNo(res.data);
        });
    }, [documentState.documentBy]);

    const handlePrint = () => {
        setIsPrintLoading(true);
        mainApi.post('/api/report/excel/01', documentState.documentBy, {
            responseType: 'blob',
        }).then((res) => {
            fileDownload(res.data, 'รายงานเลขทะเบียนส่งออก.xlsx');
        }).finally(() => {
            setIsPrintLoading(false);
        });
    };

    return (
        <div>
            <div className="content-wrapper" style={{minHeight: '100vh'}}>
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        รายงานเลขทะเบียนส่งออก
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <Link to="./report" className="text-breadcrumb">
                                                รายงาน
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            รายงานเลขทะเบียนส่งออก
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            <th>ลำดับที่</th>
                                            <th>ประเภทเลขทะเบียนส่งออก</th>
                                            <th>หมายเลขปัจจุบัน</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>หนังสือรับ (ภายใน)</td>
                                            <td>{registerNo.registerNoRIn}</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>หนังสือรับ (ภายนอก)</td>
                                            <td>{registerNo.registerNoROut}</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>ออกเลขส่ง</td>
                                            <td>{registerNo.registerNoS}</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>ออกเลขเวียน</td>
                                            <td>{registerNo.registerNoW}</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>หนังสือรับ (ลับ)</td>
                                            <td>{registerNo.registerNoSecretR}</td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>หนังสือลับ (ลับ)</td>
                                            <td>{registerNo.registerNoSecretS}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    <div className="row">
                                        <div className="col-12">
                                            <Button
                                                type="button"
                                                className="btn-color-add-small "
                                                disabled={isPrintLoading}
                                                onClick={!isPrintLoading ? handlePrint : null}
                                            >
                                                <i className="fas fa-print pr-2"></i>
                                                ดาวน์โหลดเอกสาร
                                                {isPrintLoading && (
                                                    <div
                                                        className="spinner-border spinner-border-sm ml-2"
                                                        role="status"
                                                    >
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        </div>
    );
};

export default RptRegisterNo;
