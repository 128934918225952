import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { getDateFullMonthFormat } from "../../util/Date";

export default function templateHtmlFormAutoId17(data, type) {
    const result = [];
    const getData = (data1, data2) => {
        return type === "1" ? data1 : data2;
    };

    const pageMargin = {top: 15.0, right: 0.0, bottom: 15.0, left: 0.0};

    const header = (
        <html>
        <head>
            <title>{data.autoFormName || ''}</title>
            <link rel="stylesheet" href="assets/formAutoGenreOut.css"/>
        </head>
        <body>
        <div className="flex-container">
            <img
                className="img-garuda"
                src="assets/garuda_type_2.png"
                alt="Thai Government Garuda emblem (Version 3)"
            />
        </div>
        <div className="container">
            <div style={{marginTop: '-35px', marginBottom: '6pt'}}>
                ที่&nbsp;<span className="font-th-sarabun-it9">{getData(data.autoFormNo, data.f2AutoFormNo)}</span>
            </div>
            <div style={{marginBottom: '10mm'}}><span>ถึง</span>&nbsp;&nbsp;{getData(data.inform, data.f2Inform)}</div>
            <div className="document-content">
                ***replace-content***
            </div>
        </div>
        <div style={{textAlign: 'center', paddingLeft: '4em', marginTop: '30mm'}}>
            <div style={{marginBottom: '10px'}}>กรมควบคุมโรค</div>
            <div className="font-th-sarabun-it9">{getDateFullMonthFormat(getData(data.autoFormDate, data.f2AutoFormDate))}</div>
        </div>
        <div className="footer-container">
            <div>{getData(data.governmentFt, data.f2GovernmentFt)}</div>
            <div>โทร.&nbsp;&nbsp;{getData(data.telFt, data.f2TelFt)}</div>
            <div>โทรสาร.&nbsp;&nbsp;{getData(data.faxFt, data.f2FaxFt)}</div>
            {getData(data.emailFt, data.f2EmailFt) && (
                <div>ไปรษณีย์อิเล็กทรอนิกส์&nbsp;&nbsp;{getData(data.emailFt, data.f2EmailFt)}</div>
            )}
        </div>
        </body>
        </html>
    );

    let html = ReactDOMServer.renderToString(header);
    let content = getData(data.content, data.f2Content);
    html = html.replaceAll("***replace-content***", content);

    result.push({html, pageMargin});

    return result;
};
