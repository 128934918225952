/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Button, TextField } from "@material-ui/core";

const rpt1detail = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const history = useHistory();

  const rptdetail1 = () => {
    history.push("rpt-1-detail");
  };
  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div class="row mb-3 border-bottom">
              <div class="col-sm-6 d-none d-sm-done d-lg-block  ">
                <ol class="breadcrumb float-sm-left bg-transparent p-0">
                  <li class="breadcrumb-item text-breadcrumb">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 mt-1" />
                    <Link to="./" className="text-breadcrumb">
                      หน้าหลัก
                    </Link>
                  </li>
                  <li class="breadcrumb-item text-breadcrumb">รายงาน</li>
                  <li class="breadcrumb-item text-breadcrumb-active">
                    รายงานการรับเอกสาร (รายละเอียด)
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card text-normal box-radius">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <span className="text-header-x2 ddc-color">
                          รายงานการรับเอกสาร
                        </span>
                        <br /> <br />
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              หมวดเอกสาร
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <select class="form-control shadow-none">
                              <option></option>
                            </select>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              ลงวันที่
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              className="form-control shadow-none"
                            />
                          </div>
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              ถึงวันที่
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              className="form-control shadow-none"
                            />
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              จากหน่วยงาน
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <div className="row ">
                              <div className="col-8 ">
                                <input
                                  class="form-control shadow-none"
                                  type="text"
                                  readOnly
                                />
                              </div>
                              <div className="col-4">
                                <Button
                                  type="submit"
                                  variant="contained"
                                  size="large"
                                  className="btn-no-border btn-color-search-small"
                                >
                                  <i class="fas fa-search"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              ถึงหน่วยงาน
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <div className="row">
                              <div className="col-8 ">
                                <input
                                  class="form-control shadow-none"
                                  type="text"
                                  readOnly
                                />
                              </div>
                              <div className="col-4">
                                <Button
                                  type="submit"
                                  variant="contained"
                                  size="large"
                                  className="btn-no-border btn-color-search-small"
                                >
                                  <i class="fas fa-search"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              จาก
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <div className="row">
                              <div className="col-8 ">
                                <input
                                  class="form-control shadow-none"
                                  type="text"
                                  readOnly
                                />
                              </div>
                              <div className="col-4">
                                <Button
                                  type="submit"
                                  variant="contained"
                                  size="large"
                                  className="btn-no-border btn-color-search-small"
                                >
                                  <i class="fas fa-search"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                        
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              ถึง
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <div className="row">
                              <div className="col-8 ">
                                <input
                                  class="form-control shadow-none"
                                  type="text"
                                  readOnly
                                />
                              </div>
                              <div className="col-4">
                                <Button
                                  type="submit"
                                  variant="contained"
                                  size="large"
                                  className="btn-no-border btn-color-search-small"
                                >
                                  <i class="fas fa-search"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                        
                        </div>
                        <div className="row ">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              เลขที่
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <input
                                  class="form-control shadow-none"
                                  type="text"
                                  readOnly
                                />
                          </div>
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              -
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                          <input
                                  class="form-control shadow-none"
                                  type="text"
                                  readOnly
                                />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 col-md-1 pt-2">
                            <span className="text-color-form align-middle">
                              ชั้นความเร็ว
                            </span>
                          </div>
                          <div className="col-12 col-md-2 pt-1">
                            <select class="form-control shadow-none">
                              <option></option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <hr style={{ margin: "6px" }} />
                    <br />
                    <div className="row">
                      <div className="col-12">
                        <table className="table table-head-fixed">
                          <thead>
                            <tr className="table-ddc">
                              <th className="table-ddc">เลขรับ</th>
                              <th className="table-ddc">ที่</th>
                              <th className="table-ddc">ลงวันที่</th>
                              <th className="table-ddc d-none d-sm-table-cell">
                                จาก
                              </th>
                              <th className="table-ddc d-none d-sm-table-cell">
                                ถึง
                              </th>
                              <th className="table-ddc d-none d-sm-table-cell">
                                เรื่อง
                              </th>
                              <th className="table-ddc d-none d-sm-table-cell">
                                การปฎิบัติ
                              </th>
                              <th className="table-ddc">หมายเหตุ</th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default rpt1detail;
