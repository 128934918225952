import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { fabric } from "fabric";
import { pdfjs } from "react-pdf";
import pdfJsWorker from "react-pdf/src/pdf.worker.entry";
import { Button, Container } from 'react-bootstrap';
import { Document, Page } from "react-pdf";
import config from "react-global-configuration";
import Swal from "sweetalert2";
import { getDateThaiNow, getTimeThaiNow } from "../../../util/Date";
import redStamp from "./assets/red-stamp.png";

fabric.DPI = 300;
pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker;

const HideControls = {
    'tl': true,
    'tr': true,
    'bl': true,
    'br': true,
    'ml': false,
    'mt': false,
    'mr': false,
    'mb': false,
    'mtr': false
};

export
interface
PdfListProps
{
    attachmentId: number;
    base64Str: string;
    fileName: string;
    realFilename: string;
}

export
interface
PreSignProps
{
    pdf: string;
    pdfList: PdfListProps[];
    index: number;
    realFilename: string;
    children: React.ReactNode;
}

const SignPdf = forwardRef((props: PreSignProps, ref) => {
    const userLogin = useSelector((state) => state.userLogin);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [page, setPage] = useState({width: 0, height: 0});

    const [allCanvas, setAllCanvas] = useState({});
    const [pdfSignList, setPdfSignList] = useState([]);

    const clear = () => {
        setPdfSignList([]);
    };

    useImperativeHandle(ref, () => ({
        clear,
        pdfSignList,
        handleAddSignature,
        handleAddText,
        handleAddTextStampWithSignature,
        handleAddReceiveTextBox,
        handleSaveSignature,
        handleDeleteActiveObject,
        handleAddRedStamp,
        handleAddImageQR,
        handleDeleteRegisterNo,
        handleDeleteDocumentDate,
    }));

    useEffect(() => {
        setPageNumber(1);
    }, [props.pdf]);

    const onDocumentLoadSuccess = (pdf) => {
        setNumPages(pdf.numPages);
        // setPageNumber(1);
    }

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    const onPageLoadSuccess = async (pdf) => {
        const pdfSource = await pdfjs.getDocument(props.pdf).promise;
        const page = await pdfSource.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 1.0 });
        const pdfWidth = viewport.width;
        const pdfHeight = viewport.height;

        const id = `signature-${pageNumber}`;

        const canvasOption = {
            width: pdfWidth,
            height: pdfHeight,
        }

        if (!allCanvas[props.index] || !allCanvas[props.index][pageNumber]) {
            const canvas = new fabric.Canvas(id, canvasOption);

            // canvas.on('selection:created', function (e) {
            //     console.log(e);
            // });

            setAllCanvas({
                ...allCanvas,
                [props.index]: {
                    ...allCanvas[props.index],
                    "pages": numPages,
                    [pageNumber]: canvas
                }
            });
        } else {
            const objs = allCanvas[props.index][pageNumber].getObjects();
            allCanvas[props.index][pageNumber].initialize(id, canvasOption);
            objs.forEach(obj => {
                allCanvas[props.index][pageNumber].add(obj);
            });
        }

        setPage({width: pdfWidth, height: pdfHeight});
    }

    /**
     * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
     * images to fit into a certain area.
     *
     * @param {Number} srcWidth width of source image
     * @param {Number} srcHeight height of source image
     * @param {Number} maxWidth maximum available width
     * @param {Number} maxHeight maximum available height
     * @return {Object} { width, height }
     */
    function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
        return {width: srcWidth * ratio, height: srcHeight * ratio};
    }

    function toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    const handleAddSignature = () => {
        const signature = userLogin.signature;
        if (!signature) {
            Swal.fire({
                icon: 'error',
                title: 'ไม่พบข้อมูลลายเซ็น',
                text: 'กรุณาเพิ่มข้อมูลลายเซ็น ที่ระบบข้อมูลบุคลากร\nแล้วทำการเข้าระบบสารบรรณอิเล็กทรอนิกส์ใหม่',
            });
            return;
        }
        const imgSrc = config.get('apiHrUrl') + '/api/file/' + signature;
        const img = new Image();
        img.onload = async () => {
            const maxWidth = 85;
            const maxHeight = 35;
            const imgResize = calculateAspectRatioFit(img.width, img.height, maxWidth, maxHeight);
            const x = page.width / 2 - imgResize.width / 2;
            const y = page.height / 2 - imgResize.height / 2;

            const imgInstance = new fabric.Image(img, {
                left: x,
                top: y,
                width: img.width,
                height: img.height,
                objectCaching: false,
            });

            if (img.width > img.height) {
                imgInstance.scaleToWidth(maxWidth, false);
            } else {
                imgInstance.scaleToHeight(maxHeight, false);
            }

            imgInstance.setControlsVisibility(HideControls);
            allCanvas[props.index][pageNumber].add(imgInstance);
            allCanvas[props.index][pageNumber].setActiveObject(imgInstance);
        };

        toDataURL(imgSrc, function (dataUrl) {
            img.src = dataUrl;
        })
    }

    const handleAddImageQR = (imageFile) => {
        if (imageFile) {
            const img = new Image();
            img.onload = async () => {
                const maxWidth = 100;
                const maxHeight = 100;
                const imgResize = calculateAspectRatioFit(img.width, img.height, maxWidth, maxHeight);
                const x = page.width / 2 - imgResize.width / 2;
                const y = page.height / 2 - imgResize.height / 2;

                const imgInstance = new fabric.Image(img, {
                    left: x,
                    top: y,
                    width: img.width,
                    height: img.height,
                    objectCaching: false,
                });

                if (img.width > img.height) {
                    imgInstance.scaleToWidth(maxWidth, false);
                } else {
                    imgInstance.scaleToHeight(maxHeight, false);
                }

                const HideControls = {
                    'tl': false,
                    'tr': false,
                    'bl': false,
                    'br': false,
                    'ml': false,
                    'mt': false,
                    'mr': false,
                    'mb': false,
                    'mtr': false
                }

                imgInstance.setControlsVisibility(HideControls);
                allCanvas[props.index][pageNumber].add(imgInstance);
                allCanvas[props.index][pageNumber].setActiveObject(imgInstance);
            };

            toDataURL(imageFile, function (dataUrl) {
                img.src = dataUrl;
            })
        }
    }

    const handleAddRedStamp = () => {
        const img = new Image();
        img.onload = async () => {
            const maxWidth = 95;
            const maxHeight = 95;
            const imgResize = calculateAspectRatioFit(img.width, img.height, maxWidth, maxHeight);
            const x = page.width / 2 - imgResize.width / 2;
            const y = page.height / 2 - imgResize.height / 2;

            const imgInstance = new fabric.Image(img, {
                left: x,
                top: y,
                width: img.width,
                height: img.height,
                opacity: 0.65,
                objectCaching: false,
            });

            if (img.width > img.height) {
                imgInstance.scaleToWidth(maxWidth, false);
            } else {
                imgInstance.scaleToHeight(maxHeight, false);
            }

            const HideControls = {
                'tl': false,
                'tr': false,
                'bl': false,
                'br': false,
                'ml': false,
                'mt': false,
                'mr': false,
                'mb': false,
                'mtr': false
            }

            imgInstance.setControlsVisibility(HideControls);
            allCanvas[props.index][pageNumber].add(imgInstance);
            allCanvas[props.index][pageNumber].setActiveObject(imgInstance);
        };

        toDataURL(redStamp, function (dataUrl) {
            img.src = dataUrl;
        })
    }

    const handleAddText = (text, prop = {}) => {
        if (text.trim() !== '') {
            let textSplit = text.split('\n');
            let maxWidth = 0;
            let config = {
                fontSize: 16,
                fontFamily: 'THSarabun',
                fontWeight: 'normal',
                fillStyle: '#000000',
                textAlign: 'center',
                left: 0.5 * page.width,
                top: 0.5 * page.height,
                lineHeight: 0.95,
                ...prop,
            };

            const c = document.createElement("canvas");
            const ctx = c.getContext("2d");

            ctx.font = `${config.fontWeight} ${config.fontSize}px ${config.fontFamily}`;
            ctx.fillStyle = config.fillStyle;
            ctx.textAlign = config.textAlign;
            let textHeight = parseInt(ctx.font.match(/\d+/), 10);
            let textY = 0;

            textSplit.forEach(text => {
                let textWidth = ctx.measureText(text.trim()).width;
                if (textWidth > maxWidth) {
                    maxWidth = textWidth;
                }
            });

            textSplit.forEach(text => {
                textY += textHeight + 1;
                let textX = 0;
                if (config.textAlign === 'center') {
                    textX = maxWidth / 2;
                }
                ctx.fillText(text.trim(), textX, textY);
            });

            let imgTextHeight = textY + 8;
            // const imgInstance = new fabric.Image(c, {
            //     left: config.left - (maxWidth / 2),
            //     top: config.top - (imgTextHeight / 2),
            //     width: maxWidth,
            //     height: imgTextHeight,
            //     id: config.id,
            // });

            const textInstance = new fabric.Text(text, {
                id: config.id,
                left: config.left - (maxWidth / 2),
                top: config.top - (imgTextHeight / 2),
                fontSize: config.fontSize,
                fontFamily: config.fontFamily,
                fontWeight: config.fontWeight,
                fill: config.fillStyle,
                fillStyle: config.fillStyle,
                textAlign: config.textAlign,
                lineHeight: config.lineHeight,
                objectCaching: false,
            });

            textInstance.setControlsVisibility(HideControls);
            allCanvas[props.index][pageNumber].add(textInstance);
            allCanvas[props.index][pageNumber].setActiveObject(textInstance);
        }
    }

    const handleAddTextStampWithSignature = (text, rankName) => {
        const signature = userLogin.signature;
        if (!signature) {
            Swal.fire({
                icon: 'error',
                title: 'ไม่พบข้อมูลลายเซ็น',
                text: 'กรุณาเพิ่มข้อมูลลายเซ็น ที่ระบบข้อมูลบุคลากร\nแล้วทำการเข้าระบบสารบรรณอิเล็กทรอนิกส์ใหม่',
            });
            return;
        }
        const imgSrc = config.get('apiHrUrl') + '/api/file/' + signature;
        const imgSign = new Image();
        imgSign.onload = async () => {
            const maxSignWidth = 85;
            const maxSignHeight = 35;
            const imgSignResize = calculateAspectRatioFit(imgSign.width, imgSign.height, maxSignWidth, maxSignHeight);
            const x = 0.5 * page.width;
            const y = 0.5 * page.height;
            const groupInstance = [];

            let textSplit = text.split('\n');
            let maxTextWidth = maxSignWidth;
            let config = {
                fontSize: 16,
                fontFamily: 'THSarabunIT9',
                textAlign: 'center',
                fillStyle: '#000000',
                left: 0,
                top: imgSignResize.height / 1.333333,
                lineHeight: 0.95,
            };

            const c = document.createElement("canvas");
            const ctx = c.getContext("2d");

            ctx.font = `${config.fontSize}px ${config.fontFamily}`;
            ctx.textAlign = config.textAlign;
            let textHeight = parseInt(ctx.font.match(/\d+/), 10);

            textSplit.forEach(text => {
                let textWidth = ctx.measureText(text.trim()).width;
                if (textWidth > maxTextWidth) {
                    maxTextWidth = textWidth;
                }
            });

            const imgSignScaleX = imgSignResize.width / imgSign.width;
            const imgSignScaleY = imgSignResize.height / imgSign.height;
            const imgSignInstance = new fabric.Image(imgSign, {
                originX: 'center',
                top: 0,
                scaleX: imgSignScaleX,
                scaleY: imgSignScaleY,
                objectCaching: false,
            });
            groupInstance.push(imgSignInstance);

            let textY = config.top;
            textSplit.forEach(text => {
                textY += textHeight + 1;
                let textX = 0;
                if (config.textAlign === 'center') {
                    textX = maxTextWidth / 2;
                }
                ctx.fillText(text.trim(), textX, textY);
            });

            let imgTextHeight = textY + 8;
            // const imgTextInstance = new fabric.Image(c, {
            //     originX: 'center',
            //     top: config.top,
            //     width: maxTextWidth,
            //     height: imgTextHeight,
            // });
            // groupInstance.push(imgTextInstance);

            const textInstance = new fabric.Text(text, {
                top: config.top + 16,
                left: config.left,
                fontSize: config.fontSize,
                fontFamily: config.fontFamily,
                fontWeight: config.fontWeight,
                fill: config.fillStyle,
                fillStyle: config.fillStyle,
                textAlign: config.textAlign,
                originX: 'center',
                lineHeight: config.lineHeight,
                objectCaching: false,
            });
            groupInstance.push(textInstance);

            if(rankName) {
                const textInstance = new fabric.Text(rankName, {
                    top: config.top - 16,
                    left: - (maxTextWidth / 2) - 8,
                    fontSize: config.fontSize,
                    fontFamily: config.fontFamily,
                    fontWeight: config.fontWeight,
                    fill: config.fillStyle,
                    fillStyle: config.fillStyle,
                    textAlign: 'right',
                    originX: 'right',
                    lineHeight: config.lineHeight,
                    objectCaching: false,
                });
                groupInstance.push(textInstance);
            }

            const group = new fabric.Group(groupInstance, {
                left: x - (maxTextWidth / 2),
                top: y - (imgTextHeight / 2),
                controlsVisibility: HideControls,
                objectCaching: false,
            });

            group.setControlsVisibility(HideControls);
            allCanvas[props.index][pageNumber].add(group);
            allCanvas[props.index][pageNumber].setActiveObject(group);
        };

        toDataURL(imgSrc, function (dataUrl) {
            imgSign.src = dataUrl;
        })
    }

    const handleAddReceiveTextBox = (text, receiveDocNumber) => {
        const color = "#4C5BB1FF";
        let maxWidth = 120;
        let dotStartX;
        const groupInstance = [];
        // text += "หนังสือเวียนประทับตราลงนาม รูปแบบที่ 1.pdf";

        const c = document.createElement("canvas");
        const ctx = c.getContext("2d");

        ctx.font = "17px THSarabunIT9"
        ctx.textAlign = "center";
        ctx.color = "blue";
        ctx.fillStyle = color;

        let textWidth = ctx.measureText(text).width;
        let textY = 12;
        let textMargin = 8;
        let lineDottedWidth = 0.7;
        if (textWidth > maxWidth) {
            maxWidth = textWidth;
        }

        ctx.fillText(text, maxWidth / 2, textY);
        textY += 18;

        dotStartX = 31;
        ctx.beginPath();
        ctx.setLineDash([1, 1]);
        ctx.moveTo(dotStartX, textY);
        ctx.lineTo(maxWidth, textY);
        ctx.lineWidth = lineDottedWidth;
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.font = "14px THSarabunIT9"
        ctx.textAlign = "left";
        ctx.fillText("เลขที่รับ", 0, textY);

        ctx.font = "17px THSarabunIT9"
        ctx.textAlign = "center";
        ctx.fillText(`${receiveDocNumber}`, (maxWidth - dotStartX) / 2 + dotStartX, textY - 2);
        textY += 17;

        dotStartX = 18;
        ctx.beginPath();
        ctx.setLineDash([1, 1]);
        ctx.moveTo(dotStartX, textY);
        ctx.lineTo(maxWidth, textY);
        ctx.lineWidth = lineDottedWidth;
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.font = "14px THSarabunIT9"
        ctx.textAlign = "left";
        ctx.fillText("วันที่", 0, textY);

        ctx.font = "17px THSarabunIT9"
        ctx.textAlign = "center";
        ctx.fillText(getDateThaiNow(), (maxWidth - dotStartX) / 2 + dotStartX, textY - 2);
        textY += 17;

        dotStartX = 18;
        ctx.beginPath();
        ctx.setLineDash([1, 1]);
        ctx.moveTo(dotStartX, textY);
        ctx.lineTo(maxWidth, textY);
        ctx.lineWidth = lineDottedWidth;
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.font = "14px THSarabunIT9"
        ctx.textAlign = "left";
        ctx.fillText("เวลา", 0, textY);

        ctx.font = "17px THSarabunIT9"
        ctx.textAlign = "center";
        ctx.fillText(getTimeThaiNow(), (maxWidth - dotStartX) / 2 + dotStartX, textY - 2);

        const rectInstance = new fabric.Rect({
            left: 0,
            top: 0,
            width: maxWidth + (textMargin * 2),
            height: textY + (textMargin * 2),
            fill: 'rgba(0,0,0,0)',
            stroke: color,
            strokeWidth: 2,
            rx: 2,
            ry: 2,
            objectCaching: false,
        });

        rectInstance.on('scaling', function () {
            this.set({
                width: this.width * this.scaleX,
                height: this.height * this.scaleY,
                scaleX: 1,
                scaleY: 1
            });
        });
        groupInstance.push(rectInstance);

        // const imgInstance = new fabric.Image(c, {
        //     left: textMargin,
        //     top: textMargin,
        //     width: maxWidth,
        //     height: textY + textMargin,
        //     objectCaching: false,
        // });

        const textHeaderInstance = new fabric.Text(text, {
            top: -2 + textMargin,
            left: maxWidth / 2 + textMargin,
            originX: 'center',
            fontSize: "17",
            fontFamily: "THSarabunIT9",
            fill: color,
            fillStyle: color,
            lineHeight: 0.95,
            objectCaching: false,
        });
        groupInstance.push(textHeaderInstance);

        const lineHeader = "เลขที่รับ\nวันที่\nเวลา";
        const textLineHeaderInstance = new fabric.Text(lineHeader, {
            top: 26,
            left: textMargin,
            textAlign: 'left',
            fontSize: "14",
            fontFamily: "THSarabunIT9",
            fill: color,
            fillStyle: color,
            lineHeight: 1.1,
            objectCaching: false,
        });
        groupInstance.push(textLineHeaderInstance);

        const lineY = 38;
        const lineHeight = 17;
        groupInstance.push(new fabric.Line([39, lineY, maxWidth + textMargin, lineY], {
            strokeDashArray: [1, 1],
            stroke: color,
            strokeWidth: lineDottedWidth,
            selectable: false,
            objectCaching: false,
        }));

        groupInstance.push(new fabric.Line([26, lineY + lineHeight, maxWidth + textMargin, lineY + lineHeight], {
            strokeDashArray: [1, 1],
            stroke: color,
            strokeWidth: lineDottedWidth,
            selectable: false,
            objectCaching: false,
        }));

        groupInstance.push(new fabric.Line([27, lineY + lineHeight * 2, maxWidth + textMargin, lineY + lineHeight * 2], {
            strokeDashArray: [1, 1],
            stroke: color,
            strokeWidth: lineDottedWidth,
            selectable: false,
            objectCaching: false,
        }));

        const lineValue = `${receiveDocNumber}\n${getDateThaiNow()}\n${getTimeThaiNow()}`;
        const textLineValueInstance = new fabric.Text(lineValue, {
            top: 22,
            left: 25 + (maxWidth-25) / 2 + textMargin,
            textAlign: 'center',
            originX: 'center',
            fontSize: "17",
            fontFamily: "THSarabunIT9",
            fill: color,
            fillStyle: color,
            lineHeight: 0.87,
            objectCaching: false,
        });
        groupInstance.push(textLineValueInstance);

        const HideControls = {
            'tl': false,
            'tr': false,
            'bl': false,
            'br': false,
            'ml': false,
            'mt': false,
            'mr': false,
            'mb': false,
            'mtr': false
        }

        const group = new fabric.Group(groupInstance, {
            left: 10,
            top: 10,
            controlsVisibility: HideControls,
            objectCaching: false,
        });

        group.setControlsVisibility(HideControls);
        allCanvas[props.index][pageNumber].add(group);
        allCanvas[props.index][pageNumber].setActiveObject(group);
    }

    const handleSaveSignature = () => {
        const result = [];

        props.pdfList.forEach((pdfListProps, index) => {
            let pdf = {...pdfListProps};
            const signPositionList = [];
            const pdfDoc = allCanvas[index];

            if (pdfDoc) {
                for (let i = 1; i <= pdfDoc.pages; i++) {
                    const canvas = pdfDoc[i];
                    if (canvas && canvas.getObjects().length > 0) {
                        let imgBase64 = canvas.toDataURL({
                            format: 'png',
                            multiplier: 5
                        }).split(',')[1];
                        signPositionList.push({
                            position_x: "0",
                            position_y: "0",
                            page: i,
                            width: `${canvas.width}`,
                            height: `${canvas.height}`,
                            img: imgBase64,
                        });
                    }
                }
            }

            if (signPositionList.length > 0) {
                pdf = {
                    ...pdf,
                    signPositionList: signPositionList,
                }
            }
            result.push(pdf);
        })

        setPdfSignList(result);

        return result;
    }

    const handleDeleteActiveObject = () => {
        const activeObjects = allCanvas[props.index][pageNumber].getActiveObjects();
        if (activeObjects.length > 0) {
            activeObjects.forEach((activeObject) => {
                allCanvas[props.index][pageNumber].remove(activeObject);
            });
        } else {
            Swal.fire({
                icon: 'info',
                title: 'กรุณาเลือกลายเซ็นหรือข้อความที่ต้องการลบ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
            });
        }
    }

    const handleDeleteRegisterNo = () => {
        allCanvas[props.index][pageNumber].getObjects().forEach((obj) => {
            if (obj.id === 'registerNo') {
                allCanvas[props.index][pageNumber].remove(obj);
            }
        });
    }

    const handleDeleteDocumentDate = () => {
        allCanvas[props.index][pageNumber].getObjects().forEach((obj) => {
            if (obj.id === 'documentDate') {
                allCanvas[props.index][pageNumber].remove(obj);
            }
        });
    }

    const generateCanvas = () => {
        let id = `signature-${pageNumber}`;
        return (
            <canvas id={id}/>
        );
    }

    return (
        <>
            <Container className="pdf-sign-viewer">
                <div className="row m-4">
                    <div className="col-12 text-center">
                        <h5><b>{props.realFilename.replaceAll("_", " ")}</b></h5>
                    </div>
                </div>
                <Document file={props.pdf} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page
                        width={page.width}
                        height={page.height}
                        pageNumber={pageNumber}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        onLoadSuccess={onPageLoadSuccess}>
                        <div className="canvas-signature-area">
                            {generateCanvas()}
                        </div>
                    </Page>
                </Document>
                <div className="row m-4">
                    <div className="col-12 text-center">
                        <Button type="button" disabled={pageNumber <= 1} onClick={previousPage}>‹</Button>
                        <span className="m-2">หน้า {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}</span>
                        <Button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>›</Button>
                    </div>
                </div>
            </Container>
        </>
    );
});

export default SignPdf;
