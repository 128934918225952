import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from "react-redux";

const TopNav = ({
                    onAddSign,
                    onAddRedStamp,
                    onAddText,
                    onAddImageQR,
                    onAddStamp,
                    onAddStampReceive,
                    onAddRegisterNo,
                    onAddUrgencyLevel,
                    isUrgencyDocument,
                    onDeleteActiveObject,
                    isRefer,
                    showStampReceive,
                    isGetSentNumber,
                    onCheckboxRegisterNoChange,
                    onCheckboxDocumentDateChange,
                    documentDateInput,
                    config,
                }) => {

    const documentState = useSelector((state) => state.document);

    return (
        <Container className="m-2">
            <Row className="justify-content-lg-center">
                {isRefer && (
                    <Col xs xl="2" className="mb-2">
                        <button type="button" className="btn btn-block btn-color-menu" onClick={onAddRegisterNo}>
                            <i className="fas fa-tag mr-2"></i>เลขที่หนังสือ
                        </button>
                    </Col>
                )}
                <Col xs xl="2" className="mb-2">
                    <button type="button" className="btn btn-block btn-color-add" onClick={onAddSign}>
                        <i className="fas fa-signature mr-2"></i>ลายเซ็น
                    </button>
                </Col>
                <Col xs xl="2" className="mb-2">
                    <button type="button" className="btn btn-block btn-color-menu" onClick={onAddText}>
                        <i className="fas fa-font mr-2"></i>ข้อความ
                    </button>
                </Col>
                <Col xs xl="2" className="mb-2">
                    <button type="button" className="btn btn-block btn-color-menu" onClick={onAddImageQR}>
                        <i className="fas fa-qrcode mr-2"></i>รูป QR
                    </button>
                </Col>
                <Col xs xl="2" className="mb-2">
                    <button type="button" className="btn button-stamp" onClick={onAddStamp}>
                        <i className="fas fa-stamp mr-2"></i>ตรายาง
                    </button>
                </Col>
                <Col xs xl="2" className="mb-2">
                    <button type="button" className="btn button-stamp" onClick={onAddRedStamp}>
                        <i className="fas fa-stamp mr-2"></i>ตราประทับกรม
                    </button>
                </Col>
                {showStampReceive && (
                    <Col xs xl="2" className="mb-2">
                        <button type="button" className="btn btn-block btn-color-add " onClick={onAddStampReceive}>
                            <i className="fas fa-stamp mr-2"></i>ตรารับหนังสือ
                        </button>
                    </Col>
                )}
                {isUrgencyDocument && (
                    <Col xs xl="2" className="mb-2">
                        <button type="button" className="btn btn-block btn-color-add " onClick={onAddUrgencyLevel}>
                            <i className="fas fa-stamp mr-2"></i>ชั้นความเร็ว
                        </button>
                    </Col>
                )}
                <Col xs xl="2" className="mb-2">
                    <button type="button" className="btn btn-block btn-color-delete" onClick={onDeleteActiveObject}>
                        <i className="fas fa-trash mr-2"></i>ลบ
                    </button>
                </Col>
            </Row>
            {config.isDocumentAutoForm && (
                <Row className="justify-content-lg-center">
                    {!documentState.value.registerNo && (
                        <div className="form-check form-check-inline pr-2">
                            <input className="form-check-input" type="checkbox" id="digitalSignatureRegisterNoCheckbox" onChange={onCheckboxRegisterNoChange}/>
                            <label className="form-check-label" htmlFor="digitalSignatureRegisterNoCheckbox">เลขที่หนังสือ</label>
                            {isGetSentNumber && (
                                <div
                                    className="spinner-border spinner-border-sm ml-2"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            )}
                        </div>
                    )}
                    {!documentState.value.documentDate && (
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="digitalSignatureDocumentDateCheckbox" onChange={onCheckboxDocumentDateChange}/>
                            <label className="form-check-label pr-2" htmlFor="digitalSignatureDocumentDateCheckbox">ลงวันที่</label>
                            {documentDateInput}
                        </div>
                    )}
                </Row>
            )}
        </Container>
    );
};

export default TopNav;
