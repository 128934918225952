import FroalaEditor from 'react-froala-wysiwyg';
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

const FroalaEditorComponent = (props) => {
    let toolbarDev = [];
    if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
        toolbarDev = ['|', 'help', 'html'];
    }

    return (
        <FroalaEditor
            config={{
                key: "lSA3D-17C2C1C1E1B1E1C4sGXd1WWTDSGXYOUKh1KINLb1OG1g1H-8D1B3D3D1E6D1B1B4F4I4==",
                attribution: false,
                language: 'th',
                height: 300,
                documentReady: true,
                fontSizeSelection: true,
                fontSizeDefaultSelection: '16pt',
                fontSizeUnit: 'pt',
                fontSize: ['8', '9', '10', '11', '12', '14', '16', '17', '18', '20', '24', '29', '30', '36', '48', '60', '72', '96'],
                fontFamilySelection: true,
                fontFamilyDefaultSelection: 'TH Sarabun PSK',
                fontFamily: {
                    "THSarabun": 'TH Sarabun PSK',
                    "THSarabunIT9,THSarabun": 'TH Sarabun IT๙',
                    // 'Arial,Helvetica,sans-serif': 'Arial',
                    // 'Georgia,serif': 'Georgia',
                    // 'Tahoma,Geneva,sans-serif': 'Tahoma',
                    // "'Times New Roman',Times,serif": 'Times New Roman',
                    // 'Verdana,Geneva,sans-serif': 'Verdana',
                },
                tabSpaces: 8,
                toolbarButtons: [
                    'fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript',
                    '|', 'fontFamily', 'fontSize',
                    '|', 'alignLeft', 'alignRight', 'alignCenter', 'alignJustify',
                    '|', 'formatOL', 'formatUL', 'outdent', 'indent',
                    '|', 'undo', 'redo',
                    ...toolbarDev
                ],
                events: {
                    'initialized': function() {
                        const editor = this;
                        if(props.readOnly) {
                            editor.edit.off();
                        }
                    }
                },
            }}
            {...props}
        />
    );
};

export default FroalaEditorComponent;
