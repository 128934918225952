import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useKeycloak } from '@react-keycloak/web';
import config from 'react-global-configuration';
import Swal from 'sweetalert2';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { mainApi } from '../../HttpClient';
import UploadFiles from '../share/UploadFiles';
import OrgEmpList from '../share/OrgEmpList';
import DateInput from '../share/DateInput';
import { getDateFormat, getDateThaiShortMonthFormat, getDateTimeFormat, isDuedate, isOverDueDate, } from '../util/Date';
import { getApiMasSecretLevel, getApiMasUrgencyLevel, } from '../util/ApiForSelect';
import { isOwnerCreateDocument } from '../util/PermissionDocument';
import { getCurrentDocRunning } from '../util/DocRegister';
import HistoryBox from '../history/HistoryBox';
import DigitalSignatureModal from "../share/digital-signature/DigitalSignatureModal";
import { useSelector } from "react-redux";

import 'datatables.mark.js/dist/datatables.mark.es6.min.js';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import 'datatables.net-responsive/js/dataTables.responsive.min';

import '../../App.css';

import $ from 'jquery';

const SecretDocOutbox = () => {
    const [isLoadingDocRunningNo, setIsLoadingDocRunningNo] = useState(true);
    const {register, handleSubmit, reset} = useForm();
    const {
        register: registerEdit,
        handleSubmit: handleSubmitEdit,
        errors: errorsEdit,
        setValue: setValueEdit,
    } = useForm();
    const [keycloak] = useKeycloak();
    const [documentDate, setDocumentDate] = useState(new Date());
    const [documentEditDate, setDocumentEditDate] = useState(new Date());
    const [documentEditDuedate, setDocumentEditDuedate] = useState(new Date());
    const [dataProcess, setDataProcess] = useState([]);
    const [documentDetail, setDocumentDetail] = useState({});
    const [documentReferDetail, setDocumentReferDetail] = useState({});
    const [tableSelectData, setTableSelectData] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => setModalShow(false);
    const handleModalShow = () => setModalShow(true);
    const [modalSentShow, setModalSentShow] = useState(false);
    const handleModalSentClose = () => setModalSentShow(false);
    const handleModalSentShow = () => setModalSentShow(true);
    const [modalEditShow, setModalEditShow] = useState(false);
    const handleModalEditClose = () => setModalEditShow(false);
    const handleModalEditShow = () => setModalEditShow(true);
    const [modalReferShow, setModalReferShow] = useState(false);
    const handleModalReferClose = () => setModalReferShow(false);
    const handleModalReferShow = () => setModalReferShow(true);
    const [modalCommandShow, setModalCommandShow] = useState(false);
    const handleModalCommandClose = () => setModalCommandShow(false);
    // const handleModalCommandShow = () => setModalCommandShow(true);
    const [modalActionShow, setModalActionShow] = useState(false);
    const handleModalActionClose = () => setModalActionShow(false);
    // const handleModalActionShow = () => setModalActionShow(true);
    const [modalAttachFileShow, setModalAttachFileShow] = useState(false);
    const handleModalAttachFileClose = () => setModalAttachFileShow(false);
    // const handleModalAttachFileShow = () => setModalAttachFileShow(true);
    const [documentProcess, setDocumentProcess] = useState({});
    const [fileList, setFileList] = useState([]);
    const [isReferLoading, setIsReferLoading] = useState(false);
    const [isPrintLoading, setIsPrintLoading] = useState(false);
    const orgEmpListRef = useRef();
    const historyBoxRef = useRef();
    const [docRunningNo, setDocRunningNo] = useState(1);
    const [referSelections, setReferSelections] = useState([]);
    const [referOptions, setReferOptions] = useState([]);
    const [listUrgencyLevel, setListUrgencyLevel] = useState([]);
    const [listSecretLevel, setListSecretLevel] = useState([]);
    const [isCheckedDueDate, setIsCheckedDueDate] = useState(false);
    const [isSend, setIsSend] = useState(false);

    const [selectUrgencyLevel, setSelectUrgencyLevel] = useState('');
    const [read, setRead] = useState(false);

    const digitalSignatureRef = useRef();
    const [pdfSignList, setPdfSignList] = useState([]);

    const signatureCertificate = useSelector((state) => state.userLogin.signatureCertificate);
    const documentState = useSelector((state) => state.document);

    useEffect(() => {
        const getSee = () => {
            if (documentDetail.subject === 'หนังสือลับ' || documentDetail.subject === 'หนังสือลับมาก') {
                setRead(true)
            } else {
                setRead(false)
            }
        }
        getSee();
    }, [documentDetail]);

    useEffect(() => {
        const changeSub = () => {
            if (selectUrgencyLevel <= 1 || selectUrgencyLevel === '') {
                setValueEdit('subject', documentDetail.subject)
                setRead(false)
            } else {
                if (selectUrgencyLevel === '2') {
                    setValueEdit('subject', 'หนังสือลับ')
                } else if (selectUrgencyLevel === '3') {
                    setValueEdit('subject', 'หนังสือลับมาก')
                }
                setRead(true)
            }

        }
        changeSub();
    }, [selectUrgencyLevel, documentDetail.subject, setValueEdit]);

    useEffect(() => {
        const rsUrgen = getApiMasUrgencyLevel();
        rsUrgen.then((res) => {
            setListUrgencyLevel(res.data);
        });

        const rsSecret = getApiMasSecretLevel();
        rsSecret.then((res) => {
            setListSecretLevel(res.data);
        });
    }, []);

    useEffect(() => {
        if (documentState.documentBy.id !== null) {
            setIsLoadingDocRunningNo(true);
            const docType = 'HS';
            getCurrentDocRunning(docType, documentState.documentBy).then((res) => {
                let docCount = res.data;
                if (docCount > -1) {
                    docCount += 1;
                }
                setDocRunningNo(docCount);
                setIsLoadingDocRunningNo(false);
            });
        }
    }, [documentState.documentBy]);

    useEffect(() => {
        let color2 = '#ffd43b';
        let color3 = '#fd7e14';
        let color4 = '#e03131';
        const {id: documentById} = documentState.documentBy;

        $.fn.dataTable.ext.errMode = 'none';

        $('#data-table').DataTable({
            order: [0, 'desc'],
            ajax: {
                contentType: 'application/json',
                url:
                    config.get('apiUrl') + '/api/v3/documents?pageName=secret-doc-outbox' +
                    '&senderId=' + documentById +
                    '&documentById=' + documentById,
                type: 'POST',
                data: function (d) {
                    return JSON.stringify(d);
                },
                headers: {
                    Authorization: `bearer ${keycloak.token}`,
                },
            },
            processing: true,
            serverSide: true,
            mark: true,
            columnDefs: [{width: '100px', targets: 1}],
            columns: [
                {data: 'documentDate', visible: false},
                {data: 'sentNo', className: 'text-center', width: '7%'},
                {
                    data: 'registerNo',
                    className: 'text-center',
                    responsivePriority: 1,
                    targets: 0,
                    width: '10%',
                    render: function (data, type, row) {
                        let duedate = '';
                        if (
                            isDuedate(row.documentDuedate) &&
                            row.progressStatus !== '4' &&
                            row.progressStatus !== '5' &&
                            !isOverDueDate(row.documentDuedate)
                        ) {
                            duedate = `<div class="blink_txt" style="color: ${color4};"><small>แจ้งเตือน ${getDateThaiShortMonthFormat(
                                row.documentDuedate,
                                true
                            )}</small></div>`;
                        }

                        let attachment = '';
                        if (row.countAttachment > 0) {
                            attachment = `<i class="fas fa-paperclip text-danger mr-1"></i>`;
                        }

                        let urgencyLevel = '';
                        if (row.urgencyLevel === 2) {
                            urgencyLevel = `<small class="badge badge-warning mr-1" style="background-color: ${color2};">ด่วน</small>`;
                        } else if (row.urgencyLevel === 3) {
                            urgencyLevel = `<small class="badge badge-danger mr-1" style="background-color: ${color3};">ด่วนมาก</small>`;
                        } else if (row.urgencyLevel === 4) {
                            urgencyLevel = `<small class="badge badge-danger mr-1" style="background-color: ${color4};">ด่วนที่สุด</small>`;
                        }

                        let secretLevel = '';
                        if (row.secretLevel === 2) {
                            secretLevel = `<small class="badge badge-primary">ลับ</small>`;
                        } else if (row.secretLevel === 3) {
                            secretLevel = `<small class="badge badge-danger">ลับมาก</small>`;
                        } else if (row.secretLevel === 4) {
                            secretLevel = `<small class="badge badge-danger">ลับที่สุด</small>`;
                        }

                        let registerNoCheck = '';
                        if (row.registerNo === '') {
                            registerNoCheck = `<span class="badge badge-pill badge-warning">รอดำเนินการ</span>`;
                        }

                        if (duedate || attachment || urgencyLevel || secretLevel || registerNoCheck) {
                            return (
                                data +
                                registerNoCheck +
                                '<br/>' +
                                attachment +
                                urgencyLevel +
                                secretLevel +
                                duedate
                            );
                        }
                        return data;
                    },
                },
                {
                    data: 'documentDate',
                    width: '80px',
                    className: 'text-center',
                    orderable: false,
                    render: function (data, type, row) {
                        return getDateFormat(data);
                    },
                },
                {
                    className: 'text-center',
                    searchable: false,
                    orderable: false,
                    visible: false,
                    render: function (data, type, row) {
                        let documentType = '';
                        let documentGenre = '';
                        if (row.documentType === 'HS') {
                            documentType = 'หนังสือส่ง (ลับ)';
                        } else if (row.documentType === 'HR') {
                            documentType = 'หนังสือรับ (ลับ)';
                        }
                        if (row.documentGenre === 'IN') {
                            documentGenre = ' (ภายใน)';
                        } else if (row.documentGenre === 'OUT') {
                            documentGenre = ' (ภายนอก)';
                        }
                        return documentType + documentGenre;
                    },
                },
                // { data: 'receiverProcess', className: 'text-left', width: '10%' },
                {data: 'processGroup', className: 'text-left', width: '10%'},
                {data: 'receiver', className: 'text-left', width: '10%'},
                {
                    data: 'owner',
                    className: 'text-left',
                    width: '10%',
                    render: function (data, type, row) {
                        return data + ' ' + (row.ownerTelephone || '');
                    },
                },
                {
                    data: 'subject',
                    className: 'text-left',
                    width: '25%',
                    responsivePriority: 2,
                    targets: -1,
                    render: function (data, type, row) {
                        let subjectData = '';
                        if (row.status === '5') {
                            subjectData = `<a class="show-truncate link-red" href="javascript:void(0)">${data} <span class="badge badge-pill badge-danger"> ยกเลิก</span></a>`;
                        } else {
                            subjectData = `<a class="show-truncate" href="javascript:void(0)">${data}</a>`;
                        }

                        let formAutoBadge = '';
                        if (row.formId !== null && row.formId !== '') {
                            formAutoBadge = ' <span class="badge badge-pill badge-form-auto">แบบฟอร์มหนังสือแบบอัตโนมัติ</span><br>';
                        }
                        return formAutoBadge + subjectData;
                    },
                },
                {data: 'command', className: 'text-center', visible: false},
                {
                    data: 'sentDate',
                    width: '80px',
                    className: 'text-center',
                    render: function (data, type, row) {
                        return getDateTimeFormat(data);
                    },
                },
                {
                    data: 'receiveDateSentBox',
                    width: '90px',
                    orderable: false,
                    className: 'text-center',
                    render: function (data, type, row) {
                        return getDateTimeFormat(data);
                    },
                },
                {
                    // data: 'progressStatus',
                    className: 'text-center',
                    searchable: false,
                    orderable: false,
                    visible: true,
                    render: function (data, type, row) {
                        // if (data) {
                        //     return `<h6><span class="badge ${getStatusColor(data)}">${row.progressStatusName}</span></h6>`;
                        // }
                        // return '';
                        let docTypeBadge = '';
                        if (row.documentGenre === 'IN') {
                            docTypeBadge = ' <span class="badge badge-pill badge-success"> ภายใน</span>';
                        } else if (row.documentGenre === 'OUT') {
                            docTypeBadge = ' <span class="badge badge-pill badge-dark"> ภายนอก</span>';
                        }
                        return docTypeBadge;
                    },
                },
                {
                    className: 'text-center',
                    searchable: false,
                    orderable: false,
                    responsivePriority: 3,
                    targets: -2,
                    render: function (data, type, row) {
                        let returnButton = '';
                        let closeButton = '';
                        let cancelButton = '';
                        let printDocumentButton = '';

                        if (row.formId !== null && row.formId !== '') {
                            // printDocumentButton = `<button class="dropdown-item btn-print-document" type="button""><i class="fas fa-file mr-2"></i>พิมพ์หนังสือ</button>`;
                        }
                        if (row.progressStatus === '1') {
                            if (isOwnerCreateDocument(row.documentCreatedBy, documentState.documentBy.id)) {
                                //returnButton = `<button class="dropdown-item btn-return-document" type="button""><i class="fas fa-undo-alt mr-2"></i>ดึงเรื่องกลับ</button>`;
                            }
                        }
                        if (row.progressStatus === '2') {
                            if (isOwnerCreateDocument(row.documentCreatedBy, documentState.documentBy.id)) {
                                closeButton = `<button class="dropdown-item btn-close-document" type="button""><i class="fas fa-check-circle mr-2"></i>ปิดเอกสาร</button>`;
                            }
                        }
                        if (row.progressStatus !== '4' && row.progressStatus !== '5') {
                            cancelButton = `<button class="dropdown-item btn-cancel-document" type="button""><i class="fas fa-times-circle mr-2"></i>ยกเลิกเอกสาร</button>`;
                        }

                        if (
                            printDocumentButton ||
                            returnButton ||
                            closeButton ||
                            cancelButton
                        ) {
                            return `<div>
                        <button type="button" class="btn btn-secondary btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-h"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                          ${printDocumentButton}
                          ${returnButton}
                          ${closeButton}
                          ${cancelButton}
                        </div>
                      </div>`;
                        }
                        return '';
                    },
                },
            ],
            rowCallback: function (row, data) {
                $('td', row)
                    .eq(6)
                    .attr('style', 'cursor: pointer;')
                    .on('click', function (e) {
                        fetchDocumentProcessById(data);
                    });

                $('td', row)
                    .find('.btn-print-document')
                    .on('click', function (e) {
                        handlePrintDocument(data.formId, data.documentId);
                    });

                $('td', row)
                    .find('.btn-return-document')
                    .on('click', function (e) {
                        handleReturnDocument(data.documentId);
                    });

                $('td', row)
                    .find('.btn-close-document')
                    .on('click', function (e) {
                        handleCloseDocument(data.documentId);
                    });

                $('td', row)
                    .find('.btn-cancel-document')
                    .on('click', function (e) {
                        handleCancelDocument(data.documentId);
                    });
            },
            lengthChange: true,
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
            // dom:
            //   '<"row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>><"datatable-wrap"t><"row"<"col-sm-12 col-md-5"i><"col-sm-12 col-md-7"p>>',
            language: {
                search: '_INPUT_',
                searchPlaceholder: 'ค้นหา...',
                lengthMenu: '_MENU_ รายการ/หน้า',
                sLengthMenu: '_MENU_ รายการ/หน้า',
                sProcessing: 'กำลังดำเนินการ...',
                sZeroRecords: 'ไม่พบข้อมูล',
                sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
                sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
                sInfoFiltered: '',
                // sInfoPostFix:
                //   `<br/><i class="fas fa-clock mr-1" style="color: ${color4};"></i>แจ้งเตือนกำหนดการ` +
                //   `<br/><i class="fas fa-star mr-1" style="color: ${color2};"></i>ด่วน<i class="fas fa-star mr-1 ml-2" style="color: ${color3};"></i>ด่วนมาก<i class="fas fa-star mr-1 ml-2" style="color: ${color4};"></i>ด่วนที่สุด` +
                //   `<br/><i class="fas fa-key mr-1" style="color: ${color2};"></i>ลับ<i class="fas fa-key mr-1 ml-2" style="color: ${color3};"></i>ลับมาก<i class="fas fa-key mr-1 ml-2" style="color: ${color4};"></i>ลับที่สุด`,
                sSearch: '',
                sUrl: '',
                oPaginate: {
                    sFirst: 'หน้าแรก',
                    sPrevious: 'ก่อนหน้า',
                    sNext: 'ถัดไป',
                    sLast: 'หน้าสุดท้าย',
                },
                processing:
                    '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
            },
        });

        const handlePrintDocument = (pdf_form_id, document_id) => {
            Swal.fire({
                title: 'คุณแน่ใจหรือไม่',
                text: 'ต้องการพิมพ์เอกสารใช่ไหม',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'พิมพ์เอกสาร',
                cancelButtonText: 'ยกเลิก',
            }).then((result) => {
                if (result.isConfirmed) {
                    // handleUpdateStatus(documentId, 6, 'ดึงเรื่องกลับสำเร็จ');
                    // alert("Print Document " + pdf_form_id);
                    printDocument(pdf_form_id, document_id);
                }
            });
        };

        const handleReturnDocument = (documentId) => {
            Swal.fire({
                title: 'คุณแน่ใจหรือไม่',
                text: 'ต้องการดึงเอกสารใช่ไหม',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'ดึงเรื่องกลับ',
                cancelButtonText: 'ยกเลิก',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleUpdateStatus(documentId, 6, 'ดึงเรื่องกลับสำเร็จ');
                }
            });
        };

        const handleCloseDocument = (documentId) => {
            Swal.fire({
                title: 'คุณแน่ใจหรือไม่',
                text: 'ต้องการปิดเอกสารใช่ไหม',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'ปิดเอกสาร',
                cancelButtonText: 'ยกเลิก',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleUpdateStatus(documentId, 4, 'ปิดเอกสารสำเร็จ');
                }
            });
        };

        const handleCancelDocument = (documentId) => {
            Swal.fire({
                title: 'คุณแน่ใจหรือไม่',
                text: 'ต้องการยกเลิกเอกสารใช่ไหม',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'ยกเลิกเอกสาร',
                cancelButtonText: 'ยกเลิก',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleUpdateStatus(documentId, 5, 'ยกเลิกเอกสารสำเร็จ');
                }
            });
        };

        const printDocument = async (pdfFormId, docId) => {
            let docType = '';
            let apiUrl = '';
            if (pdfFormId === '1' || pdfFormId === '5') {
                docType = '.docx';
                apiUrl = `/api/v1/document-preform/report/word/${pdfFormId}/${docId}`;
            } else {
                docType = '.pdf';
                apiUrl = `/api/v1/document-preform/report/pdf/${pdfFormId}/${docId}`;
            }
            mainApi.get(apiUrl, {responseType: 'blob'}).then((res) => {
                // console.log(res);
                fileDownload(res.data, 'Preform' + pdfFormId + '_' + docId + docType);
            });
        };

        const handleUpdateStatus = async (documentId, status, alertMessage) => {
            let params = {
                documentBy: documentState.documentBy,
            };
            mainApi.post(`/api/v3/documents/update-status/${documentId}?status=${status}`, params).then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: 'สำเร็จ',
                    text: alertMessage,
                    timer: config.get('alertTimer'),
                    timerProgressBar: true,
                });
                $('#data-table').DataTable().ajax.reload();
            });
        };

        const fetchDocumentProcessById = async (tableData) => {
            setClearData(tableData);
            setTableSelectData(tableData);
            setDocumentDate(tableData.documentDate);
            handleModalShow();

            mainApi.get(`/api/form-auto/attach-file/pdf-signature?processId=${tableData.processId}`).then((res) => {
                setPdfSignList(res.data);
            });

            const childAndAttachmentsParams = {
                documentId: tableData.documentId,
                documentBy: documentState.documentBy,
            };
            const response = await mainApi.post(`/api/v6/document-process/child-and-attachments`, childAndAttachmentsParams);

            if (historyBoxRef.current && historyBoxRef.current.dataProps.length === 0) {
                setDataProcess(response.data);
                historyBoxRef.current.setDataProps(response.data);
            }
        };

        const setClearData = (tableData) => {
            if (
                tableData.documentId !== tableSelectData.documentId &&
                tableData.processId !== tableSelectData.processId
            ) {
                reset(tableData);
            }
            setFileList([]);
            setPdfSignList([]);
            orgEmpListRef.current.clear();
        };

        // Datable Component CSS.
        document
            .querySelector('div.dataTables_length select')
            .classList.add('datatable-length');
        document
            .querySelector('div.dataTables_filter input')
            .classList.add('datatable-search');
    }, [keycloak, reset, tableSelectData, documentState.documentBy]);

    useEffect(() => {
        if (documentState.documentBy.id !== null) {
            const {id: documentById} = documentState.documentBy;
            $('#data-table').DataTable().ajax.url(
                config.get('apiUrl') + '/api/v3/documents?pageName=secret-doc-outbox' +
                '&senderId=' + documentById +
                '&documentById=' + documentById
            ).load();
        }
    }, [documentState.documentBy]);

    const handleSendSave = (formData) => {
        setIsSend(true);

        let data = {
            documentId: tableSelectData.documentId,
            documentType: tableSelectData.documentType,
            documentGenre: tableSelectData.documentGenre,
            registerNo: formData.registerNo || '',
            documentDate: documentDate,
            urgencyLevel: tableSelectData.urgencyLevel,
            secretLevel: tableSelectData.secretLevel,
            subject: formData.subject || '',
            senderId: documentState.documentBy.id,
            sender: documentState.documentBy.name,
            sentNo: tableSelectData.sentNo || '',
            receiverId: formData.receiverId || '',
            receiver: formData.receiver || '',
            owner: formData.owner || '',
            ownerTelephone: formData.ownerTelephone || '',
            refer: tableSelectData.refer || '',
            remark: tableSelectData.remark || '',
            command: formData.commandSend || formData.command || '',
            status: 1,
            // parentId: tableSelectData.processId,

            pdfSignList: pdfSignList,
            certificate: signatureCertificate.selected,
            documentBy: documentState.documentBy,
        }

        data = {
            ...data,
            insideOrg: orgEmpListRef.current.getAllSelectList(),
        }

        let params = new FormData();
        params.append('data', JSON.stringify(data));
        fileList.forEach((file) => {
            params.append('files', file);
        });

        mainApi.post('/api/book/save/v2', params).then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'ส่งหนังสือสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });
            $('#data-table').DataTable().ajax.reload();
            handleModalSentClose();
        }).finally(() => {
            setIsSend(false);
        });
    };

    const handleReferClick = async () => {
        const response = await mainApi.get(
            '/api/v2/document-detail/document-id/' + tableSelectData.refer
        );

        setDocumentReferDetail(response.data);
        handleModalClose();
        handleModalReferShow();
    };

    const handlePrint = async () => {
        setIsPrintLoading(true);
        await mainApi
            .get('/api/report/excel/02/' + tableSelectData.documentId, {
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, 'รายงานประวัติเอกสาร.xlsx');
                setIsPrintLoading(false);
            });
    };

    const handleUpdateCommand = async () => {
        let params = {
            processId: documentProcess.processId,
            value: documentProcess.command,
        };
        let response = await mainApi.post(
            '/api/v1/document-process/command',
            params
        );

        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'เพิ่มคำสั่งการสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });

            setDataProcess(
                dataProcess.map((process) => {
                    if (process.processId === documentProcess.processId) {
                        return {...process, command: documentProcess.command};
                    } else {
                        return process;
                    }
                })
            );
        }
    };

    const handleUpdateAction = async () => {
        let params = {
            processId: documentProcess.processId,
            value: documentProcess.action,
        };
        let response = await mainApi.post(
            '/api/v1/document-process/action',
            params
        );

        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'เพิ่มการปฏิบัติสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });

            setDataProcess(
                dataProcess.map((process) => {
                    if (process.processId === documentProcess.processId) {
                        return {...process, action: documentProcess.action};
                    } else {
                        return process;
                    }
                })
            );
        }
    };

    const handleAddAttachFile = async () => {
        let params = new FormData();
        params.append('documentId', documentProcess.documentId);
        params.append('processId', documentProcess.processId);

        fileList.forEach((file) => {
            params.append('files', file);
        });

        let response = await mainApi.post(
            '/api/v1/document-process/add-file',
            params
        );

        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'แนบไฟล์สำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });

            setFileList([]);
            setDataProcess(
                dataProcess.map((process) => {
                    if (process.processId === documentProcess.processId) {
                        response.data.forEach((attachFile) => {
                            process.documentAttachments.push(attachFile);
                        });
                    }
                    return process;
                })
            );
        }
    };

    const fetchDocumentDetailById = async () => {
        const response = await mainApi.get(
            '/api/v2/document-detail/document-id/' + tableSelectData.documentId
        );

        setDocumentDetail(response.data);
        setDocumentEditDate(new Date(response.data.documentDate));

        if (response.data.duedateDocument === null) {
            setDocumentEditDuedate(new Date());
            setIsCheckedDueDate(false);
        } else {
            setDocumentEditDuedate(new Date(response.data.duedateDocument));
            setIsCheckedDueDate(true);
        }

        if (response.data.refer) {
            setReferSelections([
                {
                    documentId: response.data.refer,
                    registerNo: response.data.referName,
                },
            ]);
        } else {
            setReferSelections([]);
        }
        handleModalClose();
        handleModalEditShow();
    };

    const handleDocumentEdit = (formData) => {
        let bodyData = {
            documentId: documentDetail.documentId,
            documentType: documentDetail.documentType,
            documentGenre: documentDetail.documentGenre === '--' ? 'IN' : documentDetail.documentGenre,
            registerNo: formData.registerNo || '',
            documentDate: moment(documentEditDate).format('YYYY-MM-DD'),
            urgencyLevel: formData.urgencyLevel,
            secretLevel: formData.secretLevel,
            subject: formData.subject || '',
            senderId: documentDetail.senderId || '',
            sender: documentDetail.sender || '',
            sentNo: documentDetail.sentNo || '',
            receiverId: documentDetail.receiverId || '',
            receiver: formData.receiver || '',
            owner: formData.owner || '',
            ownerTelephone: formData.ownerTelephone || '',
            remark: formData.remark || '',
            command: formData.command || '',
            status: documentDetail.documentStatus,

            documentBy: documentState.documentBy,
        }

        if (referSelections.length > 0) {
            bodyData = {
                ...bodyData,
                refer: referSelections[0].documentId,
            }
        }

        if (isCheckedDueDate === true) {
            bodyData = {
                ...bodyData,
                documentDuedate: moment(documentEditDuedate).format('YYYY-MM-DD'),
            }
        }

        let params = new FormData();
        params.append('data', JSON.stringify(bodyData));
        fileList.forEach((file) => {
            params.append('files', file);
        });

        mainApi.post('/api/book/save/v2', params).then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'บันทึกสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });
            $('#data-table').DataTable().ajax.reload();
            handleModalEditClose();
        });
    };

    const handleReferSearch = async (query) => {
        setIsReferLoading(true);

        let params = {
            registerNo: query,
        };

        let rs = await mainApi.post('/api/v1/documents/search/register_no', params);

        if (rs.status === 200) {
            setReferOptions(rs.data);
            setIsReferLoading(false);
        } else {
            setIsReferLoading(false);
        }
    };

    const handleSignatureAccept = (pdfSignList) => {
        setPdfSignList(pdfSignList);
        handleModalSentShow();
    }

    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>

                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        ทะเบียนออกเลขทั้งหมด
                                        <i className="nav-icon fas fa-angle-double-right sidebar-fa-size-header ml-2 mr-2 nvClick"></i>
                                        <button
                                            type="button"
                                            className="btn btn-primary page-heading mb-2"
                                        >
                                            {isLoadingDocRunningNo && (
                                                <div className="spinner-border spinner-border-sm mb-1" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            )}
                                            {!isLoadingDocRunningNo && (
                                                <div>{docRunningNo}</div>
                                            )}
                                        </button>
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            ทะเบียนออกเลขทั้งหมด
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    <table
                                        id="data-table"
                                        className="table table-striped table-bordered display w-100 margin-bottom-30"
                                    >
                                        <thead>
                                        <tr>
                                            <th className="text-center">ลงวันที่</th>
                                            <th className="text-center">เลขส่ง</th>
                                            <th className="text-center">เลขที่</th>
                                            <th className="text-center">ลงวันที่</th>
                                            <th className="text-center">ประเภทหนังสือ</th>
                                            <th className="text-center">ถึง</th>
                                            <th className="text-center">เรียน</th>
                                            <th className="text-center">เจ้าของเรื่อง</th>
                                            <th className="text-center">เรื่อง</th>
                                            <th className="text-center">คำสั่งการ</th>
                                            <th className="text-center">วันที่ส่ง</th>
                                            <th className="text-center">วันที่รับ</th>
                                            {/*<th className="text-center">สถานะ</th>*/}
                                            <th className="text-center">ประเภท</th>
                                            <th className="text-center">จัดการ</th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
            <Modal size="xl" show={modalShow} onHide={handleModalClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-book mr-2"></i>รายละเอียดเอกสาร
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    {tableSelectData.receiveNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขทะเบียนรับ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 ">
                                                {tableSelectData.receiveNo}
                                            </div>
                                        </div>
                                    )}
                                    {tableSelectData.oldNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขเดิม
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 ">
                                                {tableSelectData.oldNo}
                                            </div>
                                        </div>
                                    )}
                                    {tableSelectData.registerNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขที่
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {tableSelectData.registerNo}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3">
                                            <label className="text-color-form align-middle">
                                                ชัันความเร็ว / ความลับ
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {tableSelectData.urgencyLevelName} /{' '}
                                            {tableSelectData.secretLevelName}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3">
                                            <label className="text-color-form align-middle">
                                                เรื่อง
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal show-truncate">
                                            {tableSelectData.subject}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3 ">
                                            <label className="text-color-form align-middle">
                                                เรียน
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {tableSelectData.receiver}
                                        </div>
                                    </div>
                                    {tableSelectData.refer && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    อ้างถึงหนังสือ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                <a href="#section" onClick={handleReferClick}>
                                                    {tableSelectData.referName}
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {tableSelectData.owner && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3">
                                                <label className="text-color-form align-middle">
                                                    เจ้าของเรื่อง
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {tableSelectData.owner}
                                            </div>
                                        </div>
                                    )}
                                    {tableSelectData.ownerTelephone && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3">
                                                <label className="text-color-form align-middle">
                                                    เบอร์โทรศัพท์
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {tableSelectData.ownerTelephone}
                                            </div>
                                        </div>
                                    )}
                                    {tableSelectData.command && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    คำสั่งการ (รายละเอียด)
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {tableSelectData.command}
                                            </div>
                                        </div>
                                    )}
                                    {tableSelectData.remark && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    หมายเหตุ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {tableSelectData.remark}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 text-header-x2 ddc-color">
                                <i className="fas fa-paper-plane mr-2"></i>ถึงหน่วยงาน
                            </div>
                        </div>
                        <HistoryBox disableAttachFile={true} ref={historyBoxRef}/>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-add-small "
                        disabled={isPrintLoading}
                        onClick={!isPrintLoading ? handlePrint : null}
                    >
                        <i className="fas fa-print pr-2"></i>
                        ดาวน์โหลดประวัติเอกสาร
                        {isPrintLoading && (
                            <div
                                className="spinner-border spinner-border-sm ml-2"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </Button>
                    <Button
                        type="button"
                        className="btn-color-submit mr-2"
                        onClick={() => {
                            fetchDocumentDetailById();
                        }}
                    >
                        <i className="fas fa-edit mr-2"></i>แก้ไขเอกสาร
                    </Button>
                    {/*{tableSelectData.status !== '4' && tableSelectData.status !== '5' && (*/}
                    {/*    <Button*/}
                    {/*        type="button"*/}
                    {/*        className="btn-color-submit mr-2"*/}
                    {/*        onClick={() => {*/}
                    {/*            handleSignature();*/}
                    {/*            handleModalClose();*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <i className="fas fa-file-signature mr-2"></i>ลงนามและส่งต่อหน่วยงานอื่น*/}
                    {/*    </Button>*/}
                    {/*)}*/}
                    {tableSelectData.status !== '4' && tableSelectData.status !== '5' && (
                        <Button
                            type="button"
                            className="btn-color-submit mr-2"
                            onClick={() => {
                                handleModalSentShow();
                                handleModalClose();
                            }}
                        >
                            <i className="fas fa-paper-plane pr-2"></i>ส่งต่อหน่วยงานอื่น
                        </Button>
                    )}
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={handleModalClose}
                    >
                        <i className="fas fa-ban pr-2"></i>ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalSentShow} onHide={handleModalSentClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-paper-plane mr-2"></i>ส่งต่อหน่วยงานอื่น
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">ที่</label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name="registerNo"
                                    ref={register}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">ลงวันที่</label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <DateInput
                                    value={documentDate}
                                    onChange={(date) => setDocumentDate(date)}
                                    readOnly
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">เรื่อง</label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name="subject"
                                    ref={register}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">เรียน</label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <input
                                    className="form-control shadow-none d-none"
                                    type="text"
                                    name="receiverId"
                                    ref={register}
                                />
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name="receiver"
                                    ref={register}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">
                                    เจ้าของเรื่อง
                                </label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name="owner"
                                    ref={register}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">
                                    เบอร์โทรศัพท์
                                </label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name="ownerTelephone"
                                    ref={register}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">
                                    คำสั่งการ (รายละเอียด)
                                </label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <textarea
                                    className="form-control shadow-none"
                                    rows="5"
                                    name="commandSend"
                                    ref={register({max: 255})}
                                ></textarea>
                            </div>
                        </div>
                        {/*<div className="row mt-1">*/}
                        {/*    <div className="col-12 col-md-12 pt-1">*/}
                        {/*        <UploadFiles fileList={fileList} setFileList={setFileList}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-submit mr-2"
                        disabled={isSend}
                        onClick={!isSend ? () => {
                            if (!isSend) {
                                orgEmpListRef.current.clear();
                                orgEmpListRef.current.show();
                            }
                        } : null}
                    >
                        <i className="fas fa-paper-plane mr-2"></i>เลือกหน่วยงาน
                        {isSend && (
                            <div
                                className="spinner-border spinner-border-sm ml-2"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </Button>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={handleModalSentClose}
                    >
                        <i className="fas fa-ban mr-2"></i>ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="xl" show={modalEditShow} onHide={handleModalEditClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-edit mr-2"></i>แก้ไขเอกสาร
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-md-3 col-lg-2">
                            <span className="text-color-form align-middle">ที่</span>
                        </div>
                        <div className="col-12 col-md-3 text-normal">
                            <input
                                className="form-control shadow-none"
                                type="text"
                                name="registerNo"
                                ref={registerEdit}
                                defaultValue={documentDetail.registerNo}
                                // readOnly
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-md-3 col-lg-2 pt-2">
                            <span className="text-color-form align-middle">ลงวันที่</span>
                        </div>
                        <div className="col-12 col-md-3 pt-1">
                            <DateInput
                                value={documentEditDate}
                                onChange={(date) => setDocumentEditDate(date)}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-md-3 col-lg-2">
                            <span className="text-color-form align-middle">ความลับ</span>
                        </div>
                        <div className="col-12 col-md-3 text-normal">
                            <select
                                className="form-control shadow-none"
                                name="secretLevel"
                                ref={registerEdit}
                                defaultValue={documentDetail.secretLevel}
                                onChange={(e) => setSelectUrgencyLevel(e.target.value)}
                                // disabled
                            >
                                {listSecretLevel.map((item) => {
                                    return (
                                        <option
                                            key={item.secret_level_id}
                                            value={item.secret_level_id}
                                        >
                                            {item.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-12 col-md-2 pt-1 text-normal text-center">
                            ชั้นความเร็ว
                        </div>
                        <div className="col-12 col-md-3 text-normal">
                            <select
                                className="form-control shadow-none"
                                name="urgencyLevel"
                                ref={registerEdit}
                                defaultValue={documentDetail.urgencyLevel}
                                // disabled
                            >
                                {listUrgencyLevel.map((item) => {
                                    return (
                                        <option
                                            key={item.urgency_level_id}
                                            value={item.urgency_level_id}
                                        >
                                            {item.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-md-3 col-lg-2">
                            <span className="text-color-form align-middle">เรื่อง</span>
                            <span className="text-danger"> *</span>
                        </div>
                        <div className="col-12 col-md-9 col-lg-10 text-normal">
                            <input
                                className="form-control shadow-none"
                                type="text"
                                name="subject"
                                ref={registerEdit({required: true})}
                                defaultValue={documentDetail.subject}
                                readOnly={read}
                            />
                            {errorsEdit.subject && (
                                <span className="text-danger">กรุณากรอกข้อมูล</span>
                            )}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-md-3 col-lg-2">
                            <span className="text-color-form align-middle">เรียน</span>
                            <span className="text-danger"> *</span>
                        </div>
                        <div className="col-12 col-md-9 col-lg-10 text-normal">
                            <input
                                className="form-control shadow-none"
                                type="text"
                                name="receiver"
                                ref={registerEdit({required: true})}
                                defaultValue={documentDetail.receiver}
                            />
                            {errorsEdit.receiver && (
                                <span className="text-danger">กรุณากรอกข้อมูล</span>
                            )}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-md-3 col-lg-2">
                            <span className="text-color-form align-middle">
                                อ้างถึงหนังสือ (ถ้ามี)
                            </span>
                        </div>
                        <div className="col-12 col-md-9 col-lg-10 text-normal">
                            <AsyncTypeahead
                                filterBy={() => true}
                                id="basic-typeahead-single"
                                isLoading={isReferLoading}
                                labelKey="registerNo"
                                onChange={setReferSelections}
                                options={referOptions}
                                placeholder="กรุณาพิมพ์เลขที่หนังสือ..."
                                selected={referSelections}
                                minLength={2}
                                onSearch={handleReferSearch}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-md-3 col-lg-2">
                            <span className="text-color-form align-middle">
                                เจ้าของเรื่อง
                            </span>
                        </div>
                        <div className="col-12 col-md-9 col-lg-4 text-normal">
                            <input
                                className="form-control shadow-none"
                                type="text"
                                name="owner"
                                ref={registerEdit}
                                defaultValue={documentDetail.owner}
                            />
                        </div>
                        <div className="col-12 col-md-3 col-lg-2 pt-1 text-normal text-center">
                            <span className="text-color-form align-middle">
                                เบอร์โทรศัพท์
                            </span>
                        </div>
                        <div className="col-12 col-md-9 col-lg-4 text-normal">
                            <input
                                className="form-control shadow-none"
                                type="text"
                                name="ownerTelephone"
                                ref={registerEdit({
                                    maxLength: {
                                        value: 50,
                                        message: 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร',
                                    },
                                })}
                                defaultValue={documentDetail.ownerTelephone}
                            />
                            {errorsEdit.ownerTelephone && (
                                <span className="text-danger">
                                    {errorsEdit.ownerTelephone.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="row mt-3" style={{display: "none"}}>
                        <div className="col-12 col-md-3 col-lg-2">
                            <span className="text-color-form align-middle">
                                คำสั่งการ (รายละเอียด)
                            </span>
                        </div>
                        <div className="col-12 col-md-9 col-lg-10 text-normal">
                            <textarea
                                className="form-control shadow-none"
                                rows="4"
                                name="command"
                                ref={registerEdit({max: 255})}
                                defaultValue={documentDetail.command}
                            ></textarea>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-md-3 col-lg-2">
                            <span className="text-color-form align-middle">หมายเหตุ</span>
                        </div>
                        <div className="col-12 col-md-9 col-lg-10 text-normal">
                            <textarea
                                className="form-control shadow-none"
                                rows="4"
                                name="remark"
                                ref={registerEdit({max: 255})}
                                defaultValue={documentDetail.remark}
                            ></textarea>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-md-3 col-lg-2">
                            <span className="text-color-form align-middle">
                                วันที่กำหนดเอกสาร
                            </span>
                        </div>
                        <div className="col-12 col-md-9">
                            <div className="">
                                <Checkbox
                                    // defaultChecked
                                    checked={isCheckedDueDate}
                                    color="secondary"
                                    onChange={(event) => {
                                        setIsCheckedDueDate(event.target.checked);
                                    }}
                                    name="checkboxDueDate"
                                />
                                <DateInput
                                    value={documentEditDuedate}
                                    onChange={(date) => setDocumentEditDuedate(date)}
                                    disabled={!isCheckedDueDate}
                                    readOnly={!isCheckedDueDate}
                                    className="ml-50"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="submit"
                        variant="contained"
                        className="btn-no-border btn-color-submit mr-2"
                        onClick={handleSubmitEdit(handleDocumentEdit)}
                    >
                        <i className="fas fa-save pr-2"></i>บันทึก
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        className="btn-no-border btn-color-delete-small"
                        onClick={handleModalEditClose}
                    >
                        <i className="fas fa-ban pr-2"></i>
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={modalReferShow} onHide={handleModalReferClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-book mr-2"></i>รายละเอียดเอกสารอ้างอิง
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    {documentReferDetail.receiveNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขทะเบียนรับ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 ">
                                                {documentReferDetail.receiveNo}
                                            </div>
                                        </div>
                                    )}
                                    {documentReferDetail.oldNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขเดิม
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 ">
                                                {documentReferDetail.oldNo}
                                            </div>
                                        </div>
                                    )}
                                    {documentReferDetail.registerNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขที่
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {documentReferDetail.registerNo}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3">
                                            <label className="text-color-form align-middle">
                                                ชัันความเร็ว / ความลับ
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.urgencyLevelName} /{' '}
                                            {documentReferDetail.secretLevelName}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3">
                                            <label className="text-color-form align-middle">
                                                เรื่อง
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.subject}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3 ">
                                            <label className="text-color-form align-middle">
                                                เรียน
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.receiver}
                                        </div>
                                    </div>
                                    {documentReferDetail.refer && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    อ้างถึงหนังสือ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {documentReferDetail.referName}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3">
                                            <label className="text-color-form align-middle">
                                                เจ้าของเรื่อง
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.owner}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3 ">
                                            <label className="text-color-form align-middle">
                                                คำสั่งการ (รายละเอียด)
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.command}
                                        </div>
                                    </div>
                                    {documentReferDetail.remark && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    หมายเหตุ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {documentReferDetail.remark}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={() => {
                            handleModalReferClose();
                            handleModalShow();
                        }}
                    >
                        <i className="fas fa-undo-alt pr-2"></i>ย้อนกลับ
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalCommandShow} onHide={handleModalCommandClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-paper-plane mr-2"></i>เพิ่มคำสั่งการ
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <span className="text-header-x2 ddc-color">คำสั่งการ :</span>
                        <div className="row mt-3">
                            <div className="col">
                                <textarea
                                    rows="3"
                                    style={{width: '100%'}}
                                    value={documentProcess.command || ''}
                                    onChange={(e) => {
                                        setDocumentProcess({
                                            ...documentProcess,
                                            command: e.target.value,
                                        });
                                    }}
                                ></textarea>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-add-small"
                        onClick={() => {
                            handleModalCommandClose();
                            handleUpdateCommand();
                        }}
                    >
                        <i className="far fa-save pr-2"></i>บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalActionShow} onHide={handleModalActionClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-paper-plane mr-2"></i>เพิ่มการปฏิบัติ
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <span className="text-header-x2 ddc-color">การปฏิบัติ :</span>
                        <div className="row mt-3">
                            <div className="col">
                                <textarea
                                    rows="3"
                                    style={{width: '100%'}}
                                    value={documentProcess.action || ''}
                                    onChange={(e) => {
                                        setDocumentProcess({
                                            ...documentProcess,
                                            action: e.target.value,
                                        });
                                    }}
                                ></textarea>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-add-small"
                        onClick={() => {
                            handleModalActionClose();
                            handleUpdateAction();
                        }}
                    >
                        <i className="far fa-save pr-2"></i>บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalAttachFileShow} onHide={handleModalAttachFileClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-paper-plane mr-2"></i>เพิ่มแนบไฟล์
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <UploadFiles fileList={fileList} setFileList={setFileList}/>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-add-small"
                        onClick={() => {
                            handleModalAttachFileClose();
                            handleAddAttachFile();
                        }}
                    >
                        <i className="far fa-save pr-2"></i>บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>

            <OrgEmpList onAccept={handleSubmit(handleSendSave)} ref={orgEmpListRef}/>
            <DigitalSignatureModal
                onAccept={handleSignatureAccept}
                labelSubmitSignature={'ส่งต่อหน่วยงานอื่น'}
                ref={digitalSignatureRef}
            />
        </div>
    );
};

export default SecretDocOutbox;
