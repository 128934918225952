export const urgencyLevel = [
    {
        id: "1",
        name: "ปกติ"
    },
    {
        id: "2",
        name: "ด่วน"
    },
    {
        id: "3",
        name: "ด่วนมาก"
    },
    {
        id: "4",
        name: "ด่วนที่สุด"
    },
];
