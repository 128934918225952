import React, { useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { mainApi } from '../../HttpClient';
import { useForm } from 'react-hook-form';
import config from 'react-global-configuration';
import Swal from 'sweetalert2';
import { useSelector } from "react-redux";

const MasDocumentRegisterByUserLogin = () => {
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [isFetchData, setIsFetchData] = useState(false);
    const [documentRegister, setDocumentRegister] = useState({});
    const {register, handleSubmit, errors, reset} = useForm();

    const documentState = useSelector((state) => state.document);

    useEffect(() => {
        const {id: documentById, type: documentByType} = documentState.documentBy;
        const fetchDocumentRegisterByUserLogin = () => {
            setIsFetchData(true);
            mainApi.get(
                '/api/v1/documents-register/by-document-by-id'
                + '?documentById=' + documentById
                + '&documentByType=' + documentByType
            ).then((res) => {
                reset(res.data);
                setDocumentRegister(res.data);
                setIsFetchData(false);
            });
        };
        fetchDocumentRegisterByUserLogin();
    }, [documentState.documentBy, reset]);

    const handleUpdate = async (formRegister) => {
        setIsUpdateLoading(true);
        let param = {
            registerNoR: formRegister.registerNoR,
            registerNoRIn: formRegister.registerNoRIn,
            registerNoROut: formRegister.registerNoROut,
            registerNoS: formRegister.registerNoS,
            registerNoSIn: formRegister.registerNoSIn,
            registerNoSOut: formRegister.registerNoSOut,
            registerNoW: formRegister.registerNoW,
            // registerNoSecret: formRegister.registerNoSecret,
            registerNoSecretR: formRegister.registerNoSecretR,
            registerNoSecretS: formRegister.registerNoSecretS,
            documentBy: documentState.documentBy,
        };
        await mainApi
            .post('/api/v2/documents-register/update-register-no', param)
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: 'สำเร็จ',
                    text: 'บันทึกสำเร็จ',
                    timer: config.get('alertTimer'),
                    timerProgressBar: true,
                });
                setIsUpdateLoading(false);
            });
    };

    return (
        <div>
            <div className="content-wrapper" style={{minHeight: '100vh'}}>
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        ทะเบียนเลขที่หนังสือ
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            ทะเบียนเลขที่หนังสือ
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            {
                                                documentState.documentBy.type === 'employee'
                                                    ? 'เลขที่หนังสือ : ไม่มีสิทธิออกหนังสือ'
                                                    : `เลขที่หนังสือ : สธ ${documentRegister.organizeCode || ''}`
                                            }
                                        </div>
                                    </div>
                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            <th>ประเภทเลขทะเบียนส่งออก</th>
                                            <th>หมายเลขปัจจุบัน</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr style={{display: 'none'}}>
                                            <td>หนังสือรับ</td>
                                            <td className="position-relative">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="number"
                                                    name="registerNoR"
                                                    min="0"
                                                    ref={register({min: 0})}
                                                />
                                                <div
                                                    className="spinner-border spinner-border-sm ml-2 position-absolute"
                                                    style={{top: '1.75em', right: '1.5em', display: isFetchData ? 'block' : 'none'}}
                                                    role="status"
                                                >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                {errors.registerNoR && (
                                                    <span className="text-danger">
                                                        {errors.registerNoR?.type === 'required' && 'กรุณากรอกข้อมูล'}
                                                        {errors.registerNoR?.type === 'min' && 'กรุณากรอกตัวเลขตั้งแต่ 0'}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>หนังสือรับ (ภายใน)</td>
                                            <td className="position-relative">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="number"
                                                    name="registerNoRIn"
                                                    min="0"
                                                    ref={register({min: 0})}
                                                />
                                                <div
                                                    className="spinner-border spinner-border-sm ml-2 position-absolute"
                                                    style={{top: '1.75em', right: '1.5em', display: isFetchData ? 'block' : 'none'}}
                                                    role="status"
                                                >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                {errors.registerNoRIn && (
                                                    <span className="text-danger">
                                                        {errors.registerNoRIn?.type === 'required' && 'กรุณากรอกข้อมูล'}
                                                        {errors.registerNoRIn?.type === 'min' && 'กรุณากรอกตัวเลขตั้งแต่ 0'}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>หนังสือรับ (ภายนอก)</td>
                                            <td className="position-relative">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="number"
                                                    name="registerNoROut"
                                                    min="0"
                                                    ref={register({min: 0})}
                                                />
                                                <div
                                                    className="spinner-border spinner-border-sm ml-2 position-absolute"
                                                    style={{top: '1.75em', right: '1.5em', display: isFetchData ? 'block' : 'none'}}
                                                    role="status"
                                                >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                {errors.registerNoROut && (
                                                    <span className="text-danger">
                                                        {errors.registerNoROut?.type === 'required' && 'กรุณากรอกข้อมูล'}
                                                        {errors.registerNoROut?.type === 'min' && 'กรุณากรอกตัวเลขตั้งแต่ 0'}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>ออกเลขส่ง</td>
                                            <td className="position-relative">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="number"
                                                    name="registerNoS"
                                                    min="0"
                                                    ref={register({min: 0})}
                                                />
                                                <div
                                                    className="spinner-border spinner-border-sm ml-2 position-absolute"
                                                    style={{top: '1.75em', right: '1.5em', display: isFetchData ? 'block' : 'none'}}
                                                    role="status"
                                                >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                {errors.registerNoS && (
                                                    <span className="text-danger">
                                                        {errors.registerNoS?.type === 'required' && 'กรุณากรอกข้อมูล'}
                                                        {errors.registerNoS?.type === 'min' && 'กรุณากรอกตัวเลขตั้งแต่ 0'}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr style={{display: 'none'}}>
                                            <td>หนังสือส่งต่อ (ภายใน)</td>
                                            <td className="position-relative">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="number"
                                                    name="registerNoSIn"
                                                    min="0"
                                                    ref={register({min: 0})}
                                                />
                                                <div
                                                    className="spinner-border spinner-border-sm ml-2 position-absolute"
                                                    style={{top: '1.75em', right: '1.5em', display: isFetchData ? 'block' : 'none'}}
                                                    role="status"
                                                >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                {errors.registerNoSIn && (
                                                    <span className="text-danger">
                                                        {errors.registerNoSIn?.type === 'required' && 'กรุณากรอกข้อมูล'}
                                                        {errors.registerNoSIn?.type === 'min' && 'กรุณากรอกตัวเลขตั้งแต่ 0'}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr style={{display: 'none'}}>
                                            <td>หนังสือส่งต่อ (ภายนอก)</td>
                                            <td className="position-relative">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="number"
                                                    name="registerNoSOut"
                                                    min="0"
                                                    ref={register({min: 0})}
                                                />
                                                <div
                                                    className="spinner-border spinner-border-sm ml-2 position-absolute"
                                                    style={{top: '1.75em', right: '1.5em', display: isFetchData ? 'block' : 'none'}}
                                                    role="status"
                                                >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                {errors.registerNoSOut && (
                                                    <span className="text-danger">
                                                        {errors.registerNoSOut?.type === 'required' && 'กรุณากรอกข้อมูล'}
                                                        {errors.registerNoSOut?.type === 'min' && 'กรุณากรอกตัวเลขตั้งแต่ 0'}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>ออกเลขเวียน</td>
                                            <td className="position-relative">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="number"
                                                    name="registerNoW"
                                                    min="0"
                                                    ref={register({min: 0})}
                                                />
                                                <div
                                                    className="spinner-border spinner-border-sm ml-2 position-absolute"
                                                    style={{top: '1.75em', right: '1.5em', display: isFetchData ? 'block' : 'none'}}
                                                    role="status"
                                                >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                {errors.registerNoW && (
                                                    <span className="text-danger">
                                                        {errors.registerNoW?.type === 'required' && 'กรุณากรอกข้อมูล'}
                                                        {errors.registerNoW?.type === 'min' && 'กรุณากรอกตัวเลขตั้งแต่ 0'}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        {/*<tr className="d-none">*/}
                                        {/*    <td>ออกเลขลับ</td>*/}
                                        {/*    <td className="position-relative">*/}
                                        {/*        <input*/}
                                        {/*            className="form-control shadow-none"*/}
                                        {/*            type="number"*/}
                                        {/*            name="registerNoSecret"*/}
                                        {/*            min="0"*/}
                                        {/*            ref={register({min: 0})}*/}
                                        {/*        />*/}
                                        {/*        <div*/}
                                        {/*            className="spinner-border spinner-border-sm ml-2 position-absolute"*/}
                                        {/*            style={{top: '1.75em', right: '1.5em', display: isFetchData ? 'block' : 'none'}}*/}
                                        {/*            role="status"*/}
                                        {/*        >*/}
                                        {/*            <span className="sr-only">Loading...</span>*/}
                                        {/*        </div>*/}
                                        {/*        {errors.registerNoSecret && (*/}
                                        {/*            <span className="text-danger">*/}
                                        {/*                {errors.registerNoSecret?.type === 'required' && 'กรุณากรอกข้อมูล'}*/}
                                        {/*                {errors.registerNoSecret?.type === 'min' && 'กรุณากรอกตัวเลขตั้งแต่ 0'}*/}
                                        {/*            </span>*/}
                                        {/*        )}*/}
                                        {/*    </td>*/}
                                        {/*</tr>*/}
                                        <tr
                                            // style={{
                                            //     display:
                                            //         !isRole([
                                            //             'secret-doc',
                                            //         ]) && 'none',
                                            // }}
                                        >
                                            <td>หนังสือรับ (ลับ)</td>
                                            <td className="position-relative">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="number"
                                                    name="registerNoSecretR"
                                                    min="0"
                                                    ref={register({min: 0})}
                                                />
                                                <div
                                                    className="spinner-border spinner-border-sm ml-2 position-absolute"
                                                    style={{top: '1.75em', right: '1.5em', display: isFetchData ? 'block' : 'none'}}
                                                    role="status"
                                                >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                {errors.registerNoSecretR && (
                                                    <span className="text-danger">
                                                        {errors.registerNoSecretR?.type === 'required' && 'กรุณากรอกข้อมูล'}
                                                        {errors.registerNoSecretR?.type === 'min' && 'กรุณากรอกตัวเลขตั้งแต่ 0'}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr
                                            // style={{
                                            //     display:
                                            //         !isRole([
                                            //             'secret-doc',
                                            //         ]) && 'none',
                                            // }}
                                        >
                                            <td>หนังสือส่ง (ลับ)</td>
                                            <td className="position-relative">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="number"
                                                    name="registerNoSecretS"
                                                    min="0"
                                                    ref={register({min: 0})}
                                                />
                                                <div
                                                    className="spinner-border spinner-border-sm ml-2 position-absolute"
                                                    style={{top: '1.75em', right: '1.5em', display: isFetchData ? 'block' : 'none'}}
                                                    role="status"
                                                >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                {errors.registerNoSecretS && (
                                                    <span className="text-danger">
                                                        {errors.registerNoSecretS?.type === 'required' && 'กรุณากรอกข้อมูล'}
                                                        {errors.registerNoSecretS?.type === 'min' && 'กรุณากรอกตัวเลขตั้งแต่ 0'}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    <div className="row">
                                        <div className="col-12">
                                            <Button
                                                type="button"
                                                className="btn-color-add-small "
                                                disabled={isUpdateLoading || isFetchData}
                                                onClick={
                                                    !isUpdateLoading ? handleSubmit(handleUpdate) : null
                                                }
                                            >
                                                <i className="fas fa-save pr-2"></i>
                                                บันทึก
                                                {isUpdateLoading && (
                                                    <div
                                                        className="spinner-border spinner-border-sm ml-2"
                                                        role="status"
                                                    >
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        </div>
    );
};

export default MasDocumentRegisterByUserLogin;
