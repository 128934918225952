import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { getDateFullMonthFormat } from "../../util/Date";

export default function templateHtmlFormAutoGenreIn(data, type) {
    const result = [];
    const getData = (data1, data2) => {
        return type === "1" ? data1 : data2;
    };

    const pageMargin = {top: 15.0, right: 20.0, bottom: 15.0, left: 30.0};

    const header = (
        <html>
        <head>
            <title>{data.autoFormName || ''}</title>
            <link rel="stylesheet" href="assets/formAutoGenreIn.css"/>
        </head>
        <body>
        <div className="flex-container">
            <div className="title-flex-item-left">
                <img
                    className="img-garuda"
                    src="assets/garuda_type_2.png"
                    alt="Thai Government Garuda"
                />
            </div>
            <div className="title-flex-item-center text-title">บันทึกข้อความ</div>
            <div className="title-flex-item-right"></div>
        </div>
        <table style={{marginBottom: '6pt'}}>
            <tr>
                <td className="line-container">
                    <div className="text-bold">ส่วนราชการ</div>
                    <div className="font-th-sarabun-it9" style={{flex: '1', position: 'relative'}}>
                        <div className="line-content">
                            {getData(data.government, data.f2Government)}&nbsp;&nbsp;
                            โทร.&nbsp;{getData(data.tel, data.f2Tel) || "-"}&nbsp;&nbsp;
                            โทรสาร.&nbsp;{getData(data.fax, data.f2Fax) || "-"}
                        </div>
                        <div className="line-content-underline">
                            <hr className="dotted"/>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="line-container">
                    <div className="text-bold">ที่</div>
                    <div className="font-th-sarabun-it9" style={{flex: '1', position: 'relative'}}>
                        <div className="line-content">{getData(data.autoFormNo, data.f2AutoFormNo)}</div>
                        <div className="line-content-underline">
                            <hr className="dotted"/>
                        </div>
                    </div>
                    <div className="text-bold">วันที่</div>
                    <div className="font-th-sarabun-it9" style={{flex: '0.95', position: 'relative'}}>
                        <div className="line-content">&nbsp;&nbsp;&nbsp;{getDateFullMonthFormat(getData(data.autoFormDate, data.f2AutoFormDate))}</div>
                        <div className="line-content-underline">
                            <hr className="dotted"/>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="line-container">
                    <div className="text-bold">เรื่อง</div>
                    <div className="font-th-sarabun-it9" style={{flex: '1', position: 'relative'}}>
                        <div className="line-content">{getData(data.subject, data.f2Subject)}</div>
                        <div className="line-content-underline">
                            <hr className="dotted"/>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <span>{getData(data.prefixInform, data.f2PrefixInform)}</span>&nbsp;&nbsp;{getData(data.inform, data.f2Inform)}
                </td>
            </tr>
        </table>
        <div className="document-content">
            ***replace-content***
        </div>
        </body>
        </html>
    );

    let html = ReactDOMServer.renderToString(header);
    let content = getData(data.content, data.f2Content);
    html = html.replaceAll("***replace-content***", content);

    result.push({html, pageMargin});

    const departmentNameList = getData(data.departmentNameList, data.f2DepartmentNameList);
    if (departmentNameList && departmentNameList.length > 0) {
        const listType = getData(data.listType, data.f2ListType) || "0";
        const departmentListTypeName = listType.toString() === "1" ? "ผู้อำนวยการ" : "";
        const page2 = (
            <html>
            <head>
                <title>{data.autoFormName || ''}</title>
                <link rel="stylesheet" href="assets/formAutoGenreIn.css"/>
            </head>
            <body>
            <div className="container">
                <div className="text-center" style={{marginBottom: '6pt'}}>
                    {getData(data.inform, data.f2Inform)}
                </div>
                {getData(data.departmentNameList, data.f2DepartmentNameList).map((item, index) => {
                    let itemName = item;
                    if (getData(data.departmentList, data.f2DepartmentList)[index].toString() !== '1002') {
                        itemName = departmentListTypeName + item;
                    }
                    return (
                        <div key={index} className="font-th-sarabun-it9">{index + 1}. {itemName}</div>
                    );
                })}
            </div>
            </body>
            </html>
        );
        result.push({html: ReactDOMServer.renderToString(page2), pageMargin});
    }

    return result;
};
