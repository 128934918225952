import { PDFRenderer } from "@cyntler/react-doc-viewer";
import { Button, Container } from "react-bootstrap";
import { Document, Page } from "react-pdf";
import React, { useState } from 'react';

const MyCustomPDFRenderer: PDFRenderer = ({mainState: {currentDocument}}) => {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    if (!currentDocument) return null;

    let page = {width: 0, height: 0};

    const onDocumentLoadSuccess = (pdf) => {
        setNumPages(pdf.numPages);
    }

    const onPageLoadSuccess = async (pdf) => {
        const pdfWidth = pdf.originalWidth;
        const pdfHeight = pdf.originalHeight;

        page = {width: pdfWidth, height: pdfHeight};
    }

    const changePage = (offset) => {
        setPageNumber(pageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    return (
        <Container className="pdf-sign-viewer">
            <Document file={currentDocument.fileData} onLoadSuccess={onDocumentLoadSuccess}>
                <Page
                    width={page.width}
                    height={page.height}
                    pageNumber={pageNumber}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    onLoadSuccess={onPageLoadSuccess}>
                </Page>
            </Document>
            <div className="row m-4">
                <div className="col-12 text-center">
                    <Button type="button" disabled={pageNumber <= 1} onClick={previousPage}>‹</Button>
                    <span className="m-2">หน้า {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}</span>
                    <Button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>›</Button>
                </div>
            </div>
        </Container>
    );
};

export default MyCustomPDFRenderer;