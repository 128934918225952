import React, {useState, useEffect} from 'react';
import {Container, Table, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useKeycloak} from '@react-keycloak/web';
import {mainApi} from '../../HttpClient';
import moment from 'moment';
import {useSelector, useDispatch} from 'react-redux';
import {
    sMonth,
    setSelectMonth,
    sYear,
    setSelectYear,
    sListSummary,
    setListSummary,
} from "../../app/stores/report/RptSummaryByMonthSlice";

import $ from 'jquery';

import {getDateThaiShortFormat, getYearThaiShortFormat} from '../util/Date';

const RptSummaryByMonth = () => {
    const [keycloak] = useKeycloak();
    const [listMonth, setlistMonth] = useState([]);
    const [listYear, setlistYear] = useState([]);
    const selectMonth = useSelector(sMonth);
    const selectYear = useSelector(sYear);
    const listSummary = useSelector(sListSummary);
    const documentState = useSelector((state) => state.document);
    const dispatch = useDispatch();

    useEffect(() => {
        const getYear = () => {
            let tmpYear = [];
            let start = moment('2021-01-01');
            const cur_year = moment();
            for (
                let year = moment(cur_year);
                year.isAfter(start);
                year.add(-1, 'year')
            ) {
                year.format('YYYY');
                addYear(year, tmpYear);
            }
            setlistYear(tmpYear);
        };
        getYear();

        const getMonth = () => {
            let tmpMonth = [];
            setlistMonth(tmpMonth);
        };
        getMonth();
    }, [keycloak]);

    useEffect(() => {
        const findYear = () => {
            let cur_date = moment();
            let tmpMonth = [];
            if (selectYear === '2021') {
                const startOfMonth = '2021-05-01';
                const endOfMonth = '2021-12-31';
                for (
                    let m = moment(startOfMonth);
                    m.isBefore(endOfMonth) || m.isSame(endOfMonth);
                    m.add(1, 'month')
                ) {
                    tmpMonth.push({
                        label:
                            'เดือน ' + m.format('MMMM') + ' ' + getYearThaiShortFormat(m),
                        value: m.format('MM') + '-' + m.format('YYYY'),
                    });
                }
            } else if (selectYear && selectYear < cur_date) {
                let tmpLastDay = moment(selectYear + '-12-31');
                if (tmpLastDay.isSame(cur_date, 'year')) {
                    tmpLastDay = moment(cur_date);
                }
                for (
                    let m = moment(selectYear);
                    m.isBefore(tmpLastDay, 'month') || m.isSame(tmpLastDay, 'month');
                    m.add(1, 'month')
                ) {
                    tmpMonth.push({
                        label:
                            'เดือน ' + m.format('MMMM') + ' ' + getYearThaiShortFormat(m),
                        value: m.format('MM') + '-' + m.format('YYYY'),
                    });
                }
            }
            setlistMonth(tmpMonth);
        };

        findYear();
    }, [selectYear]);


    const addYear = (currentYr, currentArr) => {
        currentArr.push({
            label: getYearThaiShortFormat(currentYr),
            value: currentYr.format('YYYY'),
        });
    };

    const showLoading = async () => {
        $('.loading-container').removeClass('d-none');
        $('.loading-container').addClass('d-block');
    };

    const hideLoading = async () => {
        $('.loading-container').removeClass('d-block');
        $('.loading-container').addClass('d-none');
    };

    const disableButtonSearch = async () => {
        $('#btn-find-report').prop('disabled', true);
    };

    const enableButtonSearch = async () => {
        $('#btn-find-report').prop('disabled', false);
    };

    const handleReport = async () => {
        disableButtonSearch();
        showLoading();

        if (selectMonth === '') {
            dispatch(setListSummary([]));
            enableButtonSearch();
            hideLoading();
            return false;
        } else {
            let dt_moment = moment(selectMonth, 'MM-YYYY');
            const start_date = dt_moment.startOf('month').format('YYYY-MM-DD');
            const end_date = dt_moment.endOf('month').format('YYYY-MM-DD');

            let res = await mainApi.get(
                '/api/report_summary_by_month/v3/summary' +
                '/' + start_date +
                '/' + end_date +
                '/' + documentState.documentBy.id
            );
            dispatch(setListSummary(res.data));
            renderSummaryReport(res.data);
        }
        enableButtonSearch();
        hideLoading();
    };

    const summaryGetStartDate = (data, index) => {
        let res = '';
        if (data.length > 2) {
            if (index === data.length - 1) {
                res = data[0].targetDate;
            }
        }

        return res;
    };

    const summaryGetEndDate = (data, index) => {
        let res = '';
        if (data.length > 2) {
            // console.log(data.length-2, index)
            if (index === data.length - 1) {
                res = data[data.length - 2].targetDate;
            }
        }
        return res;
    };

    const renderSummaryReport = (data) => {
        if (data.length === 0) {
            return (
                <tr>
                    <td colSpan="6">ไม่พบข้อมูล</td>
                </tr>
            );
        } else {
            return data.map((item, index) => (
                <tr key={index}>
                    <td>{getDateThaiShortFormat(item.targetDate, true)}</td>
                    <td>{item.recieve === '0' ? '' : item.recieve}</td>
                    <td>
                        {item.sumRecieve === '0' ? (
                            ''
                        ) : (
                            <Link
                                to={{
                                    pathname: './preview-report-daily',
                                    state: {
                                        documentType: 'R',
                                        createdDate: item.targetDate,
                                        startDate: summaryGetStartDate(data, index),
                                        endDate: summaryGetEndDate(data, index),
                                    },
                                }}
                            >
                                พิมพ์ ({item.sumRecieve})
                            </Link>
                        )}
                    </td>
                    <td>{item.sumSentRecieve === '0' ? '' : item.sumSentRecieve}</td>
                    <td>
                        {item.circular === '0' ? (
                            ''
                        ) : (
                            <Link
                                to={{
                                    pathname: './preview-report-daily',
                                    state: {
                                        documentType: 'W',
                                        createdDate: item.targetDate,
                                        startDate: summaryGetStartDate(data, index),
                                        endDate: summaryGetEndDate(data, index),
                                    },
                                }}
                            >
                                พิมพ์ ({item.circular})
                            </Link>
                        )}
                    </td>
                    <td>
                        {item.sentRecieve === '0' ? (
                            ''
                        ) : (
                            <Link
                                to={{
                                    pathname: './preview-report-daily',
                                    state: {
                                        documentType: 'SR',
                                        createdDate: item.targetDate,
                                        startDate: summaryGetStartDate(data, index),
                                        endDate: summaryGetEndDate(data, index),
                                    },
                                }}
                            >
                                พิมพ์ ({item.sentRecieve})
                            </Link>
                        )}
                    </td>
                </tr>
            ));
        }
    };

    return (
        <div>
            <div className="content-wrapper" style={{minHeight: '100vh'}}>
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        รายงานสรุปการ รับ-ส่ง หนังสือ
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <Link to="./report" className="text-breadcrumb">
                                                รายงาน
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            รายงานสรุปการ รับ-ส่ง หนังสือ
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    <div className="row">
                                        <div className="col-12 col-md-4 pt-1">
                                            <select
                                                className="form-control shadow-none"
                                                name="listYear"
                                                onChange={(e) => dispatch(setSelectYear(e.target.value))}
                                                value={selectYear}
                                            >
                                                <option value="">==== เลือก ปี ====</option>
                                                {listYear.map((item) => (
                                                    <option key={item.value} value={item.value}>
                                                        {item.label}
                                                    </option>
                                                ))}
                                                ;
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-4 pt-1">
                                            <select
                                                className="form-control shadow-none"
                                                name="listMonth"
                                                onChange={(e) => dispatch(setSelectMonth(e.target.value))}
                                                value={selectMonth}
                                            >
                                                <option value="">==== เลือก เดือน ====</option>
                                                {listMonth.map((item) => (
                                                    <option key={item.value} value={item.value}>
                                                        {item.label}
                                                    </option>
                                                ))}
                                                ;
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 pt-1">
                                            <Button
                                                type=""
                                                variant="contained"
                                                id="btn-find-report"
                                                className="btn-color-add-small btn-block"
                                                onClick={handleReport}
                                            >
                                                <i className="fas fa-file pr-2"></i>
                                                รายงาน
                                            </Button>
                                        </div>
                                        <div className="col-12 pt-1">
                                            <div className="loading-container d-none">
                                                <div
                                                    className="spinner-border spinner-border-sm ml-2"
                                                    role="status"
                                                >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                <label className="pt-2 pl-4">กรุณารอสักครู่</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 pt-2">
                                            <Table
                                                striped
                                                bordered
                                                hover
                                                responsive
                                                className="text-center"
                                            >
                                                <thead className="thead-light">
                                                <tr>
                                                    <th rowSpan="2">วัน เดือน ปี</th>
                                                    <th colSpan="2" className="th-receive">
                                                        หนังสือรับ
                                                    </th>
                                                    <th colSpan="3" className="th-sent">
                                                        หนังสือส่ง
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className="th-receive">จำนวนหนังสือเข้า</th>
                                                    <th className="th-receive">รายงานประจำวัน</th>
                                                    <th className="th-sent">จำนวนหนังสือออก</th>
                                                    <th className="th-sent">รายงานเวียนประจำวัน</th>
                                                    <th className="th-sent">รายงานประจำวัน</th>
                                                </tr>
                                                </thead>
                                                <tbody>{renderSummaryReport(listSummary)}</tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        </div>
    );
};

export default RptSummaryByMonth;
