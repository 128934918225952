/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { mainApi } from '../../HttpClient';
import config from 'react-global-configuration';
import Swal from 'sweetalert2';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import moment from 'moment';
import OrgEmpList from '../share/OrgEmpList';
import DateInput from '../share/DateInput';

import { getApiMasSecretLevel, getApiMasUrgencyLevel, } from '../util/ApiForSelect';

import { getCurrentDocRunning } from '../util/DocRegister';
import DigitalSignatureModal from "../share/digital-signature/DigitalSignatureModal";
import { delay } from "../util/Delay";
import usePDF from "../formauto/pdf/usePDF";
import { useSelector } from "react-redux";

const SecretDocOutboxInternal = () => {
    let history = useHistory();
    const {convertBlobToBase64} = usePDF();
    const [isLoadingDocRunningNo, setIsLoadingDocRunningNo] = useState(true);
    const {register, errors, handleSubmit, reset, setValue} = useForm();
    const [documentDate, setDocumentDate] = useState(new Date());
    const [documentDuedate, setDocumentDuedate] = useState(new Date());
    const [outsideOrg, setOutsideOrg] = useState('');
    const [fileList, setFileList] = useState([]);
    const orgEmpListRef = useRef();
    const [referSelections, setReferSelections] = useState([]);
    const [referOptions, setReferOptions] = useState([]);
    const [isReferLoading, setIsReferLoading] = useState(false);
    const [modalSentOutShow, setModalSentOutShow] = useState(false);
    const handleModalSentOutClose = () => setModalSentOutShow(false);
    const handleModalSentOutShow = () => setModalSentOutShow(true);
    const registerNoButton = useRef();
    const [listUrgencyLevel, setListUrgencyLevel] = useState([]);
    const [listSecretLevel, setListSecretLevel] = useState([]);
    const [docRunningNo, setDocRunningNo] = useState(1);
    const [isCheckedDueDate, setIsCheckedDueDate] = useState(false);
    const [isSend, setIsSend] = useState(false);
    const [selectUrgencyLevel, setSelectUrgencyLevel] = useState('');
    const [inputSub, setInputSub] = useState('');
    const [isSub, setIsSub] = useState();

    const digitalSignatureRef = useRef();
    const [pdfSignList, setPdfSignList] = useState([]);

    const signatureCertificate = useSelector((state) => state.userLogin.signatureCertificate);
    const documentState = useSelector((state) => state.document);

    useEffect(() => {
        const changeSub = () => {
            if (selectUrgencyLevel <= 1 || selectUrgencyLevel === '') {
                setInputSub('')
                // console.log('ค่าว่าง')
                setIsSub(false)
            } else {
                if (selectUrgencyLevel === '2') {
                    setInputSub('หนังสือลับ')
                } else if (selectUrgencyLevel === '3') {
                    setInputSub('หนังสือลับมาก')
                }
                // console.log('อื่นๆ')
                setIsSub(true)
            }
        }
        changeSub();
    }, [selectUrgencyLevel]);

    useEffect(() => {
        const rsUrgen = getApiMasUrgencyLevel();
        rsUrgen.then((res) => {
            setListUrgencyLevel(res.data);
        });

        const rsSecret = getApiMasSecretLevel();
        rsSecret.then((res) => {
            setListSecretLevel(res.data.filter((item) => item.secret_level_id !== 1));
            setInputSub('หนังสือลับ');
            setIsSub(true);
        });
    }, []);

    useEffect(() => {
        if(documentState.documentBy && documentState.documentBy.id !== null) {
            reset();
            mainApi.post('/api/v2/documents-register/register-name', documentState.documentBy).then((res) => {
                setValue('registerNo', res.data + '/ล');
            });
        }
    }, [reset, documentState.documentBy, setValue]);

    useEffect(() => {
        if(documentState.documentBy && documentState.documentBy.id !== null) {
            setIsLoadingDocRunningNo(true);
            const docType = 'HS';
            getCurrentDocRunning(docType, documentState.documentBy).then((res) => {
                let docCount = res.data;
                if (docCount > -1) {
                    docCount += 1;
                }
                setDocRunningNo(docCount);
                setIsLoadingDocRunningNo(false);
            });
        }
    }, [documentState.documentBy]);

    const handleSave = (data) => {
        save(data, '--', '0');
    };

    const handleSendInSave = (data) => {
        save(data, 'IN', '1');
    };

    const handleSendOutSave = (data) => {
        save(data, 'OUT', '1');
        handleModalSentOutClose();
    };

    const save = (data, docGenre, status) => {
        setIsSend(true);
        let mes_success = `บันทึกข้อมูลสำเร็จ`;
        let bodyData = {
            documentId: data.documentId || '0',
            documentType: 'HS',
            documentGenre: 'IN',
            registerNo: data.registerNo || '',
            documentDate: moment(documentDate).format('YYYY-MM-DD'),
            urgencyLevel: data.urgencyLevel,
            secretLevel: data.secretLevel,
            subject: data.subject || inputSub || '',
            senderId: documentState.documentBy.id,
            sender: documentState.documentBy.name,
            receiverId: data.receiverId || '',
            receiver: data.receiver || '',
            owner: data.owner || '',
            ownerTelephone: data.ownerTelephone || '',
            remark: data.remark || '',
            command: data.command || '',
            status: status,

            pdfSignList: pdfSignList,
            certificate: signatureCertificate.selected,
            documentBy: documentState.documentBy,
        }

        if (referSelections.length > 0) {
            bodyData = {
                ...bodyData,
                refer: referSelections[0].documentId,
            }
        }

        if (isCheckedDueDate === true) {
            bodyData = {
                ...bodyData,
                documentDuedate: moment(documentDuedate).format('YYYY-MM-DD'),
            }
        }

        if (docGenre === 'IN') {
            mes_success = 'ส่งหนังสือ (ภายใน) สำเร็จ';
            bodyData = {
                ...bodyData,
                insideOrg: orgEmpListRef.current.getAllSelectList(),
            }
        }

        if (docGenre === 'OUT') {
            mes_success = 'ส่งหนังสือ (ภายนอก) สำเร็จ';
            bodyData = {
                ...bodyData,
                outsideOrg: data.receiver || '',
            }
        }

        let params = new FormData();
        params.append('data', JSON.stringify(bodyData));
        fileList.forEach((file) => {
            params.append('files', file);
        });

        mainApi.post('/api/book/save/v2', params).then((res) => {
            reset(res.data.book);
            Swal.fire({
                icon: 'success',
                title: mes_success,
                text: 'เลขที่เอกสาร : ' + res.data.book.registerNo,
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            }).then((result) => {
                if (
                    result.dismiss === Swal.DismissReason.timer ||
                    result.value === true
                ) {
                    if (docGenre === '--') {
                        history.push('/secret-doc-outbox-draft');
                    } else {
                        history.push('/secret-doc-outbox');
                    }
                }
            });
        }).finally(() => {
            setIsSend(false);
        });
    };

    const handleReferSearch = (query) => {
        setIsReferLoading(true);

        let params = {
            registerNo: query,
        };

        mainApi.post('/api/v1/documents/search/register_no', params).then((res) => {
            setReferOptions(res.data);
        }).finally(() => {
            setIsReferLoading(false);
        });
    };

    const handleSignature = async () => {
        let pdfBase64List = [];

        digitalSignatureRef.current.clearDataAndShowModal();

        for (const file of fileList) {
            if (file.type !== 'application/pdf') {
                continue;
            }
            const base64Str = await convertBlobToBase64(file);
            pdfBase64List.push({
                attachmentId: 0,
                base64Str: base64Str,
                fileName: "",
                realFilename: file.name,
            });
        }

        await delay(200);
        digitalSignatureRef.current.initPdfList(pdfBase64List);
    };

    const handleSignatureAccept = (pdfSignList) => {
        setPdfSignList(pdfSignList);
        orgEmpListRef.current.show();
    }

    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>

                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        ออกเลขภายใน
                                        <i className="nav-icon fas fa-angle-double-right sidebar-fa-size-header ml-2 mr-2 nvClick"></i>
                                        <button
                                            type="button"
                                            className="btn btn-primary page-heading mb-2"
                                        >
                                            {isLoadingDocRunningNo && (
                                                <div className="spinner-border spinner-border-sm mb-1" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            )}
                                            {!isLoadingDocRunningNo && (
                                                <div>{docRunningNo}</div>
                                            )}
                                        </button>
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <Link to="./secret-doc-outbox" className="text-breadcrumb">
                                                ทะเบียนออกเลขทั้งหมด
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            ออกเลขภายใน
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    <form onSubmit={(e) => e.preventDefault()}>
                                        <div className="row">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    ที่
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-3 pt-1">
                                                <input
                                                    className="form-control shadow-none d-none"
                                                    type="text"
                                                    name="documentId"
                                                    ref={register}
                                                />
                                                <input
                                                    className="form-control shadow-none"
                                                    type="text"
                                                    name="registerNo"
                                                    ref={register}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    ลงวันที่
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-3 pt-1">
                                                <DateInput
                                                    value={documentDate}
                                                    onChange={(date) => setDocumentDate(date)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    ชั้นความลับ
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-3 pt-1">
                                                <select
                                                    className="form-control shadow-none"
                                                    name="secretLevel"
                                                    ref={register}
                                                    onChange={(e) => setSelectUrgencyLevel(e.target.value)}
                                                    value={selectUrgencyLevel}
                                                >
                                                    {listSecretLevel.map((item) => {
                                                        return (
                                                            <option
                                                                key={item.secret_level_id}
                                                                value={item.secret_level_id}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>

                                            <div className="col-12 col-md-2 pt-2 text-sm-left text-md-center">
                                                <span className="text-color-form align-middle">
                                                    ชั้นความเร็ว
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-3 pt-1">
                                                <select
                                                    className="form-control shadow-none"
                                                    name="urgencyLevel"
                                                    ref={register}
                                                >
                                                    {listUrgencyLevel.map((item) => {
                                                        return (
                                                            <option
                                                                key={item.urgency_level_id}
                                                                value={item.urgency_level_id}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    เรื่อง
                                                </span>
                                                <span className="text-danger"> *</span>
                                            </div>
                                            <div className="col-12 col-md-10 pt-1">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="text"
                                                    name="subject"
                                                    readOnly={isSub}
                                                    value={inputSub}
                                                    onChange={e => setInputSub(e.target.value)}
                                                    ref={register({
                                                        required: {
                                                            value: true,
                                                            message: 'กรุณากรอกข้อมูล',
                                                        },
                                                        maxLength: {
                                                            value: 500,
                                                            message: 'ข้อมูลต้องไม่เกิน 500 ตัวอักษร',
                                                        },
                                                    })}
                                                />
                                                {errors.subject && (
                                                    <span className="text-danger">
                                                        {errors.subject.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    เรียน
                                                </span>
                                                <span className="text-danger"> *</span>
                                            </div>
                                            <div className="col-12 col-md-10 pt-1">
                                                <input
                                                    className="form-control shadow-none d-none"
                                                    type="text"
                                                    name="receiverId"
                                                    ref={register}
                                                />
                                                <input
                                                    className="form-control shadow-none"
                                                    type="text"
                                                    name="receiver"
                                                    ref={register({required: true})}
                                                />
                                                {errors.receiver && (
                                                    <span className="text-danger">กรุณากรอกข้อมูล</span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    อ้างถึงหนังสือ (ถ้ามี)
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-10 pt-1">
                                                <AsyncTypeahead
                                                    filterBy={() => true}
                                                    id="basic-typeahead-single"
                                                    isLoading={isReferLoading}
                                                    labelKey="registerNo"
                                                    onChange={setReferSelections}
                                                    options={referOptions}
                                                    placeholder="กรุณาพิมพ์เลขที่หนังสือ..."
                                                    selected={referSelections}
                                                    minLength={2}
                                                    onSearch={handleReferSearch}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    เจ้าของเรื่อง
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-6 pt-1">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="text"
                                                    name="owner"
                                                    ref={register}
                                                />
                                            </div>
                                            <div className="col-12 col-md-1 pt-2 text-md-center">
                                                <span className="text-color-form align-middle">
                                                    เบอร์ติดต่อ
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-3 pt-1">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="text"
                                                    name="ownerTelephone"
                                                    ref={register({
                                                        maxLength: {
                                                            value: 50,
                                                            message: 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร',
                                                        },
                                                    })}
                                                />
                                                {errors.ownerTelephone && (
                                                    <span className="text-danger">
                                                        {errors.ownerTelephone.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    คำสั่งการ (รายละเอียด)
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-10 pt-1">
                                                <textarea
                                                    className="form-control shadow-none"
                                                    rows="4"
                                                    name="command"
                                                    ref={register({max: 255})}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    หมายเหตุ
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-10 pt-1">
                                                <input
                                                    className="form-control shadow-none"
                                                    type="text"
                                                    name="remark"
                                                    ref={register({max: 255})}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-2 pt-2">
                                                <span className="text-color-form align-middle">
                                                    วันที่กำหนดเอกสาร
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-10 pt-1">
                                                <div className="">
                                                    <Checkbox
                                                        // defaultChecked
                                                        checked={isCheckedDueDate}
                                                        color="secondary"
                                                        onChange={(event) => {
                                                            setIsCheckedDueDate(event.target.checked);
                                                        }}
                                                        name="checkboxDueDate"
                                                    />
                                                    <DateInput
                                                        value={documentDuedate}
                                                        onChange={(date) => setDocumentDuedate(date)}
                                                        disabled={!isCheckedDueDate}
                                                        readOnly={!isCheckedDueDate}
                                                        className="ml-50"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="row mt-3">*/}
                                        {/*    <div className="col-12 col-md-2 pt-2"></div>*/}
                                        {/*    <div className="col-12 col-md-10 pt-1">*/}
                                        {/*        <UploadFiles*/}
                                        {/*            fileList={fileList}*/}
                                        {/*            setFileList={setFileList}*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col-12 mt-3">
                                            <hr style={{margin: '6px -10px 10px'}}/>
                                        </div>
                                        <div className="row">
                                            {/*<div className="col-sm-12 col-md-3 mt-2">*/}
                                            {/*    <Button*/}
                                            {/*        type="button"*/}
                                            {/*        className="btn-color-add-small btn-block"*/}
                                            {/*        disabled={isSend}*/}
                                            {/*        onClick={!isSend ? handleSubmit(handleSave) : null}*/}
                                            {/*    >*/}
                                            {/*        <i className="far fa-save pr-2"></i>บันทึก*/}
                                            {/*        {isSend && (*/}
                                            {/*            <div*/}
                                            {/*                className="spinner-border spinner-border-sm ml-2"*/}
                                            {/*                role="status"*/}
                                            {/*            >*/}
                                            {/*                <span className="sr-only">Loading...</span>*/}
                                            {/*            </div>*/}
                                            {/*        )}*/}
                                            {/*    </Button>*/}
                                            {/*</div>*/}
                                            {/*<div className="col-sm-12 col-md-3 mt-2">*/}
                                            {/*    <Button*/}
                                            {/*        type="button"*/}
                                            {/*        className="btn-block btn-color-submit-small"*/}
                                            {/*        disabled={isSend}*/}
                                            {/*        onClick={() => {*/}
                                            {/*            if (!isSend) {*/}
                                            {/*                handleSignature();*/}
                                            {/*            }*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        <i className="fas fa-file-signature mr-2"></i>ลงนามและส่งหนังสือ (ส่งภายใน)*/}
                                            {/*        {isSend && (*/}
                                            {/*            <div*/}
                                            {/*                className="spinner-border spinner-border-sm ml-2"*/}
                                            {/*                role="status"*/}
                                            {/*            >*/}
                                            {/*                <span className="sr-only">Loading...</span>*/}
                                            {/*            </div>*/}
                                            {/*        )}*/}
                                            {/*    </Button>*/}
                                            {/*</div>*/}
                                            <div className="col-sm-12 col-md-3 mt-2">
                                                <Button
                                                    type="button"
                                                    className="btn-block btn-color-submit-small"
                                                    disabled={isSend}
                                                    onClick={
                                                        !isSend
                                                            ? () => orgEmpListRef.current.show()
                                                            : null
                                                    }
                                                >
                                                    <i className="fas fa-paper-plane pr-2"></i>
                                                    ส่งหนังสือ (ส่งภายใน)
                                                    {isSend && (
                                                        <div
                                                            className="spinner-border spinner-border-sm ml-2"
                                                            role="status"
                                                        >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    )}
                                                </Button>
                                            </div>
                                            <div className="col-sm-12 col-md-3 mt-2">
                                                <Button
                                                    type="reset"
                                                    className="btn-block btn-color-delete-small"
                                                >
                                                    <i className="fas fa-ban pr-2"></i>
                                                    ยกเลิก
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>

            <Modal show={modalSentOutShow} onHide={handleModalSentOutClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-paper-plane mr-2"></i>ส่งออกหน่วยงานภายนอก
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <span className="text-header-x2 ddc-color">หน่วยงานภายนอก :</span>
                        <div className="row mt-3">
                            <div className="col">
                                <textarea
                                    rows="10"
                                    style={{width: '100%'}}
                                    value={outsideOrg}
                                    onChange={(e) => setOutsideOrg(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-submit mr-2"
                        onClick={handleSubmit(handleSendOutSave)}
                    >
                        <i className="fas fa-paper-plane pr-2"></i>
                        ส่งหนังสือ
                    </Button>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={handleModalSentOutClose}
                    >
                        <i className="fas fa-ban pr-2"></i>
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>

            <OrgEmpList onAccept={handleSubmit(handleSendInSave)} ref={orgEmpListRef}/>
            <DigitalSignatureModal
                onAccept={handleSignatureAccept}
                labelSubmitSignature={'ส่งหนังสือ (ส่งภายใน)'}
                ref={digitalSignatureRef}
            />
        </div>
    );
};

export default SecretDocOutboxInternal;
