import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './app/store'
import {Provider} from 'react-redux'
import * as serviceWorker from './serviceWorker';
import "react-datepicker/dist/react-datepicker.css";

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/languages/th';
import 'froala-editor/js/plugins/font_family.min';
import 'froala-editor/js/plugins/font_size.min';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/fullscreen.min';
import 'froala-editor/js/plugins/inline_style.min';
import 'froala-editor/js/plugins/paragraph_style.min';
import 'froala-editor/js/plugins/quote.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/plugins/emoticons.min';
import 'froala-editor/js/plugins/special_characters.min';
import 'froala-editor/js/plugins/line_breaker.min';
import 'froala-editor/js/plugins/help.min';
import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/js/plugins/track_changes.min';
import 'froala-editor/js/plugins/line_height.min';
import 'froala-editor/js/plugins/markdown.min';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
