import React from "react";
import { Container } from "react-bootstrap";

const templatefrm = () => {
  return (
    <div>
      <div class="content-wrapper">
        <section class="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 p-2"> </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default templatefrm;
