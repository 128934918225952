/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {Button, Container, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {mainApi} from "../../HttpClient";
import Select from "react-select";
import {useKeycloak} from "@react-keycloak/web";
import Swal from "sweetalert2";
import config from "react-global-configuration";


const RegisterOrgEdit = () => {
    const [comboboxOrg1Type1, setcomboboxOrg1Type1] = useState([]);
    const [keycloak] = useKeycloak();
    const [organizeId, setOrganizeId] = useState('');
    const [orgExpanded, setOrgExpanded] = useState([]);
    const [isReferLoading, setIsReferLoading] = useState(true);
    const [isReferLoading2, setIsReferLoading2] = useState(false);

    useEffect(() => {
        const fetchOrganizeId = async () => {
            const response = await mainApi.get('/api/employee/organization/1/' + keycloak.tokenParsed.preferred_username);
            setOrganizeId(response.data.orgId);
        };
        const getListOrg = async () => {
            const response = await mainApi.get('/api/organization/getOrgLevel1And0List');
            setcomboboxOrg1Type1(response.data);
        };
        fetchOrganizeId();
        getListOrg();
        isRole();
    }, [keycloak]);

    useEffect(() => {
        const fetchOrgList = async () => {
            let response = [];
            setIsReferLoading(true);
            if (organizeId !== '') {
                response = await mainApi.get('/api/organization/getEmployeeOrgListReg/?organizeId=' + organizeId);
                setOrgExpanded(response.data);
                setIsReferLoading(false);
            }
            if(isRole(['admin'])){
                setIsReferLoading2(true);
            }else {
                setIsReferLoading2(false);
            }
        };
        fetchOrgList();
    }, [organizeId]);

    const isRole = (roles) => {
        if (keycloak && roles) {
            return roles.some((r) => {
                const realm = keycloak.hasRealmRole(r);
                const resource = keycloak.hasResourceRole(r);
                return realm || resource;
            });
        }
        return false;
    };

    //save
    const handleSave = async () => {
        setIsReferLoading(true)
        let data = orgExpanded
        let req = {
            documentRegisterOrgList: data
        }
        const response = await mainApi.post('/api/document-register/save', req)
        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'บันทึกสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });
        }
        setIsReferLoading(false);
    }

    return (
        <div>
            <div className="content-wrapper" style={{minHeight: '100vh'}}>
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        กำหนดเลขสธ.หน่วยงาน
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            กำหนดเลขสธ.หน่วยงาน
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            {isRole(['super-admin']) &&
                            <div className="row">
                                <div className="col-sm-10">
                                    <Select
                                        options={comboboxOrg1Type1}
                                        value={comboboxOrg1Type1.filter(item => item.value == organizeId)}
                                        onChange={(ValueType) => setOrganizeId(ValueType.value)}
                                        isDisabled={isReferLoading}
                                    />

                                </div>
                                <div className="col-md-2">
                                    {isReferLoading && (
                                        <div
                                            className="spinner-border spinner-border-sm ml-2"
                                            role="status"
                                        >
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    )}
                                </div>
                            </div>}
                            {isRole(['admin']) &&
                                <div className="row">
                                    <div className="col-sm-10">
                                        <Select
                                            options={comboboxOrg1Type1}
                                            value={comboboxOrg1Type1.filter(item => item.value == organizeId)}
                                            onChange={(ValueType) => setOrganizeId(ValueType.value)}
                                            isDisabled={isReferLoading2}
                                        />

                                    </div>
                                    <div className="col-md-2">
                                        {isReferLoading && (
                                            <div
                                                className="spinner-border spinner-border-sm ml-2"
                                                role="status"
                                            >
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        )}
                                    </div>
                                </div>}

                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <div className="section-wrapper text-normal">
                                        <Table striped bordered hover>
                                            <thead>
                                            <tr>
                                                <th>หน่วยงาน</th>
                                                <th>เลข สธ.</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {orgExpanded.map((item, index) => (
                                                <tr key={'orgExpanded' + index}>
                                                    <td>{(item.name)}</td>
                                                    <td><input
                                                        className="form-control shadow-none"
                                                        type="text"
                                                        name="organizeCode"
                                                        value={item.organizeCode || ''}
                                                        disabled={isReferLoading}
                                                        onChange={(e) => {
                                                            item.organizeCode = e.target.value;
                                                            setOrgExpanded(
                                                                orgExpanded.map(data => {
                                                                    if (data.orgId === item.orgId) {
                                                                        return item;
                                                                    } else {
                                                                        return data;
                                                                    }
                                                                })
                                                            );
                                                        }}
                                                    /></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                        <Button
                                            type="button"
                                            onClick={handleSave}
                                            disabled={isReferLoading}
                                            className="btn-color-add-small">
                                            <i className="fas fa-save pr-2"></i>
                                            บันทึก
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        </div>
    );

};

export default RegisterOrgEdit;
