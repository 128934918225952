/* eslint-disable */
import React, {useState, forwardRef, useImperativeHandle, useRef} from 'react';
import {Timeline, TimelineEvent} from 'react-event-timeline';
import {useKeycloak} from '@react-keycloak/web';
import {getDateTimeFormat} from '../util/Date';
import {canSeeAttachmentFiles} from '../util/PermissionDocument';
import {Container, Modal, Button} from 'react-bootstrap';
import {mainApi} from '../../HttpClient';
import config from 'react-global-configuration';
import Swal from 'sweetalert2';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import UploadFiles from '../share/UploadFiles';
import DownloadFile from '../share/DownloadFile';
import {useSelector} from "react-redux";
import DocPreviewModal from "../share/doc-preview/DocPreviewModal";
import {delay} from "../util/Delay";

const HistoryBox = forwardRef(({disableAttachFile, viewOnly = false}, ref) => {
    const documentState = useSelector((state) => state.document);
    const [dataProps, setDataProps] = useState([]);
    const [keycloak] = useKeycloak();
    const [fileList, setFileList] = useState([]);

    const [modalCommandShow, setModalCommandShow] = useState(false);
    const handleModalCommandClose = () => setModalCommandShow(false);
    const handleModalCommandShow = () => setModalCommandShow(true);

    const [modalActionShow, setModalActionShow] = useState(false);
    const handleModalActionClose = () => setModalActionShow(false);
    const handleModalActionShow = () => setModalActionShow(true);

    const [modalAttachFileShow, setModalAttachFileShow] = useState(false);
    const handleModalAttachFileClose = () => setModalAttachFileShow(false);
    const handleModalAttachFileShow = () => setModalAttachFileShow(true);

    const [documentProcess, setDocumentProcess] = useState({});

    const docPreviewRef = useRef(null);

    useImperativeHandle(ref, () => ({
        dataProps,
        setDataProps,
    }));

    const docPreview = async (item) => {

        const docs = [];

        docPreviewRef.current.clearDataAndShowModal();

        item.forEach((objItem, index) => {
            docs.push({
                uri: config.get('apiFilePublicUrl') + "/v1/file/" + objItem.filename,
                fileName: objItem.realFilename,
            });
        });
        await delay(200);
        docPreviewRef.current.docsPreviewData(docs);
    };

    const getStatusColor = (statusId) => {
        if (statusId === '0') {
            return 'badge-warning';
        } else if (statusId === '1') {
            return 'badge-warning';
        } else if (statusId === '2') {
            return 'badge-primary';
        } else if (statusId === '3') {
            return 'badge-danger';
        } else if (statusId === '4') {
            return 'badge-success';
        } else if (statusId === '5') {
            return 'badge-danger';
        } else if (statusId === '6') {
            return 'badge-warning';
        }
        return '';
    };

    const handleCommandButton = (processData) => {
        setDocumentProcess(processData);
        handleModalCommandShow();
    };

    const handleUpdateCommand = async () => {
        let params = {
            processId: documentProcess.processId,
            value: documentProcess.command,
        };
        let response = await mainApi.post('/api/v1/document-process/command', params);

        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'เพิ่มคำสั่งการสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });

            fetchDocumentProcessById(documentProcess.documentId);
        }
    };

    const handleActionButton = (processData) => {
        setDocumentProcess(processData);
        handleModalActionShow();
    };

    const handleUpdateAction = async () => {
        let params = {
            processId: documentProcess.processId,
            value: documentProcess.action,
        };
        let response = await mainApi.post('/api/v1/document-process/action', params);

        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'เพิ่มการปฏิบัติสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });

            fetchDocumentProcessById(documentProcess.documentId);
        }
    };

    const handleAttachFileButton = (processData) => {
        setDocumentProcess(processData);
        setFileList([]);
        handleModalAttachFileShow();
    };

    const handleAddAttachFile = async () => {
        let params = new FormData();
        params.append('documentId', documentProcess.documentId);
        params.append('processId', documentProcess.processId);

        fileList.forEach((file) => {
            params.append('files', file);
        });

        let response = await mainApi.post('/api/v1/document-process/add-file', params);

        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'แนบไฟล์สำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });

            setFileList([]);
            fetchDocumentProcessById(documentProcess.documentId);
        }
    };

    const handleCancelDocumentByUserButton = (processData) => {
        Swal.fire({
            title: 'คุณแน่ใจหรือไม่',
            text: 'ต้องการยกเลิกเอกสารใช่ไหม',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'ยกเลิกเอกสาร',
            cancelButtonText: 'ยกเลิก',
        }).then((result) => {
            if (result.isConfirmed) {
                handleCancelDocumentByUser(processData);
            }
        });
    };

    const handleCancelDocumentByUser = async (data) => {
        let params = {
            processId: data.processId,
        };
        let response = await mainApi.post(
            '/api/v1/document-process/cancel-document',
            params
        );

        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'ยกเลิกเอกสารสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });
        }
        //Refresh Timeline
        fetchDocumentProcessById(data.documentId);
    };

    const handleDeleteAttachFile = (documentAttachment) => {
        Swal.fire({
            title: 'ต้องการลบไฟล์ใช่หรือไม่?',
            text: 'ชื่อไฟล์ : ' + documentAttachment.realFilename,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'ลบไฟล์!',
            cancelButtonColor: '#3085d6',
            cancelButtonText: 'ยกเลิก',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteAttachFile(documentAttachment);
            }
        });
    };

    const deleteAttachFile = async (documentAttachment) => {
        let response = await mainApi.post(
            '/api/v1/document-process/delete-file',
            documentAttachment
        );

        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'ลบไฟล์สำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });

            //Refresh Timeline
            fetchDocumentProcessById(documentAttachment.documentId);
        }
    };

    const fetchDocumentProcessById = async (documentId) => {
        setDataProps([]);

        const response = await mainApi.get(
            `/api/v5/document-process/document-id/${documentId}`
        );

        setDataProps(response.data);
    };

    const updateCurrentCommand = (proc, target_proc_id) => {
        if (typeof proc.documentChilds !== 'undefined') {
            if (proc.documentChilds.length > 0) {
                for (let item of proc.documentChilds) {
                    return updateCurrentCommand(item, documentProcess.processId);
                }
            } else {
                if (proc.processId === target_proc_id) {
                    let tmp = {...proc, command: documentProcess.command};
                    return tmp;
                } else {
                    return proc;
                }
            }
        }
    };

    const getAttachmentList = (objItem) => {
        return objItem.documentAttachments.filter((item) => {
            return !objItem.documentAttachmentsSignature.some(itemSign => itemSign.realFilename == item.realFilename);
        });
    };

    const getDownloadSignatureFile = (objItem, docAttach, docIndex) => {
        let targetId = "";
        if (objItem.status === "1") {
            targetId = objItem.senderId;
        } else if (objItem.status === "2") {
            targetId = objItem.receiverId;
        }

        if (targetId === documentState.documentBy.id) {
            return (<DownloadFile filename={docAttach.filename} realfilename={docIndex + 1 + '. ' + docAttach.realFilename}/>);
        }
        return (docIndex + 1) + '. ' + docAttach.realFilename;
    };

    const recursiveRenderChilds = (objItem) => {
        return (
            <TimelineEvent
                key={objItem.processId}
                container="card"
                style={{boxShadow: 'none !important', borderLeftStyle: 'dashed'}}
                className="timeline-childs"
                cardHeaderStyle={{backgroundColor: '#dee2e6', color: '#503331', fontSize: '12px'}}
                collapsible={true}
                showContent={objItem.showContent}
                title=""
                createdAt={getDateTimeFormat(objItem.transactionDate)}
                icon={<i className="material-icons md-18">{getTimelineIcon(objItem.status)}</i>}
                iconStyle={{width: '32px', height: '32px', cursor: 'default'}}
            >
                <div className="row mt-2 mb-2">
                    <div className="col-sm-12 text-color-form">
                        {/* **************** viewOnly = true สำหรับหน้า Archive Document ซ่อนปุ่มต่างๆ ***************** */}
                        {viewOnly !== true && (
                            <button type="button" className="btn btn-secondary btn-sm bg-history-btn-color d-inline-flex mr-2"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-ellipsis-h"></i>
                            </button>
                        )}
                        <div className="dropdown-menu">
                            <button className="dropdown-item" type="button" onClick={() => handleCommandButton(objItem)}>
                                คำสั่งการ
                            </button>
                            <button className="dropdown-item" type="button" onClick={() => handleActionButton(objItem)}>
                                การปฏิบัติ
                            </button>
                            <button className="dropdown-item" type="button" onClick={() => handleAttachFileButton(objItem)}
                                    style={{display: disableAttachFile ? 'none' : canSeeAttachmentFiles(objItem, documentState.documentBy.id)}}>
                                แนบไฟล์
                            </button>
                            {objItem.status !== '5' && (
                                <>
                                    <div className="dropdown-divider"></div>
                                    <button className="dropdown-item text-danger" type="button" onClick={() => handleCancelDocumentByUserButton(objItem)}>
                                        ยกเลิกเอกสาร
                                    </button>
                                </>
                            )}
                        </div>
                        {/* *************************************** */}

                        <span className={`d-table d-md-inline mt-2 mr-2 badge ${getStatusColor(objItem.status)}`}>{objItem.statusName}</span>
                        {objItem.receiver}
                    </div>
                    <div className="col-sm-12 text-color-form">
                        {objItem.command && (
                            <div className="row">
                                <div className="col-12 ddc-color">
                                    <div className="dropdown-divider"></div>
                                    <div className="text-shadow-header timeline-header bg-command-color d-sm-inline d-md-inline">คำสั่งการ</div>
                                    <span className="pl-3">{objItem.command}</span>
                                </div>
                            </div>
                        )}
                        {objItem.action && (
                            <div className="row">
                                <div className="col-12 ddc-color">
                                    <div className="dropdown-divider"></div>
                                    <div className="text-shadow-header timeline-header bg-action-color d-sm-inline d-md-inline">การปฏิบัติ</div>
                                    <span className="pl-3" style={{color: '#3d9970'}}>{objItem.action}</span>
                                </div>
                            </div>
                        )}
                        {objItem.documentAttachmentsSignature.length > 0 && (
                            <>
                                <div className="row mb-2">
                                    <div className="col-12">
                                         <span className="text-color-form align-middle">
                                             {'เอกสารลายเซ็นอิเล็กทรอนิกส์ '}
                                             {objItem.documentAttachmentsSignature ? objItem.documentAttachmentsSignature.length : 0}
                                             {' ไฟล์'}
                                        </span>
                                        <button type="button" className="btn-secondary bg-preview-btn-color ml-2"
                                                onClick={() => docPreview(objItem.documentAttachmentsSignature)}>
                                            ตัวอย่างไฟล์
                                        </button>
                                    </div>
                                </div>
                                {objItem.documentAttachmentsSignature.map((docAttach, docIndex) => (
                                    <div className="row" key={docIndex}>
                                        <div className="col-12 col-md-1 d-none d-sm-block">
                                            <span className="text-color-form align-middle"></span>
                                        </div>
                                        <div className="col-12 col-md-11 text-normal">
                                            {getDownloadSignatureFile(objItem, docAttach, docIndex)}
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                        {getAttachmentList(objItem).length > 0 && (
                            <>
                                <div className="row mb-2">
                                    <div className="col-12">
                                    <span className="text-color-form align-middle">
                                        ไฟล์แนบ{' '}
                                        {getAttachmentList(objItem)
                                            ? getAttachmentList(objItem).length
                                            : 0}{' '}
                                        ไฟล์
                                    </span>
                                        <button
                                            type="button"
                                            className="btn-secondary bg-preview-btn-color ml-2"
                                            onClick={() => docPreview(getAttachmentList(objItem))}
                                        >ตัวอย่างไฟล์
                                        </button>
                                    </div>
                                </div>
                                {getAttachmentList(objItem).map((docAttech, docIndex) => (
                                    <div className="row" key={docIndex}>
                                        <div className="col-12 col-md-1 d-none d-sm-block">
                                            <span className="text-color-form align-middle"></span>
                                        </div>
                                        <div className="col-12 col-md-11 text-normal">
                                            <DownloadFile
                                                filename={docAttech.filename}
                                                realfilename={docIndex + 1 + '. ' + docAttech.realFilename}
                                                deleteFile={
                                                    (docAttech.createdBy || '') === keycloak.tokenParsed.preferred_username
                                                    && (() => handleDeleteAttachFile(docAttech))
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
                {objItem.documentChilds && objItem.documentChilds.length > 0 && (
                    <Timeline key={objItem.processId}>
                        {objItem.documentChilds.map((value, index) => recursiveRenderChilds(value))}
                    </Timeline>
                )}
            </TimelineEvent>
        );
    };

    const recursiveRenderNodes = (objItem) => {
        return (
            <TimelineEvent
                key={objItem.processId}
                container="card"
                className="timeline-nodes"
                cardHeaderStyle={{backgroundColor: '#dee2e6', color: '#503331', fontSize: '12px'}}
                collapsible={true}
                showContent={objItem.showContent}
                title=""
                createdAt={getDateTimeFormat(objItem.transactionDate)}
                icon={<i className="material-icons md-18">{getTimelineIcon(objItem.status)}</i>}
                iconStyle={{width: '32px', height: '32px', cursor: 'default'}}
            >
                <div className="row mt-2 mb-2">
                    <div className="col-sm-12 text-color-form">
                        {/* **************** viewOnly = true สำหรับหน้า Archive Document ซ่อนปุ่มต่างๆ ***************** */}
                        {viewOnly !== true && (
                            <button type="button" className="btn btn-secondary btn-sm bg-history-btn-color d-inline-flex mr-2"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-ellipsis-h"></i>
                            </button>
                        )}
                        <div className="dropdown-menu">
                            <button className="dropdown-item" type="button" onClick={() => handleCommandButton(objItem)}>
                                คำสั่งการ
                            </button>
                            <button className="dropdown-item" type="button" onClick={() => handleActionButton(objItem)}>
                                การปฏิบัติ
                            </button>
                            <button className="dropdown-item" type="button" onClick={() => handleAttachFileButton(objItem)}
                                    style={{display: disableAttachFile ? 'none' : canSeeAttachmentFiles(objItem, documentState.documentBy.id)}}>
                                แนบไฟล์
                            </button>
                            {objItem.status !== '5' && (
                                <>
                                    <div className="dropdown-divider"></div>
                                    <button className="dropdown-item text-danger" type="button" onClick={() => handleCancelDocumentByUserButton(objItem)}>
                                        ยกเลิกเอกสาร
                                    </button>
                                </>
                            )}
                        </div>
                        {/* *************************************** */}

                        <span className={`d-table d-md-inline mt-2 mr-2 badge ${getStatusColor(objItem.status)}`}>{objItem.statusName}</span>
                        {objItem.receiver}
                    </div>
                    <div className="col-sm-12 text-color-form">
                        {objItem.command && (
                            <div className="row">
                                <div className="col-12 ddc-color">
                                    <div className="dropdown-divider"></div>
                                    <div className="text-shadow-header timeline-header bg-command-color d-sm-inline d-md-inline">
                                        คำสั่งการ
                                    </div>
                                    <span className="pl-3">{objItem.command}</span>
                                </div>
                            </div>
                        )}
                        {objItem.action && (
                            <div className="row">
                                <div className="col-12 ddc-color">
                                    <div className="dropdown-divider"></div>
                                    <div className="text-shadow-header timeline-header bg-action-color d-sm-inline d-md-inline">
                                        การปฏิบัติ
                                    </div>
                                    <span className="pl-3" style={{color: '#3d9970'}}>{objItem.action}</span>
                                </div>
                            </div>
                        )}
                        {objItem.documentAttachmentsSignature.length > 0 && (
                            <>
                                <div className="row mt-2 mb-2">
                                    <div className="col-12">
                                    <span className="text-color-form align-middle">
                                        {'เอกสารลายเซ็นอิเล็กทรอนิกส์ '}
                                        {objItem.documentAttachmentsSignature ? objItem.documentAttachmentsSignature.length : 0}
                                        {' ไฟล์'}
                                    </span>
                                        <button type="button" className=" btn-secondary bg-preview-btn-color ml-2"
                                                onClick={() => docPreview(objItem.documentAttachmentsSignature)}>
                                            ตัวอย่างไฟล์
                                        </button>
                                    </div>
                                </div>
                                {objItem.documentAttachmentsSignature.map((docAttach, docIndex) => (
                                    <div className="row" key={docIndex}>
                                        <div className="col-12 col-md-1 d-none d-sm-block">
                                            <span className="text-color-form align-middle"></span>
                                        </div>
                                        <div className="col-12 col-md-11 text-normal">
                                            {getDownloadSignatureFile(objItem, docAttach, docIndex)}
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                        {getAttachmentList(objItem).length > 0 && (
                            <>
                                <div className="row mt-2 mb-2">
                                    <div className="col-12">
                                    <span className="text-color-form align-middle">
                                        {'ไฟล์แนบ '}
                                        {getAttachmentList(objItem) ? getAttachmentList(objItem).length : 0}
                                        {' ไฟล์'}
                                    </span>
                                        <button type="button" className="btn-secondary bg-preview-btn-color ml-2"
                                                onClick={() => docPreview(getAttachmentList(objItem))}>
                                            ตัวอย่างไฟล์
                                        </button>
                                    </div>
                                </div>
                                {getAttachmentList(objItem).map((docAttech, docIndex) => (
                                    <div className="row" key={docIndex}>
                                        <div className="col-12 col-md-1 d-none d-sm-block">
                                            <span className="text-color-form align-middle"></span>
                                        </div>
                                        <div className="col-12 col-md-11 text-normal">
                                            <DownloadFile
                                                filename={docAttech.filename}
                                                realfilename={docIndex + 1 + '. ' + docAttech.realFilename}
                                                deleteFile={
                                                    (docAttech.createdBy || '') === keycloak.tokenParsed.preferred_username
                                                    && (() => handleDeleteAttachFile(docAttech))
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
                {objItem.documentChilds && objItem.documentChilds.length > 0 && (
                    <Timeline key={objItem.processId}>
                        {objItem.documentChilds.map((value, index) => recursiveRenderChilds(value))}
                    </Timeline>
                )}
            </TimelineEvent>
        );
    };

    const getTimelineSkeleton = () => {
        return (
            <Timeline>
                <TimelineEvent
                    container="card"
                    className="timeline-nodes"
                    cardHeaderStyle={{backgroundColor: '#dee2e6', color: '#503331', fontSize: '12px'}}
                    title=""
                    createdAt={<Skeleton width={'15%'}/>}
                    icon={<i className="material-icons md-18">{getTimelineIcon(0)}</i>}
                    iconStyle={{width: '32px', height: '32px', cursor: 'default'}}
                >
                    <div className="row mt-2 mb-2">
                        <div className="col-sm-12 text-color-form">
                            {/* **************** viewOnly = true สำหรับหน้า Archive Document ซ่อนปุ่มต่างๆ ***************** */}
                            {viewOnly !== true && (
                                <button type="button" className="btn btn-secondary btn-sm bg-history-btn-color d-inline-flex mr-2"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-ellipsis-h"></i>
                                </button>
                            )}
                            <div className="dropdown-menu">
                                <button className="dropdown-item" type="button">คำสั่งการ</button>
                                <button className="dropdown-item" type="button">การปฏิบัติ</button>
                                <button className="dropdown-item" type="button">แนบไฟล์</button>
                                <div className="dropdown-divider"></div>
                                <button className="dropdown-item text-danger" type="button">ยกเลิกเอกสาร</button>
                            </div>

                            <span className={`d-table d-md-inline mt-2 mr-2 badge ${getStatusColor('1')}`}>ส่งหนังสือ</span>
                            <Skeleton width={'30%'}/>
                        </div>
                    </div>
                </TimelineEvent>
            </Timeline>
        );
    };

    const getTimelineIcon = (status) => {
        let style = {color: '#6fba1c'};
        let result = <i className="fas fa-check" style={style}></i>;
        if (status == 1) {
            result = <i className="far fa-paper-plane" style={style}></i>;
        } else if (status == 2) {
            result = <i className="far fa-envelope" style={style}></i>;
        }
        return result;
    };

    return (
        <div className="history-container">
            {dataProps.length === 0 && getTimelineSkeleton()}

            {dataProps && (
                <Timeline>
                    {dataProps.map((value, index) => recursiveRenderNodes(value))}
                </Timeline>
            )}

            <Modal show={modalCommandShow} onHide={handleModalCommandClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-paper-plane mr-2"></i>เพิ่มคำสั่งการ
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <span className="text-header-x2 ddc-color">คำสั่งการ :</span>
                        <div className="row mt-3">
                            <div className="col">
                                <textarea
                                    rows="3"
                                    style={{width: '100%'}}
                                    value={documentProcess.command || ''}
                                    onChange={(e) => {
                                        setDocumentProcess({
                                            ...documentProcess,
                                            command: e.target.value,
                                        });
                                    }}
                                ></textarea>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" className="btn-color-add-small"
                            onClick={() => {
                                handleModalCommandClose();
                                handleUpdateCommand();
                            }}>
                        <i className="far fa-save pr-2"></i>บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalActionShow} onHide={handleModalActionClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-paper-plane mr-2"></i>เพิ่มการปฏิบัติ
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <span className="text-header-x2 ddc-color">การปฏิบัติ :</span>
                        <div className="row mt-3">
                            <div className="col">
                                <textarea
                                    rows="3"
                                    style={{width: '100%'}}
                                    value={documentProcess.action || ''}
                                    onChange={(e) => {
                                        setDocumentProcess({
                                            ...documentProcess,
                                            action: e.target.value,
                                        });
                                    }}
                                ></textarea>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" className="btn-color-add-small"
                            onClick={() => {
                                handleModalActionClose();
                                handleUpdateAction();
                            }}>
                        <i className="far fa-save pr-2"></i>บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalAttachFileShow} onHide={handleModalAttachFileClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-paper-plane mr-2"></i>เพิ่มแนบไฟล์
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <UploadFiles fileList={fileList} setFileList={setFileList}/>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" className="btn-color-add-small"
                            onClick={() => {
                                handleModalAttachFileClose();
                                handleAddAttachFile();
                            }}>
                        <i className="far fa-save pr-2"></i>บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>
            <DocPreviewModal ref={docPreviewRef}/>
        </div>
    );
});

export default HistoryBox;
