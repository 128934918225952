import { configureStore } from '@reduxjs/toolkit';
import RptSummaryByMonthSlice from './stores/report/RptSummaryByMonthSlice';
import RptSecretDocSummaryByMonthSlice from './stores/report/RptSecretDocSummaryByMonthSlice';
import userLoginSlice from "./stores/features/userLoginSlice";
import documentSlice from "./stores/features/documentSlice";

export default configureStore({
    reducer: {
        rptSummaryByMonth: RptSummaryByMonthSlice,
        rptSecretDocSummaryByMonth: RptSecretDocSummaryByMonthSlice,
        userLogin: userLoginSlice,
        document: documentSlice,
    },
});