import React, { useEffect, useState } from 'react';
import { Container, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import $ from "jquery";
import config from "react-global-configuration";
import { useKeycloak } from "@react-keycloak/web"
import Switch from "react-switch";
import { mainApi } from "../../HttpClient";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

const RubberStampReceive = () => {
    const [keycloak] = useKeycloak();
    const userLogin = useSelector((state) => state.userLogin.value);
    const {register, errors, handleSubmit, reset, clearErrors} = useForm();
    const [active, setActive] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => setModalShow(false);
    const handleModalShow = () => setModalShow(true);

    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';

        $('#data-table').DataTable({
            order: [0, 'asc'],
            ajax: {
                contentType: 'application/json',
                url: config.get('apiUrl') + '/api/rubber-stamp-receive/data-table',
                type: 'POST',
                data: function (d) {
                    return JSON.stringify(d);
                },
                headers: {
                    Authorization: `bearer ${keycloak.token}`,
                },
            },
            processing: true,
            serverSide: true,
            mark: true,
            columns: [
                {
                    "data": "id",
                    className: 'text-center',
                    width: '40px',
                    render: function (data, type, row, meta) {
                        return meta.row + meta.settings._iDisplayStart + 1;
                    }
                },
                {data: 'stampName', className: 'text-left'},
                {data: 'active', className: 'text-center', width: '75px'},
                {
                    width: '100px',
                    className: 'text-center',
                    searchable: false,
                    orderable: false,
                    render: function (data, type, row) {
                        return `<i class="fas fa-edit edit-button"></i>`;
                    },
                },
            ],
            rowCallback: function (row, data) {
                $('td', row).find('.edit-button').closest('td').on('click', (e) => {
                    e.preventDefault();
                    showEditModal(data);
                }).hover(function () {
                    $(this).css('cursor', 'pointer');
                });
            },
            lengthChange: true,
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
            language: {
                search: '_INPUT_',
                searchPlaceholder: 'ค้นหา...',
                lengthMenu: '_MENU_ รายการ/หน้า',
                sLengthMenu: '_MENU_ รายการ/หน้า',
                sProcessing: 'กำลังดำเนินการ...',
                sZeroRecords: 'ไม่พบข้อมูล',
                sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
                sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
                sInfoFiltered: '',
                sSearch: '',
                sUrl: '',
                oPaginate: {
                    sFirst: 'หน้าแรก',
                    sPrevious: 'ก่อนหน้า',
                    sNext: 'ถัดไป',
                    sLast: 'หน้าสุดท้าย',
                },
                processing:
                    '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
            },
        });

        const showEditModal = (data) => {
            handleModalShow();
            reset();
            setActive(true);

            mainApi.get(config.get('apiUrl') + '/api/rubber-stamp-receive/get-detail/' + data.stampId).then((res) => {
                reset(res.data);
                setActive(res.data.active === '1');
            });
        };
        // Datable Component CSS.
        document.querySelector('div.dataTables_length select').classList.add('datatable-length');
        document.querySelector('div.dataTables_filter input').classList.add('datatable-search');
    }, [keycloak, reset]);

    const handleChangeActive = nextActive => {
        setActive(nextActive);
    };

    const handleAddNewStamp = () => {
        clearErrors("stampName");
        reset({
            stampId: 0,
            stampName: '',
        });
        setActive(true);
        handleModalShow();
    }

    const handleSave = (data) => {
        let params = {
            stampId: data.stampId || 0,
            employeeId: userLogin.employee_id,
            stampName: data.stampName,
            active: active ? '1' : '0',
        }
        mainApi.post(config.get('apiUrl') + '/api/rubber-stamp-receive/save', params).then((res) => {
            Swal.fire({
                title: 'บันทึกสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ตกลง',
            }).then(() => {
                handleModalClose();
                $('#data-table').DataTable().ajax.reload();
            });
        });
    }

    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        ข้อมูลตรายาง (หนังสือรับ)
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            ตั้งค่าข้อมูล
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            ข้อมูลตรายาง (หนังสือรับ)
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <button onClick={handleAddNewStamp} type="button" className="btn-color-add-lag btn btn-success"><i className="fa fa-plus pr-2"></i>เพิ่มข้อมูลตรายาง
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    <table
                                        id="data-table"
                                        className="table table-striped table-bordered display w-100 margin-bottom-30">
                                        <thead>
                                        <tr>
                                            <th className="text-center">ลำดับ</th>
                                            <th className="text-center">ชื่อตราหนังสือรับ</th>
                                            <th className="text-center">สถานะ</th>
                                            <th className="text-center">จ้ดการ</th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>

            <Modal show={modalShow} onHide={handleModalClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>เพิ่มข้อมูลตรายาง (หนังสือรับ)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(handleSave)}>
                        <Container fluid>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ชื่อตราหนังสือรับ <span className="text-danger">*</span></label>
                                <div className="col-sm-9">
                                    <Form.Control type="hidden" name="stampId" ref={register}/>
                                    <Form.Control type="text" name="stampName" ref={register({required: true})}/>
                                    {errors.stampName && (<span className="text-danger">กรุณากรอกชื่อตรารับหนังสือ</span>)}
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">สถานะการใช้งาน</label>
                                <div className="col-md-9 d-flex">
                                    <Switch
                                        onChange={handleChangeActive}
                                        checked={active}
                                        className="react-switch"
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <button type="submit" className="btn btn-success float-right">
                                    <i className="fa fa-save pr-2"></i>บันทึก
                                </button>
                            </div>
                        </Container>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};


export default RubberStampReceive;