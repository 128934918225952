import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useKeycloak } from '@react-keycloak/web';
import { mainApi } from '../../HttpClient';
import config from 'react-global-configuration';
import Swal from 'sweetalert2';
import UploadFiles from '../share/UploadFiles';
import OrgEmpList from '../share/OrgEmpList';
import DateInput from '../share/DateInput';
import { getDateFormat, getDateThaiShortMonthFormat, getDateTimeFormat, isDuedate, isOverDueDate, } from '../util/Date';
import { getCurrentDocRunning } from '../util/DocRegister';
import HistoryBox from '../history/HistoryBox';
import DigitalSignatureModal from "../share/digital-signature/DigitalSignatureModal";
import { delay } from "../util/Delay";
import { useDispatch, useSelector } from "react-redux";
import { clearDocumentRunningNo, setDocument } from "../../app/stores/features/documentSlice";

import 'datatables.mark.js/dist/datatables.mark.es6.min.js';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import 'datatables.net-responsive/js/dataTables.responsive.min';

import '../../App.css';

import $ from 'jquery';

const InboxWaiting = () => {
    let history = useHistory();
    const [isLoadingDocRunningNo, setIsLoadingDocRunningNo] = useState(true);
    const {register, handleSubmit, reset} = useForm();
    const [keycloak] = useKeycloak();
    const [documentDate, setDocumentDate] = useState(new Date());
    // const [dataProcess, setDataProcess] = useState([]);
    const [tableSelectData, setTableSelectData] = useState({});
    const [documentReferDetail, setDocumentReferDetail] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => setModalShow(false);
    const handleModalShow = () => setModalShow(true);
    const [modalRejectShow, setModalRejectShow] = useState(false);
    const handleModalRejectClose = () => setModalRejectShow(false);
    const handleModalRejectShow = () => setModalRejectShow(true);
    const [modalReferShow, setModalReferShow] = useState(false);
    const handleModalReferClose = () => setModalReferShow(false);
    const handleModalReferShow = () => setModalReferShow(true);
    const [remarkReject, setRemarkReject] = useState('');
    const [docRunningNo, setDocRunningNo] = useState(1);

    const [modalSentShow, setModalSentShow] = useState(false);
    const handleModalSentClose = () => setModalSentShow(false);
    const handleModalSentShow = () => setModalSentShow(true);

    const [outsideOrg, setOutsideOrg] = useState('');
    const orgEmpListRef = useRef();
    const historyBoxRef = useRef();
    const [modalSentOutShow, setModalSentOutShow] = useState(false);
    const handleModalSentOutClose = () => setModalSentOutShow(false);
    // const handleModalSentOutShow = () => setModalSentOutShow(true);

    const [fileList, setFileList] = useState([]);

    const [isReceive, setIsReceive] = useState(false);
    const [isReceiveAndSend, setReceiveAndSend] = useState(false);
    const [isSignReceiveAndSend, setSignReceiveAndSend] = useState(false);
    const [isReject, setReject] = useState(false);

    const digitalSignatureRef = useRef();
    const [pdfSignList, setPdfSignList] = useState([]);
    const [pdfAttachments, setPdfAttachments] = useState([]);

    const dispatch = useDispatch();
    const signatureCertificate = useSelector((state) => state.userLogin.signatureCertificate);
    const documentState = useSelector((state) => state.document);

    useEffect(() => {
        if (documentState.documentBy.id !== null) {
            setIsLoadingDocRunningNo(true);
            const docType = 'R';
            const documentGenre = 'IN';
            getCurrentDocRunning(docType, documentState.documentBy, documentGenre).then((res) => {
                let docCount = res.data;
                if (docCount > -1) {
                    docCount += 1;
                }
                setDocRunningNo(docCount);
                setIsLoadingDocRunningNo(false);
            });
        }
    }, [documentState.documentBy]);

    useEffect(() => {
        let color2 = '#ffd43b';
        let color3 = '#fd7e14';
        let color4 = '#e03131';
        const {id: documentById} = documentState.documentBy;

        $.fn.dataTable.ext.errMode = 'none';

        $('#data-table').DataTable({
            order: [0, 'desc'],
            ajax: {
                contentType: 'application/json',
                url:
                    config.get('apiUrl') + '/api/v3/documents?pageName=inbox-waiting' +
                    '&receiverId=' + documentById +
                    '&documentById=' + documentById,
                type: 'POST',
                data: function (d) {
                    return JSON.stringify(d);
                },
                headers: {
                    Authorization: `bearer ${keycloak.token}`,
                },
            },
            processing: true,
            serverSide: true,
            mark: true,
            columnDefs: [{width: '100px', targets: 1}],
            columns: [
                {data: 'documentDate', visible: false},
                {
                    className: 'text-center',
                    searchable: false,
                    orderable: false,
                    render: function (data, type, row) {
                        return row.receiveNoDept || row.sentNo;
                    },
                },
                {
                    data: 'registerNo',
                    className: 'text-center',
                    responsivePriority: 1,
                    targets: 0,
                    render: function (data, type, row) {
                        let duedate = '';
                        if (
                            isDuedate(row.documentDuedate) &&
                            row.progressStatus !== '4' &&
                            row.progressStatus !== '5' &&
                            !isOverDueDate(row.documentDuedate)
                        ) {
                            duedate = `<div class="blink_txt" style="color: ${color4};"><small>แจ้งเตือน ${getDateThaiShortMonthFormat(row.documentDuedate, true)}</small></div>`;
                        }

                        // let urgencyLevel = '';
                        // if (row.urgencyLevel === 2) {
                        //   urgencyLevel = `<i class="fas fa-star mr-1" style="color: ${color2};"></i>`;
                        // } else if (row.urgencyLevel === 3) {
                        //   urgencyLevel = `<i class="fas fa-star mr-1" style="color: ${color3};"></i>`;
                        // } else if (row.urgencyLevel === 4) {
                        //   urgencyLevel = `<i class="fas fa-star mr-1" style="color: ${color4};"></i>`;
                        // }

                        // let urgencyLevel = '';
                        // if (row.urgencyLevel === 2) {
                        //   urgencyLevel = `<br/><small style="color: ${color2};"> ด่วน </small>`;
                        // } else if (row.urgencyLevel === 3) {
                        //   urgencyLevel = `<br/><small style="color: ${color3};"> ด่วนมาก </small>`;
                        // } else if (row.urgencyLevel === 4) {
                        //   urgencyLevel = `<br/><small style="color: ${color4};"> ด่วนที่สุด </small>`;
                        // }

                        let attachment = '';
                        if (row.countAttachment > 0) {
                            // if (row.flagEditAttachment > 0) {
                            //   attachment = `<i class="fas fa-paperclip text-danger mr-1"></i>`;
                            // } else {
                            //   attachment = `<i class="fas fa-paperclip text-muted mr-1"></i>`;
                            // }
                            attachment = `<i class="fas fa-paperclip text-danger mr-1"></i>`;
                        }

                        let urgencyLevel = '';
                        if (row.urgencyLevel === 2) {
                            urgencyLevel = `<small class="badge badge-warning mr-1" style="background-color: ${color2};">ด่วน</small>`;
                        } else if (row.urgencyLevel === 3) {
                            urgencyLevel = `<small class="badge badge-danger mr-1" style="background-color: ${color3};">ด่วนมาก</small>`;
                        } else if (row.urgencyLevel === 4) {
                            urgencyLevel = `<small class="badge badge-danger mr-1" style="background-color: ${color4};">ด่วนที่สุด</small>`;
                        }

                        // let secretLevel = '';
                        // if (row.secretLevel === 2) {
                        //   secretLevel = `<i class="fas fa-key mr-1" style="color: ${color2};"></i>`;
                        // } else if (row.secretLevel === 3) {
                        //   secretLevel = `<i class="fas fa-key mr-1" style="color: ${color3};"></i>`;
                        // } else if (row.secretLevel === 4) {
                        //   secretLevel = `<i class="fas fa-key mr-1" style="color: ${color4};"></i>`;
                        // }

                        // let secretLevel = '';
                        // if (row.secretLevel === 2) {
                        //   secretLevel = `<br/><small style="color: ${color2};"> ลับ </small>`;
                        // } else if (row.secretLevel === 3) {
                        //   secretLevel = `<br/><small style="color: ${color3};" > ลับมาก </small>`;
                        // } else if (row.secretLevel === 4) {
                        //   secretLevel = `<br/><small style="color: ${color4};"> ลับที่สุด </small>`;
                        // }

                        let secretLevel = '';
                        if (row.secretLevel === 2) {
                            secretLevel = `<small class="badge badge-warning" style="background-color: ${color2};">ลับ</small>`;
                        } else if (row.secretLevel === 3) {
                            secretLevel = `<small class="badge badge-danger" style="background-color: ${color3};">ลับมาก</small>`;
                        } else if (row.secretLevel === 4) {
                            secretLevel = `<small class="badge badge-danger" style="background-color: ${color4};">ลับที่สุด</small>`;
                        }

                        let registerNoCheck = '';
                        if (row.registerNo === '') {
                            registerNoCheck = `<span class="badge badge-pill badge-warning">รอดำเนินการ</span>`;
                        }

                        if (duedate || attachment || urgencyLevel || secretLevel || registerNoCheck) {
                            return (
                                data +
                                registerNoCheck +
                                '<br/>' +
                                attachment +
                                urgencyLevel +
                                secretLevel +
                                duedate
                            );
                        }
                        return data;
                    },
                },
                {
                    data: 'documentDate',
                    width: '80px',
                    className: 'text-center',
                    orderable: false,
                    render: function (data, type, row) {
                        return getDateFormat(data);
                    },
                },
                {
                    className: 'text-center',
                    searchable: false,
                    orderable: false,
                    visible: false,
                    render: function (data, type, row) {
                        let documentType = '';
                        let documentGenre = '';
                        if (row.documentType === 'S') {
                            documentType = 'หนังสือส่ง';
                        } else if (row.documentType === 'W') {
                            documentType = 'หนังสือเวียน';
                        } else if (row.documentType === 'R') {
                            documentType = 'หนังสือรับ';
                        }
                        if (row.documentGenre === 'IN') {
                            documentGenre = ' (ภายใน)';
                        } else if (row.documentGenre === 'OUT') {
                            documentGenre = ' (ภายนอก)';
                        }
                        return documentType + documentGenre;
                    },
                },
                {data: 'sender', className: 'text-left', width: '10%'},
                // { data: 'processGroup', className: 'text-left', width: '10%' },
                {data: 'receiver', className: 'text-left', width: '10%'},
                {
                    data: 'owner',
                    className: 'text-left',
                    width: '10%',
                    render: function (data, type, row) {
                        return data + ' ' + row.ownerTelephone;
                    },
                },
                {
                    data: 'subject',
                    className: 'text-left',
                    width: '35%',
                    responsivePriority: 2,
                    targets: -1,
                    render: function (data, type, row) {
                        let subjectData = '';
                        if (row.status === '5') {
                            subjectData = `<a class="show-truncate link-red" href="javascript:void(0)">${data}</a>`;
                        } else {
                            subjectData = `<a class="show-truncate" href="javascript:void(0)">${data}</a>`;
                        }

                        let formAutoBadge = '';
                        if (row.formId !== null && row.formId !== '') {
                            formAutoBadge = ' <span class="badge badge-pill badge-form-auto">แบบฟอร์มหนังสือแบบอัตโนมัติ</span><br>';
                        }
                        return formAutoBadge + subjectData;
                    },
                },
                {data: 'command', className: 'text-center', visible: false},
                {
                    data: 'sentDate',
                    width: '80px',
                    className: 'text-center',
                    render: function (data, type, row) {
                        return getDateTimeFormat(row.sentDate);
                    },
                },
                {
                    data: 'progressStatus',
                    className: 'text-center',
                    searchable: false,
                    orderable: false,
                    visible: false,
                    render: function (data, type, row) {
                        if (data) {
                            return `<h6><span class="badge ${getStatusColor(data)}">${
                                row.progressStatusName
                            }</span></h6>`;
                        }
                        return '';
                    },
                },
                {
                    className: 'text-center',
                    searchable: false,
                    orderable: false,
                    responsivePriority: 3,
                    targets: -2,
                    visible: false,
                    render: function (data, type, row) {
                        let returnButton = '';
                        let closeButton = '';
                        let cancelButton = '';
                        if (row.progressStatus === '1') {
                            //returnButton = `<button class="dropdown-item btn-return-document" type="button""><i class="fas fa-undo-alt mr-2"></i>ดึงเรื่องกลับ</button>`;
                        }
                        if (row.progressStatus === '2') {
                            closeButton = `<button class="dropdown-item btn-close-document" type="button""><i class="fas fa-check-circle mr-2"></i>ปิดเอกสาร</button>`;
                        }
                        if (row.progressStatus !== '4' && row.progressStatus !== '5') {
                            cancelButton = `<button class="dropdown-item btn-cancel-document" type="button""><i class="fas fa-times-circle mr-2"></i>ยกเลิกเอกสาร</button>`;
                        }

                        if (returnButton || closeButton || cancelButton) {
                            return `<div>
                        <button type="button" class="btn btn-secondary btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-ellipsis-h"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                          ${returnButton}
                          ${closeButton}
                          ${cancelButton}
                        </div>
                      </div>`;
                        }
                        return '';
                    },
                },
            ],
            rowCallback: function (row, data) {
                $('td', row)
                    .eq(6)
                    .attr('style', 'cursor: pointer;')
                    .on('click', function (e) {
                        fetchDocumentProcessById(data);
                    });

                $('td', row)
                    .find('.btn-return-document')
                    .on('click', function (e) {
                        handleReturnDocument(data.documentId);
                    });

                $('td', row)
                    .find('.btn-close-document')
                    .on('click', function (e) {
                        handleCloseDocument(data.documentId);
                    });

                $('td', row)
                    .find('.btn-cancel-document')
                    .on('click', function (e) {
                        handleCancelDocument(data.documentId);
                    });
            },
            lengthChange: true,
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
            // dom:
            //   '<"row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>><"datatable-wrap"t><"row"<"col-sm-12 col-md-5"i><"col-sm-12 col-md-7"p>>',
            language: {
                search: '_INPUT_',
                searchPlaceholder: 'ค้นหา...',
                lengthMenu: '_MENU_ รายการ/หน้า',
                sLengthMenu: '_MENU_ รายการ/หน้า',
                sProcessing: 'กำลังดำเนินการ...',
                sZeroRecords: 'ไม่พบข้อมูล',
                sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
                sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
                sInfoFiltered: '',
                // sInfoPostFix:
                //   `<br/><i class="fas fa-clock mr-1" style="color: ${color4};"></i>แจ้งเตือนกำหนดการ` +
                //   `<br/><i class="fas fa-star mr-1" style="color: ${color2};"></i>ด่วน<i class="fas fa-star mr-1 ml-2" style="color: ${color3};"></i>ด่วนมาก<i class="fas fa-star mr-1 ml-2" style="color: ${color4};"></i>ด่วนที่สุด` +
                //   `<br/><i class="fas fa-key mr-1" style="color: ${color2};"></i>ลับ<i class="fas fa-key mr-1 ml-2" style="color: ${color3};"></i>ลับมาก<i class="fas fa-key mr-1 ml-2" style="color: ${color4};"></i>ลับที่สุด`,
                sSearch: '',
                sUrl: '',
                oPaginate: {
                    sFirst: 'หน้าแรก',
                    sPrevious: 'ก่อนหน้า',
                    sNext: 'ถัดไป',
                    sLast: 'หน้าสุดท้าย',
                },
                processing:
                    '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span>',
            },
        });

        const handleReturnDocument = (documentId) => {
            Swal.fire({
                title: 'คุณแน่ใจหรือไม่',
                text: 'ต้องการดึงเอกสารใช่ไหม',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'ดึงเรื่องกลับ',
                cancelButtonText: 'ยกเลิก',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleUpdateStatus(documentId, 6, 'ดึงเรื่องกลับสำเร็จ');
                }
            });
        };

        const handleCloseDocument = (documentId) => {
            Swal.fire({
                title: 'คุณแน่ใจหรือไม่',
                text: 'ต้องการปิดเอกสารใช่ไหม',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'ปิดเอกสาร',
                cancelButtonText: 'ยกเลิก',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleUpdateStatus(documentId, 4, 'ปิดเอกสารสำเร็จ');
                }
            });
        };

        const handleCancelDocument = (documentId) => {
            Swal.fire({
                title: 'คุณแน่ใจหรือไม่',
                text: 'ต้องการยกเลิกเอกสารใช่ไหม',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'ยกเลิกเอกสาร',
                cancelButtonText: 'ยกเลิก',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleUpdateStatus(documentId, 5, 'ยกเลิกเอกสารสำเร็จ');
                }
            });
        };

        const handleUpdateStatus = async (documentId, status, alertMessage) => {
            let params = {
                documentBy: documentState.documentBy,
            };
            mainApi.post(`/api/v3/documents/update-status/${documentId}?status=${status}`, params).then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: 'สำเร็จ',
                    text: alertMessage,
                    timer: config.get('alertTimer'),
                    timerProgressBar: true,
                });
                $('#data-table').DataTable().ajax.reload();
            });
        };

        const fetchDocumentProcessById = async (tableData) => {
            setClearData(tableData);
            setTableSelectData(tableData);
            setDocumentDate(tableData.documentDate);
            handleModalShow();
            // console.log('tableData', tableData);

            dispatch(setDocument({
                id: tableData.documentId,
                type: tableData.documentType,
                genre: tableData.documentGenre,
                registerNo: tableData.registerNo,
                documentDate: tableData.documentDate,
            }));

            mainApi.get(`/api/form-auto/attach-file/pdf-signature?processId=${tableData.processId}`).then((res) => {
                setPdfSignList(res.data);
            });

            const childAndAttachmentsParams = {
                documentId: tableData.documentId,
                documentBy: documentState.documentBy,
            };
            const response = await mainApi.post(`/api/v6/document-process/child-and-attachments`, childAndAttachmentsParams);

            mainApi.get(`/api/form-auto/attach-file/pdf-signature/by-documentId?documentId=${response.data[0].documentId}`).then((res) => {
                setPdfAttachments(res.data);
            });

            if (historyBoxRef.current && historyBoxRef.current.dataProps.length === 0) {
                // setDataProcess(response.data);
                historyBoxRef.current.setDataProps(response.data);
            }
        };

        const setClearData = (tableData) => {
            if (
                tableData.documentId !== tableSelectData.documentId &&
                tableData.processId !== tableSelectData.processId
            ) {
                reset(tableData);
            }
            setFileList([]);
            setPdfSignList([]);
            orgEmpListRef.current.clear();
        };

        // Datable Component CSS.
        document
            .querySelector('div.dataTables_length select')
            .classList.add('datatable-length');
        document
            .querySelector('div.dataTables_filter input')
            .classList.add('datatable-search');
    }, [keycloak, reset, tableSelectData, documentState.documentBy, dispatch]);

    useEffect(() => {
        if (documentState.documentBy.id !== null) {
            const {id: documentById} = documentState.documentBy;
            $('#data-table').DataTable().ajax.url(
                config.get('apiUrl') + '/api/v3/documents?pageName=inbox-waiting' +
                '&receiverId=' + documentById +
                '&documentById=' + documentById
            ).load();
        }
    }, [documentState.documentBy]);

    const getStatusColor = (statusId) => {
        if (statusId === '0') {
            return 'badge-warning';
        } else if (statusId === '1') {
            return 'badge-warning';
        } else if (statusId === '2') {
            return 'badge-primary';
        } else if (statusId === '3') {
            return 'badge-danger';
        } else if (statusId === '4') {
            return 'badge-success';
        } else if (statusId === '5') {
            return 'badge-danger';
        } else if (statusId === '6') {
            return 'badge-warning';
        }
        return '';
    };

    const handleDocumentAccept = (pdfSignList) => {
        setIsReceive(true);

        let documentId = tableSelectData.documentId;
        let processId = tableSelectData.processId;
        let params = {
            pdfSignList: pdfSignList,
            certificate: signatureCertificate.selected,
            documentSentNo: documentState.isDigitalSignatureCheckSentNo ? documentState.sentNo : null,
            documentReceiverNo: documentState.receiverNo,
            documentDate: documentState.isDigitalSignatureCheckDocumentDate ? documentState.documentDate : null,
            documentBy: documentState.documentBy,
        };

        mainApi.post(
            '/api/v1/documents/receive-document?' +
            'documentId=' + documentId +
            '&processId=' + processId +
            '&status=2',
            JSON.stringify(params)
        ).then((res) => {
            dispatch(clearDocumentRunningNo());
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'รับหนังสือสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });
            $('#data-table').DataTable().ajax.reload();
            digitalSignatureRef.current.close();
            handleModalClose();

            //Auto redirect ไปหน้าหนังสือรับ
            redirectAfterReceive();
        }).finally(() => {
            setIsReceive(false);
        });
    };

    const handleReject = () => {
        setReject(true);

        mainApi.put(
            '/api/v2/document-detail/remark-reject/document-id/' +
            tableSelectData.documentId + '/' +
            tableSelectData.processId,
            {remarkReject}
        ).then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'เอกสารตีกลับสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });
            $('#data-table').DataTable().ajax.reload();
            handleModalClose();
            handleModalRejectClose();
        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: 'ผิดพลาด',
                text: 'เอกสารตีกลับไม่สำเร็จ',
            });
        }).finally(() => {
            setReject(false);
        });
    };

    const handleReferClick = () => {
        mainApi.get('/api/v2/document-detail/document-id/' + tableSelectData.refer).then((res) => {
            setDocumentReferDetail(res.data);
            handleModalClose();
            handleModalReferShow();
        });
    };

    const handleSendInSave = (formData) => {
        setReceiveAndSend(true);

        let documentId = tableSelectData.documentId;
        let processId = tableSelectData.processId;

        let data = {
            pdfSignList: pdfSignList,
            certificate: signatureCertificate.selected,
            documentSentNo: documentState.isDigitalSignatureCheckSentNo ? documentState.sentNo : null,
            documentReceiverNo: documentState.receiverNo,
            documentDate: documentState.isDigitalSignatureCheckDocumentDate ? documentState.documentDate : null,
            command: formData.commandSend || formData.command || '',
            documentBy: documentState.documentBy,
        }

        // เก็บค่าหน่วยงาน สำหรับรับแล้วส่งต่อ
        data = {
            ...data,
            recAndSendOrg: orgEmpListRef.current.getAllSelectList(),
        }

        let params = new FormData();
        params.append('data', JSON.stringify(data));
        fileList.forEach((file) => {
            params.append('files', file);
        });

        mainApi.post(
            '/api/v1/documents/receive-and-send-document?' +
            'documentId=' + documentId +
            '&processId=' + processId +
            '&status=2',
            params
        ).then((res) => {
            dispatch(clearDocumentRunningNo());
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'รับและส่งต่อหนังสือสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });
            $('#data-table').DataTable().ajax.reload();
            handleModalSentClose();
            handleModalClose();

            //Auto redirect ไปหน้าหนังสือรับ
            redirectAfterReceive();
        }).finally(() => {
            setReceiveAndSend(false);
        });
    };

    const handleSendOutSave = async () => {
        handleModalSentOutClose();
    };

    const isRole = (roles) => {
        if (keycloak && roles) {
            return roles.some((r) => {
                const realm = keycloak.hasRealmRole(r);
                const resource = keycloak.hasResourceRole(r);
                return realm || resource;
            });
        }
        return false;
    };

    const redirectAfterReceive = () => {
        //Auto redirect ไปหน้าหนังสือรับ
        if (tableSelectData.documentGenre === 'IN') {
            history.push('/inbox-int');
        } else {
            //tableSelectData.documentGenre === "OUT"
            if (isRole(['super-admin', 'admin-dep-out', 'out'])) {
                history.push('/inbox-ext');
            } else {
                history.push('/inbox-int');
            }
        }
    };

    const handleSignature = async () => {
        digitalSignatureRef.current.clearDataAndShowModal();
        await delay(200);
        if (pdfSignList.length === 0) {
            digitalSignatureRef.current.initPdfList(pdfAttachments);
        } else {
            digitalSignatureRef.current.initPdfList(pdfSignList);
        }
    };

    const handleSignatureAccept = (pdfSignList) => {
        setPdfSignList(pdfSignList);
        if (!isSignReceiveAndSend) {
            handleDocumentAccept(pdfSignList);
        } else {
            handleModalSentShow();
        }
    }

    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>

                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        หนังสือรับ (รอลงรับ)
                                        <i className="nav-icon fas fa-angle-double-right sidebar-fa-size-header ml-2 mr-2 nvClick"></i>
                                        <button
                                            type="button"
                                            className="btn btn-primary page-heading mb-2"
                                        >
                                            {isLoadingDocRunningNo && (
                                                <div className="spinner-border spinner-border-sm mb-1" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            )}
                                            {!isLoadingDocRunningNo && (
                                                <div>{docRunningNo}</div>
                                            )}
                                        </button>
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <Link to="./inbox" className="text-breadcrumb">
                                                ทะเบียนหนังสือรับ
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            หนังสือรับ (รอลงรับ)
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    <table
                                        id="data-table"
                                        className="table table-striped table-bordered display w-100 margin-bottom-30"
                                    >
                                        <thead>
                                        <tr>
                                            <th className="text-center">ลงวันที่</th>
                                            <th className="text-center">เลข</th>
                                            <th className="text-center">เลขที่</th>
                                            <th className="text-center">ลงวันที่</th>
                                            <th className="text-center">ประเภทหนังสือ</th>
                                            <th className="text-center">จาก</th>
                                            <th className="text-center">เรียน</th>
                                            <th className="text-center">เจ้าของเรื่อง</th>
                                            <th className="text-center">เรื่อง</th>
                                            <th className="text-center">คำสั่งการ</th>
                                            <th className="text-center">วันที่ส่ง</th>
                                            <th className="text-center">สถานะ</th>
                                            <th className="text-center">จัดการ</th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
            <Modal size="xl" show={modalShow} onHide={handleModalClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-book mr-2"></i>รายละเอียดเอกสาร
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    {tableSelectData.receiveNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขทะเบียนรับ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 ">
                                                {tableSelectData.receiveNo}
                                            </div>
                                        </div>
                                    )}
                                    {tableSelectData.oldNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขเดิม
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 ">
                                                {tableSelectData.oldNo}
                                            </div>
                                        </div>
                                    )}
                                    {tableSelectData.registerNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขที่
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {tableSelectData.registerNo}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3">
                                            <label className="text-color-form align-middle">
                                                ชั้นความเร็ว
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {tableSelectData.urgencyLevelName}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3">
                                            <label className="text-color-form align-middle">
                                                เรื่อง
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal show-truncate">
                                            {tableSelectData.subject}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3 ">
                                            <label className="text-color-form align-middle">
                                                เรียน
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {tableSelectData.receiver}
                                        </div>
                                    </div>
                                    {tableSelectData.refer && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    อ้างถึงหนังสือ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                <a href="#section" onClick={handleReferClick}>
                                                    {tableSelectData.referName}
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {tableSelectData.command && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3">
                                                <label className="text-color-form align-middle">
                                                    เจ้าของเรื่อง
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {tableSelectData.owner}
                                            </div>
                                        </div>
                                    )}
                                    {tableSelectData.command && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    คำสั่งการ (รายละเอียด)
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {tableSelectData.command}
                                            </div>
                                        </div>
                                    )}
                                    {tableSelectData.remark && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    หมายเหตุ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {tableSelectData.remark}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-header-x2 ddc-color">
                                <i className="fas fa-paper-plane mr-2"></i>ถึงหน่วยงาน
                            </div>
                        </div>
                        <HistoryBox ref={historyBoxRef}/>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="submit"
                        variant="contained"
                        className="btn-no-border btn-color-add-small mr-2"
                        disabled={isReceive}
                        onClick={() => {
                            if (!isReceive) {
                                setSignReceiveAndSend(false);
                                handleSignature();
                                handleModalClose();
                            }
                        }}
                    >
                        <i className="fas fa-file-signature mr-2"></i>ลงนามรับหนังสือ
                        {isReceive && (
                            <div
                                className="spinner-border spinner-border-sm ml-2"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        className="btn-color-delete-small mr-2"
                        disabled={isReceive}
                        onClick={() => {
                            setSignReceiveAndSend(true);
                            handleSignature();
                            handleModalClose();
                        }}
                    >
                        <i className="fas fa-file-signature mr-2"></i>ลงนามรับและส่งต่อ
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        className="btn-no-border btn-color-submit mr-2"
                        disabled={isReceive}
                        onClick={() => {
                            if (!isReceive) {
                                handleDocumentAccept(pdfSignList);
                            }
                        }}
                    >
                        <i className="fas fa-check pr-2"></i>รับหนังสือ
                        {isReceive && (
                            <div
                                className="spinner-border spinner-border-sm ml-2"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </Button>
                    {/* <Button
            type="button"
            variant="contained"
            className="btn-no-border btn-color-submit mr-2"
            onClick={() => orgEmpListRef.current.show()}
          >
            <i className="fas fa-paper-plane pr-2"></i>
            รับและส่งต่อ
          </Button> */}
                    <Button
                        type="button"
                        variant="contained"
                        className="btn-color-submit mr-2"
                        onClick={() => {
                            handleModalSentShow();
                            handleModalClose();
                        }}
                    >
                        <i className="fas fa-paper-plane pr-2"></i>รับและส่งต่อ
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        className="btn-no-border btn-color-delete-small"
                        data-toggle="modal"
                        data-target="#exampleModalCenter2"
                        onClick={() => {
                            handleModalRejectShow();
                        }}
                    >
                        <i className="fas fa-ban pr-2"></i>ตีกลับ
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalRejectShow} onHide={handleModalRejectClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-ban pr-2"></i>ตีกลับหนังสือ
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 col-md-12 ">
                                <span className="text-color-form align-middle">กรุณาระบุหมายเหตุในการตีกลับหนังสือ</span>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-md-3 ">
                                <span className="text-color-form align-middle">หมายเหตุ</span>
                            </div>
                            <div className="col-12 col-md-9 ">
                                <textarea
                                    rows="10"
                                    style={{width: '100%'}}
                                    value={remarkReject}
                                    onChange={(e) => setRemarkReject(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="submit"
                        className="btn-color-submit mr-2"
                        disabled={isReject}
                        onClick={!isReject ? handleReject : null}
                    >
                        <i className="fas fa-check mr-2"></i>ตีกลับ
                        {isReject && (
                            <div
                                className="spinner-border spinner-border-sm ml-2"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </Button>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={handleModalRejectClose}
                    >
                        <i className="fas fa-ban mr-2"></i>ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={modalReferShow} onHide={handleModalReferClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-book mr-2"></i>รายละเอียดเอกสารอ้างอิง
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    {documentReferDetail.receiveNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขทะเบียนรับ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 ">
                                                {documentReferDetail.receiveNo}
                                            </div>
                                        </div>
                                    )}
                                    {documentReferDetail.oldNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขเดิม
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 ">
                                                {documentReferDetail.oldNo}
                                            </div>
                                        </div>
                                    )}
                                    {documentReferDetail.registerNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขที่
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {documentReferDetail.registerNo}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3">
                                            <label className="text-color-form align-middle">
                                                ชัันความเร็ว
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.urgencyLevelName}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3">
                                            <label className="text-color-form align-middle">
                                                เรื่อง
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.subject}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3 ">
                                            <label className="text-color-form align-middle">
                                                เรียน
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.receiver}
                                        </div>
                                    </div>
                                    {documentReferDetail.refer && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    อ้างถึงหนังสือ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {documentReferDetail.referName}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3">
                                            <label className="text-color-form align-middle">
                                                เจ้าของเรื่อง
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.owner}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3 ">
                                            <label className="text-color-form align-middle">
                                                คำสั่งการ (รายละเอียด)
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.command}
                                        </div>
                                    </div>
                                    {documentReferDetail.remark && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    หมายเหตุ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {documentReferDetail.remark}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={() => {
                            handleModalReferClose();
                            handleModalShow();
                        }}
                    >
                        <i className="fas fa-undo-alt pr-2"></i>ย้อนกลับ
                    </Button>
                </Modal.Footer>
            </Modal>

            {/********************  Modal ส่งต่อหน่วยงานอื่น **********************/}
            <Modal show={modalSentShow} onHide={handleModalSentClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-paper-plane mr-2"></i>ส่งต่อหน่วยงานอื่น
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">ที่</label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name="registerNo"
                                    ref={register}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">ลงวันที่</label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <DateInput
                                    value={documentDate}
                                    onChange={(date) => setDocumentDate(date)}
                                    readOnly
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">เรื่อง</label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name="subject"
                                    ref={register}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">เรียน</label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <input
                                    className="form-control shadow-none d-none"
                                    type="text"
                                    name="receiverId"
                                    ref={register}
                                />
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name="receiver"
                                    ref={register}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">
                                    เจ้าของเรื่อง
                                </label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name="owner"
                                    ref={register}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">
                                    เบอร์โทรศัพท์
                                </label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name="ownerTelephone"
                                    ref={register}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">
                                    คำสั่งการ (รายละเอียด)
                                </label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <textarea
                                    className="form-control shadow-none"
                                    rows="5"
                                    name="commandSend"
                                    ref={register({max: 255})}
                                ></textarea>
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-12 pt-1">
                                <UploadFiles fileList={fileList} setFileList={setFileList}/>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-submit mr-2"
                        disabled={isReceiveAndSend}
                        onClick={
                            !isReceiveAndSend
                                ? () => {
                                    if (!isReceiveAndSend) {
                                        orgEmpListRef.current.clear();
                                        orgEmpListRef.current.show();
                                    }
                                }
                                : null
                        }
                    >
                        <i className="fas fa-paper-plane mr-2"></i>เลือกหน่วยงาน
                        {isReceiveAndSend && (
                            <div
                                className="spinner-border spinner-border-sm ml-2"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </Button>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={handleModalSentClose}
                    >
                        <i className="fas fa-ban mr-2"></i>ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>

            {/********************  Modal Organize **********************/}

            <Modal show={modalSentOutShow} onHide={handleModalSentOutClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-paper-plane mr-2"></i>ส่งออกหน่วยงานภายนอก
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <span className="text-header-x2 ddc-color">หน่วยงานภายนอก :</span>
                        <div className="row mt-3">
                            <div className="col">
                                <textarea
                                    rows="10"
                                    style={{width: '100%'}}
                                    value={outsideOrg}
                                    onChange={(e) => setOutsideOrg(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-submit mr-2"
                        onClick={() => {
                            handleSendOutSave();
                        }}
                    >
                        <i className="fas fa-paper-plane pr-2"></i>
                        ส่งหนังสือ
                    </Button>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={handleModalSentOutClose}
                    >
                        <i className="fas fa-ban pr-2"></i>
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>

            <OrgEmpList onAccept={handleSubmit(handleSendInSave)} ref={orgEmpListRef}/>
            <DigitalSignatureModal
                onAccept={handleSignatureAccept}
                labelSubmitSignature={isSignReceiveAndSend ? 'รับและส่งต่อ' : 'รับหนังสือ'}
                showStampReceive={true}
                ref={digitalSignatureRef}
            />
        </div>
    );
};

export default InboxWaiting;
