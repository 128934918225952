import axios from 'axios';
import config from 'react-global-configuration';
import Swal from 'sweetalert2';

export const mainApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const hrApi = axios.create({
  baseURL: process.env.REACT_APP_API_HR,
});

export const fileApi = axios.create({
  baseURL: process.env.REACT_APP_API_FILE,
});

export const setClientToken = (token) => {
  mainApi.interceptors.request.use((mainConfig) => {
    mainConfig.headers = {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    };
    return mainConfig;
  });

  mainApi.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      alertErrorMessage(error);
      return Promise.reject(error.message);
    }
  );

  hrApi.interceptors.request.use((hrConfig) => {
    hrConfig.headers = {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    };
    return hrConfig;
  });

  hrApi.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      alertErrorMessage(error);
      return Promise.reject(error.message);
    }
  );

  fileApi.interceptors.request.use((fileConfig) => {
    fileConfig.headers = {
      'Content-Type': 'application/json',
      Authorization: `bearer ${token}`,
    };
    return fileConfig;
  });

  fileApi.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      alertErrorMessage(error);
      return Promise.reject(error.message);
    }
  );
};

const alertErrorMessage = (error) => {
  let msg = error.response ? error.response.data.detail || error.response.data.message : '';
  if (error.config.responseType === 'blob') {
    msg = 'ไม่พบไฟล์';
  }
  Swal.fire({
    icon: 'error',
    title: msg,
    timer: config.get('alertTimer'),
    timerProgressBar: true,
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
  });
};
