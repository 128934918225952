import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from 'react-global-configuration';
import { setClientToken } from './HttpClient';
import Keycloak from 'keycloak-js';
import { KeycloakProvider } from '@react-keycloak/web';
import Navigation from './components/Navigation';
import Topnav from './components/Topnav';
import { PrivateRoute } from './PrivateRoute';

// import Dashboard from './components/Dashboard';
import DashboardV2 from './components/DashboardV2';
import Documents from './components/documents';
import Report from './components/Report';
import TemplateFrm from './components/TemplateFrm';
import AllForm from './components/AllForm';

/* ทะเบียนหนังสือรับ */
import InboxInternal from './components/inbox/InboxInternal';
import InboxExternal from './components/inbox/InboxExternal';
import InboxWaiting from './components/inbox/InboxWaiting';
// import Inbox from './components/inbox/Inbox';
import InboxIntBox from './components/inbox/InboxIntBox';
import InboxExtBox from './components/inbox/InboxExtBox';

// ทะเบียนหนังสือส่ง
import OutboxDraft from './components/outbox/OutboxDraft';
import OutboxInternal from './components/outbox/OutboxInternal';
import OutboxExternal from './components/outbox/OutboxExternal';
import Outbox from './components/outbox/Outbox';

// ทะเบียนหนังสือเวียน
import CircularDraft from './components/circular/CircularDraft';
import CircularInternal from './components/circular/CircularInternal';
import CircularExternal from './components/circular/CircularExternal';
import Circular from './components/circular/Circular';

// หนังสือลับ
import SecretDocInboxInternal from './components/secret/SecretDocInboxInternal';
import SecretDocInboxWaiting from './components/secret/SecretDocInboxWaiting';
import SecretDocInbox from './components/secret/SecretDocInbox';
import SecretDocOutboxExternal from './components/secret/SecretDocOutboxExternal';
import SecretDocOutboxInternal from './components/secret/SecretDocOutboxInternal';
import SecretDocOutbox from './components/secret/SecretDocOutbox';

/*test pdf preview*/
import main from './components/test/test-doc-viewer/main';

/* Per from */
import pre1 from './components/perform/pre1';
import pre2 from './components/perform/pre2';
import pre3 from './components/perform/pre3';
import pre4 from './components/perform/pre4';
import pre5 from './components/perform/pre5';
import pre6 from './components/perform/pre6';
import pre7 from './components/perform/pre7';
import pre8 from './components/perform/pre8';
import pre9 from './components/perform/pre9';
import pre10 from './components/perform/pre10';
import pre11 from './components/perform/pre11';
import pre12 from './components/perform/pre12';
import pre13 from './components/perform/pre13';
import pre14 from './components/perform/pre14';
import pre15 from './components/perform/pre15';


/* Report */
import rpt1 from './components/report/rpt1';
import rpt2 from './components/report/rpt2';
import rpt3 from './components/report/rpt3';
import rpt4 from './components/report/rpt4';
import rpt1detail from './components/report/rpt1Detail';
import rpt2detail from './components/report/rpt2Detail';
import rpt3detail from './components/report/rpt3Detail';
import rpt4detail from './components/report/rpt4Detail';
import RptRegisterNo from './components/report/RptRegisterNo';
import ReportChart from './components/report/ReportChart';
import RptSummaryByMonth from './components/report/RptSummaryByMonth';
import RptSecretDocSummaryByMonth from './components/report/RptSecretDocSummaryByMonth';
import PreviewRptDaily from './components/report/PreviewRptDaily';
import PreviewRptSecretDocDaily from './components/report/PreviewRptSecretDocDaily';
import ReportDestruction from './components/report/ReportDestruction';

/* MasExpert */
import MasExpert from './components/MasExpert';

/* MasManager */
import MasManager from './components/MasManager';

/* MasOrganization */
import MasOrganization from './components/MasOrganization';

/* SpecialOrganization */
import SpecialOrganization from "./components/SpecialOrganization";

/* Master */
import MasDocumentRegisterByUserLogin from './components/master/MasDocumentRegisterByUserLogin';
import RegisterOrgEdit from "./components/manage/RegisterOrgEdit";
import RegisterManagerEdit from "./components/manage/RegisterManagerEdit";
import RegisterExpertEdit from "./components/manage/RegisterExpertEdit";
import ArchiveDocument from './components/archive/ArchiveDocument';

/* EditNo. */
import TransferBooks from "./components/transferbooks/TransferBooks";

/* FormAuto */
import FormAutoMenuList from './components/formauto/FormAutoMenuList';
import FormAutoMenuCopyList from './components/formauto/FormAutoMenuCopyList';
import FormAutoBox from './components/formauto/FormAutoBox';
import FormAuto from './components/formauto/FormAuto';
import FormAutoCopy from './components/formauto/FormAutoCopy';

/* SettingData */
import RubberStampData from './components/settingdata/RubberStampData';
import RubberStampReceive from './components/settingdata/RubberStampReceive';
import BindEmpData from './components/settingdata/BindEmpData';
// import openInNewTab from "./components/util/openInNewTab";


config.set({
    baseURL: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PATH,
    apiUrl: process.env.REACT_APP_API_URL,
    apiHrUrl: process.env.REACT_APP_API_HR,
    apiFileUrl: process.env.REACT_APP_API_FILE,
    apiFilePublicUrl: process.env.REACT_APP_API_FILE_PUBLIC,
    hrUrl: process.env.REACT_APP_HR_BASE_URL,
    smartfixUrl: process.env.REACT_APP_SMART_FIX_BASE_URL,
    carbookExternalUrl: process.env.REACT_APP_CAR_BOOK_RESERVE_EXTERNAL_URL,
    oldSarabunUrl: process.env.REACT_APP_OLD_DDC_SARABUN_URL,
    userManual: process.env.REACT_APP_USER_MANUAL_URL,
    adminManual: process.env.REACT_APP_ADMIN_MANUAL_URL,
    ssoSarabunUrl: process.env.REACT_APP_SSO_SARABUN_URL,
    alertTimer: 5000,
});

const App = () => {
    const keycloak = new Keycloak({
        url: `${process.env.REACT_APP_KEYCLOAK_BASE_URL}/auth`,
        realm: `${process.env.REACT_APP_KEYCLOAK_REALM}`,
        clientId: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
    });
    const initConfig = {pkceMethod: 'S256'};
    const handleOnEvent = async (event, error) => {
        if (event === 'onReady' && !keycloak.authenticated) {
            if (!keycloak.authenticated) {
                keycloak.login();
            }
        } else if (event === 'onAuthSuccess') {
            setClientToken(keycloak.token);
        }
    };

    const loadingComponent = (
        <div className="loading-container">
            <div className="loading-wrap"></div>
            <div className="branding">
                <img src="img/logo-small.png" alt="กรมควบคุมโรค"/>
                <div className="spinner"></div>
            </div>
        </div>
    );

    return (
        <KeycloakProvider
            keycloak={keycloak}
            initConfig={initConfig}
            LoadingComponent={loadingComponent}
            onEvent={(event, error) => handleOnEvent(event, error)}
        >
            <BrowserRouter basename={process.env.REACT_APP_PATH}>
                <div>
                    <Topnav/>
                    <Navigation/>
                    <Switch>
                        {/* <Route exact path="/" component={Dashboard} /> */}
                        <Route exact path="/" component={DashboardV2}/>
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/dashbordV2"
                            component={DashboardV2}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/doc_dt"
                            component={Documents}
                        />
                        {/*<PrivateRoute*/}
                        {/*    roles={[*/}
                        {/*        'super-admin',*/}
                        {/*        'admin',*/}
                        {/*        'admin-dep',*/}
                        {/*        'admin-dep-out',*/}
                        {/*        'admin-sup-dep',*/}
                        {/*        'user',*/}
                        {/*    ]}*/}
                        {/*    path="/inbox"*/}
                        {/*    component={Inbox}*/}
                        {/*/>*/}
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/inbox-internal"
                            component={InboxInternal}
                        />
                        <PrivateRoute
                            roles={['super-admin', 'admin-dep-out', 'out']}
                            path="/inbox-external"
                            component={InboxExternal}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/inbox-waiting"
                            component={InboxWaiting}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/inbox-int"
                            component={InboxIntBox}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'out',
                                'user',
                            ]}
                            path="/inbox-ext"
                            component={InboxExtBox}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/outbox"
                            component={Outbox}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/outbox-draft"
                            component={OutboxDraft}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/outbox-internal"
                            component={OutboxInternal}
                        />
                        <PrivateRoute
                            roles={['super-admin', 'admin-dep-out', 'admin', 'out']}
                            path="/outbox-external"
                            component={OutboxExternal}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/circular"
                            component={Circular}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/circular-draft"
                            component={CircularDraft}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/circular-internal"
                            component={CircularInternal}
                        />
                        <PrivateRoute
                            roles={['super-admin', 'admin-dep-out', 'admin', 'out']}
                            path="/circular-external"
                            component={CircularExternal}
                        />
                        <PrivateRoute
                            roles={[
                                'secret-doc',
                            ]}
                            path="/secret-doc-inbox-internal"
                            component={SecretDocInboxInternal}
                        />
                        <PrivateRoute
                            roles={[
                                'secret-doc',
                            ]}
                            path="/secret-doc-inbox-waiting"
                            component={SecretDocInboxWaiting}
                        />
                        <PrivateRoute
                            roles={[
                                'secret-doc',
                            ]}
                            path="/secret-doc-inbox"
                            component={SecretDocInbox}
                        />
                        <PrivateRoute
                            roles={[
                                'secret-doc',
                            ]}
                            path="/secret-doc-outbox-external"
                            component={SecretDocOutboxExternal}
                        />
                        <PrivateRoute
                            roles={[
                                'secret-doc',
                            ]}
                            path="/secret-doc-outbox-internal"
                            component={SecretDocOutboxInternal}
                        />
                        <PrivateRoute
                            roles={[
                                'secret-doc',
                            ]}
                            path="/secret-doc-outbox"
                            component={SecretDocOutbox}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/report"
                            component={Report}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/report-chart"
                            component={ReportChart}
                        />
                        <PrivateRoute
                            roles={['user', 'admin']}
                            path="/templatefrm"
                            component={TemplateFrm}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/pre-form-1"
                            component={pre1}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                            ]}
                            path="/pre-form-2"
                            component={pre2}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                            ]}
                            path="/pre-form-3"
                            component={pre3}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                            ]}
                            path="/pre-form-4"
                            component={pre4}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/pre-form-5"
                            component={pre5}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/pre-form-6"
                            component={pre6}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/pre-form-7"
                            component={pre7}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/pre-form-8"
                            component={pre8}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                            ]}
                            path="/pre-form-9"
                            component={pre9}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                            ]}
                            path="/pre-form-10"
                            component={pre10}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/pre-form-11"
                            component={pre11}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/pre-form-12"
                            component={pre12}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/pre-form-13"
                            component={pre13}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                            ]}
                            path="/pre-form-14"
                            component={pre14}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                            ]}
                            path="/pre-form-15"
                            component={pre15}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/all-form"
                            component={AllForm}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/rpt-1"
                            component={rpt1}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/rpt-2"
                            component={rpt2}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/rpt-3"
                            component={rpt3}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/rpt-4"
                            component={rpt4}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/rpt-1-detail"
                            component={rpt1detail}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/rpt-2-detail"
                            component={rpt2detail}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/rpt-3-detail"
                            component={rpt3detail}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/rpt-4-detail"
                            component={rpt4detail}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/report-register-no"
                            component={RptRegisterNo}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/report-summary-by-month"
                            component={RptSummaryByMonth}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/preview-report-daily"
                            component={PreviewRptDaily}
                        />
                        <PrivateRoute
                            roles={[
                                'secret-doc',
                            ]}
                            path="/report-secret-doc-summary-by-month"
                            component={RptSecretDocSummaryByMonth}
                        />
                        <PrivateRoute
                            roles={[
                                'secret-doc',
                            ]}
                            path="/preview-report-secret-doc-daily"
                            component={PreviewRptSecretDocDaily}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/report-destruction"
                            component={ReportDestruction}
                        />
                        <PrivateRoute
                            roles={['admin', 'system']}
                            path="/mas-expert"
                            component={MasExpert}
                        />
                        <PrivateRoute
                            roles={['super-admin', 'system']}
                            path="/mas-manager"
                            component={MasManager}
                        />
                        <PrivateRoute
                            roles={['super-admin', 'system']}
                            path="/mas-organization"
                            component={MasOrganization}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/mas-document-register-by-user-login"
                            component={MasDocumentRegisterByUserLogin}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/register-org-edit"
                            component={RegisterOrgEdit}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                            ]}
                            path="/register-manager-edit"
                            component={RegisterManagerEdit}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                            ]}
                            path="/register-expert-edit"
                            component={RegisterExpertEdit}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/archive-document"
                            component={ArchiveDocument}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'admin',
                                'admin-dep',
                                'admin-dep-out',
                                'admin-sup-dep',
                                'user',
                            ]}
                            path="/transfer-books"
                            component={TransferBooks}
                        />
                        <PrivateRoute
                           roles={[
                              'super-admin',
                              'admin',
                              'admin-dep',
                              'admin-dep-out',
                              'admin-sup-dep',
                              'user',
                          ]}
                            path="/form-auto-menu-list"
                            component={FormAutoMenuList}
                        />
                        <PrivateRoute
                           roles={[
                              'super-admin',
                              'admin',
                              'admin-dep',
                              'admin-dep-out',
                              'admin-sup-dep',
                              'user',
                          ]}
                            path="/form-auto-menu-copy-list"
                            component={FormAutoMenuCopyList}
                        />
                        <PrivateRoute
                           roles={[
                              'super-admin',
                              'admin',
                              'admin-dep',
                              'admin-dep-out',
                              'admin-sup-dep',
                              'user',
                          ]}
                            path="/form-auto-box"
                            component={FormAutoBox}
                        />
                        <PrivateRoute
                            roles={[
                                'user',
                            ]}
                            path="/rubber-stamp-data"
                            component={RubberStampData}
                        />
                        <PrivateRoute
                            roles={[
                                'user',
                            ]}
                            path="/rubber-stamp-receive"
                            component={RubberStampReceive}
                        />
                        <PrivateRoute
                            roles={[
                                'admin',
                            ]}
                            path="/rubber-stamp-bind-data"
                            component={BindEmpData}
                        />
                        <PrivateRoute
                            roles={[
                                'user',
                            ]}
                            path="/form-auto"
                            component={FormAuto}
                        />
                        <PrivateRoute
                            roles={[
                                'user',
                            ]}
                            path="/form-auto-copy"
                            component={FormAutoCopy}
                        />
                        <PrivateRoute
                            roles={[
                                'user',
                            ]}
                            path="/test-doc-viewer"
                            component={main}
                        />
                        <PrivateRoute
                            roles={[
                                'super-admin',
                                'system',
                            ]}
                            path="/special-organization"
                            component={SpecialOrganization}
                        />

                    </Switch>
                </div>
            </BrowserRouter>
        </KeycloakProvider>
    );
};

export default App;
