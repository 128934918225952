import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { pdfjs } from "react-pdf";
import pdfJsWorker from "react-pdf/src/pdf.worker.entry";
import PdfList from "./components/PdfList";
import PdfView from "./components/PdfView";
import Skeleton from "react-loading-skeleton";

pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker;

const PdfSelectionViewModal = forwardRef(({onAccept}, ref) => {
    const [pdfList, setPdfList] = useState([]);
    const [previewPdf, setPreviewPdf] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const close = () => setModalShow(false);
    const show = () => setModalShow(true);

    const [isPdfLoading, setIsPdfLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const signPdfRef = useRef();

    const clear = () => {
        setPdfList([]);
        setPreviewPdf(null);

        setIsPdfLoading(false);
        setIsSubmit(false);
    };

    const initPdfList = (pdfList) => {
        if (pdfList.length > 0) {
            setPdfList(pdfList);
            handlePreviewPdf(0, pdfList);
            setIsPdfLoading(false);
        } else {
            clear();
        }
    }

    const clearDataAndShowModal = () => {
        clear();
        show();
        setIsPdfLoading(true);
    };

    useImperativeHandle(ref, () => ({
        show,
        close,
        clear,
        initPdfList,
        clearDataAndShowModal,
    }));

    const handlePdfListSelected = (e, index) => {
        let pdfListSelected = pdfList.map((pdf, i) => {
            if (i === index) {
                return {
                    ...pdf,
                    isSelected: e.target.checked
                };
            } else if(!pdf.isSelected) {
                return {
                    ...pdf,
                    isSelected: false
                };
            }
            return pdf;
        });
        setPdfList(pdfListSelected);
    }

    const handlePreviewPdf = (index, pdfList) => {
        setPreviewPdf({
            base64Str: pdfList[index].base64Str,
            realFilename: pdfList[index].realFilename,
            index: index,
        });
    }

    const handleSubmit = () => {
        if (onAccept) {
            onAccept(pdfList);
        }
        // console.log('PdfSelectionViewModal.handleSubmit', pdfList);
        close();
    }

    const generateMenuSkeleton = (count = 1) => {
        return Array.from(Array(count).keys()).map((index) => (
            <div key={index}>
                <div className="col-12 text-center mb-2">
                    <Skeleton height={84} width={60}/>
                </div>
                <div className="col-12 text-center mb-2">
                    <Skeleton height={15} width={80}/>
                    <Skeleton height={15} width={120}/>
                </div>
            </div>
        ));
    }

    const generateSignPdfSkeleton = () => {
        return (
            <>
                <div className="m-4">
                    <div className="col-12 text-center">
                        <Skeleton height={30} width={400}/>
                    </div>
                </div>
                <div className="col-12 text-center">
                    <Skeleton height={841} width={595}/>
                </div>
            </>
        );
    }

    return (
        <>
            <Modal
                size="xl"
                show={modalShow}
                onHide={close}
                backdrop="static"
                keyboard={false}
                scrollable
            >
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-file-signature mr-2"></i>เลือกเอกสาร
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="bg-light p-0">
                    <Container fluid>
                        <Row>
                            <Col xs={2} className="border-right pt-2 pb-2">
                                {pdfList.length === 0 && isPdfLoading && generateMenuSkeleton(2)}
                                <PdfList
                                    pdfList={pdfList}
                                    previewPdf={previewPdf}
                                    onSelected={handlePdfListSelected}
                                    onViewSelected={handlePreviewPdf}
                                />
                            </Col>
                            <Col xs={10}>
                                {!previewPdf && !isPdfLoading && <div className="m-4 text-center" style={{"height": "841px"}}><h5><b>ไม่พบไฟล์ PDF</b></h5></div>}
                                {!previewPdf && isPdfLoading && generateSignPdfSkeleton()}
                                {previewPdf &&
                                    <PdfView
                                        pdf={previewPdf.base64Str}
                                        index={previewPdf.index}
                                        realFilename={previewPdf.realFilename}
                                        ref={signPdfRef}
                                    />
                                }
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-submit mr-2"
                        disabled={isSubmit}
                        onClick={!isSubmit ? handleSubmit : null}
                        style={{width: "100px"}}
                    >
                        ตกลง
                        {isSubmit && (
                            <div
                                className="spinner-border spinner-border-sm ml-2"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </Button>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={close}
                        style={{width: "100px"}}
                    >
                        ปิด
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
});

export default PdfSelectionViewModal;
