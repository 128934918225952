import React from 'react';
import { Form, Nav } from "react-bootstrap";
import { Document, Page } from "react-pdf";

const PdfList = ({pdfList, previewPdf, onSelected, onViewSelected}) => {
    const handleSelected = (e, index) => {
        if (onSelected) onSelected(e, index);
    }

    const handleViewSelected = (eventKey) => {
        const index = eventKey;
        if (onViewSelected) onViewSelected(index, pdfList);
    }

    const generatePdfList = () => {
        const result = [];
        pdfList.forEach(({base64Str, realFilename, isSelected}, index) => {
            result.push(
                <div className="d-flex align-items-center" key={index}>
                    <Form.Check type="checkbox" onChange={(e) => handleSelected(e, index)} checked={isSelected}/>
                    <Nav.Item className="text-center">
                        <Nav.Link eventKey={index}>
                            <Document file={base64Str}>
                                <Page
                                    width={60}
                                    pageNumber={1}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                />
                            </Document>
                            <span style={{fontSize: "12px"}}><b>{realFilename.replaceAll("_", " ")}</b></span>
                        </Nav.Link>
                    </Nav.Item>
                </div>
            )
        })
        return result;
    }

    return (
        <Nav className="d-flex flex-column"
             variant="pills"
             onSelect={selectedKey => handleViewSelected(selectedKey)}
             defaultActiveKey={previewPdf ? previewPdf.index : "0"}
        >
            <div className="sidebar-sticky"></div>
            {generatePdfList()}
        </Nav>
    );
};

export default PdfList;
