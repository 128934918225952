/* eslint-disable */
import { Button, Container } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Select from "react-select";
import $ from "jquery";
import { useKeycloak } from "@react-keycloak/web";
import { mainApi } from "../../HttpClient";
import Swal from "sweetalert2";
import config from "react-global-configuration";

const TransferBooks = () => {
    const [keycloak] = useKeycloak();
    const [organizeId, setOrganizeId] = useState('');
    const [orgIdSelected1, setOrgIdSelected1] = useState('');
    const [orgIdSelected2, setOrgIdSelected2] = useState('');
    const [comboboxOrgExpanded1, setComboboxOrgExpanded1] = useState([]);
    const [comboboxOrgExpanded2, setComboboxOrgExpanded2] = useState([]);
    const [isReferLoading, setIsReferLoading] = useState(true);

    useEffect(() => {
        initDataTable();
        fetchOrganizeId();
    }, [keycloak.token]);


    const fetchOrganizeId = async () => {
        const response = await mainApi.get('/api/employee/organization/1/' + keycloak.tokenParsed.preferred_username);
        setOrganizeId(response.data.orgId);
    };

    useEffect(() => {
        const fetchOrgList = async () => {
            let response = [];
            setIsReferLoading(true);
            if (organizeId !== '') {
                response = await mainApi.get('/api/organization/react-select/transfer-books-list/' + organizeId);
                setComboboxOrgExpanded1(response.data);
                setComboboxOrgExpanded2(response.data);
            }
            setIsReferLoading(false);
        };
        fetchOrgList();
    }, [organizeId]);

    //save
    const save = async () => {
        if (orgIdSelected1 === '' || orgIdSelected2 === '') {
            Swal.fire({
                    icon: 'warning',
                    title: 'โปรดเลือก',
                    text: 'หน่วยงานที่ต้องการ',
                    timer: config.get('alertTimer'),
                    timerProgressBar: true,
                }
            );
        } else if (orgIdSelected1 === orgIdSelected2) {
            Swal.fire({
                    icon: 'warning',
                    title: 'หน่วยงานเดิมและหน่วยงานใหม่ต้องไม่เหมือนกัน',
                    timer: config.get('alertTimer'),
                    timerProgressBar: true,
                }
            );
        } else {
            let params = {
                transferId: 0,
                transferSource: orgIdSelected1.value,
                transferTarget: orgIdSelected2.value,
                status: 1,
            };
            mainApi
                .post(
                    `/api/document-transfer/save`,
                    params
                )
                .then((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'สำเร็จ',
                        text: 'บันทึกข้อมูลสำเร็จ',
                        timer: config.get('alertTimer'),
                        timerProgressBar: true,
                    });
                    $('#data-table').DataTable().ajax.reload();
                });
        }
    };

    const transfer = async () => {
        mainApi
            .get(`/api/document-transfer/transfer`)
            .then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: 'สำเร็จ',
                    text: 'โอนข้อมูลสำเร็จ',
                    timer: config.get('alertTimer'),
                    timerProgressBar: true,
                });
                $('#data-table').DataTable().ajax.reload();
            });
    };

    const initDataTable = () => {
        $.fn.dataTable.ext.errMode = 'none';
        $('#data-table').DataTable({
            order: [0, 'desc'],
            ajax: {
                contentType: 'application/json',
                url: config.get('apiUrl') + '/api/document-transfer/datatables',
                type: 'POST',
                data: function (d) {
                    return JSON.stringify(d);
                },
                headers: {
                    Authorization: `bearer ${keycloak.token}`,
                },
            },
            processing: true,
            serverSide: true,
            mark: true,
            columnDefs: [{width: '100px', targets: 3}],
            columns: [
                {data: 'transferId', className: 'text-center', visible: false, searchable: false},
                {data: 'date', className: 'text-center'},
                {data: 'statusDesc', className: 'text-center'},
                {
                    data: '', className: 'text-center', responsivePriority: 2,
                    render: (data, type, row) => {
                        if (row.status === 1) {
                            return `<button type="button" class="btn btn-danger btn-transfer-cancel">ยกเลิก</button>`;
                        } else {
                            return '';
                        }
                    }
                },
            ],
            rowCallback: function (row, data) {
                $('td', row)
                    .find('.btn-transfer-cancel')
                    .on('click', function (e) {
                        handleTransferCancel(data.transferId);
                    });
            },
            lengthChange: true,
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
            language: {
                search: '_INPUT_',
                searchPlaceholder: 'ค้นหา...',
                lengthMenu: '_MENU_ รายการ/หน้า',
                sLengthMenu: '_MENU_ รายการ/หน้า',
                sProcessing: 'กำลังดำเนินการ...',
                sZeroRecords: 'ไม่พบข้อมูล',
                sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
                sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
                sInfoFiltered: '',
                sSearch: '',
                sUrl: '',
                oPaginate: {
                    sFirst: 'หน้าแรก',
                    sPrevious: 'ก่อนหน้า',
                    sNext: 'ถัดไป',
                    sLast: 'หน้าสุดท้าย',
                },
                processing:
                    '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
            },
        });
    };

    const handleTransferCancel = (transferId) => {
        Swal.fire({
            title: 'คุณแน่ใจหรือไม่',
            text: 'ต้องการยกเลิกใช่ไหม',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ปิด',
        }).then((result) => {
            if (result.isConfirmed) {
                mainApi
                    .post(
                        `/api/document-transfer/cancel/${transferId}`
                    )
                    .then((res) => {
                        Swal.fire({
                            icon: 'success',
                            title: 'สำเร็จ',
                            text: 'บันทึกข้อมูลสำเร็จ',
                            timer: config.get('alertTimer'),
                            timerProgressBar: true,
                        });
                        $('#data-table').DataTable().ajax.reload();
                    });
            }
        });
    };
    return (
        <div>
            <div className="content-wrapper" style={{minHeight: '100vh'}}>
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        โอนย้ายหนังสือ
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            โอนย้ายหนังสือ
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="table-header-MasOrganization card-header text-dark">
                                        <h6>จัดการโอนย้ายหนังสือ</h6>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className="form-group row">
                                                <div className="col-12 col-md-2 pt-2">
                                                    <span className="text-color-form align-middle">
                                                        หน่วยงานเดิม
                                                    </span><span className="text-danger"> *</span>
                                                </div>

                                                <div className="col-12 col-md-6 pt-1">
                                                    <Select
                                                        value={orgIdSelected1}
                                                        options={comboboxOrgExpanded1}
                                                        onChange={(ValueType) => setOrgIdSelected1(ValueType)}
                                                        isDisabled={isReferLoading}
                                                    >
                                                    </Select>
                                                </div>
                                                <div className="col-md-1 pt-2">
                                                    {isReferLoading && (
                                                        <div
                                                            className="spinner-border spinner-border-sm ml-2"
                                                            role="status"
                                                        >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12 col-md-2 pt-2">
                                                    <span className="text-color-form align-middle">
                                                        หน่วยงานใหม่
                                                    </span>
                                                    <span className="text-danger"> *</span>
                                                </div>
                                                <div className="col-12 col-md-6 pt-1">
                                                    <Select
                                                        value={orgIdSelected2}
                                                        options={comboboxOrgExpanded2}
                                                        onChange={(ValueType) => setOrgIdSelected2(ValueType)}
                                                        isDisabled={isReferLoading}
                                                    ></Select>
                                                </div>
                                                <div className="col-md-1 pt-2">
                                                    {isReferLoading && (
                                                        <div
                                                            className="spinner-border spinner-border-sm ml-2"
                                                            role="status"
                                                        >
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-12  pt-3">
                                                    <span className="text-danger">**กระบวนการโอนย้ายหน่วยงาน จะดำเนินการอัตโนมัติในเวลา 02:00 น. ของทุกวัน</span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-3 pt-2">
                                                <Button
                                                    type="button"
                                                    onClick={save}
                                                    disabled={isReferLoading}
                                                    className="btn-color-add-small">
                                                    <i className="fas fa-save pr-2"></i>
                                                    บันทึก
                                                </Button>
                                                <Button
                                                    type="button"
                                                    onClick={transfer}
                                                    disabled={isReferLoading}
                                                    className="btn-color-add-small pl-2 d-none">
                                                    <i className="fas fa-save pr-2"></i>
                                                    ทดสอบโอน
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    <table
                                        id="data-table"
                                        className="table table-striped table-bordered display w-100 margin-bottom-30"
                                    >
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th className="text-center">วันทีเปลี่ยน</th>
                                            <th className="text-center">สถานะ</th>
                                            <th className="text-center">จัดการ</th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </Container>
                </section>
            </div>
        </div>
    )
};

export default TransferBooks;
