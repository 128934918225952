export const informList = [
    {
        label: "เรียน",
        value: "เรียน",
        postScript: "ขอแสดงความนับถือ"
    },
    {
        label: "กราบเรียน",
        value: "กราบเรียน",
        postScript: "ขอแสดงความนับถืออย่างยิ่ง"
    },
    {
        label: "นมัสการ",
        value: "นมัสการ",
        postScript: "ขอนมัสการด้วยความเคารพ"
    },
    {
        label: "ทูล (ชั้นหม่อมเจ้า)",
        value: "ทูล (ชั้นหม่อมเจ้า)",
        postScript: "แล้วแต่จะโปรด"
    },
    {
        label: "ทูล (ชั้นพระวรวงศ์เธอ)",
        value: "ทูล (ชั้นพระวรวงศ์เธอ)",
        postScript: "ควรมิควรแล้วแต่จะโปรด"
    },
];
