import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
import { mainApi } from '../HttpClient';
import openInNewTab from './util/openInNewTab';
import config from 'react-global-configuration';
import { useSelector } from "react-redux";
// import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';

const DashboardV2 = () => {
  const [keycloak] = useKeycloak();
  const [sum, setSum] = useState([]);
  const document = useSelector((state) => state.document);

  useEffect(() => {
    if(document.documentBy.id !== null) {
      mainApi.get(`/api/v1/documents/summary?documentById=${document.documentBy.id}`).then((res) => {
        setSum(res.data);
      });
    }
  }, [document.documentBy.id]);

  const getReceiveOut = () => {
    let link = './inbox-int';

    if (isRole(['super-admin', 'admin-dep-out'])) {
      link = './inbox-ext';
    }

    return link;
  };

  const isRole = (roles) => {
    if (keycloak && roles) {
      return roles.some((r) => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }
    return false;
  };

  return (
    <div className="content-wrapper" style={{ minHeight: '100vh' }}>
      <section className="content">
        <Container fluid>
          <div className="row">
            <div className="col-12 content-top-padding"></div>
          </div>

          <div className="content-header">
            <div className="row">
              <div className="col-sm-12 col-md-2">
                <h1 className="text-light-dark page-heading mt-2">หน้าหลัก</h1>
                <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb-active">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                    หน้าหลัก
                  </li>
                </ol>
              </div>
              <div className="col-sm-12 col-md-10 text-sm-right">
                <Link
                  to=""
                  onClick={() => {
                    openInNewTab(config.get('oldSarabunUrl'));
                  }}
                  className="small-box-footer"
                >
                  ระบบงานสารบรรณอิเล็กทรอนิกส์ (เดิม)
                  <i className="fas fa-arrow-circle-right ml-1"></i>
                </Link>
                <br />
                <Link
                  to=""
                  onClick={() => {
                    openInNewTab(
                      config.get('baseURL') + config.get('adminManual')
                    );
                  }}
                  className="small-box-footer text-decoration-none"
                >
                  <i className="far fa-file-pdf text-danger"></i>
                  &nbsp;&nbsp;คู่มือระบบสารบรรณสำหรับผู้ดูแลระบบ
                </Link>
                <br />
                <Link
                  to=""
                  onClick={() => {
                    openInNewTab(
                      config.get('baseURL') + config.get('userManual')
                    );
                  }}
                  className="small-box-footer text-decoration-none"
                >
                  <i className="far fa-file-pdf text-danger"></i>
                  &nbsp;&nbsp;คู่มือระบบสารบรรณสำหรับผู้ใช้งาน
                </Link>
                <br />
                <Link
                    to=""
                    onClick={() => {
                      openInNewTab('https://www.youtube.com/playlist?list=PLPgigTJYxRijlkLtnS5EXpIdaRC6nDOX0');
                    }}
                    className="small-box-footer text-decoration-none"
                >
                  <i className="far fa-file-video text-green"></i>
                  &nbsp;&nbsp;วีดีโอสอนการใช้งานระบบสารบรรณ
                </Link>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-12">
                {/* <ol className="breadcrumb float-sm-left bg-transparent p-0">
                  <li className="breadcrumb-item text-breadcrumb-active">
                    <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                    หน้าหลัก
                  </li>
                </ol> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>{sum.cntRIn}</h3>

                  <p>หนังสือรับภายใน</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag"></i>
                </div>
                <Link to="./inbox-int" className="small-box-footer">
                  รายละเอียด <i className="fas fa-arrow-circle-right"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>{sum.cntROut}</h3>

                  <p>หนังสือรับภายนอก</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars"></i>
                </div>
                <Link to={getReceiveOut} className="small-box-footer">
                  รายละเอียด <i className="fas fa-arrow-circle-right"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>{sum.cntSIn}</h3>

                  <p>หนังสือส่งภายใน</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add"></i>
                </div>
                <Link to="./outbox" className="small-box-footer">
                  รายละเอียด <i className="fas fa-arrow-circle-right"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>{sum.cntSOut}</h3>

                  <p>หนังสือส่งภายนอก</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph"></i>
                </div>
                <Link to="./outbox" className="small-box-footer">
                  รายละเอียด <i className="fas fa-arrow-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>{sum.cntWIn}</h3>

                  <p>หนังสือเวียนภายใน</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag"></i>
                </div>
                <Link to="./circular" className="small-box-footer">
                  รายละเอียด <i className="fas fa-arrow-circle-right"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>{sum.cntWout}</h3>

                  <p>หนังสือเวียนภายนอก</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars"></i>
                </div>
                <Link to="./circular" className="small-box-footer">
                  รายละเอียด <i className="fas fa-arrow-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="row container_menu_dashboard">
            <div className="col-lg-3 col-6">
              <div className="small-box bg-secondary">
                <div className="inner">
                  <i className="far fa-calendar-minus fa-2x"></i>
                  <p>ใบลา</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag"></i>
                </div>
                <Link
                  to=""
                  onClick={() => {
                    openInNewTab(config.get('hrUrl') + '/leave-form');
                  }}
                  className="small-box-footer"
                >
                  รายละเอียด <i className="fas fa-arrow-circle-right"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-secondary">
                <div className="inner">
                  <i className="far fa-clock fa-2x"></i>
                  <p>ขอบันทึกเวลานอกสถานที่</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars"></i>
                </div>
                <Link
                  to=""
                  onClick={() => {
                    openInNewTab(config.get('hrUrl') + '/recored-outing');
                  }}
                  className="small-box-footer"
                >
                  รายละเอียด <i className="fas fa-arrow-circle-right"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-secondary">
                <div className="inner">
                  <i className="fas fa-wrench fa-2x"></i>
                  <p>หนังสือแจ้งซ่อม</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars"></i>
                </div>
                <Link
                  to=""
                  onClick={() => {
                    openInNewTab(
                      config.get('smartfixUrl') + '/index?external=request'
                    );
                  }}
                  className="small-box-footer"
                >
                  รายละเอียด <i className="fas fa-arrow-circle-right"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-secondary">
                <div className="inner">
                  <i className="fas fa-car fa-2x"></i>
                  <p>หนังสือจองรถ</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars"></i>
                </div>
                <Link
                  to=""
                  onClick={() => {
                    openInNewTab(config.get('carbookExternalUrl'));
                  }}
                  className="small-box-footer"
                >
                  รายละเอียด <i className="fas fa-arrow-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default DashboardV2;
