import React, { useState, useEffect } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useForm } from 'react-hook-form';
import { mainApi, hrApi } from '../HttpClient';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import config from 'react-global-configuration';
import Swal from 'sweetalert2';

// import 'datatables.net-dt/js/dataTables.dataTables.min';
import 'datatables.mark.js/dist/datatables.mark.es6.min.js';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import 'datatables.net-responsive/js/dataTables.responsive.min';

import $ from 'jquery';

const MasManager = () => {
  const { register, errors, handleSubmit } = useForm();
  const [idCard, setIdCard] = useState('');
  const [isEmptyIdCard, setIsEmptyIdCard] = useState(false);
  const [empName, setEmpName] = useState('');
  const [empLastName, setEmpLastName] = useState('');
  const [referSelections, setReferSelections] = useState([]);
  const [referOptions, setReferOptions] = useState();
  const [isReferLoading, setIsReferLoading] = useState(false);

  /** Form Add User */
  const [userIdCard, setUserIdCard] = useState('');
  const [isEmptyUserIdCard, setIsEmptyUserIdCard] = useState(false);
  const [referSelectionsUserId, setReferSelectionsUserId] = useState([]);
  const [referOptionsUserId, setReferOptionsUserId] = useState();
  const [isReferLoadingUserId, setIsReferLoadingUserId] = useState(false);

  const {
    register: modalRegister,
    errors: modalErrors,
    handleSubmit: modalHandleSubmit,
  } = useForm();

  const {
    register: modalAddUser,
    errors: modalAddUserErrors,
    handleSubmit: modalHandleAddUserSubmit,
  } = useForm();

  const [keycloak] = useKeycloak();
  const [editData, setEditData] = useState({});
  const [AddUserData, setAddUserData] = useState({});

  // Modal Edit
  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  // Modal Add User
  const [modalAddUserShow, setModalAddUserShow] = useState(false);
  const handleModalAddUserClose = () => setModalAddUserShow(false);
  const handleModalAddUserShow = () => setModalAddUserShow(true);

  const disableButtonSubmit = () =>
    $('#btn-submit-mas-expert').attr('disabled', 'disabled');
  const enableButtonSubmit = () =>
    $('#btn-submit-mas-expert').removeAttr('disabled');

  const handleDuplicate = async () => {
    Swal.fire({
      title: 'รหัสบัตรประชาชนซ้ำ',
      text: 'ไม่สามารถเพิ่มข้อมูลได้ เนื่องจากรหัสบัตรประชาชนซ้ำในระบบ',
      icon: 'warning',
      showConfirmButton: false,
      showCancelButton: true,
      // confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      // confirmButtonText: 'ลบข้อมูล',
      cancelButtonText: 'ยกเลิก',
    });
  };

  const handleDelete = async (employeeId) => {
    Swal.fire({
      title: 'คุณแน่ใจหรือไม่',
      text: 'ต้องการลบข้อมูลผู้บริหารใช่ไหม',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'ลบข้อมูล',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.isConfirmed) {
        Delete(employeeId.trim());
      }
    });
  };

  const Delete = async (employeeId) => {
    const response = await mainApi.delete(
      '/api/mas_manager/delete/' + employeeId
    );
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        title: 'สำเร็จ',
        text: 'ลบข้อมูลผู้บริหารสำเร็จ',
        timer: config.get('alertTimer'),
        timerProgressBar: true,
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ผิดพลาด',
        text: 'ลบไม่สำเร็จ กรุณาลองใหม่ภายหลัง',
      });
    }
    fetchDataTable();
  };

  const handleDeleteUser = async (employeeId, userId) => {
    Swal.fire({
      title: 'คุณแน่ใจหรือไม่',
      text: 'ต้องการลบบัญชีใช่ไหม',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'ลบข้อมูล',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteUser(employeeId.trim(), userId.trim());
      }
    });
  };

  const DeleteUser = async (employeeId, userId) => {
    const response = await mainApi.delete(
      '/api/mas_manager_admin/delete/' + employeeId + '/' + userId
    );
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        title: 'สำเร็จ',
        text: 'ลบข้อมูลบัญชีสำเร็จ',
        timer: config.get('alertTimer'),
        timerProgressBar: true,
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ผิดพลาด',
        text: 'ลบไม่สำเร็จ กรุณาลองใหม่ภายหลัง',
      });
    }
    fetchDataTableAddUser();
  };

  const handleSave = async (data) => {
    // console.log(data);
    disableButtonSubmit();
    setIsEmptyIdCard(false);

    if (idCard === '') {
      setIsEmptyIdCard(true);
      enableButtonSubmit();
      return false;
    }

    let count_data = 0;
    let res = await mainApi.get('/api/mas_manager/check_duplicate/' + idCard);

    if (res.status === 200) {
      count_data = parseInt(res.data);
      if (count_data > 0) {
        handleDuplicate();
      } else {
        save(data);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ผิดพลาด',
        text: 'ตรวจสอบข้อมูลไม่สำเร็จ กรุณาลองใหม่ภายหลัง',
      });
    }
    enableButtonSubmit();
  };

  const handleEdit = async (data) => {
    // console.log("Edit", data);
    let tmp_num_order = parseInt(data.edit_num_order) || 0;
    let valid_number = 0;
    if (Number.isInteger(tmp_num_order) === true) {
      valid_number = tmp_num_order;
    } else {
      valid_number = 0;
    }
    let params = {
      employeeId: data.edit_employeeId.trim(),
      fname: data.edit_fname.trim(),
      lname: data.edit_lname.trim(),
      position: data.edit_position.trim(),
      num_order: valid_number,
    };

    let rsEdit = await mainApi.put('/api/mas_manager/update', params);
    handleModalClose();

    if (rsEdit.status === 200) {
      Swal.fire({
        icon: 'success',
        title: 'สำเร็จ',
        text: 'แก้ไขข้อมูลผู้บริหารสำเร็จ',
        timer: config.get('alertTimer'),
        timerProgressBar: true,
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ผิดพลาด',
        text: 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่ภายหลัง',
      });
    }
    fetchDataTable();
  };

  const handleAddUserSave = async (data) => {
    //console.log("Add User Save", data)
    setIsEmptyUserIdCard(false);

    if (userIdCard === '') {
      setIsEmptyUserIdCard(true);
      return false;
    }

    let mes_success = `บันทึกข้อมูลสำเร็จ`;
    let mes_error = `บันทึกไม่สำเร็จ`;

    let params = {
      employeeId: data.add_user_employeeId,
      userId: userIdCard.trim(),
    };
    //console.log(params);
    let rsSave = await mainApi.post('/api/mas_manager_admin/save', params);
    //console.log(rsSave);

    if (rsSave.data === 'ok') {
      Swal.fire({
        icon: 'success',
        title: 'สำเร็จ',
        text: mes_success,
        timer: config.get('alertTimer'),
        timerProgressBar: true,
      });
    } else if (rsSave.data === 'duplicate') {
      Swal.fire({
        icon: 'error',
        title: 'ข้อมูลบัญชีซ้ำ',
        text: 'ข้อมูลบัญชี ' + userIdCard + ' ได้ทำการผูกข้อมูลอยู่แล้ว',
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ผิดพลาด',
        text: mes_error,
      });
    }
    // $('#btn_reset').click();
    clearFormDataAddUser();
    fetchDataTableAddUser();
  };

  const initSetEditData = (data) => {
    setEditData({});
    setEditData(data);
    handleModalShow();
  };

  const initSetAddUser = (data) => {
    setAddUserData({});
    setAddUserData(data);
    handleModalAddUserShow();
  };

  const save = async (data) => {
    let mes_success = `บันทึกข้อมูลสำเร็จ`;
    let mes_error = `บันทึกไม่สำเร็จ`;

    let tmp_num_order = parseInt(data.num_order) || 0;
    let valid_number = 0;
    if (Number.isInteger(tmp_num_order) === true) {
      valid_number = tmp_num_order;
    } else {
      valid_number = 0;
    }

    // let params = {
    //   employeeId: data.employeeId.trim(),
    //   fname: data.fname.trim(),
    //   lname: data.lname.trim(),
    //   position: data.position.trim(),
    //   num_order: valid_number,
    // };
    // console.log("idCard", idCard);
    let params = {
      employeeId: idCard.trim(),
      fname: data.fname.trim(),
      lname: data.lname.trim(),
      position: data.position.trim(),
      num_order: valid_number,
    };
    // console.log(params);
    let rsSave = await mainApi.post('/api/mas_manager/save', params);

    if (rsSave.status === 200) {
      Swal.fire({
        icon: 'success',
        title: 'สำเร็จ',
        text: mes_success,
        timer: config.get('alertTimer'),
        timerProgressBar: true,
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ผิดพลาด',
        text: mes_error,
      });
    }
    $('#btn_reset').click();
    fetchDataTable();
  };

  const fetchDataTable = () => {
    $('#data-table').DataTable().ajax.reload();
  };

  const fetchDataTableAddUser = () => {
    $('#data-table-add-user').DataTable().ajax.reload();
  };

  const handleIdCardChange = (item) => {
    if (item.length > 0) {
      setIdCard(item[0].employee_id);
      setEmpName(item[0].fname);
      setEmpLastName(item[0].lname);
      setReferSelections(item);
      // Reset Error Message.
      if (isEmptyIdCard === true) {
        setIsEmptyIdCard(false);
      }
    } else {
      console.log(' Set Employee ID Card Error !!');
    }
  };

  const handleUserIdCardChange = (item) => {
    if (item.length > 0) {
      setUserIdCard(item[0].employee_id);
      setReferSelectionsUserId(item);
      // Reset Error Message.
      if (isEmptyUserIdCard === true) {
        setIsEmptyUserIdCard(false);
      }
    }
  };

  const clearFormData = () => {
    resetEmployee();
    $("input[name='position']").val('');
    $("input[name='num_order']").val('0');
  };

  const clearFormDataAddUser = () => {
    resetUserId();
  };

  const resetEmployee = () => {
    setIdCard('');
    setIsEmptyIdCard(false);
    setEmpName('');
    setEmpLastName('');
    setReferSelections([]);
  };

  const resetUserId = () => {
    setUserIdCard('');
    setIsEmptyUserIdCard(false);
    setReferSelectionsUserId([]);
  };

  const handleReferSearch = async (query) => {
    setIsReferLoading(true);

    let params = {
      criteria: {
        like_employee_id: query,
        like_fname: query,
        like_lname: query,
      },
      current_page: 1,
      page_size: 10,
    };
    const rs = await hrApi.post('/api/employee/search', params);
    // console.log(rs);

    // let tmpVal = [{id:"1",label:"1"}, {id:"2",label:"2"}]
    // setReferOptions(tmpVal)
    if (rs.status === 200) {
      setReferOptions(rs.data.data.records);
      setIsReferLoading(false);
    } else {
      setIsReferLoading(false);
    }
  };

  const handleReferSearchUserId = async (query) => {
    setIsReferLoadingUserId(true);

    let params = {
      criteria: {
        like_employee_id: query,
        like_fname: query,
        like_lname: query,
      },
      current_page: 1,
      page_size: 10,
    };
    const rs = await hrApi.post('/api/employee/search', params);

    if (rs.status === 200) {
      setReferOptionsUserId(rs.data.data.records);
      setIsReferLoadingUserId(false);
    } else {
      setIsReferLoadingUserId(false);
    }
  };

  useEffect(() => {
    $.fn.dataTable.ext.errMode = 'none';

    $('#data-table').DataTable({
      order: [0, 'asc'],
      ajax: {
        contentType: 'application/json',
        url: config.get('apiUrl') + '/api/mas_manager/list',
        type: 'POST',
        data: function (d) {
          return JSON.stringify(d);
        },
        headers: {
          Authorization: `bearer ${keycloak.token}`,
        },
      },
      processing: true,
      serverSide: true,
      mark: true,
      columnDefs: [{ width: '100px' }],
      columns: [
        {
          data: 'num_order',
          visible: true,
          className: 'text-left show-truncate',
          width: '15%',
          render: function (data, type, row) {
            return `<div class="d-flex justify-content-around align-items-center">
              <button type="button" class="btn btn-sm btn-order-up btn-primary">
                <i class="fas fa-angle-up"></i>
              </button>
              <div>${row.num_order}</div>
              <button type="button" class="btn btn-sm btn-order-down btn-secondary">
                <i class="fas fa-angle-down"></i>
              </button>
            </div>`;
          },
        },
        {
          data: 'employeeId',
          visible: true,
          className: 'text-center',
          width: '20%',
        },
        {
          data: 'fname',
          className: 'text-left',
          responsivePriority: 1,
          targets: 0,
          width: '20%',
        },
        {
          data: 'lname',
          className: 'text-left',
          width: '20%',
        },
        {
          data: 'position',
          className: 'text-left show-truncate',
          width: '25%',
        },
        {
          className: 'text-center',
          searchable: false,
          orderable: false,
          responsivePriority: 3,
          targets: -2,
          width: '15%',
          render: function (data, type, row) {
            return `<div>
                    <button type="button" class="btn btn-secondary btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                      <button class="dropdown-item btn-add-user" type="button"><i class="fas fa-users-cog mr-2"></i>จัดการบัญชีผู้ใช้</button>
                      <button class="dropdown-item btn-edit" type="button""><i class="fas fa-undo-alt mr-2"></i>แก้ไข</button>
                      <button class="dropdown-item btn-delete" type="button""><i class="fas fa-times-circle mr-2"></i>ลบข้อมูล</button>
                    </div>
                  </div>`;
          },
        },
      ],
      rowCallback: function (row, data) {
        $('td', row)
          .find('.btn-order-up')
          .on('click', function (e) {
            // console.log(data);
            reorder(data.employeeId, data.num_order, 'UP');
          });

        $('td', row)
          .find('.btn-order-down')
          .on('click', function (e) {
            reorder(data.employeeId, data.num_order, 'DOWN');
          });

        $('td', row)
          .find('.btn-edit')
          .on('click', function (e) {
            initSetEditData(data);
          });

        $('td', row)
          .find('.btn-delete')
          .on('click', function (e) {
            handleDelete(data.employeeId);
          });

        $('td', row)
          .find('.btn-add-user')
          .on('click', function (e) {
            initSetAddUser(data);
          });
      },
      lengthChange: true,
      ordering: true,
      info: true,
      responsive: true,
      // dom:
      //   '<"row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>><"datatable-wrap"t><"row"<"col-sm-12 col-md-5"i><"col-sm-12 col-md-7"p>>',
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'ค้นหา...',
        lengthMenu: '_MENU_ รายการ/หน้า',
        sLengthMenu: '_MENU_ รายการ/หน้า',
        sProcessing: 'กำลังดำเนินการ...',
        sZeroRecords: 'ไม่พบข้อมูล',
        sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
        sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
        sInfoFiltered: '',
        sSearch: '',
        sUrl: '',
        oPaginate: {
          sFirst: 'หน้าแรก',
          sPrevious: 'ก่อนหน้า',
          sNext: 'ถัดไป',
          sLast: 'หน้าสุดท้าย',
        },
        processing:
          '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
      },
    });

    const reorder = async (emp_id, cur_position, order_action) => {
      await mainApi
        .get(
          '/api/mas_manager/re-order?employeeId=' +
            emp_id +
            '&curNumOrder=' +
            cur_position +
            '&action=' +
            order_action
        )
        .then((res) => {
          fetchDataTable();
        });
    };
  });

  // DataTable Add User
  const loadDataTableAddUser = async () => {
    $('#data-table-add-user').DataTable({
      order: [1, 'asc'],
      ajax: {
        contentType: 'application/json',
        url:
          config.get('apiUrl') +
          '/api/mas_manager_admin/list?employeeId=' +
          AddUserData.employeeId,
        type: 'POST',
        data: function (d) {
          return JSON.stringify(d);
        },
        headers: {
          Authorization: `bearer ${keycloak.token}`,
        },
      },
      processing: true,
      serverSide: true,
      columnDefs: [{ width: '100px' }],
      columns: [
        {
          visible: false,
          data: 'employeeId',
          className: 'text-left',
          responsivePriority: 1,
          targets: 0,
          width: '20%',
        },
        {
          data: 'userId',
          className: 'text-center',
          width: '20%',
        },
        {
          className: 'text-center',
          searchable: false,
          orderable: false,
          responsivePriority: 3,
          targets: -2,
          width: '15%',
          render: function (data, type, row) {
            return `
                      <button class="dropdown-item btn-delete-user text-danger" type="button"><i class="fas fa-times-circle mr-2"></i>ลบข้อมูล</button>
                   `;
          },
        },
      ],
      rowCallback: function (row, data) {
        $('td', row)
          .find('.btn-delete-user')
          .on('click', function (e) {
            handleDeleteUser(data.employeeId, data.userId);
          });
      },
      lengthChange: true,
      ordering: true,
      info: true,
      responsive: true,
      dom:
        '<"row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>><"datatable-wrap"t><"row"<"col-sm-12 col-md-5"i><"col-sm-12 col-md-7"p>>',
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'ค้นหา...',
        lengthMenu: '_MENU_ รายการ/หน้า',
        sLengthMenu: '_MENU_ รายการ/หน้า',
        sProcessing: 'กำลังดำเนินการ...',
        sZeroRecords: 'ไม่พบข้อมูล',
        sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
        sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
        sInfoFiltered: '',
        sSearch: '',
        sUrl: '',
        oPaginate: {
          sFirst: 'หน้าแรก',
          sPrevious: 'ก่อนหน้า',
          sNext: 'ถัดไป',
          sLast: 'หน้าสุดท้าย',
        },
      },
    });
  };

  return (
    <div>
      <div
        className="content-wrapper text-normal"
        style={{ minHeight: '100vh' }}
      >
        <section className="content">
          <Container>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>
            <div className="content-header">
              <div className="row">
                <div className="col-sm-12">
                  <h1 className="text-light-dark page-heading mt-2">
                    ข้อมูลผู้บริหาร
                  </h1>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12">
                  <ol className="breadcrumb float-sm-left bg-transparent p-0">
                    <li className="breadcrumb-item text-breadcrumb">
                      <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                      <Link to="./" className="text-breadcrumb">
                        หน้าหลัก
                      </Link>
                    </li>
                    <li className="breadcrumb-item text-breadcrumb-active">
                      ข้อมูลผู้บริหาร
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="table-header-MasManager card-header text-dark">
                    เพิ่มข้อมูลผู้บริหาร
                  </div>
                  <div className="card-body">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="form-group row">
                        <div className="col-12 col-md-3 pt-2">
                          <span className="text-color-form align-middle">
                            รหัสบัตรประชาชน
                          </span>
                          <span className="text-danger"> *</span>
                        </div>

                        <div className="col-12 col-md-9 pt-1">
                          {/* <input
                            className="form-control shadow-none"
                            type="text"
                            name="employeeId"
                            ref={register({
                              required: {
                                value: true,
                                message: "กรุณากรอกข้อมูล"
                              },
                              maxLength: {
                                value: 50,
                                message: "ข้อมูลต้องไม่เกิน 50 ตัวอักษร"
                              }
                            })}
                          /> */}
                          <AsyncTypeahead
                            filterBy={() => true}
                            id="basic-typeahead-single"
                            isLoading={isReferLoading}
                            labelKey="employee_id"
                            emptyLabel="ไม่พบข้อมูล"
                            // onChange={setReferSelections}
                            options={referOptions}
                            placeholder="กรุณาพิมพ์เลขบัตรประชาชน"
                            selected={referSelections}
                            minLength={2}
                            onSearch={(query) => handleReferSearch(query)}
                            onChange={(selected) => {
                              // console.log(selected);
                              handleIdCardChange(selected);
                            }}
                            onKeyDown={(event) => {
                              resetEmployee();
                            }}
                            renderMenuItemChildren={(option, props, idx) => (
                              <div>
                                {option.employee_id}{' '}
                                <small>
                                  ({option.fname} {option.lname})
                                </small>
                              </div>
                            )}
                          />
                          {isEmptyIdCard && (
                            <div className="text-left">
                              <span className="text-danger">
                                กรุณากรอกข้อมูล
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-12 col-md-3 pt-2">
                          <span className="text-color-form align-middle">
                            ชื่อ
                          </span>
                          <span className="text-danger"> *</span>
                        </div>
                        <div className="col-12 col-md-9 pt-1">
                          <input
                            className="form-control shadow-none"
                            type="text"
                            name="fname"
                            value={empName}
                            onChange={(e) => {
                              setEmpName(e.target.value);
                            }}
                            ref={register({
                              required: {
                                value: true,
                                message: 'กรุณากรอกข้อมูล',
                              },
                              maxLength: {
                                value: 50,
                                message: 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร',
                              },
                            })}
                          />
                          {errors.fname && (
                            <div className="text-left">
                              <span className="text-danger">
                                {errors.fname.message}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-12 col-md-3 pt-2">
                          <span className="text-color-form align-middle">
                            นามสกุล
                          </span>
                          <span className="text-danger"> *</span>
                        </div>
                        <div className="col-12 col-md-9 pt-1">
                          <input
                            className="form-control shadow-none"
                            type="text"
                            name="lname"
                            value={empLastName}
                            onChange={(e) => {
                              setEmpLastName(e.target.value);
                            }}
                            ref={register({
                              required: {
                                value: true,
                                message: 'กรุณากรอกข้อมูล',
                              },
                              maxLength: {
                                value: 50,
                                message: 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร',
                              },
                            })}
                          />
                          {errors.lname && (
                            <div className="text-left">
                              <span className="text-danger">
                                {errors.lname.message}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-12 col-md-3 pt-2">
                          <span className="text-color-form align-middle">
                            ตำแหน่ง
                          </span>
                          <span className="text-danger"> *</span>
                        </div>
                        <div className="col-12 col-md-9 pt-1">
                          <input
                            className="form-control shadow-none"
                            type="text"
                            name="position"
                            ref={register({
                              required: {
                                value: true,
                                message: 'กรุณากรอกข้อมูล',
                              },
                              maxLength: {
                                value: 500,
                                message: 'ข้อมูลต้องไม่เกิน 500 ตัวอักษร',
                              },
                            })}
                          />
                          {errors.position && (
                            <div className="text-left">
                              <span className="text-danger">
                                {errors.position.message}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-12 col-md-3 pt-2">
                          <span className="text-color-form align-middle">
                            ลำดับ
                          </span>
                        </div>
                        <div className="col-12 col-md-9 pt-1">
                          <input
                            className="form-control shadow-none"
                            type="number"
                            name="num_order"
                            min={0}
                            step={1}
                            defaultValue={0}
                            ref={register()}
                          />
                          {errors.num_order && (
                            <div className="text-left">
                              <span className="text-danger">
                                {errors.num_order.message}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-sm-12 col-md-3 offset-md-6 pt-2">
                        <Button
                          type="submit"
                          id="btn-submit-mas-expert"
                          className="btn-color-add-small btn-block"
                          onClick={handleSubmit(handleSave)}
                        >
                          <i className="far fa-save pr-2"></i>บันทึก
                        </Button>
                      </div>
                      <div className="col-sm-12 col-md-3 pt-2">
                        <Button
                          type="reset"
                          className="btn-block btn-color-delete-small"
                          id="btn_reset"
                          onClick={clearFormData}
                        >
                          <i className="fas fa-ban pr-2"></i>
                          ยกเลิก
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card mt-2">
                  <div className="table-header-MasManager card-header text-dark">
                    รายชื่อผู้ทรงคุณวุฒิ
                  </div>
                  <div className="card-body">
                    <table
                      id="data-table"
                      className="table table-striped table-bordered display w-100 margin-bottom-30"
                    >
                      <thead>
                        <tr>
                          <th className="text-center">ลำดับ</th>
                          <th className="text-center">รหัสบัตรประชาชน</th>
                          <th className="text-center">ชื่อ</th>
                          <th className="text-center">นามสกุล</th>
                          <th className="text-center">ตำแหน่ง</th>
                          <th className="text-center">จัดการ</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>

      <Modal show={modalShow} onHide={handleModalClose}>
        <Modal.Header closeButton className="bg-color">
          <Modal.Title className="nav-text text-shadow-header">
            <i className="fas fa-paper-plane mr-2"></i>แก้ไขข้อมูลผู้บริหาร
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row mt-3">
                <div className="col">
                  <div className="form-group row">
                    <div className="col-12 col-md-4 pt-2">
                      <span className="text-color-form align-middle">
                        รหัสบัตรประชาชน
                      </span>
                      <span className="text-danger"> *</span>
                    </div>
                    <div className="col-12 col-md-8 pt-1">
                      <input
                        className="form-control shadow-none"
                        type="text"
                        name="edit_employeeId"
                        defaultValue={editData.employeeId}
                        readOnly
                        ref={modalRegister({
                          required: {
                            value: true,
                            message: 'กรุณากรอกข้อมูล',
                          },
                          maxLength: {
                            value: 50,
                            message: 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร',
                          },
                        })}
                      />
                      {modalErrors.edit_employeeId && (
                        <div className="text-left">
                          <span className="text-danger">
                            {modalErrors.edit_employeeId.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12 col-md-4 pt-2">
                      <span className="text-color-form align-middle">ชื่อ</span>
                      <span className="text-danger"> *</span>
                    </div>
                    <div className="col-12 col-md-8 pt-1">
                      <input
                        className="form-control shadow-none"
                        type="text"
                        name="edit_fname"
                        defaultValue={editData.fname}
                        ref={modalRegister({
                          required: {
                            value: true,
                            message: 'กรุณากรอกข้อมูล',
                          },
                          maxLength: {
                            value: 50,
                            message: 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร',
                          },
                        })}
                      />
                      {modalErrors.edit_fname && (
                        <div className="text-left">
                          <span className="text-danger">
                            {modalErrors.edit_fname.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12 col-md-4 pt-2">
                      <span className="text-color-form align-middle">
                        นามสกุล
                      </span>
                      <span className="text-danger"> *</span>
                    </div>
                    <div className="col-12 col-md-8 pt-1">
                      <input
                        className="form-control shadow-none"
                        type="text"
                        name="edit_lname"
                        defaultValue={editData.lname}
                        ref={modalRegister({
                          required: {
                            value: true,
                            message: 'กรุณากรอกข้อมูล',
                          },
                          maxLength: {
                            value: 50,
                            message: 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร',
                          },
                        })}
                      />
                      {modalErrors.edit_lname && (
                        <div className="text-left">
                          <span className="text-danger">
                            {modalErrors.edit_lname.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12 col-md-4 pt-2">
                      <span className="text-color-form align-middle">
                        ตำแหน่ง
                      </span>
                      <span className="text-danger"> *</span>
                    </div>
                    <div className="col-12 col-md-8 pt-1">
                      <input
                        className="form-control shadow-none"
                        type="text"
                        name="edit_position"
                        defaultValue={editData.position}
                        ref={modalRegister({
                          required: {
                            value: true,
                            message: 'กรุณากรอกข้อมูล',
                          },
                          maxLength: {
                            value: 500,
                            message: 'ข้อมูลต้องไม่เกิน 500 ตัวอักษร',
                          },
                        })}
                      />
                      {modalErrors.edit_position && (
                        <div className="text-left">
                          <span className="text-danger">
                            {modalErrors.edit_position.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12 col-md-4 pt-2">
                      <span className="text-color-form align-middle">
                        การเรียงลำดับ
                      </span>
                    </div>
                    <div className="col-12 col-md-8 pt-1">
                      <input
                        className="form-control shadow-none"
                        type="number"
                        name="edit_num_order"
                        min={0}
                        step={1}
                        defaultValue={editData.num_order}
                        ref={modalRegister()}
                      />
                      {errors.edit_num_order && (
                        <div className="text-left">
                          <span className="text-danger">
                            {errors.edit_num_order.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="btn-color-submit mr-2"
            onClick={modalHandleSubmit(handleEdit)}
          >
            <i className="fas fa-save pr-2"></i>
            บันทึก
          </Button>
          <Button
            type="button"
            className="btn-color-delete-small"
            onClick={handleModalClose}
          >
            <i className="fas fa-ban pr-2"></i>
            ยกเลิก
          </Button>
        </Modal.Footer>
      </Modal>

      {/* // *****************************Modal Add User ********************************** */}

      <Modal
        show={modalAddUserShow}
        onHide={handleModalAddUserClose}
        size="lg"
        onEntered={() => {
          resetUserId();
          loadDataTableAddUser();
        }}
      >
        <Modal.Header closeButton className="bg-color">
          <Modal.Title className="nav-text text-shadow-header">
            <i className="fas fa-users-cog mr-2"></i>จัดการบัญชีผู้ใช้
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row mt-3">
                <div className="col">
                  <div className="form-group row">
                    <div className="col-12 col-md-4 pt-2">
                      <span className="text-color-form align-middle">
                        รหัสบัตรประชาชน
                      </span>
                      {/* <span className="text-danger"> *</span> */}
                    </div>
                    <div className="col-12 col-md-8 pt-1">
                      <input
                        className="form-control shadow-none"
                        type="text"
                        name="add_user_employeeId"
                        defaultValue={AddUserData.employeeId}
                        readOnly
                        ref={modalAddUser({
                          required: {
                            value: true,
                            message: 'กรุณากรอกข้อมูล',
                          },
                          maxLength: {
                            value: 50,
                            message: 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร',
                          },
                        })}
                      />
                      {modalAddUserErrors.edit_employeeId && (
                        <div className="text-left">
                          <span className="text-danger">
                            {modalAddUserErrors.edit_employeeId.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12 col-md-4 pt-2">
                      <span className="text-color-form align-middle">ชื่อ</span>
                      {/* <span className="text-danger"> *</span> */}
                    </div>
                    <div className="col-12 col-md-8 pt-1">
                      <input
                        className="form-control shadow-none"
                        type="text"
                        name="add_user_fname"
                        defaultValue={AddUserData.fname}
                        readOnly
                        ref={modalAddUser({
                          required: {
                            value: true,
                            message: 'กรุณากรอกข้อมูล',
                          },
                          maxLength: {
                            value: 50,
                            message: 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร',
                          },
                        })}
                      />
                      {modalAddUserErrors.edit_fname && (
                        <div className="text-left">
                          <span className="text-danger">
                            {modalAddUserErrors.edit_fname.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12 col-md-4 pt-2">
                      <span className="text-color-form align-middle">
                        นามสกุล
                      </span>
                      {/* <span className="text-danger"> *</span> */}
                    </div>
                    <div className="col-12 col-md-8 pt-1">
                      <input
                        className="form-control shadow-none"
                        type="text"
                        name="add_user_lname"
                        defaultValue={AddUserData.lname}
                        readOnly
                        ref={modalAddUser({
                          required: {
                            value: true,
                            message: 'กรุณากรอกข้อมูล',
                          },
                          maxLength: {
                            value: 50,
                            message: 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร',
                          },
                        })}
                      />
                      {modalAddUserErrors.edit_lname && (
                        <div className="text-left">
                          <span className="text-danger">
                            {modalAddUserErrors.edit_lname.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12 col-md-4 pt-2">
                      <span className="text-color-form align-middle">
                        ตำแหน่ง
                      </span>
                      {/* <span className="text-danger"> *</span> */}
                    </div>
                    <div className="col-12 col-md-8 pt-1">
                      <input
                        className="form-control shadow-none"
                        type="text"
                        name="add_user_position"
                        defaultValue={AddUserData.position}
                        readOnly
                        ref={modalAddUser({
                          required: {
                            value: true,
                            message: 'กรุณากรอกข้อมูล',
                          },
                          maxLength: {
                            value: 500,
                            message: 'ข้อมูลต้องไม่เกิน 500 ตัวอักษร',
                          },
                        })}
                      />
                      {modalAddUserErrors.edit_position && (
                        <div className="text-left">
                          <span className="text-danger">
                            {modalAddUserErrors.edit_position.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12 col-md-4 pt-2">
                      <span className="text-color-form align-middle">
                        ชื่อบัญชีผู้ใช้
                      </span>
                      <span className="text-danger"> *</span>
                    </div>
                    <div className="col-12 col-md-8 pt-1">
                      {/* <input
                        className="form-control shadow-none"
                        type="number"
                        name="edit_num_order"
                        min={0}
                        step={1}
                        defaultValue={editData.num_order}
                        ref={modalAddUser()}
                      />
                      {errors.edit_num_order && (
                        <div className="text-left">
                          <span className="text-danger">
                            {errors.edit_num_order.message}
                          </span>
                        </div>
                      )} */}

                      <AsyncTypeahead
                        filterBy={() => true}
                        id="basic-typeahead-single"
                        isLoading={isReferLoadingUserId}
                        labelKey="employee_id"
                        emptyLabel="ไม่พบข้อมูล"
                        // onChange={setReferSelections}
                        options={referOptionsUserId}
                        placeholder="กรุณาพิมพ์เลขบัตรประชาชน/ชื่อบัญชีผู้ใช้"
                        selected={referSelectionsUserId}
                        minLength={2}
                        onSearch={(query) => handleReferSearchUserId(query)}
                        onChange={(selected) => {
                          // console.log(selected);
                          handleUserIdCardChange(selected);
                        }}
                        onKeyDown={(event) => {
                          resetUserId();
                        }}
                        renderMenuItemChildren={(option, props, idx) => (
                          <div>
                            {option.employee_id}{' '}
                            <small>
                              ({option.fname} {option.lname})
                            </small>
                          </div>
                        )}
                      />
                      {isEmptyUserIdCard && (
                        <div className="text-left">
                          <span className="text-danger">กรุณากรอกข้อมูล</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <Button
                    type="button"
                    className="btn-color-submit mr-2"
                    onClick={modalHandleAddUserSubmit(handleAddUserSave)}
                  >
                    <i className="fas fa-save pr-2"></i>
                    เพิ่มบัญชี
                  </Button>
                  <Button
                    type="button"
                    className="btn-color-delete-small"
                    onClick={clearFormDataAddUser}
                  >
                    <i className="fas fa-ban pr-2"></i>
                    ยกเลิก
                  </Button>
                </div>
              </div>
            </form>

            <div className="row">
              <div className="col-md-12">
                <div className="card mt-2">
                  <div className="table-header-masexpert card-header text-dark">
                    ข้อมูลบัญชีผู้ใช้
                  </div>
                  <div className="card-body">
                    <table
                      id="data-table-add-user"
                      className="table table-striped table-bordered display w-100 margin-bottom-30"
                    >
                      <thead>
                        <tr>
                          <th className="text-center">รหัสผู้ทรง</th>
                          <th className="text-center">บัญชีผู้ใช้</th>
                          <th className="text-center">จัดการ</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            type="button"
            className="btn-color-submit mr-2"
            onClick={modalHandleAddUserSubmit(handleEdit)}
          >
            <i className="fas fa-save pr-2"></i>
            บันทึก
          </Button> */}
          <Button
            type="button"
            className="btn-color-delete-small"
            onClick={handleModalAddUserClose}
          >
            <i className="fas fa-ban pr-2"></i>
            ยกเลิก
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MasManager;
