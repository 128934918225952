import { createSlice } from "@reduxjs/toolkit";

export const userLoginSlice = createSlice({
    name: "userLogin",
    initialState: {
        value: {
            org: {
                orgId: 0,
                orgName: '',
            },
            orgAllLevel: {
                workBu1Id: 0,
                workBu1Name: '',
                workBu2Id: 0,
                workBu2Name: '',
                workBu3Id: 0,
                workBu3Name: '',
                workBu4Id: 0,
                workBu4Name: '',
                workBu5Id: 0,
                workBu5Name: '',
                workBu6Id: 0,
                workBu6Name: '',
            },
            role: ['User'],
        },
        signature: '',
        signatureCertificate: {
            dataList: [],
            selected: {},
        },
        managerAdminList: [],
        expertAdminList: [],
        organizationSpecialList: [],
    },
    reducers: {
        setSignature: (state, action) => {
            state.signature = action.payload;
        },
        setUserLoginManagerAdmin: (state, action) => {
            state.managerAdminList = action.payload;
        },
        setUserLoginExpertAdmin: (state, action) => {
            state.expertAdminList = action.payload;
        },
        setUserLoginOrganizationSpecial: (state, action) => {
            state.organizationSpecialList = action.payload;
        },
        setUserLoginDetail: (state, action) => {
            state.value = {...state.value, ...action.payload};
        },
        setSignatureCertificateList: (state, action) => {
            state.signatureCertificate.dataList = action.payload;
            state.signatureCertificate.selected = action.payload[0];
        },
        setSignatureCertificateSelected: (state, action) => {
            state.signatureCertificate.selected = action.payload;
        },
    },
});

export const {
    setSignature,
    setUserLoginManagerAdmin,
    setUserLoginExpertAdmin,
    setUserLoginOrganizationSpecial,
    setUserLoginDetail,
    setSignatureCertificateList,
    setSignatureCertificateSelected,
} = userLoginSlice.actions;

export default userLoginSlice.reducer;
