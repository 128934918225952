import moment from 'moment';
import 'moment/locale/th';

const convertYearThai = 543;

export const getDateTimeFormat = (datetime) => {
  let dt = moment(datetime);
  dt.locale('th');
  // เพิ่มตัวแปรเก็บปี
  const thaiYear = dt.year() + convertYearThai;
  let dts = String (dt.format('lll')).replace(dt.year(), thaiYear);
  return datetime ? dts : '';
};

export const getDateFormat = (date) => {
  let d = moment(date);
  d.locale('th');
  const thaiYear = d.year() + convertYearThai;
  let dts = String (d.format('ll')).replace(d.year(), thaiYear);
  return date ? dts : '';
};

export const getDateFullMonthFormat = (date) => {
  let d = moment(date);
  d.locale('th');
  const thaiYear = d.year() + convertYearThai;
  let dts = String (d.format('LL')).replace(d.year(), thaiYear);
  return date ? dts : '';
};

export const searchDateFormat = (date) => {
  let d = moment(date, 'DD/MM/YYYY');
  d.add(-convertYearThai, 'year');
  return date ? d.format('YYYY-MM-DD') : '';
};

export const getDateNowThaiYear = () => {
  let dt = new Date();
  dt.setFullYear(dt.getFullYear() + convertYearThai);
  return dt;
};

export const getDateThaiYear = (date) => {
  let dt = new Date(date);
  dt.setFullYear(dt.getFullYear() + convertYearThai);
  return dt;
};

export const convertThaiYearToDatabase = (date) => {
  let d = moment(date, 'DD/MM/YYYY');
  d.add(-convertYearThai, 'year');
  return date ? d.format('YYYY-MM-DD') : '';
};

export const isDuedate = (date) => {
  let dt = moment(date);
  dt.add(-2, 'day');
  return dt.isBefore(moment());
}

export const isOverDueDate = (date) => {
  let dt = moment(date);
  const cur_dt = moment();
  return moment(cur_dt).isAfter(dt, 'day');
}

export const getDateThaiShortFormat = (date, flag_year_2digit = false) => {
  if(date === "รวม"){
    return "รวม";
  }else{
    let dt = moment(date);
    dt.locale('th');
    if(flag_year_2digit === false){
      const thaiYear = dt.year() + convertYearThai;
      return String(dt.format('D MMM YYYY')).replace(dt.year(), thaiYear);
    }else{
      const thaiYear = dt.year() + convertYearThai;
      return String(dt.format('D MMM YY')).replace(dt.year(), thaiYear);
    }
  } 
}

export const getDateThaiStartAndEndFullMonth = (startDate, endDate, flag_year_2digit = false) => {
  let st = moment(startDate);
  st.locale('th');
  st.add(convertYearThai, 'year');
  const thaiYearSt = st.year() + convertYearThai;
  let sts = String (st.format('D - ')).replace(st.year(), thaiYearSt);

  let ed = moment(endDate);
  ed.locale('th');
  const thaiYearEd = ed.year() + convertYearThai;

  if(flag_year_2digit === false){
    return String (st.format('D - ')).replace(ed.year(), thaiYearSt) + String (ed.format('D MMMM YYYY')).replace(ed.year(), thaiYearEd);
  }else{
    return String (st.format('D - ')).replace(ed.year(), thaiYearSt) + String (ed.format('D MMMM YY')).replace(ed.year(), thaiYearEd);
  }
}

export const getYearThaiShortFormat = (date) => {
  let dt = moment(date);
  dt.locale('th');
  dt.add(convertYearThai, 'year');
  return dt.format("YYYY");
}

export const getDateThaiShortMonthFormat = (date, flag_year_2digit = false) => {
  let dt = moment(date);
  dt.locale('th');
  dt.add(convertYearThai, 'year');
  const thaiYear = dt.year() + convertYearThai;
  if(flag_year_2digit === false){
    return String (dt.format('D MMM')).replace(dt.year(), thaiYear);
  }else{
    return String (dt.format('D MMM YY')).replace(dt.year(), thaiYear);
  }
}

export const getDateThaiNow = () => {
  let d = moment();
  d.locale('th');
  const thaiYear = d.year() + convertYearThai;
  return String (d.format('ll')).replace(d.year(), thaiYear);
};

export const getTimeThaiNow = () => {
  let d = moment();
  d.locale('th');
  d.add(convertYearThai, 'year');
  return d.format('HH.mm น.');
};
