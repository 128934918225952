import {createSlice} from '@reduxjs/toolkit'

export const rptSummaryByMonthSlice = createSlice({
    name: 'rptSummaryByMonth',
    initialState: {
        sMonth: "",
        sYear: "",
        sListSummary: [],
    },
    reducers: {
        setSelectMonth: (state, action) => {
            state.sMonth = action.payload
        },
        setSelectYear: (state, action) => {
            state.sYear = action.payload
        },
        setListSummary: (state, action) => {
            state.sListSummary = action.payload
        },

    },
})

// Action creators are generated for each case reducer function
export const {setSelectMonth, setSelectYear, setListSummary} = rptSummaryByMonthSlice.actions
export const sMonth = state => state.rptSummaryByMonth.sMonth;
export const sYear = state => state.rptSummaryByMonth.sYear;
export const sListSummary = state => state.rptSummaryByMonth.sListSummary;
export default rptSummaryByMonthSlice.reducer