import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button } from '@material-ui/core';
import { Container, Modal } from 'react-bootstrap';
import { mainApi } from '../../HttpClient';
import config from 'react-global-configuration';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import _ from 'lodash';
import CheckboxTree from 'react-checkbox-tree-duplicate-value';
import 'react-checkbox-tree-duplicate-value/lib/react-checkbox-tree.css';
import Swal from 'sweetalert2';
import { useSelector } from "react-redux";
import { useForm } from 'react-hook-form';


const OrgEmpListAuto = forwardRef(({onAccept, selectOne}, ref) => {
    const [empSelectList, setEmpSelectList] = useState([]);
    const [orgSelectList, setOrgSelectList] = useState([]);
    const [orgList, setOrgList] = useState([]);
    const [empList, setEmpList] = useState([]);
    const { register, getValues } = useForm();
    const [orgChecked, setOrgChecked] = useState([]);
    const [orgExpanded, setOrgExpanded] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [listType, setListType] = useState();


    const close = () => setModalShow(false);
    const show = () => setModalShow(true);
    const clear = () => {
        setEmpSelectList([]);
        setEmpList([]);
        setOrgSelectList([]);
        setOrgChecked([]);
    };
    const setDepartment = (data) => {
        setOrgSelected(data);
        setOrgChecked(data);
    }
    const setRadioListType = (data) => {
        setListType(data);
    }
    const [isSubmit, setIsSubmit] = useState(false);

    const documentState = useSelector((state) => state.document);

    const onOptionChange = e => {
        setListType(e.target.value)
    }

    useImperativeHandle(ref, () => ({
        show,
        close,
        clear,
        getAllSelectList: () => {
            return _.union(orgSelectList, empSelectList);
        },
        getSelectRadio: () => {
            return getValues();
        },
        setDepartment,
        setRadioListType,
    }));

    useEffect(() => {
        if (documentState.documentBy.id !== null) {
            clear();
            const params = {
                orgTypeId: 1,
                documentBy: documentState.documentBy,
            };
            mainApi.post('/api/organization/form-auto/checkbox-list', params).then((res) => {
                setOrgExpanded(res.data.expanded);
                setOrgList(res.data.checkBoxTrees);
            });
        }
    }, [documentState.documentBy]);

    const fetchEmpList = async (checked) => {
        let empSelectListView = [];
        if (!selectOne) {
            empSelectListView = _.map(empSelectList, (emp) => {
                return _.find(empList, (item) => item.value === emp);
            });
        }
        const response = await mainApi.post(`/api/employee/listbox/v2`, checked);
        setEmpList(_.union(response.data, empSelectListView));
    };

    const handleOnAccept = () => {
        setIsSubmit(true);
        if (empSelectList.length > 0 || orgSelectList.length > 0) {
            // onAccept();
            // close();
            setIsSubmit(true);
            if(listType !== null && listType !== "" && listType !== undefined){
                onAccept();
                close();
            }else{
                Swal.fire({
                    icon: 'info',
                    title: 'กรุณาเลือกประเภทรายชื่อ',
                    timer: config.get('alertTimer'),
                    timerProgressBar: true,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                });
            }
        } else {
            Swal.fire({
                icon: 'info',
                title: 'กรุณาเลือกหน่วยงานหรือรายชื่อ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
            });
        }
        setIsSubmit(false);
    };

    const handleOrgChecked = (checked, targetNode) => {
        setOrgSelected(checked);
        setOrgChecked(checked);
        fetchEmpList(checked);
    };

    const setOrgSelected = (checked) => {
        let resultList = [];
        checked.forEach((check) => {
            let hasAddData = false;
            orgList.forEach(({children}) => {
                let orgData = _.find(children, ['value', check]);
                if (!hasAddData && orgData) {
                    hasAddData = true;
                    let value = orgData.value.split('#person')[0];
                    let label = orgData.label.replaceAll('- ', '');
                    let orgSelected = value + ':' + label;
                    resultList = [...resultList, orgSelected];
                }
            });
        });
        setOrgSelectList(resultList);
    };

    const handleExpand = async (expanded, targetNode) => {
        if (targetNode.children && targetNode.children.length === 0) {
            let orgId = targetNode.value.substring(1);
            let childOrgList = await fetchChildOrgList(orgId);
            let list = await addChildOrgList(orgList, childOrgList, targetNode);
            await setOrgList(list);
        }
        setOrgExpanded(expanded);
    };

    const addChildOrgList = (list, children, targetNode) => {
        return list.map((item) => {
            if (item.value === targetNode.value) {
                return {...item, children};
            } else if (_.isArray(item.children)) {
                return {
                    ...item,
                    children: addChildOrgList(item.children, children, targetNode),
                };
            }
            return item;
        });
    };

    const fetchChildOrgList = async (orgId) => {
        let result = [];
        const response = await mainApi.get(
            `/api/organization/checkbox/children/${orgId}`
        );
        if (response.status === 200) {
            result = response.data;
        }
        return result;
    };

    return (
        <Modal size="lg" show={modalShow} onHide={close} scrollable>
            <Modal.Header closeButton className="bg-color">
                <Modal.Title className="nav-text text-shadow-header">
                    <i className="fas fa-paper-plane mr-2"></i>หน่วยงานที่ต้องการส่ง
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <div className="row">
                        <div className="col border border-dark p-2">
                            <label
                                className="text-color-form align-middle position-absolute bg-white pl-2 pr-2"
                                style={{top: '-14px'}}
                            >
                                หน่วยงาน
                            </label>
                            <CheckboxTree
                                className="text-color-form"
                                nodes={orgList}
                                checked={orgChecked}
                                expanded={orgExpanded}
                                onCheck={handleOrgChecked}
                                onExpand={handleExpand}
                                // showExpandAll //แสดงปุ่มย่อขยายทั้งหมด
                                // expandDisabled //ปุ่มลำดับชั้นไม่สามารถกดได้
                                nativeCheckboxes
                                // onlyLeafCheckboxes //ให้แสดงแค่ปุ่มลำดับชั้นเท่านั้น
                                iconsClass="fa5"
                                icons={{
                                    check: '',
                                    uncheck: '',
                                    halfCheck: '',
                                    // expandClose: <i className="fas fa-plus"></i>,
                                    // expandOpen: <i className="fas fa-minus"></i>,
                                    expandAll: <i className="fas fa-expand-alt"></i>,
                                    collapseAll: <i className="fas fa-compress-alt"></i>,
                                    parentClose: '',
                                    parentOpen: '',
                                    leaf: '',
                                }}
                            />
                        </div>
                    </div>
                </Container>
            </Modal.Body>
            
                <Container>
                    <div className="row">
                        <div className="col p-2 ml-3">
                            <br/>
                            <div className='form-label  mt-10'>
                                ประเภทรายชื่อ
                            </div>
                            <div className="form-check ml-5 form-check-inline pt-2 pb-2">
                                <input
                                type="radio"
                                name="listType"
                                className="form-check-input"
                                ref={register}
                                value="1"
                                onChange={onOptionChange}
                                checked={parseInt(listType) === 1}
                                />{' '}
                                รายชื่อผู้อำนวยการในสังกัดกรมควบคุมโรค
                            </div>
                            <div className="form-check ml-5 form-check-inline pt-2 pb-2 ml-3">
                                <input
                                type="radio"
                                name="listType"
                                className="form-check-input"
                                ref={register}
                                value="2"
                                checked={parseInt(listType) === 2}
                                onChange={onOptionChange}   
                                />{' '}
                                รายชื่อหน่วยงานในสังกัดกรมควบคุมโรค
                            </div>
                        </div>
                    </div>
                </Container>
            
            <Modal.Footer>
                <Button
                    type="button"
                    className="btn-color-submit mr-2"
                    disabled={isSubmit}
                    onClick={!isSubmit ? handleOnAccept : null}
                >
                    <i className="fas fa-paper-plane mr-2"></i>เลือก
                    {isSubmit && (
                        <div
                            className="spinner-border spinner-border-sm ml-2"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    )}
                </Button>
                <Button
                    type="button"
                    className="btn-color-delete-small"
                    onClick={close}
                >
                    <i className="fas fa-ban mr-2"></i>ยกเลิก
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default OrgEmpListAuto;

