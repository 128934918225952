import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import Swal from "sweetalert2";
import config from 'react-global-configuration';
import { getDateFormat, getDateThaiShortMonthFormat, isDuedate, isOverDueDate } from '../util/Date';
import { mainApi } from "../../HttpClient";
import { useForm } from "react-hook-form";
import OrgEmpList from "../share/OrgEmpList";
import DateInput from "../share/DateInput";
import UploadFiles from "../share/UploadFiles";
import fileDownload from "js-file-download";
import usePDF from "./pdf/usePDF";
import HistoryBox from "../history/HistoryBox";
import DigitalSignatureModal from "../share/digital-signature/DigitalSignatureModal";
import FormAutoEditModal from "./FormAutoEditModal";
import DownloadFile from "../share/DownloadFile";
import { delay } from "../util/Delay";
import { urgencyLevel } from "./util/UrgencyLevel";
import templateHtmlFormAutoGenreIn from "./pdf/templateHtmlFormAutoGenreIn";
import templateHtmlFormAutoGenreOut from "./pdf/templateHtmlFormAutoGenreOut";
import templateHtmlFormAutoId17 from "./pdf/templateHtmlFormAutoId17";

import 'datatables.mark.js/dist/datatables.mark.es6.min.js';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import 'datatables.net-responsive/js/dataTables.responsive.min';

import '../../App.css';

import $ from 'jquery';
import { clearDocumentRunningNo, setDocument } from "../../app/stores/features/documentSlice";

const InboxInt = () => {
    const [keycloak] = useKeycloak();
    const {register, handleSubmit, reset} = useForm();
    const {convertReactPdfToBase64} = usePDF();

    const signatureCertificate = useSelector((state) => state.userLogin.signatureCertificate);
    const documentState = useSelector((state) => state.document);

    const [tableSelectData, setTableSelectData] = useState({});
    const [documentDate, setDocumentDate] = useState(new Date());
    const [attachFileList, setAttachFileList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [pdfSignList, setPdfSignList] = useState([]);
    const [isSend, setIsSend] = useState(false);
    const [documentReferDetail, setDocumentReferDetail] = useState({});
    const [isPrintLoading, setIsPrintLoading] = useState(false);
    const [isDocumentProcessLoading, setIsDocumentProcessLoading] = useState(false);

    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => setModalShow(false);
    const handleModalShow = () => setModalShow(true);
    const [modalSentShow, setModalSentShow] = useState(false);
    const handleModalSentClose = () => setModalSentShow(false);
    const handleModalSentShow = () => setModalSentShow(true);
    const [modalReferShow, setModalReferShow] = useState(false);
    const handleModalReferClose = () => setModalReferShow(false);
    const handleModalReferShow = () => setModalReferShow(true);

    const orgEmpListRef = useRef();
    const historyBoxRef = useRef();
    const digitalSignatureRef = useRef();
    const formAutoEditModalRef = useRef();

    const dispatch = useDispatch();

    useEffect(() => {
        let color2 = '#ffd43b';
        let color3 = '#fd7e14';
        let color4 = '#e03131';
        const {id: documentById} = documentState.documentBy;
        $.fn.dataTable.ext.errMode = 'none';

        var table = $('#data-table').DataTable({
            pageLength: 10,
            order: [0, 'desc'],
            ajax: {
                contentType: 'application/json',
                url: config.get('apiUrl') + '/api/form-auto/data-table?documentById=' + documentById,
                type: 'POST',
                data: function (d) {
                    return JSON.stringify(d);
                },
                headers: {
                    Authorization: `bearer ${keycloak.token}`,
                },
            },
            processing: true,
            serverSide: true,
            mark: true,
            columnDefs: [{width: '100px', targets: 1}],
            columns: [
                {data: 'autoFormId', visible: false},
                {
                    data: 'autoFormNo',
                    className: 'text-center',
                    render: function (data, type, row) {
                        let duedate = '';
                        if (
                            row.autoFormDueDate &&
                            isDuedate(row.autoFormDueDate) &&
                            row.progressStatus !== '4' &&
                            row.progressStatus !== '5' &&
                            !isOverDueDate(row.autoFormDueDate)
                        ) {
                            duedate = `<div class="blink_txt" style="color: ${color4};"><small>แจ้งเตือน ${getDateThaiShortMonthFormat(row.autoFormDueDate, true)}</small></div>`;
                        }

                        let attachment = '';
                        if (row.countAttachment > 0) {
                            attachment = `<i class="fas fa-paperclip text-danger mr-1"></i>`;
                        }

                        let urgencyLevel = '';
                        if(!row.urgencyLevel){
                           urgencyLevel = `<small class="badge badge-danger mr-1" style=""> - </small>`;
                        }
                        else if (row.urgencyLevel.toString() === "2") {
                            urgencyLevel = `<small class="badge badge-warning mr-1" style="background-color: ${color2};">ด่วน</small>`;
                        } else if (row.urgencyLevel.toString() === "3") {
                            urgencyLevel = `<small class="badge badge-danger mr-1" style="background-color: ${color3};">ด่วนมาก</small>`;
                        } else if (row.urgencyLevel.toString() === "4") {
                            urgencyLevel = `<small class="badge badge-danger mr-1" style="background-color: ${color4};">ด่วนที่สุด</small>`;
                        }

                        let secretLevel = '';
                        if (row.secretLevel === 2) {
                            secretLevel = `<small class="badge badge-warning" style="background-color: ${color2};">ลับ</small>`;
                        } else if (row.secretLevel === 3) {
                            secretLevel = `<small class="badge badge-danger" style="background-color: ${color3};">ลับมาก</small>`;
                        } else if (row.secretLevel === 4) {
                            secretLevel = `<small class="badge badge-danger" style="background-color: ${color4};">ลับที่สุด</small>`;
                        }

                        let autoFormNoCheck = '';
                        if (row.autoFormNo === '') {
                            autoFormNoCheck = `<span class="badge badge-pill badge-warning">รอดำเนินการ</span>`;
                        }

                        if (duedate || attachment || urgencyLevel || secretLevel || autoFormNoCheck) {
                            return (
                                data +
                                autoFormNoCheck +
                                '<br/>' +
                                attachment +
                                urgencyLevel +
                                secretLevel +
                                duedate
                            );
                        }
                        return data;
                    },
                },
                {data: 'government', className: 'text-center'},
                {data: 'inform', className: 'text-center'},
                {
                    data: 'subject',
                    className: 'text-left',
                    width: '25%',
                    render: function (data, type, row) {
                        let subjectData = '';
                        if (row.status === '5') {
                            subjectData = `<a class="show-truncate link-red" href="javascript:void(0)">${data}</a>&nbsp;<span class="badge badge-pill badge-danger">ยกเลิก</span>`;
                        } else if (row.status !== '0') {
                            subjectData = `<a class="show-truncate text-primary" href="javascript:void(0)">${data}</a>&nbsp;<span class="badge badge-pill badge-primary">ส่งเอกสารแล้ว</span>`;
                        } else {
                            subjectData = `<a class="show-truncate" href="javascript:void(0)">${data}</a>`;
                        }

                        let formAutoBadge = '';
                        if (row.formId !== null && row.formId !== '') {
                            formAutoBadge = '<span class="badge badge-pill badge-form-auto">แบบฟอร์มหนังสือแบบอัตโนมัติ</span><br>';
                        }
                        return formAutoBadge + subjectData;
                    },
                },
                {
                    data: 'autoFormDate',
                    width: '90px',
                    className: 'text-center',
                    render: function (data, type, row) {
                        return getDateFormat(data);
                    },
                },
                {data: 'autoFormName', className: 'text-center'},
                {
                    className: 'text-center',
                    responsivePriority: 4,
                    targets: -3,
                    render: function (data, type, row) {
                        let editButton = '';
                        let cancelButton = '';
                        if (row.status !== '4' && row.status !== '5' && row.status === '0' && !row.refer) {
                            editButton = `<button class="dropdown-item btn-edit-document" type="button""><i class="fas fa-edit mr-2"></i>แก้ไข</button>`;
                        }
                        if (row.status !== '4' && row.status !== '5') {
                            cancelButton = `<button class="dropdown-item btn-cancel-document" type="button""><i class="fas fa-times-circle mr-2"></i>ยกเลิกเอกสาร</button>`;
                        }

                        if (editButton === '' && cancelButton === '') {
                            return '';
                        }

                        return `
                            <div>
                                <button type="button" class="btn btn-secondary btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-h"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    ${editButton}
                                    ${cancelButton}
                                </div>
                            </div>
                        `;
                    },
                },
            ],
            rowCallback: function (row, data) {
                $('td', row).eq(3).attr('style', 'cursor: pointer;').on('click', function (e) {
                    fetchDocumentProcess(data);
                });

                $('td', row).find('.btn-edit-document').on('click', function (e) {
                    editFormAuto(data);
                });

                $('td', row).find('.btn-cancel-document').on('click', function (e) {
                    cancelFormAuto(data);
                });
            },
            lengthChange: true,
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
            language: {
                search: '_INPUT_',
                searchPlaceholder: 'ค้นหา...',
                lengthMenu: '_MENU_ รายการ/หน้า',
                sLengthMenu: '_MENU_ รายการ/หน้า',
                sProcessing: 'กำลังดำเนินการ...',
                sZeroRecords: 'ไม่พบข้อมูล',
                sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
                sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
                sInfoFiltered: '',
                sSearch: '',
                sUrl: '',
                oPaginate: {
                    sFirst: 'หน้าแรก',
                    sPrevious: 'ก่อนหน้า',
                    sNext: 'ถัดไป',
                    sLast: 'หน้าสุดท้าย',
                },
                processing:
                    '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
            },
        });

        const getTemplateFormAuto = (data, type) => {
            if (data.formId === 17) {
                return templateHtmlFormAutoId17(data, type);
            } else if (data.autoFormGenre === "IN") {
                return templateHtmlFormAutoGenreIn(data, type);
            } else if (data.autoFormGenre === "OUT") {
                return templateHtmlFormAutoGenreOut(data, type);
            }
            return {};
        };

        const fetchDocumentProcess = async (data) => {
            setIsDocumentProcessLoading(true);
            setClearData(data);
            setTableSelectData(data);
            setDocumentDate(data.autoFormDate);
            handleModalShow();
            let pdfBase64List = [];

            dispatch(setDocument({
                id: data.documentId,
                type: data.autoFormType,
                genre: data.autoFormGenre,
                registerNo: data.autoFormNo,
                documentDate: data.autoFormDate,
            }));

            if (data.status === "0") {
                mainApi.get(`/api/form-auto/attach-file/${data.autoFormId}`).then((res) => {
                    setAttachFileList(res.data);
                });

                if (!data.refer) {
                    await mainApi.get('/api/form-auto/sub-detail?documentId=' + data.documentId).then((res) => {
                        Object.assign(data, {
                            departmentList: res.data.departmentList,
                            departmentNameList: res.data.departmentNameList,
                            attachList: res.data.attachList,
                            referenceList: res.data.referenceList,
                            f2DepartmentList: res.data.f2DepartmentList,
                            f2DepartmentNameList: res.data.f2DepartmentNameList,
                            f2AttachList: res.data.f2AttachList,
                            f2ReferenceList: res.data.f2ReferenceList,
                        })
                    });

                    let pdfDataList = [];
                    pdfDataList.push({
                        fileName: `${data.autoFormName} รูปแบบที่ 1.pdf`,
                        ironPdfHtmlData: getTemplateFormAuto(data, "1"),
                    });

                    if (data.createInstead === "1") {
                        pdfDataList.push({
                            fileName: `${data.autoFormName} รูปแบบที่ 2.pdf`,
                            ironPdfHtmlData: getTemplateFormAuto(data, "2"),
                        });
                    }

                    let params = {
                        data: pdfDataList,
                    }
                    await mainApi.post('/api/form-auto-report/generate', params).then((res) => {
                        pdfBase64List = res.data;
                    });
                }

                await mainApi.get(`/api/form-auto/attach-file/pdf?documentId=${data.documentId}&processId=0`).then((res) => {
                    pdfBase64List.push(...res.data);
                });
            } else {
                await mainApi.get(`/api/form-auto/attach-file/pdf-signature-latest?documentId=${data.documentId}`).then((res) => {
                    pdfBase64List.push(...res.data);
                });
            }
            setPdfSignList(pdfBase64List);

            const childAndAttachmentsParams = {
                documentId: data.documentId,
                documentBy: documentState.documentBy,
            };
            const response = await mainApi.post(`/api/form-auto/document-process?autoFormId=${data.autoFormId}`, childAndAttachmentsParams);
            if (historyBoxRef.current && historyBoxRef.current.dataProps.length === 0) {
                historyBoxRef.current.setDataProps(response.data);
            }
            setIsDocumentProcessLoading(false);
        };

        const setClearData = (data) => {
            reset(data);
            setAttachFileList([]);
            setFileList([]);
            setPdfSignList([]);
            orgEmpListRef.current.clear();
        };

        const editFormAuto = (data) => {
            mainApi.get(`/api/form-auto/attach-file/${data.autoFormId}`).then((res) => {
                data.attachFileList = res.data;
                formAutoEditModalRef.current.showModal(data);
            });
        };

        const cancelFormAuto = (data) => {
            mainApi.delete(`/api/form-auto/cancel/${data.autoFormId}`, documentState.documentBy).then((res) => {
                Swal.fire({
                    title: 'ยกเลิกเอกสารสำเร็จ',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                });
                table.ajax.reload(null, false);
            });
        };

        // Datable Component CSS.
        document.querySelector('div.dataTables_length select').classList.add('datatable-length');
        document.querySelector('div.dataTables_filter input').classList.add('datatable-search');
    }, [keycloak, convertReactPdfToBase64, reset, tableSelectData, documentState.documentBy, dispatch]);

    useEffect(() => {
        if (documentState.documentBy.id !== null) {
            const {id: documentById} = documentState.documentBy;
            $('#data-table').DataTable().ajax.url(
                config.get('apiUrl') + '/api/form-auto/data-table?documentById=' + documentById
            ).load();
        }
    }, [documentState.documentBy]);

    const reloadDataTable = () => {
        $('#data-table').DataTable().ajax.reload(null, false);
    };

    const handleSignature = async () => {
        digitalSignatureRef.current.clearDataAndShowModal();
        await delay(200);
        digitalSignatureRef.current.setAutoFormData(tableSelectData);
        digitalSignatureRef.current.initPdfList(pdfSignList);
    };

    const handleSignatureAccept = (pdfSignList) => {
        setPdfSignList(pdfSignList);
        if (tableSelectData.autoFormGenre === 'OUT') {
            mainSave(tableSelectData, pdfSignList);
        } else if (tableSelectData.status === '0') {
            orgEmpListRef.current.show();
        } else {
            handleModalSentShow();
        }
    }

    const handleSendSave = (formData) => {
        mainSave(formData, pdfSignList);
    }

    const mainSave = (formData, pdfSignList) => {
        setIsSend(true);
        let data = {
            autoFormId: tableSelectData.autoFormId,
            documentId: tableSelectData.documentId,
            command: formData.commandSend || tableSelectData.command || '',
            status: "1",
            urgencyLevel: tableSelectData.urgencyLevel || '1',
            pdfSignList: pdfSignList,
            certificate: signatureCertificate.selected,
            documentSentNo: documentState.isDigitalSignatureCheckSentNo ? documentState.sentNo : null,
            documentReceiverNo: documentState.receiverNo,
            documentDate: documentState.isDigitalSignatureCheckDocumentDate ? documentState.documentDate : null,
            documentBy: documentState.documentBy,
        }

        if (tableSelectData.autoFormGenre === 'IN') {
            data = {
                ...data,
                insideOrg: orgEmpListRef.current.getAllSelectList(),
            }
        }

        if (tableSelectData.autoFormGenre === 'OUT') {
            data = {
                ...data,
                outsideOrg: tableSelectData.inform || '',
            }
        }

        let params = new FormData();
        params.append('data', JSON.stringify(data));
        fileList.forEach((file) => {
            params.append('files', file);
        });

        mainApi.post('/api/form-auto/send-document', params).then((res) => {
            dispatch(clearDocumentRunningNo());
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'ส่งหนังสือสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });
            $('#data-table').DataTable().ajax.reload();
            handleModalSentClose();
        }).finally(() => {
            setIsSend(false);
        });
    };

    const handleReferClick = async () => {
        const response = await mainApi.get(
            '/api/v2/document-detail/document-id/' + tableSelectData.refer
        );

        setDocumentReferDetail(response.data);
        handleModalClose();
        handleModalReferShow();
    };

    const handlePrint = async () => {
        setIsPrintLoading(true);
        await mainApi
            .get('/api/report/excel/02/' + tableSelectData.documentId, {
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, 'รายงานประวัติเอกสาร.xlsx');
                setIsPrintLoading(false);
            });
    };

    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>

                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        รายการแม่แบบ
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            ทะเบียนแม่แบบ
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            รายการแม่แบบ
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    <table
                                        id="data-table"
                                        className="table table-striped table-bordered display w-100 margin-bottom-30"
                                    >
                                        <thead>
                                        <tr>
                                            <th className="text-center">ลงวันที่</th>
                                            <th className="text-center">เลขที่</th>
                                            <th className="text-center">ส่วนราชการ</th>
                                            <th className="text-center">เรียน</th>
                                            <th className="text-center">เรื่อง</th>
                                            <th className="text-center">ลงวันที่</th>
                                            <th className="text-center">ชื่อแบบฟอร์ม</th>
                                            <th className="text-center">จัดการ</th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>

            <Modal size="xl" show={modalShow} onHide={handleModalClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-book mr-2"></i>รายละเอียดเอกสาร
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    {tableSelectData.autoFormNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขที่
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {tableSelectData.autoFormNo}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3">
                                            <label className="text-color-form align-middle">
                                                เรื่อง
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal show-truncate">
                                            {tableSelectData.subject}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3 ">
                                            <label className="text-color-form align-middle">
                                                เรียน
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {tableSelectData.inform}
                                        </div>
                                    </div>
                                    {tableSelectData.refer && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    อ้างถึงหนังสือ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                <a href="#section" onClick={handleReferClick}>
                                                    {tableSelectData.referName}
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {tableSelectData.tel && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3">
                                                <label className="text-color-form align-middle">
                                                    เบอร์โทรศัพท์
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {tableSelectData.tel}
                                            </div>
                                        </div>
                                    )}
                                    {tableSelectData.urgencyLevel && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    ชั้นความเร็ว
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {urgencyLevel.map((item, index) => {
                                                    if (tableSelectData.urgencyLevel === item.id) {
                                                        return <span key={index}>{item.name}</span>;
                                                    }
                                                    return null;
                                                })}
                                            </div>
                                        </div>
                                    )}
                                    {tableSelectData.command && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    คำสั่งการ (รายละเอียด)
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {tableSelectData.command}
                                            </div>
                                        </div>
                                    )}
                                    {attachFileList.length > 0 && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    ไฟล์แนบ {attachFileList ? attachFileList.length : 0} ไฟล์
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                <span className="text-color-form align-middle">
                                                    {attachFileList.map((docAtth, docIndex) => {
                                                        return (
                                                            <div className="row" key={docIndex}>
                                                                <div className="col-12 col-md-11 text-normal">
                                                                    <DownloadFile
                                                                        filename={docAtth.filename}
                                                                        realfilename={docIndex + 1 + '. ' + docAtth.realFilename}
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {tableSelectData.status !== '0' && (
                            <>
                                <div className="row">
                                    <div className="col-8 text-header-x2 ddc-color">
                                        <i className="fas fa-paper-plane mr-2"></i>ถึงหน่วยงาน
                                    </div>
                                </div>
                                <HistoryBox ref={historyBoxRef}/>
                            </>
                        )}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {tableSelectData.status !== '0' && (
                        <Button
                            type="button"
                            className="btn-color-add-small "
                            disabled={isPrintLoading}
                            onClick={!isPrintLoading ? handlePrint : null}
                        >
                            <i className="fas fa-print pr-2"></i>
                            ดาวน์โหลดประวัติเอกสาร
                            {isPrintLoading && (
                                <div
                                    className="spinner-border spinner-border-sm ml-2"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            )}
                        </Button>
                    )}
                    {tableSelectData.status !== '4' && tableSelectData.status !== '5' &&
                        (
                            <Button
                                type="button"
                                className="btn-color-submit mr-2"
                                disabled={isDocumentProcessLoading}
                                onClick={isDocumentProcessLoading ? null : () => {
                                    handleSignature(tableSelectData);
                                    handleModalClose();
                                }}
                            >
                                <i className="fas fa-file-signature mr-2"></i>การลงนาม
                                {isDocumentProcessLoading && (
                                    <div
                                        className="spinner-border spinner-border-sm ml-2"
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                )}
                            </Button>
                        )
                    }
                    {tableSelectData.status !== '4' && tableSelectData.status !== '5' &&
                        (
                            <Button
                                type="button"
                                className="btn-color-submit mr-2"
                                onClick={() => {
                                    if (tableSelectData.autoFormGenre === 'OUT') {
                                        mainSave(tableSelectData, pdfSignList);
                                    } else if (tableSelectData.status === '0') {
                                        orgEmpListRef.current.show();
                                    } else {
                                        handleModalSentShow();
                                    }
                                    handleModalClose();
                                }}
                            >
                                <i className="fas fa-paper-plane pr-2"></i>ส่งหนังสือ ({tableSelectData.autoFormGenre === 'OUT' ? 'ภายนอก' : 'ภายใน'})
                            </Button>
                        )
                    }
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={handleModalClose}
                    >
                        <i className="fas fa-ban pr-2"></i>ปิด
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalSentShow} onHide={handleModalSentClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-paper-plane mr-2"></i>ส่งต่อหน่วยงานอื่น
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">ที่</label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name="autoFormNo"
                                    ref={register}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">ลงวันที่</label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <DateInput
                                    value={documentDate}
                                    onChange={(date) => setDocumentDate(date)}
                                    readOnly
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">เรื่อง</label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name="subject"
                                    ref={register}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">เรียน</label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name="inform"
                                    ref={register}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">
                                    เบอร์โทรศัพท์
                                </label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <input
                                    className="form-control shadow-none"
                                    type="text"
                                    name="tel"
                                    ref={register}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-3 pt-2">
                                <label className="text-color-form align-middle">
                                    คำสั่งการ (รายละเอียด)
                                </label>
                            </div>
                            <div className="col-12 col-md-9 pt-1">
                                <textarea
                                    className="form-control shadow-none"
                                    rows="5"
                                    name="commandSend"
                                    ref={register({max: 255})}
                                ></textarea>
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-md-12 pt-1">
                                <UploadFiles fileList={fileList} setFileList={setFileList}/>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-submit mr-2"
                        disabled={isSend}
                        onClick={!isSend ? () => {
                            if (!isSend) {
                                digitalSignatureRef.current.clear();
                                orgEmpListRef.current.clear();
                                orgEmpListRef.current.show();
                            }
                        } : null}
                    >
                        <i className="fas fa-paper-plane mr-2"></i>เลือกหน่วยงาน
                        {isSend && (
                            <div
                                className="spinner-border spinner-border-sm ml-2"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </Button>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={handleModalSentClose}
                    >
                        <i className="fas fa-ban mr-2"></i>ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={modalReferShow} onHide={handleModalReferClose}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-book mr-2"></i>รายละเอียดเอกสารอ้างอิง
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    {documentReferDetail.receiveNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขทะเบียนรับ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 ">
                                                {documentReferDetail.receiveNo}
                                            </div>
                                        </div>
                                    )}
                                    {documentReferDetail.oldNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขเดิม
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 ">
                                                {documentReferDetail.oldNo}
                                            </div>
                                        </div>
                                    )}
                                    {documentReferDetail.registerNo && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    เลขที่
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {documentReferDetail.registerNo}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3">
                                            <label className="text-color-form align-middle">
                                                ชัันความเร็ว
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.urgencyLevelName}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3">
                                            <label className="text-color-form align-middle">
                                                เรื่อง
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.subject}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3 ">
                                            <label className="text-color-form align-middle">
                                                เรียน
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.receiver}
                                        </div>
                                    </div>
                                    {documentReferDetail.refer && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    อ้างถึงหนังสือ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {documentReferDetail.referName}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3">
                                            <label className="text-color-form align-middle">
                                                เจ้าของเรื่อง
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.owner}
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-12 col-md-3 ">
                                            <label className="text-color-form align-middle">
                                                คำสั่งการ (รายละเอียด)
                                            </label>
                                        </div>
                                        <div className="col-12 col-md-9 text-normal">
                                            {documentReferDetail.command}
                                        </div>
                                    </div>
                                    {documentReferDetail.remark && (
                                        <div className="row mt-1">
                                            <div className="col-12 col-md-3 ">
                                                <label className="text-color-form align-middle">
                                                    หมายเหตุ
                                                </label>
                                            </div>
                                            <div className="col-12 col-md-9 text-normal">
                                                {documentReferDetail.remark}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={() => {
                            handleModalReferClose();
                            handleModalShow();
                        }}
                    >
                        <i className="fas fa-undo-alt pr-2"></i>ย้อนกลับ
                    </Button>
                </Modal.Footer>
            </Modal>

            <OrgEmpList onAccept={handleSubmit(handleSendSave)} ref={orgEmpListRef}/>
            <DigitalSignatureModal
                onAccept={handleSignatureAccept}
                labelSubmitSignature={'ส่งหนังสือ (' + (tableSelectData.autoFormGenre === 'OUT' ? 'ภายนอก' : 'ภายใน') + ')'}
                ref={digitalSignatureRef}
            />
            <FormAutoEditModal ref={formAutoEditModalRef} reloadDataTable={reloadDataTable}/>
        </div>
    );
};

export default InboxInt;
