export const rankList = [
    {label: "ว่าที่สิบตรี", value: "ว่าที่สิบตรี"},
    {label: "ว่าที่สิบโท", value: "ว่าที่สิบโท"},
    {label: "ว่าที่สิบเอก", value: "ว่าที่สิบเอก"},
    {label: "สิบตรี", value: "สิบตรี"},
    {label: "สิบโท", value: "สิบโท"},
    {label: "สิบเอก", value: "สิบเอก"},
    {label: "ว่าที่สิบตรีหญิง", value: "ว่าที่สิบตรีหญิง"},
    {label: "ว่าที่สิบโทหญิง", value: "ว่าที่สิบโทหญิง"},
    {label: "ว่าที่สิบเอกหญิง", value: "ว่าที่สิบเอกหญิง"},
    {label: "สิบตรีหญิง", value: "สิบตรีหญิง"},
    {label: "สิบโทหญิง", value: "สิบโทหญิง"},
    {label: "สิบเอกหญิง", value: "สิบเอกหญิง"},
    {label: "ว่าที่จ่าสิบตรี", value: "ว่าที่จ่าสิบตรี"},
    {label: "ว่าที่จ่าสิบโท", value: "ว่าที่จ่าสิบโท"},
    {label: "ว่าที่จ่าสิบเอก", value: "ว่าที่จ่าสิบเอก"},
    {label: "จ่าสิบตรี", value: "จ่าสิบตรี"},
    {label: "จ่าสิบโท", value: "จ่าสิบโท"},
    {label: "จ่าสิบเอก", value: "จ่าสิบเอก"},
    {label: "ว่าที่จ่าสิบตรีหญิง", value: "ว่าที่จ่าสิบตรีหญิง"},
    {label: "ว่าที่จ่าสิบโทหญิง", value: "ว่าที่จ่าสิบโทหญิง"},
    {label: "ว่าที่จ่าสิบเอกหญิง", value: "ว่าที่จ่าสิบเอกหญิง"},
    {label: "จ่าสิบตรีหญิง", value: "จ่าสิบตรีหญิง"},
    {label: "จ่าสิบโทหญิง", value: "จ่าสิบโทหญิง"},
    {label: "จ่าสิบเอกหญิง", value: "จ่าสิบเอกหญิง"},
    {label: "จ่าสิบเอกพิเศษ", value: "จ่าสิบเอกพิเศษ"},
    {label: "ว่าที่ร้อยตรี", value: "ว่าที่ร้อยตรี"},
    {label: "ว่าที่ร้อยโท", value: "ว่าที่ร้อยโท"},
    {label: "ว่าที่ร้อยเอก", value: "ว่าที่ร้อยเอก"},
    {label: "ร้อยตรี", value: "ร้อยตรี"},
    {label: "ร้อยโท", value: "ร้อยโท"},
    {label: "ร้อยเอก", value: "ร้อยเอก"},
    {label: "ว่าที่ร้อยตรีหญิง", value: "ว่าที่ร้อยตรีหญิง"},
    {label: "ว่าที่ร้อยโทหญิง", value: "ว่าที่ร้อยโทหญิง"},
    {label: "ว่าที่ร้อยเอกหญิง", value: "ว่าที่ร้อยเอกหญิง"},
    {label: "ร้อยตรีหญิง", value: "ร้อยตรีหญิง"},
    {label: "ร้อยโทหญิง", value: "ร้อยโทหญิง"},
    {label: "ร้อยเอกหญิง", value: "ร้อยเอกหญิง"},
    {label: "ว่าที่พันตรี", value: "ว่าที่พันตรี"},
    {label: "ว่าที่พันโท", value: "ว่าที่พันโท"},
    {label: "ว่าที่พันเอก", value: "ว่าที่พันเอก"},
    {label: "พันตรี", value: "พันตรี"},
    {label: "พันโท", value: "พันโท"},
    {label: "พันเอก",value: "พันเอก"},
    {label: "ว่าที่พันตรีหญิง", value: "ว่าที่พันตรีหญิง"},
    {label: "ว่าที่พันโทหญิง", value: "ว่าที่พันโทหญิง"},
    {label: "ว่าที่พันเอกหญิง", value: "ว่าที่พันเอกหญิง"},
    {label: "พันตรีหญิง", value: "พันตรีหญิง"},
    {label: "พันโทหญิง", value: "พันโทหญิง"},
    {label: "พันเอกหญิง", value: "พันเอกหญิง"},
    {label: "ว่าที่พลตรี", value: "ว่าที่พลตรี"},
    {label: "ว่าที่พลโท", value: "ว่าที่พลโท"},
    {label: "ว่าที่พลเอก", value: "ว่าที่พลเอก"},
    {label: "พลตรี", value: "พลตรี"},
    {label: "พลโท", value: "พลโท"},
    {label: "พลเอก", value: "พลตรี"},
    {label: "ว่าที่พลตรีหญิง", value: "ว่าที่พลตรีหญิง"},
    {label: "ว่าที่พลโทหญิง", value: "ว่าที่พลโทหญิง"},
    {label: "ว่าที่พลเอกหญิง", value: "ว่าที่พลเอกหญิง"},
    {label: "พลตรีหญิง", value: "พลตรีหญิง"},
    {label: "พลโทหญิง", value: "พลโทหญิง"},
    {label: "พลเอกหญิง", value: "พลเอกหญิง"},
    {label: "ว่าที่จ่าตรี", value: "ว่าที่จ่าตรี"},
    {label: "ว่าที่จ่าโท", value: "ว่าที่จ่าโท"},
    {label: "ว่าที่จ่าเอก", value: "ว่าที่จ่าเอก"},
    {label: "จ่าตรี", value: "จ่าตรี"},
    {label: "จ่าโท", value: "จ่าโท"},
    {label: "จ่าเอก", value: "จ่าเอก"},
    {label: "ว่าที่จ่าตรีหญิง", value: "ว่าที่จ่าตรีหญิง"},
    {label: "ว่าที่จ่าโทหญิง", value: "ว่าที่จ่าโทหญิง"},
    {label: "ว่าที่จ่าเอกหญิง", value: "ว่าที่จ่าเอกหญิง"},
    {label: "จ่าตรีหญิง", value: "จ่าตรีหญิง"},
    {label: "จ่าโทหญิง", value: "จ่าโทหญิง"},
    {label: "จ่าเอกหญิง", value: "จ่าเอกหญิง"},
    {label: "ว่าที่พันจ่าตรี", value: "ว่าที่พันจ่าตรี"},
    {label: "ว่าที่พันจ่าโท", value: "ว่าที่พันจ่าโท"},
    {label: "ว่าที่พันจ่าเอก", value: "ว่าที่พันจ่าเอก"},
    {label: "พันจ่าตรี", value: "พันจ่าตรี"},
    {label: "พันจ่าโท", value: "พันจ่าโท"},
    {label: "พันจ่าเอก", value: "พันจ่าเอก"},
    {label: "ว่าที่พันจ่าตรีหญิง", value: "ว่าที่พันจ่าตรีหญิง"},
    {label: "ว่าที่พันจ่าโทหญิง", value: "ว่าที่พันจ่าโทหญิง"},
    {label: "ว่าที่พันจ่าเอกหญิง", value: "ว่าที่พันจ่าเอกหญิง"},
    {label: "พันจ่าตรีหญิง", value: "พันจ่าตรีหญิง"},
    {label: "พันจ่าโทหญิง", value: "พันจ่าโทหญิง"},
    {label: "พันจ่าเอกหญิง", value: "พันจ่าเอกหญิง"},
    {label: "ว่าที่เรือตรี", value: "ว่าที่เรือตรี"},
    {label: "ว่าที่เรือโท", value: "ว่าที่เรือโท"},
    {label: "ว่าที่เรือเอก", value: "ว่าที่เรือเอก"},
    {label: "เรือตรี", value: "เรือตรี"},
    {label: "เรือโท", value: "เรือโท"},
    {label: "เรือเอก", value: "เรือเอก"},
    {label: "ว่าที่เรือตรีหญิง", value: "ว่าที่เรือตรีหญิง"},
    {label: "ว่าที่เรือโทหญิง", value: "ว่าที่เรือโทหญิง"},
    {label: "ว่าที่เรือเอกหญิง", value: "ว่าที่เรือเอกหญิง"},
    {label: "เรือตรีหญิง", value: "เรือตรีหญิง"},
    {label: "เรือโทหญิง", value: "เรือโทหญิง"},
    {label: "เรือเอกหญิง", value: "เรือเอกหญิง"},
    {label: "ว่าที่นาวาตรี", value: "ว่าที่นาวาตรี"},
    {label: "ว่าที่นาวาโท", value: "ว่าที่นาวาโท"},
    {label: "ว่าที่นาวาเอก", value: "ว่าที่นาวาเอก"},
    {label: "นาวาตรี", value: "นาวาตรี"},
    {label: "นาวาโท", value: "นาวาโท"},
    {label: "นาวาเอก", value: "นาวาเอก"},
    {label: "ว่าที่นาวาตรีหญิง", value: "ว่าที่นาวาตรีหญิง"},
    {label: "ว่าที่นาวาโทหญิง", value: "ว่าที่นาวาโทหญิง"},
    {label: "ว่าที่นาวาเอกหญิง", value: "ว่าที่นาวาเอกหญิง"},
    {label: "นาวาตรีหญิง", value: "นาวาตรีหญิง"},
    {label: "นาวาโทหญิง", value: "นาวาโทหญิง"},
    {label: "นาวาเอกหญิง", value: "นาวาเอกหญิง"},
    {label: "ว่าที่พลเรือตรี", value: "ว่าที่พลเรือตรี"},
    {label: "ว่าที่พลเรือโท", value: "ว่าที่พลเรือโท"},
    {label: "ว่าที่พลเรือเอก", value: "ว่าที่พลเรือเอก"},
    {label: "พลเรือตรี", value: "พลเรือตรี"},
    {label: "พลเรือโท", value: "พลเรือโท"},
    {label: "พลเรือเอก", value: "พลเรือเอก"},
    {label: "ว่าที่พลเรือตรีหญิง", value: "ว่าที่พลเรือตรีหญิง"},
    {label: "ว่าที่พลเรือโทหญิง", value: "ว่าที่พลเรือโทหญิง"},
    {label: "ว่าที่พลเรือเอกหญิง", value: "ว่าที่พลเรือเอกหญิง"},
    {label: "พลเรือตรีหญิง", value: "พลเรือตรีหญิง"},
    {label: "พลเรือโทหญิง", value: "พลเรือโทหญิง"},
    {label: "พลเรือเอกหญิง", value: "พลเรือเอกหญิง"},
    {label: "ว่าที่จ่าอากาศตรี", value: "ว่าที่จ่าอากาศตรี"},
    {label: "ว่าที่จ่าอากาศโท", value: "ว่าที่จ่าอากาศโท"},
    {label: "ว่าที่จ่าอากาศเอก", value: "ว่าที่จ่าอากาศเอก"},
    {label: "จ่าอากาศตรี", value: "จ่าอากาศตรี"},
    {label: "จ่าอากาศโท", value: "จ่าอากาศโท"},
    {label: "จ่าอากาศเอก", value: "จ่าอากาศเอก"},
    {label: "ว่าที่จ่าอากาศตรีหญิง", value: "ว่าที่จ่าอากาศตรีหญิง"},
    {label: "ว่าที่จ่าอากาศโทหญิง", value: "ว่าที่จ่าอากาศโทหญิง"},
    {label: "ว่าที่จ่าอากาศเอกหญิง", value: "ว่าที่จ่าอากาศเอกหญิง"},
    {label: "จ่าอากาศตรีหญิง", value: "จ่าอากาศตรีหญิง"},
    {label: "จ่าอากาศโทหญิง", value: "จ่าอากาศโทหญิง"},
    {label: "จ่าอากาศเอกหญิง", value: "จ่าอากาศเอกหญิง"},
    {label: "ว่าที่พันจ่าอากาศตรี", value: "ว่าที่พันจ่าอากาศตรี"},
    {label: "ว่าที่พันจ่าอากาศโท", value: "ว่าที่พันจ่าอากาศโท"},
    {label: "ว่าที่พันจ่าอากาศเอก", value: "ว่าที่พันจ่าอากาศเอก"},
    {label: "พันจ่าอากาศตรี", value: "พันจ่าอากาศตรี"},
    {label: "พันจ่าอากาศโท", value: "พันจ่าอากาศโท"},
    {label: "พันจ่าอากาศเอก", value: "พันจ่าอากาศเอก"},
    {label: "ว่าที่พันจ่าอากาศตรีหญิง", value: "ว่าที่พันจ่าอากาศตรีหญิง"},
    {label: "ว่าที่พันจ่าอากาศโทหญิง", value: "ว่าที่พันจ่าอากาศโทหญิง"},
    {label: "ว่าที่พันจ่าอากาศเอกหญิง", value: "ว่าที่พันจ่าอากาศเอกหญิง"},
    {label: "พันจ่าอากาศตรีหญิง", value: "พันจ่าอากาศตรีหญิง"},
    {label: "พันจ่าอากาศโทหญิง", value: "พันจ่าอากาศโทหญิง"},
    {label: "พันจ่าอากาศเอกหญิง", value: "พันจ่าอากาศเอกหญิง"},
    {label: "ว่าที่เรืออากาศตรี", value: "ว่าที่เรืออากาศตรี"},
    {label: "ว่าที่เรืออากาศโท", value: "ว่าที่เรืออากาศโท"},
    {label: "ว่าที่เรืออากาศเอก", value: "ว่าที่เรืออากาศเอก"},
    {label: "เรืออากาศตรี", value: "เรืออากาศตรี"},
    {label: "เรืออากาศโท", value: "เรืออากาศโท"},
    {label: "เรืออากาศเอก", value: "เรืออากาศเอก"},
    {label: "ว่าที่เรืออากาศตรีหญิง", value: "ว่าที่เรืออากาศตรีหญิง"},
    {label: "ว่าที่เรืออากาศโทหญิง", value: "ว่าที่เรืออากาศโทหญิง"},
    {label: "ว่าที่เรืออากาศเอกหญิง", value: "ว่าที่เรืออากาศเอกหญิง"},
    {label: "เรืออากาศตรีหญิง", value: "เรืออากาศตรีหญิง"},
    {label: "เรืออากาศโทหญิง", value: "เรืออากาศโทหญิง"},
    {label: "เรืออากาศเอกหญิง", value: "เรืออากาศเอกหญิง"},
    {label: "ว่าที่นาวาอากาศตรี", value: "ว่าที่นาวาอากาศตรี"},
    {label: "ว่าที่นาวาอากาศโท", value: "ว่าที่นาวาอากาศโท"},
    {label: "ว่าที่นาวาอากาศเอก", value: "ว่าที่นาวาอากาศเอก"},
    {label: "นาวาอากาศตรี", value: "นาวาอากาศตรี"},
    {label: "นาวาอากาศโท", value: "นาวาอากาศโท"},
    {label: "นาวาอากาศเอก", value: "นาวาอากาศเอก"},
    {label: "ว่าที่นาวาอากาศตรีหญิง", value: "ว่าที่นาวาอากาศตรีหญิง"},
    {label: "ว่าที่นาวาอากาศโทหญิง", value: "ว่าที่นาวาอากาศโทหญิง"},
    {label: "ว่าที่นาวาอากาศเอกหญิง", value: "ว่าที่นาวาอากาศเอกหญิง"},
    {label: "นาวาอากาศตรีหญิง", value: "นาวาอากาศตรีหญิง"},
    {label: "นาวาอากาศโทหญิง", value: "นาวาอากาศโทหญิง"},
    {label: "นาวาอากาศเอกหญิง", value: "นาวาอากาศเอกหญิง"},
    {label: "ว่าที่พลอากาศตรี", value: "ว่าที่พลอากาศตรี"},
    {label: "ว่าที่พลอากาศโท", value: "ว่าที่พลอากาศโท"},
    {label: "ว่าที่พลอากาศเอก", value: "ว่าที่พลอากาศเอก"},
    {label: "พลอากาศตรี", value: "พลอากาศตรี"},
    {label: "พลอากาศโท", value: "พลอากาศโท"},
    {label: "พลอากาศเอก", value: "พลอากาศเอก"},
    {label: "ว่าที่พลอากาศตรีหญิง", value: "ว่าที่พลอากาศตรีหญิง"},
    {label: "ว่าที่พลอากาศโทหญิง", value: "ว่าที่พลอากาศโทหญิง"},
    {label: "ว่าที่พลอากาศเอกหญิง", value: "ว่าที่พลอากาศเอกหญิง"},
    {label: "พลอากาศตรีหญิง", value: "พลอากาศตรีหญิง"},
    {label: "พลอากาศโทหญิง", value: "พลอากาศโทหญิง"},
    {label: "พลอากาศเอกหญิง", value: "พลอากาศเอกหญิง"},
    {label: "ว่าที่สิบตำรวจตรี", value: "ว่าที่สิบตำรวจตรี"},
    {label: "ว่าที่สิบตำรวจโท", value: "ว่าที่สิบตำรวจโท"},
    {label: "ว่าที่สิบตำรวจเอก", value: "ว่าที่สิบตำรวจเอก"},
    {label: "สิบตำรวจตรี", value: "สิบตำรวจตรี"},
    {label: "สิบตำรวจโท", value: "สิบตำรวจโท"},
    {label: "สิบตำรวจเอก", value: "สิบตำรวจเอก"},
    {label: "ว่าที่สิบตำรวจตรีหญิง", value: "ว่าที่สิบตำรวจตรีหญิง"},
    {label: "ว่าที่สิบตำรวจโทหญิง", value: "ว่าที่สิบตำรวจโทหญิง"},
    {label: "ว่าที่สิบตำรวจเอกหญิง", value: "ว่าที่สิบตำรวจเอกหญิง"},
    {label: "สิบตำรวจตรีหญิง", value: "สิบตำรวจตรีหญิง"},
    {label: "สิบตำรวจโทหญิง", value: "สิบตำรวจโทหญิง"},
    {label: "สิบตำรวจเอกหญิง", value: "สิบตำรวจเอกหญิง"},
    {label: "ว่าที่จ่าสิบตำรวจ", value: "ว่าที่จ่าสิบตำรวจ"},
    {label: "จ่าสิบตำรวจ", value: "จ่าสิบตำรวจ"},
    {label: "ว่าที่จ่าสิบตำรวจหญิง", value: "ว่าที่จ่าสิบตำรวจหญิง"},
    {label: "จ่าสิบตำรวจหญิง", value: "จ่าสิบตำรวจหญิง"},
    {label: "ว่าที่ดาบตำรวจ", value: "ว่าที่ดาบตำรวจ"},
    {label: "ดาบตำรวจ", value: "ดาบตำรวจ"},
    {label: "ว่าที่ดาบตำรวจหญิง", value: "ว่าที่ดาบตำรวจหญิง"},
    {label: "ดาบตำรวจหญิง", value: "ดาบตำรวจหญิง"},
    {label: "ว่าที่ร้อยตำรวจตรี", value: "ว่าที่ร้อยตำรวจตรี"},
    {label: "ว่าที่ร้อยตำรวจโท", value: "ว่าที่ร้อยตำรวจโท"},
    {label: "ว่าที่ร้อยตำรวจเอก", value: "ว่าที่ร้อยตำรวจเอก"},
    {label: "ร้อยตำรวจตรี", value: "ร้อยตำรวจตรี"},
    {label: "ร้อยตำรวจโท", value: "ร้อยตำรวจโท"},
    {label: "ร้อยตำรวจเอก", value: "ร้อยตำรวจเอก"},
    {label: "ว่าที่ร้อยตำรวจตรีหญิง", value: "ว่าที่ร้อยตำรวจตรีหญิง"},
    {label: "ว่าที่ร้อยตำรวจโทหญิง", value: "ว่าที่ร้อยตำรวจโทหญิง"},
    {label: "ว่าที่ร้อยตำรวจเอกหญิง", value: "ว่าที่ร้อยตำรวจเอกหญิง"},
    {label: "ร้อยตำรวจตรีหญิง", value: "ร้อยตำรวจตรีหญิง"},
    {label: "ร้อยตำรวจโทหญิง", value: "ร้อยตำรวจโทหญิง"},
    {label: "ร้อยตำรวจเอกหญิง", value: "ร้อยตำรวจเอกหญิง"},
    {label: "ว่าที่พันตำรวจตรี", value: "ว่าที่พันตำรวจตรี"},
    {label: "ว่าที่พันตำรวจโท", value: "ว่าที่พันตำรวจโท"},
    {label: "ว่าที่พันตำรวจเอก", value: "ว่าที่พันตำรวจเอก"},
    {label: "พันตำรวจตรี", value: "พันตำรวจตรี"},
    {label: "พันตำรวจโท", value: "พันตำรวจโท"},
    {label: "พันตำรวจเอก", value: "พันตำรวจเอก"},
    {label: "ว่าที่พันตำรวจตรีหญิง", value: "ว่าที่พันตำรวจตรีหญิง"},
    {label: "ว่าที่พันตำรวจโทหญิง", value: "ว่าที่พันตำรวจโทหญิง"},
    {label: "ว่าที่พันตำรวจเอกหญิง", value: "ว่าที่พันตำรวจเอกหญิง"},
    {label: "พันตำรวจตรีหญิง", value: "พันตำรวจตรีหญิง"},
    {label: "พันตำรวจโทหญิง", value: "พันตำรวจโทหญิง"},
    {label: "พันตำรวจเอกหญิง", value: "พันตำรวจเอกหญิง"},
    {label: "ว่าที่พลตำรวจตรี", value: "ว่าที่พลตำรวจตรี"},
    {label: "ว่าที่พลตำรวจโท", value: "ว่าที่พลตำรวจโท"},
    {label: "ว่าที่พลตำรวจเอก", value: "ว่าที่พลตำรวจเอก"},
    {label: "พลตำรวจตรี", value: "พลตำรวจตรี"},
    {label: "พลตำรวจโท", value: "พลตำรวจโท"},
    {label: "พลตำรวจเอก", value: "พลตำรวจเอก"},
    {label: "ว่าที่พลตำรวจตรีหญิง", value: "ว่าที่พลตำรวจตรีหญิง"},
    {label: "ว่าที่พลตำรวจโทหญิง", value: "ว่าที่พลตำรวจโทหญิง"},
    {label: "ว่าที่พลตำรวจเอกหญิง", value: "ว่าที่พลตำรวจเอกหญิง"},
    {label: "พลตำรวจตรีหญิง", value: "พลตำรวจตรีหญิง"},
    {label: "พลตำรวจโทหญิง", value: "พลตำรวจโทหญิง"},
    {label: "พลตำรวจเอกหญิง", value: "พลตำรวจเอกหญิง"}
];
