import React, { useEffect, useState } from 'react';
import { Button, Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { mainApi } from "../../HttpClient";
import Select from "react-select";
import $ from "jquery";
import config from "react-global-configuration";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Swal from "sweetalert2";

const BindEmpData = () => {
    const [keycloak] = useKeycloak();
    const [comboboxOrg1Type1, setcomboboxOrg1Type1] = useState([]);
    const [organizeId, setOrganizeId] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [comboboxEmpList, setComboboxEmpList] = useState([]);
    const [idCard, setIdCard] = useState([]);
    const [userUDisplay, setUserUDisplay] = useState('');
    const [userU, setUserU] = useState('');
    const [isReferLoading, setIsReferLoading] = useState(false);
    useEffect(() => {
        const fetchOrganizeId = async () => {
            const response = await mainApi.get('/api/employee/organization/1/' + keycloak.tokenParsed.preferred_username);
            setOrganizeId(response.data.orgId);
        };
        const getListOrg = async () => {
            const response = await mainApi.get('/api/organization/getOrgLevel1And0List');
            setcomboboxOrg1Type1(response.data);
        };

        const initDataTable = async () => {
            $.fn.dataTable.ext.errMode = 'none';
            $('#data-table').DataTable({
                order: [0, 'asc'],
                ajax: {
                    contentType: 'application/json',
                    url: config.get('apiUrl') + ('/api/bind-emp-data/data-table'),
                    type: 'POST',
                    data: function (d) {
                        return JSON.stringify(d);
                    },
                    headers: {
                        Authorization: `bearer ${keycloak.token}`,
                    },
                },
                processing: true,
                serverSide: true,
                mark: true,
                columns: [
                    {
                        width: '50px', data: '', className: 'text-center',
                        render: function (data, type, row, meta) {
                            return meta.row + meta.settings._iDisplayStart + 1;
                        }
                    },
                    {
                        width: '50px',
                        data: 'employeeId', className: 'text-center',
                    },
                    {
                        width: '100px',
                        data: 'fname', className: 'text-left'
                    },
                    {
                        width: '100px',
                        data: 'lname', className: 'text-left'
                    },
                    {
                        width: '100px',
                        data: 'orgName', className: 'text-left'
                    },
                    {
                        width: '100px',
                        data: 'employeeName', className: 'text-left'
                    },
                    {
                        width: '50px',
                        className: 'text-center',
                        searchable: false,
                        orderable: false,
                        responsivePriority: 4,
                        targets: -3,
                        render: function (data, type, row) {
                            if (row.employeeName) {
                                return `
                            <div>
                                <button type="button" class="btn btn-secondary btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-h"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <button class="dropdown-item btn-edit-document" type="button""><i class="fas fa-edit mr-2"></i>แก้ไข</button>
                                    <button class="dropdown-item btn-delete-document" type="button""><i class="fas fa-trash-alt mr-2"></i>ลบ</button>
                                </div>
                            </div>
                        `;
                            } else {
                                return `
                            <div>
                                <button type="button" class="btn btn-secondary btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-h"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <button class="dropdown-item btn-edit-document" type="button""><i class="fas fa-edit mr-2"></i>แก้ไข</button>
                                </div>
                            </div>
                        `;
                            }
                        },
                    },
                ],
                rowCallback: function (row, data) {
                    $('td', row).find('.btn-edit-document').on('click', function (e) {
                        showEditModal(data);
                    });
                    $('td', row).find('.btn-delete-document').on('click', function (e) {
                        handleDel(data.employeeId);
                    });
                },
                lengthChange: true,
                ordering: true,
                info: true,
                autoWidth: false,
                responsive: true,
                language: {
                    search: '_INPUT_',
                    searchPlaceholder: 'ค้นหา...',
                    lengthMenu: '_MENU_ รายการ/หน้า',
                    sLengthMenu: '_MENU_ รายการ/หน้า',
                    sProcessing: 'กำลังดำเนินการ...',
                    sZeroRecords: 'ไม่พบข้อมูล',
                    sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
                    sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
                    sInfoFiltered: '',
                    sSearch: '',
                    sUrl: '',
                    oPaginate: {
                        sFirst: 'หน้าแรก',
                        sPrevious: 'ก่อนหน้า',
                        sNext: 'ถัดไป',
                        sLast: 'หน้าสุดท้าย',
                    },
                    processing:
                        '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
                },
            });
        };
        fetchOrganizeId();
        getListOrg();
        initDataTable();

        const showEditModal = async (data) => {
            setShowModal(true);
            const response = await mainApi.get('/api/bind-emp-data/get-detail/' + data.employeeId);
            setUserU(data.employeeId);
            setUserUDisplay(data.employeeId + ' ' + data.fname + ' ' + data.lname);
            if (response.data.userU !== null) {
                setIdCard([{
                    id: data.employeeId,
                    name: data.employeeName,
                }])
            } else {
                setIdCard([]);
            }
        };

        const handleDel = async (data) => {
            Swal.fire({
                title: 'คุณต้องการลบข้อมูลใช่หรือไม่?',
                text: "คุณจะไม่สามารถกู้คืนข้อมูลได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบข้อมูล!',
                cancelButtonText: 'ยกเลิก',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await mainApi.delete('/api/bind-emp-data/delete/' + data);
                    await Swal.fire({
                        title: 'ลบข้อมูลสำเร็จ',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
                handleClose();
                $('#data-table').DataTable().ajax.reload();
            });
        };

    }, [keycloak]);

    useEffect(() => {
        // console.log('id', idCard);

    }, [idCard]);

    const handleClose = () => {
        setShowModal(false);
        setIdCard([]);
        setUserU('');
    };


    const handleSearchEmp = async (query) => {
        setIsReferLoading(true);
        let params = {
            id: query,
        }
        let rs = await mainApi.post('/api/bind-emp-data/employee-list', params);
        if (rs.status === 200) {
            setComboboxEmpList(rs.data);
            setIsReferLoading(false);
        } else {
            setComboboxEmpList([]);
            setIsReferLoading(false);
        }
    };

    const save = async () => {
        let data = {
            idCard: '',
            userU: userU
        };
        idCard.map((item) => data.idCard = item.id);
        await mainApi.post(config.get('apiUrl') + '/api/bind-emp-data/save', data)
            .then((res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            title: 'บันทึกสำเร็จ',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'ตกลง',
                        }).then(() => {
                            handleClose();
                            $('#data-table').DataTable().ajax.reload();
                        });
                    }
                }
            );
    }

    const handleSearch = () => {
        $('#data-table').DataTable().ajax.url(config.get('apiUrl') + '/api/bind-emp-data/data-table?orgId=' + organizeId).load();
    };
    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        ผูกข้อมูลผู้ใช้งาน
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            ตั้งค่าข้อมูล
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            ผูกข้อมูลผู้ใช้งาน
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-wrapper text-normal">
                                        <div className="row">
                                            <div className="col-12 col-md-1 pt-2">
                                                <span className="text-color-form align-middle">
                                                    หน่วยงาน :
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-9 pt-2">
                                                <Select
                                                    options={comboboxOrg1Type1}
                                                    value={comboboxOrg1Type1.filter(item => item.value === organizeId)}
                                                    onChange={(ValueType) => setOrganizeId(ValueType.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-2 offset-1 pt-3">
                                                <Button className="btn btn-success btn-md"
                                                        type="button"
                                                        onClick={handleSearch}>
                                                    <i className="fas fa-search pr-2"></i>
                                                    ค้นหา
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-wrapper text-normal">
                                        <table
                                            id="data-table"
                                            className="table table-striped table-bordered display w-100 margin-bottom-30">
                                            <thead>
                                            <tr>
                                                <th className="text-center">ลำดับ</th>
                                                <th className="text-center">ชื่อ(ผู้ใช้)</th>
                                                <th className="text-center">ชื่อ(บัญชีผู้ใช้)</th>
                                                <th className="text-center">นามสกุล(บัญชีผู้ใช้)</th>
                                                <th className="text-center">หน่วยงาน</th>
                                                <th className="text-center">ชื่อบุคลากร</th>
                                                <th className="text-center">จ้ดการ</th>
                                            </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
            <Modal show={showModal} onHide={handleClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>แก้ไขข้อมูลผู้ใช้งาน</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-md-12 pt-2">
                            <span className="text-color-form align-middle">
                                บัญชี:
                            </span>
                        </div>
                        <div className="col-12 col-md-12 pt-2">
                            <input type="text" className="form-control" value={userUDisplay} disabled/>

                        </div>
                        <div className="col-12 col-md-12 pt-2">
                            <span className="text-color-form align-middle">
                                ชื่อ - นามสกุล:
                            </span>
                        </div>
                        <div className="col-12 col-md-12 pt-2">
                            <AsyncTypeahead
                                id="async-example"
                                filterBy={() => true}
                                labelKey="name"
                                minLength={2}
                                isLoading={isReferLoading}
                                onSearch={handleSearchEmp}
                                onChange={setIdCard}
                                options={comboboxEmpList}
                                selected={idCard}
                                placeholder="กรุณากรอกชื่อบุคลากร"
                            />
                        </div>
                        <div className="col-12 pt-2">
                            <button type="button" className="btn btn-success float-right" onClick={save}>
                                <i className="fa fa-save pr-2"></i>บันทึก
                            </button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    );

};

export default BindEmpData;