import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";

const GenFormAutoGroupMenu = ({title}) => {
    return (
        <div className="col-12">
            <h5 className="text-light-dark">{title}</h5>
            <hr className="hr-custom"/>
        </div>
    );
}

const GenFormAutoSubGroupMenu = ({title}) => {
    return (
        <div className="col-12 text-center my-4">
            <h5 className="text-light-dark"><i className="fas fa-book icon-color mr-2"></i>{title}</h5>
            <hr className="hr-custom-width mb-1"/>
        </div>
    );
}

const GenFormAutoMenu = ({title, formId, autoFormType, autoFormGenre}) => {
    return (
        <div className="col-sm-12 col-md-12 p-2">
            <Link
                to={{
                    pathname: './form-auto-copy',
                    state: {
                        formId: formId,
                        autoFormType: autoFormType,
                        autoFormGenre: autoFormGenre
                    }
                }}
                className="btn btn-block elevation-1 align-middle p-3 h-100 text-light-dark"
            >
                {title}
            </Link>
        </div>
    );
}

const FormAutoCopy = () => {
    const documentState = useSelector((state) => state.document);
    const isDocumentByBu1 = documentState.documentBy.type.toLowerCase() === 'bu1';

    const history = useHistory();

    useEffect(() => {
      //   if (!documentState.documentBy || documentState.documentBy.type === 'employee' || documentState.documentBy.type === 'manager') {
      //       history.push('./dashbordV2');
      //   }
    }, [documentState.documentBy, history]);
    return (
        <div>
            <div className="content-wrapper" style={{minHeight: '100vh'}}>
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>

                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">แม่แบบ (คัดลอกข้อมูล)</h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            ทะเบียนแม่แบบ
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            แม่แบบ (คัดลอกข้อมูล)
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="section-wrapper text-normal">
                                    <div className="row">
                                        <GenFormAutoGroupMenu title="วิทยากร"/>
                                    </div>
                                    <div className="row p-2 mb-5 justify-content-center">
                                        <div className="col-md-6">
                                            <GenFormAutoSubGroupMenu title="หนังสือภายใน"/>
                                            <GenFormAutoMenu title="ขอเชิญเป็นวิทยากร (ภายใน)" formId={1} autoFormType={'S'} autoFormGenre={'IN'}/>
                                            <GenFormAutoMenu title="ขอความอนุเคราะห์วิทยากร (ภายใน) - กรมควบคุมโรค" formId={5} autoFormType={'S'} autoFormGenre={'IN'}/>
                                            {/* {isDocumentByBu1 && ( */}
                                                <GenFormAutoMenu title="ขอความอนุเคราะห์วิทยากร (ภายใน) - หน่วยงานเทียบเท่ากอง" formId={6} autoFormType={'S'} autoFormGenre={'IN'}/>
                                            {/* )} */}
                                        </div>
                                        {/* {isDocumentByBu1 && ( */}
                                            <div className="col-md-6">
                                                <GenFormAutoSubGroupMenu title="หนังสือภายนอก"/>
                                                <GenFormAutoMenu title="ขอเชิญเป็นวิทยากร (ภายนอก)" formId={4} autoFormType={'S'} autoFormGenre={'OUT'}/>
                                                <GenFormAutoMenu title="ขอเชิญเป็นวิทยากร (ภายนอก) - กรมควบคุมโรค" formId={2} autoFormType={'S'} autoFormGenre={'OUT'}/>
                                                <GenFormAutoMenu title="ขอเชิญเป็นวิทยากร (ภายนอก) - หน่วยงานเทียบเท่ากอง" formId={3} autoFormType={'S'} autoFormGenre={'OUT'}/>
                                                <GenFormAutoMenu title="ขอความอนุเคราะห์วิทยากร (ภายนอก) - กรมควบคุมโรค" formId={7} autoFormType={'S'} autoFormGenre={'OUT'}/>
                                                <GenFormAutoMenu title="ขอความอนุเคราะห์วิทยากร (ภายนอก) - หน่วยงานเทียบเท่ากอง" formId={8} autoFormType={'S'} autoFormGenre={'OUT'}/>
                                            </div>
                                        {/* )} */}
                                    </div>
                                    <div className="row">
                                        <GenFormAutoGroupMenu title="ประชุม"/>
                                    </div>
                                    <div className="row p-2 mb-5 justify-content-center">
                                        <div className="col-md-6">
                                            <GenFormAutoSubGroupMenu title="หนังสือภายใน"/>
                                            <GenFormAutoMenu title="ขอความอนุเคราะห์ใช้ห้องประชุม" formId={15} autoFormType={'S'} autoFormGenre={'IN'}/>
                                            <GenFormAutoMenu title="ขอเชิญประชุม (ภายใน) - กรมควบคุมโรค" formId={9} autoFormType={'S'} autoFormGenre={'IN'}/>
                                            {/* {isDocumentByBu1 && ( */}
                                                <GenFormAutoMenu title="ขอเชิญประชุม (ภายใน) - หน่วยงานเทียบเท่ากอง" formId={10} autoFormType={'S'} autoFormGenre={'IN'}/>
                                            {/* )} */}
                                            <GenFormAutoMenu title="ขอเชิญประชุม (ภายใน-เวียน) - กรมควบคุมโรค" formId={11} autoFormType={'W'} autoFormGenre={'IN'}/>
                                            {/* {isDocumentByBu1 && ( */}
                                                <GenFormAutoMenu title="ขอเชิญประชุม (ภายใน-เวียน) - หน่วยงานเทียบเท่ากอง" formId={12} autoFormType={'W'} autoFormGenre={'IN'}/>
                                            {/* )} */}
                                        </div>
                                        {/* {isDocumentByBu1 && ( */}
                                            <div className="col-md-6">
                                                <GenFormAutoSubGroupMenu title="หนังสือภายนอก"/>
                                                <GenFormAutoMenu title="ขอเชิญประชุม (ภายนอก) - กรมควบคุมโรค" formId={13} autoFormType={'S'} autoFormGenre={'OUT'}/>
                                                <GenFormAutoMenu title="ขอเชิญประชุม (ภายนอก) - หน่วยงานเทียบเท่ากอง" formId={14} autoFormType={'S'} autoFormGenre={'OUT'}/>
                                            </div>
                                        {/* )} */}
                                    </div>
                                    <div className="row">
                                        <GenFormAutoGroupMenu title="อื่นๆ"/>
                                    </div>
                                    <div className="row p-2 mb-5 justify-content-center">
                                        <div className="col-md-6">
                                            <GenFormAutoSubGroupMenu title="หนังสือภายใน"/>
                                            <GenFormAutoMenu title="หนังสือเวียนทั่วไปแบบบันทึก" formId={16} autoFormType={'W'} autoFormGenre={'IN'}/>
                                            <GenFormAutoMenu title="หนังสือเวียนประทับตราลงนาม" formId={17} autoFormType={'W'} autoFormGenre={'IN'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        </div>
    );
};

export default FormAutoCopy;
