import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { pdfjs } from "react-pdf";
import pdfJsWorker from "react-pdf/src/pdf.worker.entry";
import SignPdf from "./components/SignPdf";
import { TextField } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";

pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker;

const PdfPreview = forwardRef((
    {
        onAccept,
        labelSubmitSignature,
        iconSubmitSignature,
    }, ref) => {
    const [pdfList, setPdfList] = useState([]);
    const [previewPdf, setPreviewPdf] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const close = () => setModalShow(false);
    const show = () => setModalShow(true);
    const [modalTextShow, setModalTextShow] = useState(false);
    const closeText = () => setModalTextShow(false);


    const [isPdfLoading, setIsPdfLoading] = useState(false);
    const [pdfSignList, setPdfSignList] = useState([]);
    const [customText, setCustomText] = useState("");

    const signPdfRef = useRef();

    const clear = () => {
        setPdfList([]);
        setPreviewPdf(null);

        setIsPdfLoading(false);
        setPdfSignList([]);
        setCustomText("");
    };

    const initPdfList = (pdfList) => {
        if (pdfList.length > 0) {
            setPdfList(pdfList);
            handlePreviewPdf(0, pdfList);
            setIsPdfLoading(false);
        } else {
            clear();
        }
    }

    const clearDataAndShowModal = () => {
        clear();
        show();
        setIsPdfLoading(true);
    };

    useImperativeHandle(ref, () => ({
        show,
        close,
        clear,
        pdfSignList,
        initPdfList,
        clearDataAndShowModal,
    }));


    const handlePreviewPdf = (index, pdfList) => {
        setPreviewPdf({
            base64Str: pdfList[index].base64Str,
            realFilename: pdfList[index].realFilename,
            index: index,
        });
    }
    const generateSignPdfSkeleton = () => {
        return (
            <>
                <div className="m-4">
                    <div className="col-12 text-center">
                        <Skeleton height={30} width={400}/>
                    </div>
                </div>
                <div className="col-12 text-center">
                    <Skeleton height={841} width={595}/>
                </div>
            </>
        );
    }

    return (
        <>
            <Modal
                size="xl"
                show={modalShow}
                onHide={close}
                backdrop="static"
                keyboard={false}
                scrollable
            >
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="far fa-file-pdf mr-2"></i>ตัวอย่างเอกสาร
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="bg-light p-0">
                    <hr className="m-0"/>
                    <Container fluid>
                        <Row>
                            <Col>
                                {!previewPdf && !isPdfLoading && <div className="m-4 text-center" style={{"height": "841px"}}><h5><b>ไม่พบไฟล์ PDF</b></h5></div>}
                                {!previewPdf && isPdfLoading && generateSignPdfSkeleton()}
                                {previewPdf &&
                                    <SignPdf
                                        pdfList={pdfList}
                                        pdf={previewPdf.base64Str}
                                        index={previewPdf.index}
                                        realFilename={previewPdf.realFilename}
                                        ref={signPdfRef}
                                    />
                                }
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={close}
                    >
                        <i className="fas fa-ban mr-2"></i>ปิด
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={modalTextShow}
                onHide={closeText}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-file-signature mr-2"></i>ข้อความ
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="ข้อความ"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth
                                    value={customText}
                                    onChange={(e) => setCustomText(e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
});

export default PdfPreview;
