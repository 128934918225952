export const unit = [
    {
        id: "แผ่น",
        name: "แผ่น"
    },
    {
        id: "ฉบับ",
        name: "ฉบับ"
    },
    {
        id: "เล่ม",
        name: "เล่ม"
    },
    {
        id: "แฟ้ม",
        name: "แฟ้ม"
    },
    {
        id: "ชุด",
        name: "ชุด"
    },
    {
        id: "ชิ้น",
        name: "ชิ้น"
    },
];
