import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Document, Page } from "react-pdf";

export interface PreSignProps {
    pdf: string;
    index: number;
    realFilename: string;
    children: React.ReactNode;
}

const PdfView = forwardRef((props: PreSignProps, ref) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [page, setPage] = useState({width: 0, height: 0});

    const [pdfSignList, setPdfSignList] = useState([]);

    const clear = () => {
        setPdfSignList([]);
    };

    useImperativeHandle(ref, () => ({
        clear,
        pdfSignList,
    }));

    useEffect(() => {
        setPageNumber(1);
    }, [props.pdf]);

    const onDocumentLoadSuccess = (pdf) => {
        setNumPages(pdf.numPages);
    }

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    const onPageLoadSuccess = async (pdf) => {
        const pdfWidth = pdf.originalWidth;
        const pdfHeight = pdf.originalHeight;

        setPage({width: pdfWidth, height: pdfHeight});
    }

    return (
        <Container className="pdf-sign-viewer">
            <div className="row m-4">
                <div className="col-12 text-center">
                    <h5><b>{props.realFilename.replaceAll("_", " ")}</b></h5>
                </div>
            </div>
            <Document file={props.pdf} onLoadSuccess={onDocumentLoadSuccess}>
                <Page
                    width={page.width}
                    height={page.height}
                    pageNumber={pageNumber}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    onLoadSuccess={onPageLoadSuccess}
                />
            </Document>
            <div className="row m-4">
                <div className="col-12 text-center">
                    <Button type="button" disabled={pageNumber <= 1} onClick={previousPage}>‹</Button>
                    <span className="m-2">หน้า {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}</span>
                    <Button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>›</Button>
                </div>
            </div>
        </Container>
    );
});

export default PdfView;
