import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { getDateFullMonthFormat } from "../../util/Date";

export default function templateHtmlFormAutoGenreOut(data, type) {
    const result = [];
    const getData = (data1, data2) => {
        return type === "1" ? data1 : data2;
    };

    const pageMargin = {top: 15.0, right: 0.0, bottom: 15.0, left: 0.0};

    const header = (
        <html>
        <head>
            <title>{data.autoFormName || ''}</title>
            <link rel="stylesheet" href="assets/formAutoGenreOut.css"/>
        </head>
        <body>
        <div className="flex-container">
            <img
                className="img-garuda"
                src="assets/garuda_type_2.png"
                alt="Thai Government Garuda emblem (Version 3)"
            />
        </div>
        <div className="container">
            <div className="flex-container" style={{marginTop: '-35px', marginBottom: '6pt'}}>
                <div style={{flex: 1}}>ที่&nbsp;<span className="font-th-sarabun-it9">{getData(data.autoFormNo, data.f2AutoFormNo)}</span></div>
                <div style={{flex: 1}}></div>
                <div style={{flex: 1.12}}>
                    {',3,8,14,'.indexOf(',' + data.formId + ',') > -1 && (
                        <>{getData(data.government, data.f2Government)}<br/></>
                    )}
                    กรมควบคุมโรค<br/>
                    ถนนติวานนท์ จังหวัดนนทบุรี ๑๑๐๐๐
                </div>
            </div>
            <div className="flex-container" style={{marginBottom: '6pt'}}>
                <div style={{flex: 52.5}}></div>
                <div style={{flex: 56}}><span className="font-th-sarabun-it9">{getDateFullMonthFormat(getData(data.autoFormDate, data.f2AutoFormDate)) || <span>&nbsp;</span>}</span></div>
            </div>
            <div style={{marginBottom: '6pt'}}>
                <span>เรื่อง</span>&nbsp;&nbsp;{getData(data.subject, data.f2Subject)}
            </div>
            <div style={{marginBottom: '6pt'}}>
                <span>{getData(data.prefixInform, data.f2PrefixInform)}</span>&nbsp;&nbsp;{getData(data.inform, data.f2Inform)}
            </div>
            {getData(data.referenceList, data.f2ReferenceList) != null && getData(data.referenceList, data.f2ReferenceList).length > 0 && (
                <div style={{marginBottom: '6pt'}}>
                    {getData(data.referenceList, data.f2ReferenceList).map((item, index) => (
                        <div className="flex-container" key={index}>
                            <div className="reference-flex-item-label">
                                {index === 0 ? 'อ้างถึง' : ''}
                            </div>
                            <div className="reference-flex-item-detail">
                                {item.detail}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {getData(data.attachList, data.f2AttachList) != null && getData(data.attachList, data.f2AttachList).length > 0 && (
                <div style={{marginBottom: '6pt'}}>
                    {getData(data.attachList, data.f2AttachList).map((item, index) => (
                        <div className="flex-container" key={index}>
                            <div className="enclosure-flex-item-label">
                                {index === 0 ? 'สิ่งที่ส่งมาด้วย' : ''}
                            </div>
                            <div className="enclosure-flex-item-detail">
                                <span className="font-th-sarabun-it9">{index + 1}.</span>&nbsp;{item.detail}
                            </div>
                            <div className="enclosure-flex-item-qty font-th-sarabun-it9">
                                จำนวน&nbsp;&nbsp;{item.qty}
                            </div>
                            <div className="enclosure-flex-item-unit font-th-sarabun-it9">
                                {item.unit}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className="document-content">
                ***replace-content***
            </div>
        </div>
        <div className="flex-container" style={{marginTop: '12pt'}}>
            <div style={{flex: 1}}></div>
            <div style={{flex: 1}}>{getData(data.postScript, data.f2PostScript)}</div>
        </div>
        <div className="footer-container">
            <div>{getData(data.governmentFt, data.f2GovernmentFt)}</div>
            <div>โทร.&nbsp;&nbsp;{getData(data.telFt, data.f2TelFt)}</div>
            <div>โทรสาร.&nbsp;&nbsp;{getData(data.faxFt, data.f2FaxFt)}</div>
            {getData(data.emailFt, data.f2EmailFt) && (
                <div>ไปรษณีย์อิเล็กทรอนิกส์&nbsp;&nbsp;{getData(data.emailFt, data.f2EmailFt)}</div>
            )}
        </div>
        </body>
        </html>
    );

    let html = ReactDOMServer.renderToString(header);
    let content = getData(data.content, data.f2Content);
    html = html.replaceAll("***replace-content***", content);

    result.push({html, pageMargin});

    return result;
};
