import React, { useState } from 'react';
import { fileApi } from '../../HttpClient';
import fileDownload from 'js-file-download';
// import config from 'react-global-configuration';
// import Swal from 'sweetalert2';

const DownloadFile = ({filename, realfilename, deleteFile}) => {
    const [isLoading, setIsLoading] = useState(false);

    const download = () => {
        setIsLoading(true);
        fileApi.get(`/v1/file/${filename}`, {
            responseType: 'blob',
        }).then((res) => {
            fileDownload(res.data, realfilename || filename);
        // }).catch((error) => {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'ไม่พบไฟล์',
        //         timer: config.get('alertTimer'),
        //         timerProgressBar: true,
        //         toast: true,
        //         position: 'top-end',
        //         showConfirmButton: false,
        //     });
        }).finally(() => setIsLoading(false));
    };

    return (
        <div>
            <a
                href="#section"
                className="show-truncate"
                disabled={isLoading}
                onClick={!isLoading ? download : null}
            >
                {realfilename || filename}
                {isLoading && (
                    <div className="spinner-border spinner-border-sm ml-2" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                )}
            </a>
            {deleteFile && (
                <i
                    className="fas fa-trash-alt ml-2 text-secondary"
                    style={{cursor: 'pointer'}}
                    onClick={deleteFile}
                ></i>
            )}
        </div>
    );
};

export default DownloadFile;
