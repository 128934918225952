import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { mainApi } from "../../../../HttpClient";
import config from "react-global-configuration";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { rankList } from "../../../formauto/util/RankList";
import { prefixList } from "../../../formauto/util/PrefixList";
import CreatableSelect from "react-select/creatable";

const RubberStampDataModal = forwardRef(({setRubberStampData}, ref) => {
    const userLogin = useSelector((state) => state.userLogin.value);
    const {register, handleSubmit, reset} = useForm();
    const [saveLoading, setSaveLoading] = useState(false);
    const [rankName, setRankName] = useState([]);
    const [prefixName, setPrefixName] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const closeModal = () => setModalShow(false);
    const openModal = () => {
        setModalShow(true);
        clear();
    };

    const clear = () => {
        setSaveLoading(false);
        reset();
    };

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal,
    }));

    const handleSave = (data) => {
        setSaveLoading(true);
        let params = {
            id: 0,
            firstName: data.firstName,
            lastName: data.lastName,
            positionName1: data.positionName1,
            positionName2: data.positionName2,
            positionName3: data.positionName3,
            positionName4: data.positionName4,
            positionName5: data.positionName5,
            employeeId: userLogin.employee_id,
            active: "1",
        }

        if (rankName.length > 0) {
            params = {...params, rankName: rankName[0].value}
        }

        if (prefixName.length > 0) {
            params = {...params, prefixName: prefixName[0].value}
        }

        mainApi.post(config.get('apiUrl') + '/api/rubber-stamp-data/save', params).then((res) => {
            if (res.status === 200) {
                if(setRubberStampData) {
                    setRubberStampData([params]);
                }
                Swal.fire({
                    title: 'บันทึกสำเร็จ',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ตกลง',
                }).then(() => {
                    closeModal();
                });
            }
        }).finally(() => {
            setSaveLoading(false);
        });
    }

    return (
        <>
            <Modal show={modalShow} onHide={closeModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Form className="form-auto" onSubmit={handleSubmit(handleSave)}>
                    <Modal.Header closeButton className="bg-color">
                        <Modal.Title className="nav-text text-shadow-header">
                            <i className="fas fa-file-signature mr-2"></i>เพิ่มข้อมูลตรายาง
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-color-form text-center">
                        <Container fluid>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ยศ</label>
                                <div className="col-sm-9">
                                    <CreatableSelect
                                        isClearable
                                        id="rank-name-typeahead-single"
                                        labelKey="name"
                                        className="selectInform"
                                        options={rankList}
                                        onChange={(selected) => {
                                            setRankName([selected])
                                        }}
                                        selected={rankName}
                                        placeholder="เลือกยศ (ถ้ามี)"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">คำนำหน้า</label>
                                <div className="col-sm-9">
                                    <CreatableSelect
                                        isClearable
                                        id="rank-name-typeahead-single"
                                        labelKey="name"
                                        options={prefixList}
                                        onChange={(selected) => {
                                            setPrefixName([selected])
                                        }}
                                        placeholder="เลือกคำนำหน้า (ถ้ามี)"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ชื่อ <span className="text-danger">*</span></label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="ชื่อ" name="firstName" ref={register({required: true})}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">นามสกุล <span className="text-danger">*</span></label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="นามสกุล" name="lastName" ref={register({required: true})}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ตำแหน่งที่ 1 <span className="text-danger">*</span></label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="ตำแหน่งที่ 1" name="positionName1" ref={register({required: true})}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ตำแหน่งที่ 2</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="ตำแหน่งที่ 2" name="positionName2" ref={register}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ตำแหน่งที่ 3</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="ตำแหน่งที่ 3" name="positionName3" ref={register}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ตำแหน่งที่ 4</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="ตำแหน่งที่ 4" name="positionName4" ref={register}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ตำแหน่งที่ 5</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="ตำแหน่งที่ 5" name="positionName5" ref={register}/>
                                </div>
                            </div>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            type="submit"
                            className="btn-color-submit ml-3 mr-2"
                            disabled={saveLoading}
                        >
                            บันทึกตรายาง
                            {saveLoading && (
                                <div
                                    className="spinner-border spinner-border-sm ml-2"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            )}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
});

export default RubberStampDataModal;
