/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {Button, Container, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {mainApi} from "../../HttpClient";
import Swal from "sweetalert2";
import config from "react-global-configuration";


const RegisterManagerEdit = () => {
    const [masManagerRegister, setMasManagerRegister] = useState([]);
    const [isReferLoading, setIsReferLoading] = useState(true);
    useEffect(() => {
        const fetchMasManagerRegister = async () => {
            const response = await mainApi.get('/api/mas_manager/masManagerRegister');
            setMasManagerRegister(response.data);
            setIsReferLoading(false);
        };
        fetchMasManagerRegister();
    }, []);
    const handleSave = async () => {
        setIsReferLoading(true);
        let data = masManagerRegister
        let req = {
            documentRegisterManagerList: data
        }
        const response = await mainApi.post('/api/document-register-manager/save', req)
        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'บันทึกสำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });
        }
        setIsReferLoading(false);
    }
    return (
        <div>
            <div className="content-wrapper" style={{minHeight: '100vh'}}>
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        กำหนดเลขสธ.ผู้บริหาร
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            กำหนดเลขสธ.ผู้บริหาร
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-wrapper text-normal">

                                        <Table striped bordered hover>
                                            <thead>
                                            <tr>
                                                <th>ชื่อ</th>
                                                <th>นามสกุล</th>
                                                <th>เลข สธ.</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {masManagerRegister.map((item, index) => (
                                                <tr key={'masManagerRegister' + index}>
                                                    <td>{(item.fname).replace("(", "")}</td>
                                                    <td>{item.lname.replace(")", "")}</td>
                                                    <td><input
                                                        className="form-control shadow-none"
                                                        type="text"
                                                        name="organizeCode"
                                                        defaultValue={item.organizeCode}
                                                        onChange={(e) => {
                                                            item.organizeCode = e.target.value;
                                                        }}
                                                    /></td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Button
                                        type="button"
                                        onClick={handleSave}
                                        disabled={isReferLoading}
                                        className="btn-color-add-small">
                                        <i className="fas fa-save pr-2"></i>
                                        บันทึก
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        </div>
    );
};

export default RegisterManagerEdit;
