/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { mainApi } from '../../HttpClient';
import fileDownload from 'js-file-download';
import Select from 'react-select';
import moment from 'moment';
import _ from 'lodash';

import DateInput from '../share/DateInput';

import { getApiMasUrgencyLevel } from '../util/ApiForSelect';

const rpt2 = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [orgList, setOrgList] = useState([]);
  const [sender, setSender] = useState('');
  const [receiver, setReceiver] = useState('');
  const [urgencyLevel, setUrgencyLevel] = useState('');
  const [isExportFile, setIsExportFile] = useState(false);
  const [urgencyLevelOptions, setUrgencyLevelOptions] = useState([]);

  let listUrgen = [];

  useEffect(() => {
    const reArrangeOrgValue = (categories) => {
      setCategoryList(
        _.flatMap(categories, (mVal) => {
          return {
            value: mVal.categoryId,
            label: mVal.name,
          };
        })
      );
    };
    const fetchCategory = async () => {
      const response = await mainApi.get('/api/categories/list');
      reArrangeOrgValue(response.data);
    };
    fetchCategory();

    const fetchOrgList = async () => {
      const response = await mainApi.get('/api/organization/list/1');
      setOrgList(response.data);
    };
    fetchOrgList();

    const rsUrgen = getApiMasUrgencyLevel();
    rsUrgen.then((res) => {
      for(let i=0; i <= res.data.length-1; i++){
        listUrgen.push({
          value: res.data[i].urgency_level_id,
          label: res.data[i].name
        });
      }
    });
    setUrgencyLevelOptions(listUrgen);
  }, []);

  const handlePrint = () => {
    setIsExportFile(true);
    let condition = '?';
    condition += 'startDate=' + moment(startDate).format('YYYY-MM-DD');
    condition += '&endDate=' + moment(endDate).format('YYYY-MM-DD');

    if (category) condition += '&categoryId=' + category;
    if (sender) condition += '&sender=' + sender;
    if (receiver) condition += '&receiver=' + receiver;
    if (urgencyLevel) condition += '&urgencyLevel=' + urgencyLevel;

    mainApi
      .get('/api/report/excel/04' + condition, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, 'รายงานการส่งเอกสารภายในองค์กร.xlsx');
        setIsExportFile(false);
      });
  };

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: '100vh' }}>
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>

            <div className="content-header">
              <div className="row">
                <div className="col-sm-12">
                  <h1 className="text-light-dark page-heading mt-2">
                    รายงานการส่งเอกสารภายในองค์กร
                  </h1>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12">
                  <ol className="breadcrumb float-sm-left bg-transparent p-0">
                    <li className="breadcrumb-item text-breadcrumb">
                      <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                      <Link to="./" className="text-breadcrumb">
                        หน้าหลัก
                      </Link>
                    </li>
                    <li className="breadcrumb-item text-breadcrumb">
                      <Link to="./report" className="text-breadcrumb">
                        รายงาน
                      </Link>
                    </li>
                    <li className="breadcrumb-item text-breadcrumb-active">
                      รายงานการส่งเอกสารภายในองค์กร
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="section-wrapper text-normal">
                  <div className="row">
                    <div className="col-12 col-md-2">
                      <span className="text-color-form align-middle">
                        หมวดเอกสาร
                      </span>
                    </div>
                    <div className="col-12 col-md-5">
                      <Select
                        isClearable
                        options={categoryList}
                        onChange={(ValueType) => {
                          setCategory(ValueType ? ValueType.value : '');
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-md-2">
                      <span className="text-color-form align-middle">
                        ลงวันที่
                      </span>
                    </div>
                    <div className="col-12 col-md-3">
                      <DateInput
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                    <div className="col-12 col-md-2">
                      <span className="text-color-form align-middle">
                        ถึงวันที่
                      </span>
                    </div>
                    <div className="col-12 col-md-3">
                      <DateInput
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-md-2">
                      <span className="text-color-form align-middle">จาก</span>
                    </div>
                    <div className="col-12 col-md-5">
                      <Select
                        isClearable
                        options={orgList}
                        onChange={(ValueType) =>
                          setSender(ValueType ? ValueType.value : '')
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-md-2">
                      <span className="text-color-form align-middle">ถึง</span>
                    </div>
                    <div className="col-12 col-md-5">
                      <Select
                        isClearable
                        options={orgList}
                        onChange={(ValueType) =>
                          setReceiver(ValueType ? ValueType.value : '')
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-md-2">
                      <span className="text-color-form align-middle">
                        ชั้นความเร็ว
                      </span>
                    </div>
                    <div className="col-12 col-md-3">
                      <Select
                        isClearable
                        options={urgencyLevelOptions}
                        onChange={(ValueType) =>
                          setUrgencyLevel(ValueType ? ValueType.value : '')
                        }
                      />
                    </div>
                  </div>
                  <div className="row mt-5 mb-3">
                    <div className="mx-auto">
                      <Button
                        type="button"
                        variant="contained"
                        className="btn-no-border btn-color-add-small"
                        disabled={isExportFile}
                        onClick={!isExportFile ? handlePrint : null}
                      >
                        <i className="fas fa-print pr-2"></i>
                        ดาวน์โหลดเอกสาร
                        {isExportFile && (
                          <div
                            className="spinner-border spinner-border-sm ml-2"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default rpt2;
