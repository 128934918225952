import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import { pdfjs } from "react-pdf";
import pdfJsWorker from "react-pdf/src/pdf.worker.entry";
import MenuPdf from "./components/MenuPdf";
import TopNav from "./components/TopNav";
import SignPdf from "./components/SignPdf";
import { TextField } from "@material-ui/core";
import { mainApi } from "../../../HttpClient";
import Swal from "sweetalert2";
import config from "react-global-configuration";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { setSignatureCertificateSelected } from '../../../app/stores/features/userLoginSlice';
import {
    clearDocumentDate,
    setDigitalSignatureCheckDocumentDate,
    setDigitalSignatureCheckSentNo,
    setDocumentDateRedux,
    setDocumentReceiverNo,
    setDocumentSentNo
} from "../../../app/stores/features/documentSlice";
import RubberStampDataModal from "./components/RubberStampDataModal";
import { urgencyLevel } from "../../formauto/util/UrgencyLevel";
import DateInput from "../DateInput";
import moment from "moment/moment";
import { getDateFullMonthFormat } from "../../util/Date";
import reactCSS from 'reactcss'
import { SketchPicker } from "react-color";

pdfjs.GlobalWorkerOptions.workerSrc = pdfJsWorker;

const DigitalSignature = forwardRef((
    {
        onAccept,
        labelSubmitSignature,
        iconSubmitSignature,
        showStampReceive = false,
    }, ref) => {
    const [pdfList, setPdfList] = useState([]);
    const [previewPdf, setPreviewPdf] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const close = () => setModalShow(false);
    const show = () => setModalShow(true);
    const [modalTextShow, setModalTextShow] = useState(false);
    const closeText = () => setModalTextShow(false);
    const showText = () => setModalTextShow(true);
    const [modalTextStampShow, setModalTextStampShow] = useState(false);
    const closeTextStamp = () => setModalTextStampShow(false);
    const showTextStamp = () => setModalTextStampShow(true);
    const [modalTextStampReceiveShow, setModalTextStampReceiveShow] = useState(false);
    const closeTextStampReceive = () => setModalTextStampReceiveShow(false);
    const showTextStampReceive = () => setModalTextStampReceiveShow(true);
    const [modalSignatureCertificateShow, setModalSignatureCertificateShow] = useState(false);
    const closeSignatureCertificate = () => setModalSignatureCertificateShow(false);
    const showSignatureCertificate = () => setModalSignatureCertificateShow(true);

    const [isPdfLoading, setIsPdfLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isGetReceiveNumber, setIsGetReceiveNumber] = useState(false);
    const [isGetSentNumber, setIsGetSentNumber] = useState(false);
    const [pdfSignList, setPdfSignList] = useState([]);
    const [customText, setCustomText] = useState("");
    const [autoFormData, setAutoFormData] = useState({});
    const [registerName, setRegisterName] = useState("");
    const [documentDate, setDocumentDate] = useState(new Date());

    const [rubberStampList, setRubberStampList] = useState([]);
    const [customTextStamp, setCustomTextStamp] = useState("");
    const [rankNameTextStamp, setRankNameTextStamp] = useState("");
    const [rubberStampChecked, setRubberStampChecked] = useState("");
    const [rubberStampSignatureChecked, setRubberStampSignatureChecked] = useState(true);
    const [rubberStampSignatureDate, setRubberStampSignatureDate] = useState(new Date());
    const [rubberStampSignatureDateChecked, setRubberStampSignatureDateChecked] = useState(true);

    const [rubberStampReceiveList, setRubberStampReceiveList] = useState([]);
    const [customTextStampReceive, setCustomTextStampReceive] = useState("");
    const [rubberStampReceiveChecked, setRubberStampReceiveChecked] = useState("");

    const signPdfRef = useRef();
    const rubberStampDataModalRef = useRef();
    const inputFileImageQRRef = useRef();

    const dispatch = useDispatch();
    const signatureCertificate = useSelector((state) => state.userLogin.signatureCertificate);
    const documentState = useSelector((state) => state.document);

    const [stateColor, setStateColor] = useState(
        {
            displayColorPicker: false,
            color: {
                hex: '#000000',
            },
        }
    );

    const stylesColorPicker = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                backgroundColor: stateColor.color.hex,
            },
            swatch: {
                padding: '5px',
                backgroundColor: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
                bottom: '3em',
                right: '7.5em',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    const handleStateColorClick = () => {
        setStateColor({...stateColor, displayColorPicker: !stateColor.displayColorPicker});
    };

    const handleStateColorClose = () => {
        setStateColor({...stateColor, displayColorPicker: false});
    };

    const handleStateColorChange = (color) => {
        setStateColor({...stateColor, color: color});
    };

    const clear = () => {
        setPdfList([]);
        setPreviewPdf(null);

        setIsPdfLoading(false);
        setIsSubmit(false);
        setPdfSignList([]);
        setCustomText("");
        setCustomTextStamp("");
        setRubberStampChecked("");
        setAutoFormData({});
        setRubberStampSignatureChecked(true);
    };

    const initPdfList = (pdfList) => {
        if (pdfList.length > 0) {
            setPdfList(pdfList);
            handlePreviewPdf(0, pdfList);
            setIsPdfLoading(false);
        } else {
            clear();
        }
    }

    const clearDataAndShowModal = () => {
        clear();
        show();
        setIsPdfLoading(true);
    };

    useImperativeHandle(ref, () => ({
        show,
        close,
        clear,
        pdfSignList,
        initPdfList,
        setAutoFormData,
        clearDataAndShowModal,
    }));

    useEffect(() => {
        mainApi.get('/api/form-auto/rubber-stamp').then((res) => {
            setRubberStampList(res.data);
        });
        mainApi.get('/api/form-auto/rubber-stamp-receive').then((res) => {
            setRubberStampReceiveList(res.data);
        });
    }, [])


    useEffect(() => {
        if (documentState.documentBy && documentState.documentBy.id !== null) {
            mainApi.post('/api/v2/documents-register/register-name', documentState.documentBy).then((res) => {
                setRegisterName(res.data);
            });
        }
    }, [documentState.documentBy]);

    const handlePreviewPdf = (index, pdfList) => {
        setPreviewPdf({
            base64Str: pdfList[index].base64Str,
            realFilename: pdfList[index].realFilename,
            index: index,
        });
    }

    const handleSubmit = () => {
        const pdfSignList = signPdfRef.current.handleSaveSignature();
        // if (pdfSignList.some((pdf) => pdf.signPositionList)) {
        if (onAccept) {
            onAccept(pdfSignList);
        }
        setPdfSignList(pdfSignList);
        // console.log('DigitalSignatureModal.handleSubmit', pdfSignList);
        close();
        closeSignatureCertificate();
        // } else {
        //     Swal.fire({
        //         icon: 'info',
        //         title: 'กรุณาเพิ่มลายเซ็นหรือข้อความลงในเอกสาร',
        //         timer: config.get('alertTimer'),
        //         timerProgressBar: true,
        //         toast: true,
        //         position: 'top-end',
        //         showConfirmButton: false,
        //     });
        // }
    }

    const handleAddSign = () => {
        if (!isSubmit && previewPdf) {
            signPdfRef.current.handleAddSignature();
        } else {
            alertPdfNotFound();
        }
    }

    const handleAddImageQR = () => {
        if (!isSubmit && previewPdf) {
            inputFileImageQRRef.current.click();
        } else {
            alertPdfNotFound();
        }
    }

    const handleFileImageQRChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                signPdfRef.current.handleAddImageQR(e.target.result);
            };
            reader.readAsDataURL(file);
        }
        e.target.value = null;
    }

    const handleAddRedStamp = () => {
        if (!isSubmit && previewPdf) {
            signPdfRef.current.handleAddRedStamp();
        } else {
            alertPdfNotFound();
        }
    }

    const handleOpenTextModal = () => {
        if (!isSubmit && previewPdf) {
            showText();
        } else {
            alertPdfNotFound();
        }
    }

    const handleOpenTextStampModal = () => {
        if (!isSubmit && previewPdf) {
            showTextStamp();
        } else {
            alertPdfNotFound();
        }
    }

    const handleOpenTextStampReceiveModal = () => {
        if (!isSubmit && previewPdf) {
            showTextStampReceive();
        } else {
            alertPdfNotFound();
        }
    }

    const handleAddText = () => {
        let props = {
            fillStyle: stateColor.color.hex,
        }

        if (!isSubmit && previewPdf) {
            signPdfRef.current.handleAddText(customText, props);
            closeText();
        } else {
            alertPdfNotFound();
        }
    }

    const handleAddStamp = () => {
        if (!isSubmit && previewPdf) {
            let resultText = customTextStamp;
            if (rubberStampSignatureDateChecked) {
                const documentDateFormat = moment(rubberStampSignatureDate).format('YYYY-MM-DD');
                const documentDateResult = getDateFullMonthFormat(documentDateFormat);
                resultText += "\n" + documentDateResult;
            }
            if (rubberStampSignatureChecked) {
                signPdfRef.current.handleAddTextStampWithSignature(resultText, rankNameTextStamp);
            } else {
                signPdfRef.current.handleAddText(resultText);
            }
            closeTextStamp();
        } else {
            alertPdfNotFound();
        }
    }

    const handleAddRegisterNo = async () => {
        if (!isSubmit && previewPdf) {
            let left = 135;
            let top = 133.5;

            if (autoFormData.formId === 17) {
                left = 132;
                top = 110.5;
            } else if (autoFormData.autoFormGenre === 'OUT') {
                left = 132;
                top = 110.5;
            }

            let sentNo = documentState.sentNo;
            if (sentNo == null) {
                setIsGetSentNumber(true);
                let specialChar = '';
                if (documentState.value.type === "W") {
                    specialChar = '/ว';
                }
                let params = {
                    registerName: registerName + specialChar,
                    documentType: documentState.value.type,
                    documentBy: documentState.documentBy,
                }
                let res = await mainApi.post('/api/v2/document-register/get-register-no', params).finally(() => setIsGetSentNumber(false));
                sentNo = res.data;
                dispatch(setDocumentSentNo(res.data));
            }

            signPdfRef.current.handleAddText(sentNo.registerNo, {
                fontFamily: 'THSarabunIT9',
                textAlign: 'left',
                left: left,
                top: top,
                id: 'registerNo',
            });
        } else {
            alertPdfNotFound();
        }
    }

    const handleAddDocumentDate = () => {
        if (!isSubmit && previewPdf) {
            let left = 390;
            let top = 133.5;

            if (autoFormData.formId === 17) {
                left = 333;
                top = 381.5;
            } else if (autoFormData.autoFormGenre === 'OUT') {
                left = 349;
                top = 151.5;
            }

            const documentDateFormat = moment(documentDate).format('YYYY-MM-DD');
            const documentDateResult = getDateFullMonthFormat(documentDateFormat);
            dispatch(setDocumentDateRedux(documentDateFormat));
            signPdfRef.current.handleAddText(documentDateResult, {
                fontFamily: 'THSarabunIT9',
                textAlign: 'left',
                left: left,
                top: top,
                id: 'documentDate',
            });
        } else {
            alertPdfNotFound();
        }
    }

    const handleAddUrgencyLevel = () => {
        if (!isSubmit && previewPdf) {
            let left = 170;
            let top = 67;

            if (autoFormData.formId === 17) {
                left = 124;
                top = 78;
            } else if (autoFormData.autoFormGenre === 'OUT') {
                left = 124;
                top = 78;
            }

            let text = "";
            if (isUrgencyDocument()) {
                text = urgencyLevel.find((item) => item.id.toString() === autoFormData.urgencyLevel.toString()).name;
            }

            signPdfRef.current.handleAddText(text, {
                fontSize: 32,
                fontFamily: 'THSarabun',
                fontWeight: 'bold',
                fillStyle: 'red',
                textAlign: 'left',
                left: left,
                top: top,
            });
        } else {
            alertPdfNotFound();
        }
    }

    const isUrgencyDocument = () => {
        return autoFormData.urgencyLevel && autoFormData.urgencyLevel.toString() !== '1';
    }

    const handleAddReceiveTextBox = async () => {
        if (!isSubmit && previewPdf) {
            let receiveNo = documentState.receiverNo;
            if (receiveNo == null) {
                setIsGetReceiveNumber(true);
                let documentType = 'R';
                if (['HR', 'HS'].includes(documentState.value.type)) {
                    documentType = 'HR';
                }
                let params = {
                    documentId: documentState.value.id,
                    documentType: documentType,
                    documentGenre: documentState.value.genre,
                    documentBy: documentState.documentBy,
                }
                let res = await mainApi.post('/api/rubber-stamp-receive/get-receive-number', params).finally(() => setIsGetReceiveNumber(false));
                receiveNo = res.data;
                dispatch(setDocumentReceiverNo(receiveNo));
            }
            signPdfRef.current.handleAddReceiveTextBox(customTextStampReceive, receiveNo);
            closeTextStampReceive();
        } else {
            alertPdfNotFound();
        }
    }

    const handleDeleteActiveObject = () => {
        if (!isSubmit && previewPdf) {
            signPdfRef.current.handleDeleteActiveObject();
        } else {
            alertPdfNotFound();
        }
    }

    const handleCheckboxRegisterNoChange = (e) => {
        const checked = e.target.checked;
        dispatch(setDigitalSignatureCheckSentNo(checked));
        if (checked) {
            handleAddRegisterNo();
        } else {
            signPdfRef.current.handleDeleteRegisterNo();
        }
    }

    const handleCheckboxDocumentDateChange = (e) => {
        const checked = e.target.checked;
        dispatch(setDigitalSignatureCheckDocumentDate(checked));
        if (checked) {
            handleAddDocumentDate();
        } else {
            dispatch(clearDocumentDate());
            signPdfRef.current.handleDeleteDocumentDate();
        }
    }

    const generateDocumentDateInput = () => {
        return (
            <DateInput
                name="documentDate"
                value={documentDate}
                onChange={(date) => setDocumentDate(date)}
                style={{width: '220px'}}
            />
        )
    }

    const alertPdfNotFound = () => {
        Swal.fire({
            icon: 'info',
            title: 'ไม่พบไฟล์ PDF',
            timer: config.get('alertTimer'),
            timerProgressBar: true,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
        });
    }

    const generateMenuSkeleton = (count = 1) => {
        return Array.from(Array(count).keys()).map((index) => (
            <div key={index}>
                <div className="col-12 text-center mb-2">
                    <Skeleton height={84} width={60}/>
                </div>
                <div className="col-12 text-center mb-2">
                    <Skeleton height={15} width={80}/>
                    <Skeleton height={15} width={120}/>
                </div>
            </div>
        ));
    }

    const generateSignPdfSkeleton = () => {
        return (
            <>
                <div className="m-4">
                    <div className="col-12 text-center">
                        <Skeleton height={30} width={400}/>
                    </div>
                </div>
                <div className="col-12 text-center">
                    <Skeleton height={841} width={595}/>
                </div>
            </>
        );
    }

    return (
        <>
            <Modal
                size="xl"
                show={modalShow}
                onHide={close}
                backdrop="static"
                keyboard={false}
                scrollable
            >
                <Modal.Header className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-file-signature mr-2"></i>การลงนาม
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body className="bg-light p-0">
                    <Container fluid>
                        <TopNav
                            onAddSign={handleAddSign}
                            onAddRedStamp={handleAddRedStamp}
                            onAddText={handleOpenTextModal}
                            onAddImageQR={handleAddImageQR}
                            onAddStamp={handleOpenTextStampModal}
                            onAddStampReceive={handleOpenTextStampReceiveModal}
                            onAddRegisterNo={handleAddRegisterNo}
                            onAddUrgencyLevel={handleAddUrgencyLevel}
                            isUrgencyDocument={isUrgencyDocument()}
                            onDeleteActiveObject={handleDeleteActiveObject}
                            isRefer={autoFormData.refer}
                            showStampReceive={showStampReceive}
                            isGetSentNumber={isGetSentNumber}
                            onCheckboxRegisterNoChange={handleCheckboxRegisterNoChange}
                            onCheckboxDocumentDateChange={handleCheckboxDocumentDateChange}
                            documentDateInput={generateDocumentDateInput()}
                            config={{
                                isDocumentAutoForm: autoFormData.formId,
                            }}
                        />
                    </Container>
                    <input
                        type="file"
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={handleFileImageQRChange}
                        ref={inputFileImageQRRef}
                    />
                    <hr className="m-0"/>
                    <Container fluid>
                        <Row>
                            <Col xs={2} className="border-right pt-2 pb-2">
                                {pdfList.length === 0 && isPdfLoading && generateMenuSkeleton(2)}
                                <MenuPdf
                                    pdfList={pdfList}
                                    previewPdf={previewPdf}
                                    onSelected={handlePreviewPdf}
                                />
                            </Col>
                            <Col xs={10}>
                                {!previewPdf && !isPdfLoading && <div className="m-4 text-center" style={{"height": "841px"}}><h5><b>ไม่พบไฟล์ PDF</b></h5></div>}
                                {!previewPdf && isPdfLoading && generateSignPdfSkeleton()}
                                {previewPdf &&
                                    <SignPdf
                                        pdfList={pdfList}
                                        pdf={previewPdf.base64Str}
                                        index={previewPdf.index}
                                        realFilename={previewPdf.realFilename}
                                        ref={signPdfRef}
                                    />
                                }
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-submit mr-2"
                        disabled={isSubmit || !previewPdf}
                        onClick={!isSubmit && previewPdf ? showSignatureCertificate : null}
                    >
                        {iconSubmitSignature || <i className="fas fa-paper-plane mr-2"></i>}
                        {labelSubmitSignature || "ส่งข้อมูลทะเบียนออกเลข"}
                        {isSubmit && (
                            <div
                                className="spinner-border spinner-border-sm ml-2"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </Button>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={close}
                    >
                        <i className="fas fa-ban mr-2"></i>ปิด
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={modalTextShow}
                onHide={closeText}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-file-signature mr-2"></i>ข้อความ
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="ข้อความ"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth
                                    value={customText}
                                    onChange={(e) => setCustomText(e.target.value)}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    <span>สีตัวอักษร</span>
                    <div style={stylesColorPicker.swatch} onClick={handleStateColorClick}>
                        <div style={stylesColorPicker.color}/>
                    </div>
                    {stateColor.displayColorPicker && (
                        <div style={stylesColorPicker.popover}>
                            <div style={stylesColorPicker.cover} onClick={handleStateColorClose}/>
                            <SketchPicker color={stateColor.color} onChange={handleStateColorChange}/>
                        </div>
                    )}
                    <Button
                        type="button"
                        className="btn-color-submit ml-3 mr-2"
                        onClick={handleAddText}
                    >
                        เพิ่มข้อความ
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={modalTextStampShow}
                onHide={closeTextStamp}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-file-signature mr-2"></i>ตรายาง
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {rubberStampList.length === 0 && (
                        <Container fluid>
                            <Row className="mb-2">
                                <Col className="text-center">
                                    ไม่พบข้อมูลตรายาง
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <Button
                                        type="button"
                                        className="btn"
                                        onClick={() => rubberStampDataModalRef.current.openModal()}
                                    >
                                        เพิ่มตรายาง
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    {rubberStampList.length > 0 && (
                        <Container fluid>
                            <Table responsive bordered>
                                <thead>
                                <tr>
                                    <th width="60px" align="center">เลือก</th>
                                    <th>ยศ</th>
                                    <th>คำนำหน้า</th>
                                    <th>ชื่อ</th>
                                    <th>นามสกุล</th>
                                    <th>ตำแหน่ง 1</th>
                                    <th>ตำแหน่ง 2</th>
                                    <th>ตำแหน่ง 3</th>
                                    <th>ตำแหน่ง 4</th>
                                    <th>ตำแหน่ง 5</th>
                                </tr>
                                </thead>
                                <tbody>
                                {rubberStampList.map((stamp, index) => (
                                    <tr key={index}>
                                        <td align="center">
                                            <Form.Check
                                                type="radio"
                                                name="stamp"
                                                checked={rubberStampChecked === stamp.id}
                                                onChange={() => {
                                                    setRubberStampChecked(stamp.id);

                                                    let textStamp = "(" + stamp.prefixName + stamp.firstName.trim() + " " + stamp.lastName.trim() + ")";
                                                    if (stamp.positionName1) textStamp += "\n" + stamp.positionName1.trim();
                                                    if (stamp.positionName2) textStamp += "\n" + stamp.positionName2.trim();
                                                    if (stamp.positionName3) textStamp += "\n" + stamp.positionName3.trim();
                                                    if (stamp.positionName4) textStamp += "\n" + stamp.positionName4.trim();
                                                    if (stamp.positionName5) textStamp += "\n" + stamp.positionName5.trim();
                                                    setCustomTextStamp(textStamp);
                                                    setRankNameTextStamp(stamp.rankName);
                                                }}
                                            />
                                        </td>
                                        <td>{stamp.rankName}</td>
                                        <td>{stamp.prefixName}</td>
                                        <td>{stamp.firstName}</td>
                                        <td>{stamp.lastName}</td>
                                        <td>{stamp.positionName1}</td>
                                        <td>{stamp.positionName2}</td>
                                        <td>{stamp.positionName3}</td>
                                        <td>{stamp.positionName4}</td>
                                        <td>{stamp.positionName5}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            <Row>
                                <Col>
                                    <TextField
                                        id="outlined-multiline-static"
                                        className="text-center"
                                        label="ข้อความตรายาง"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        fullWidth
                                        value={customTextStamp}
                                        onChange={(e) => setCustomTextStamp(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="rubberStampSignatureDateCheckbox"
                            checked={rubberStampSignatureDateChecked}
                            onChange={(e) => setRubberStampSignatureDateChecked(e.target.checked)}
                        />
                        <label className="form-check-label mr-2" htmlFor="rubberStampSignatureDateCheckbox">ประทับวันที่</label>
                    </div>
                    <DateInput
                        className="mr-4"
                        name="rubberStampSignatureDate"
                        value={rubberStampSignatureDate}
                        onChange={(date) => setRubberStampSignatureDate(date)}
                        style={{width: '185px'}}
                    />
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="rubberStampSignatureCheckbox"
                            checked={rubberStampSignatureChecked}
                            onChange={(e) => setRubberStampSignatureChecked(e.target.checked)}
                        />
                        <label className="form-check-label mr-4" htmlFor="rubberStampSignatureCheckbox">พร้อมลายเช็น</label>
                    </div>
                    <Button
                        type="button"
                        className="btn-color-submit mr-2"
                        disabled={rubberStampChecked.length === 0}
                        onClick={rubberStampChecked.length === 0 ? null : handleAddStamp}
                    >
                        เพิ่มตรายาง
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={modalTextStampReceiveShow}
                onHide={closeTextStampReceive}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-file-signature mr-2"></i>ตรายาง (หนังสือรับ)
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {rubberStampReceiveList.length === 0 && (
                        <Container fluid>
                            <Row>
                                <Col>
                                    ไม่พบข้อมูลตรายาง กรุณาเพิ่มตรายางที่ ตั้งค่าข้อมูล > ข้อมูลตรายาง (หนังสือรับ)
                                </Col>
                            </Row>
                        </Container>
                    )}
                    {rubberStampReceiveList.length > 0 && (
                        <Container fluid>
                            <Table responsive bordered>
                                <thead>
                                <tr>
                                    <th width="60px" align="center">เลือก</th>
                                    <th>ชื่อตราหนังสือรับ</th>
                                </tr>
                                </thead>
                                <tbody>
                                {rubberStampReceiveList.map((stamp, index) => (
                                    <tr key={index}>
                                        <td align="center">
                                            <Form.Check
                                                type="radio"
                                                name="stamp"
                                                checked={rubberStampReceiveChecked === stamp.stampId}
                                                onChange={() => {
                                                    setRubberStampReceiveChecked(stamp.stampId);
                                                    setCustomTextStampReceive(stamp.stampName);
                                                }}
                                            />
                                        </td>
                                        <td>{stamp.stampName}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Container>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-submit ml-3 mr-2"
                        disabled={rubberStampReceiveChecked.length === 0 || isGetReceiveNumber}
                        onClick={rubberStampReceiveChecked.length === 0 || isGetReceiveNumber ? null : handleAddReceiveTextBox}
                    >
                        เพิ่มตรายาง
                        {isGetReceiveNumber && (
                            <div
                                className="spinner-border spinner-border-sm ml-2"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                show={modalSignatureCertificateShow}
                onHide={closeSignatureCertificate}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-file-signature mr-2"></i>ใบรับรองลายเซ็น
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {signatureCertificate.dataList.length > 0 && (
                        <Container fluid>
                            <Table responsive bordered>
                                <thead>
                                <tr>
                                    <th width="60px" align="center">เลือก</th>
                                    <th width="130px" align="center">ประเภทใบรับรอง</th>
                                    <th align="center">ระดับ</th>
                                </tr>
                                </thead>
                                <tbody>
                                {signatureCertificate.dataList.map((cert, index) => (
                                    <tr key={index}>
                                        <td align="center">
                                            <Form.Check
                                                type="radio"
                                                name="stamp"
                                                checked={signatureCertificate.selected === cert}
                                                onChange={() => {
                                                    dispatch(setSignatureCertificateSelected(cert));
                                                }}
                                            />
                                        </td>
                                        <td align="center">{cert.certType}</td>
                                        <td>{cert.certName}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Container>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        type="button"
                        className="btn-color-submit ml-3 mr-2"
                        onClick={handleSubmit}
                    >
                        ยืนยัน
                    </Button>
                </Modal.Footer>
            </Modal>

            <RubberStampDataModal setRubberStampData={setRubberStampList} ref={rubberStampDataModalRef}/>
        </>
    )
});

export default DigitalSignature;
