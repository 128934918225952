import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import $ from "jquery";
import config from "react-global-configuration";
import { useKeycloak } from "@react-keycloak/web"
import { mainApi } from "../../HttpClient";
import Swal from "sweetalert2";
import Switch from "react-switch";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { rankList } from "../formauto/util/RankList";
import { prefixList } from "../formauto/util/PrefixList";
import CreatableSelect from "react-select/creatable";

const RubberStampData = () => {
    const userLogin = useSelector((state) => state.userLogin.value);
    const {register, handleSubmit, reset} = useForm();
    const [keycloak] = useKeycloak();
    const [saveLoading, setSaveLoading] = useState(false);
    const [rankName, setRankName] = useState([]);
    const [prefixName, setPrefixName] = useState([]);
    const [active, setActive] = useState('1');
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {

        const initDataTable = () => {
            $.fn.dataTable.ext.errMode = 'none';
            $('#data-table').DataTable({
                order: [0, 'asc'],
                ajax: {
                    contentType: 'application/json',
                    url: config.get('apiUrl') + '/api/rubber-stamp-data/data-table',
                    type: 'POST',
                    data: function (d) {
                        return JSON.stringify(d);
                    },
                    headers: {
                        Authorization: `bearer ${keycloak.token}`,
                    },
                },
                processing: true,
                serverSide: true,
                mark: true,
                columns: [
                    {
                        width: '40px', data: '', className: 'text-center',
                        render: function (data, type, row, meta) {
                            return meta.row + meta.settings._iDisplayStart + 1;
                        }
                    },
                    {data: 'rankName', className: 'text-left'},
                    {data: 'prefixName', className: 'text-left'},
                    {width: '100px', data: 'firstName', className: 'text-left'},
                    {width: '100px', data: 'lastName', className: 'text-left'},
                    {width: '200px', data: 'positionName1', className: 'text-left'},
                    {width: '200px', data: 'positionName2', className: 'text-left'},
                    {width: '200px', data: 'positionName3', className: 'text-left'},
                    {width: '200px', data: 'positionName4', className: 'text-left'},
                    {width: '200px', data: 'positionName5', className: 'text-left'},
                    {width: '75px', data: 'activeName', className: 'text-center'},
                    {
                        width: '100px',
                        className: 'text-center',
                        searchable: false,
                        orderable: false,
                        responsivePriority: 4,
                        targets: -3,
                        render: function (data, type, row) {
                            return `<i class="fas fa-edit mr-2"></i>`;
                        },
                    },
                ],
                rowCallback: function (row, data) {
                    $('td', row).on('click', (e) => {
                        e.preventDefault();
                        handleEditModal(data);
                    });

                    $('td', row).hover(function () {
                        $(this).css('cursor', 'pointer');
                    });
                },
                lengthChange: true,
                ordering: true,
                info: true,
                autoWidth: false,
                responsive: true,
                language: {
                    search: '_INPUT_',
                    searchPlaceholder: 'ค้นหา...',
                    lengthMenu: '_MENU_ รายการ/หน้า',
                    sLengthMenu: '_MENU_ รายการ/หน้า',
                    sProcessing: 'กำลังดำเนินการ...',
                    sZeroRecords: 'ไม่พบข้อมูล',
                    sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
                    sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
                    sInfoFiltered: '',
                    sSearch: '',
                    sUrl: '',
                    oPaginate: {
                        sFirst: 'หน้าแรก',
                        sPrevious: 'ก่อนหน้า',
                        sNext: 'ถัดไป',
                        sLast: 'หน้าสุดท้าย',
                    },
                    processing:
                        '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
                },
            });
        };

        const handleEditModal = (data) => {
            setModalShow(true);
            reset(data);

            let rankName = [];
            if (rankList.some((item) => item.label === data.rankName)) {
                rankName.push(rankList.find((item) => item.label === data.rankName));
            }else{
                rankName.push({label: data.rankName, value: data.rankName});
            }
            setRankName(rankName);

            let prefixName = [];
            if (prefixList.some((item) => item.label === data.prefixName)) {
                prefixName.push(prefixList.find((item) => item.label === data.prefixName));
            }else {
                prefixName.push({label: data.prefixName, value: data.prefixName});
            }
            setPrefixName(prefixName);
            setActive(data.active);
        };

        initDataTable();
    }, [keycloak.token, reset]);

    const handleSave = (data) => {
        setSaveLoading(true);
        let params = {
            id: data.id || 0,
            firstName: data.firstName,
            lastName: data.lastName,
            positionName1: data.positionName1,
            positionName2: data.positionName2,
            positionName3: data.positionName3,
            positionName4: data.positionName4,
            positionName5: data.positionName5,
            employeeId: userLogin.employee_id,
            active: active,
        }

        if (rankName.length > 0) {
            params = {...params, rankName: rankName[0].value}
        }

        if (prefixName.length > 0) {
            params = {...params, prefixName: prefixName[0].value}
        }

        mainApi.post(config.get('apiUrl') + '/api/rubber-stamp-data/save', params).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    title: 'บันทึกสำเร็จ',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ตกลง',
                }).then(() => {
                    handleCloseModal();
                    $('#data-table').DataTable().ajax.reload();
                });
            }
        }).finally(() => {
            setSaveLoading(false);
        });
    }

    const handleChangeActive = nextActive => {
        setActive(nextActive ? '1' : '0');
    };

    const handleShowModal = () => {
        setModalShow(true);
        reset({});
        setRankName('');
        setPrefixName('');
        setActive('1');
    };

    const handleCloseModal = () => {
        setModalShow(false);
        // reset({});
        // setRankName('');
        // setPrefixName('');
        // setActive('1');
    }

    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-12 content-top-padding"></div>
                        </div>
                        <div className="content-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="text-light-dark page-heading mt-2">
                                        ข้อมูลตรายางประทับ
                                    </h1>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                                        <li className="breadcrumb-item text-breadcrumb">
                                            <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                                            <Link to="./" className="text-breadcrumb">
                                                หน้าหลัก
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb">
                                            ตั้งค่าข้อมูล
                                        </li>
                                        <li className="breadcrumb-item text-breadcrumb-active">
                                            ข้อมูลตรายางประทับ
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <button onClick={handleShowModal} type="button" className="btn-color-add-lag  btn btn-success"><i className="fa fa-plus pr-2"></i>เพิ่มข้อมูลตรายาง
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-wrapper text-normal">
                                    <table
                                        id="data-table"
                                        className="table table-striped table-bordered display w-100 margin-bottom-30">
                                        <thead>
                                        <tr>
                                            <th className="text-center">ลำดับ</th>
                                            <th className="text-center">ยศ</th>
                                            <th className="text-center">คำนำหน้า</th>
                                            <th className="text-center">ชื่อ</th>
                                            <th className="text-center">นามสกุล</th>
                                            <th className="text-center">ตำแหน่งที่ 1</th>
                                            <th className="text-center">ตำแหน่งที่ 2</th>
                                            <th className="text-center">ตำแหน่งที่ 3</th>
                                            <th className="text-center">ตำแหน่งที่ 4</th>
                                            <th className="text-center">ตำแหน่งที่ 5</th>
                                            <th className="text-center">สถานะ</th>
                                            <th className="text-center">จัดการ</th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
            <Modal show={modalShow} onHide={handleCloseModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Form className="form-auto" onSubmit={handleSubmit(handleSave)}>
                    <Modal.Header closeButton className="bg-color">
                        <Modal.Title className="nav-text text-shadow-header">
                            <i className="fas fa-file-signature mr-2"></i>เพิ่มข้อมูลตรายาง
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-color-form text-center">
                        <Container fluid>
                            <div className="form-group row d-none">
                                <label className="col-sm-3 col-form-label">id</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" name="id" ref={register()}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ยศ</label>
                                <div className="col-sm-9">
                                    <CreatableSelect
                                        isClearable
                                        id="rank-name-typeahead-single"
                                        labelKey="name"
                                        className="selectInform"
                                        options={rankList}
                                        onChange={(selected) => {
                                            setRankName([selected])
                                        }}
                                        value={rankName}
                                        placeholder="เลือกยศ (ถ้ามี)"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">คำนำหน้า</label>
                                <div className="col-sm-9">
                                    <CreatableSelect
                                        isClearable
                                        id="rank-name-typeahead-single"
                                        labelKey="name"
                                        options={prefixList}
                                        onChange={(selected) => {
                                            setPrefixName([selected])
                                        }}
                                        placeholder="เลือกคำนำหน้า (ถ้ามี)"
                                        value={prefixName}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ชื่อ <span className="text-danger">*</span></label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="ชื่อ" name="firstName" ref={register({required: true})}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">นามสกุล <span className="text-danger">*</span></label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="นามสกุล" name="lastName" ref={register({required: true})}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ตำแหน่งที่ 1 <span className="text-danger">*</span></label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="ตำแหน่งที่ 1" name="positionName1" ref={register({required: true})}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ตำแหน่งที่ 2</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="ตำแหน่งที่ 2" name="positionName2" ref={register}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ตำแหน่งที่ 3</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="ตำแหน่งที่ 3" name="positionName3" ref={register}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ตำแหน่งที่ 4</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="ตำแหน่งที่ 4" name="positionName4" ref={register}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">ตำแหน่งที่ 5</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" placeholder="ตำแหน่งที่ 5" name="positionName5" ref={register}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">สถานะการใช้งาน <span className="text-danger">*</span></label>
                                <div className="col-md-9 d-flex">
                                    <Switch
                                        onChange={handleChangeActive}
                                        checked={active === '1'}
                                        className="react-switch"
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                    />
                                </div>
                            </div>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            type="submit"
                            className="btn-color-submit ml-3 mr-2"
                            disabled={saveLoading}
                        >
                            บันทึกตรายาง
                            {saveLoading && (
                                <div
                                    className="spinner-border spinner-border-sm ml-2"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            )}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
};


export default RubberStampData;