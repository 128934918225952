import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { fabric } from "fabric";
import { Button, Container } from 'react-bootstrap';
import { Document, Page } from "react-pdf";
import config from "react-global-configuration";
import Swal from "sweetalert2";

const HideControls = {
    'tl': true,
    'tr': true,
    'bl': true,
    'br': true,
    'ml': false,
    'mt': false,
    'mr': false,
    'mb': false,
    'mtr': false
};

export interface PdfListProps {
    attachmentId: number;
    base64Str: string;
    fileName: string;
    realFilename: string;
}

export interface PreSignProps {
    pdf: string;
    pdfList: PdfListProps[];
    index: number;
    realFilename: string;
    children: React.ReactNode;
}

const SignPdf = forwardRef((props: PreSignProps, ref) => {
    const userLogin = useSelector((state) => state.userLogin);

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [page, setPage] = useState({width: 0, height: 0});

    const [allCanvas, setAllCanvas] = useState({});
    const [pdfSignList, setPdfSignList] = useState([]);

    const clear = () => {
        setPdfSignList([]);
    };

    useImperativeHandle(ref, () => ({
        clear,
        pdfSignList,
        handleAddSignature,
        handleAddText,
        handleAddTextWithSignature,
        handleAddReceiveTextBox,
        handleSaveSignature,
        handleDeleteActiveObject,
    }));

    useEffect(() => {
        setPageNumber(1);
    }, [props.pdf]);

    const onDocumentLoadSuccess = (pdf) => {
        setNumPages(pdf.numPages);
        // setPageNumber(1);
    }

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    const onPageLoadSuccess = async (pdf) => {
        const pdfWidth = pdf.originalWidth;
        const pdfHeight = pdf.originalHeight;

        const id = `signature-${pageNumber}`;

        const canvasOption = {
            width: pdfWidth,
            height: pdfHeight,
        }

        if (!allCanvas[props.index] || !allCanvas[props.index][pageNumber]) {
            const canvas = new fabric.Canvas(id, canvasOption);

            // canvas.on('selection:created', function (e) {
            //     console.log(e);
            // });

            setAllCanvas({
                ...allCanvas,
                [props.index]: {
                    ...allCanvas[props.index],
                    "pages": numPages,
                    [pageNumber]: canvas
                }
            });
        } else {
            const objs = allCanvas[props.index][pageNumber].getObjects();
            allCanvas[props.index][pageNumber].initialize(id, canvasOption);
            objs.forEach(obj => {
                allCanvas[props.index][pageNumber].add(obj);
            });
        }

        setPage({width: pdfWidth, height: pdfHeight});
    }

    /**
     * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
     * images to fit into a certain area.
     *
     * @param {Number} srcWidth width of source image
     * @param {Number} srcHeight height of source image
     * @param {Number} maxWidth maximum available width
     * @param {Number} maxHeight maximum available height
     * @return {Object} { width, height }
     */
    function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
        return {width: srcWidth * ratio, height: srcHeight * ratio};
    }

    function toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    const handleAddSignature = () => {
        const signature = userLogin.signature;
        if (!signature) {
            Swal.fire({
                icon: 'error',
                title: 'ไม่พบข้อมูลลายเซ็น',
                text: 'กรุณาเพิ่มข้อมูลลายเซ็น ที่ระบบข้อมูลบุคลากร\nแล้วทำการเข้าระบบสารบรรณอิเล็กทรอนิกส์ใหม่',
            });
            return;
        }
        const imgSrc = config.get('apiHrUrl') + '/api/file/' + signature;
        const img = new Image();
        img.onload = async () => {
            const maxWidth = 85;
            const maxHeight = 35;
            const imgResize = calculateAspectRatioFit(img.width, img.height, maxWidth, maxHeight);
            const x = page.width / 2 - imgResize.width / 2;
            const y = page.height / 2 - imgResize.height / 2;

            const imgInstance = new fabric.Image(img, {
                left: x,
                top: y,
                width: img.width,
                height: img.height,
            });

            if (img.width > img.height) {
                imgInstance.scaleToWidth(maxWidth, false);
            } else {
                imgInstance.scaleToHeight(maxHeight, false);
            }

            imgInstance.setControlsVisibility(HideControls);
            allCanvas[props.index][pageNumber].add(imgInstance);
            allCanvas[props.index][pageNumber].setActiveObject(imgInstance);
        };

        toDataURL(imgSrc, function (dataUrl) {
            img.src = dataUrl;
        })
    }

    const handleAddText = (text, prop = {}) => {
        if (text.trim() !== '') {
            let textSplit = text.split('\n');
            let maxWidth = 0;
            let config = {
                fontSize: 16,
                fontFamily: 'THSarabun',
                textAlign: 'center',
                left: 0.5 * page.width,
                top: 0.5 * page.height,
                ...prop,
            };

            const c = document.createElement("canvas");
            const ctx = c.getContext("2d");

            ctx.font = `${config.fontSize}px ${config.fontFamily}`;
            ctx.textAlign = config.textAlign;
            let textHeight = parseInt(ctx.font.match(/\d+/), 10);
            let textY = 0;

            textSplit.forEach(text => {
                let textWidth = ctx.measureText(text.trim()).width;
                if (textWidth > maxWidth) {
                    maxWidth = textWidth;
                }
            });

            textSplit.forEach(text => {
                textY += textHeight + 1;
                let textX = 0;
                if (config.textAlign === 'center') {
                    textX = maxWidth / 2;
                }
                ctx.fillText(text.trim(), textX, textY);
            });

            let imgTextHeight = textY + 8;
            const imgInstance = new fabric.Image(c, {
                left: config.left - (maxWidth / 2),
                top: config.top - (imgTextHeight / 2),
                width: maxWidth,
                height: imgTextHeight,
            });

            imgInstance.setControlsVisibility(HideControls);
            allCanvas[props.index][pageNumber].add(imgInstance);
            allCanvas[props.index][pageNumber].setActiveObject(imgInstance);
        }
    }

    const handleAddTextWithSignature = (text) => {
        const signature = userLogin.signature;
        if (!signature) {
            Swal.fire({
                icon: 'error',
                title: 'ไม่พบข้อมูลลายเซ็น',
                text: 'กรุณาเพิ่มข้อมูลลายเซ็น ที่ระบบข้อมูลบุคลากร\nแล้วทำการเข้าระบบสารบรรณอิเล็กทรอนิกส์ใหม่',
            });
            return;
        }
        const imgSrc = config.get('apiHrUrl') + '/api/file/' + signature;
        const imgSign = new Image();
        imgSign.onload = async () => {
            const maxSignWidth = 85;
            const maxSignHeight = 35;
            const imgSignResize = calculateAspectRatioFit(imgSign.width, imgSign.height, maxSignWidth, maxSignHeight);
            const x = 0.5 * page.width;
            const y = 0.5 * page.height;
            const groupInstance = [];

            let textSplit = text.split('\n');
            let maxTextWidth = maxSignWidth;
            let config = {
                fontSize: 16,
                fontFamily: 'THSarabun',
                textAlign: 'center',
                left: 0,
                top: imgSignResize.height / 1.333333,
            };

            const c = document.createElement("canvas");
            const ctx = c.getContext("2d");

            ctx.font = `${config.fontSize}px ${config.fontFamily}`;
            ctx.textAlign = config.textAlign;
            let textHeight = parseInt(ctx.font.match(/\d+/), 10);

            textSplit.forEach(text => {
                let textWidth = ctx.measureText(text.trim()).width;
                if (textWidth > maxTextWidth) {
                    maxTextWidth = textWidth;
                }
            });

            const imgSignScaleX = imgSignResize.width / imgSign.width;
            const imgSignScaleY = imgSignResize.height / imgSign.height;
            const imgSignInstance = new fabric.Image(imgSign, {
                originX: 'center',
                top: 0,
                scaleX: imgSignScaleX,
                scaleY: imgSignScaleY,
            });
            groupInstance.push(imgSignInstance);

            let textY = config.top;
            textSplit.forEach(text => {
                textY += textHeight + 1;
                let textX = 0;
                if (config.textAlign === 'center') {
                    textX = maxTextWidth / 2;
                }
                ctx.fillText(text.trim(), textX, textY);
            });

            let imgTextHeight = textY + 8;
            const imgTextInstance = new fabric.Image(c, {
                originX: 'center',
                top: config.top,
                width: maxTextWidth,
                height: imgTextHeight,
            });
            groupInstance.push(imgTextInstance);

            const group = new fabric.Group(groupInstance, {
                left: x - (maxTextWidth / 2),
                top: y - (imgTextHeight / 2),
                controlsVisibility: HideControls,
            });

            group.setControlsVisibility(HideControls);
            allCanvas[props.index][pageNumber].add(group);
            allCanvas[props.index][pageNumber].setActiveObject(group);
        };

        toDataURL(imgSrc, function (dataUrl) {
            imgSign.src = dataUrl;
        })
    }

    const handleAddReceiveTextBox = () => {
        const textDept = "สำนักงานเลขานุการกรม";
        const color = "rgba(76,91,177,1)";
        let maxWidth = 140;

        const c = document.createElement("canvas");
        const ctx = c.getContext("2d");

        ctx.font = "17px THSarabun"
        ctx.textAlign = "center";
        ctx.color = "blue";
        ctx.fillStyle = color;

        let textWidth = ctx.measureText(textDept).width;
        let textY = 12;
        let textMargin = 8;
        let lineDottedWidth = 0.7;
        if (textWidth > maxWidth) {
            maxWidth = textWidth;
        }

        ctx.fillText(textDept, maxWidth / 2, textY);
        textY += 18;

        ctx.font = "14px THSarabun"
        ctx.textAlign = "left";

        ctx.beginPath();
        ctx.setLineDash([1, 1]);
        ctx.moveTo(31, textY);
        ctx.lineTo(maxWidth, textY);
        ctx.lineWidth = lineDottedWidth;
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.fillText("เลขที่รับ", 0, textY);
        textY += 17;

        ctx.beginPath();
        ctx.setLineDash([1, 1]);
        ctx.moveTo(18, textY);
        ctx.lineTo(maxWidth, textY);
        ctx.lineWidth = lineDottedWidth;
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.fillText("วันที่", 0, textY);
        textY += 17;

        ctx.beginPath();
        ctx.setLineDash([1, 1]);
        ctx.moveTo(18, textY);
        ctx.lineTo(maxWidth, textY);
        ctx.lineWidth = lineDottedWidth;
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.fillText("เวลา", 0, textY);

        const rectInstance = new fabric.Rect({
            left: 0,
            top: 0,
            width: maxWidth + (textMargin * 2),
            height: textY + (textMargin * 2),
            fill: 'rgba(0,0,0,0)',
            stroke: color,
            strokeWidth: 2,
            rx: 2,
            ry: 2,
            objectCaching: false,
        });

        rectInstance.on('scaling', function () {
            this.set({
                width: this.width * this.scaleX,
                height: this.height * this.scaleY,
                scaleX: 1,
                scaleY: 1
            });
        });

        const imgInstance = new fabric.Image(c, {
            left: textMargin,
            top: textMargin,
            width: maxWidth,
            height: textY + textMargin,
        });

        const HideControls = {
            'tl': false,
            'tr': false,
            'bl': false,
            'br': false,
            'ml': false,
            'mt': false,
            'mr': false,
            'mb': false,
            'mtr': false
        }

        const group = new fabric.Group([rectInstance, imgInstance], {
            left: 10,
            top: 10,
            controlsVisibility: HideControls,
        });

        group.setControlsVisibility(HideControls);
        allCanvas[props.index][pageNumber].add(group);
        allCanvas[props.index][pageNumber].setActiveObject(group);
    }

    const handleSaveSignature = () => {
        const result = [];

        props.pdfList.forEach((pdfListProps, index) => {
            let pdf = {...pdfListProps};
            const signPositionList = [];
            const pdfDoc = allCanvas[index];

            if (pdfDoc) {
                for (let i = 1; i <= pdfDoc.pages; i++) {
                    const canvas = pdfDoc[i];
                    if (canvas && canvas.getObjects().length > 0) {
                        let imgBase64 = canvas.toDataURL().split(',')[1];
                        signPositionList.push({
                            position_x: "0",
                            position_y: "0",
                            page: i,
                            width: `${canvas.width}`,
                            height: `${canvas.height}`,
                            img: imgBase64,
                        });
                    }
                }
            }

            if (signPositionList.length > 0) {
                pdf = {
                    ...pdf,
                    signPositionList: signPositionList,
                }
            }
            result.push(pdf);
        })

        setPdfSignList(result);

        return result;
    }

    const handleDeleteActiveObject = () => {
        const activeObjects = allCanvas[props.index][pageNumber].getActiveObjects();
        if (activeObjects.length > 0) {
            activeObjects.forEach((activeObject) => {
                allCanvas[props.index][pageNumber].remove(activeObject);
            });
        } else {
            Swal.fire({
                icon: 'info',
                title: 'กรุณาเลือกลายเซ็นหรือข้อความที่ต้องการลบ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
            });
        }
    }

    const generateCanvas = () => {
        let id = `signature-${pageNumber}`;
        return (
            <canvas id={id}/>
        );
    }

    return (
        <>
            <Container className="pdf-sign-viewer">
                <div className="row m-4">
                    <div className="col-12 text-center">
                        <h5><b>{props.realFilename.replaceAll("_", " ")}</b></h5>
                    </div>
                </div>
                <Document file={props.pdf} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page
                        width={page.width}
                        height={page.height}
                        pageNumber={pageNumber}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        onLoadSuccess={onPageLoadSuccess}>
                        <div className="canvas-signature-area">
                            {generateCanvas()}
                        </div>
                    </Page>
                </Document>
                <div className="row m-4">
                    <div className="col-12 text-center">
                        <Button type="button" disabled={pageNumber <= 1} onClick={previousPage}>‹</Button>
                        <span className="m-2">หน้า {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}</span>
                        <Button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>›</Button>
                    </div>
                </div>
            </Container>
        </>
    );
});

export default SignPdf;
