import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import isRole from './util/isRole';

const AllForm = () => {
  const checkVisibleUser = (preFrm) => {
    let result = '';
    let flag_check = false;
    const valid_prefrm = ['2', '3', '4', '9', '10', '14', '15'];

    valid_prefrm.forEach((item, index) => {
      if (item === preFrm) {
        flag_check = true;
        return false;
      }
    });

    // เฉพาะ user และไม่ใช่ฟอร์ม ส่งออกภายนอก
    if (isRole(['user']) && flag_check) {
      result = 'none';
    }

    return result;
  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>

            <div className="content-header">
              <div className="row">
                <div className="col-sm-12">
                  <h1 className="text-light-dark page-heading mt-2">
                    แบบฟอร์ม
                  </h1>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12">
                  <ol className="breadcrumb float-sm-left bg-transparent p-0">
                    <li className="breadcrumb-item text-breadcrumb">
                      <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                      <Link to="./" className="text-breadcrumb">
                        หน้าหลัก
                      </Link>
                    </li>
                    <li className="breadcrumb-item text-breadcrumb-active">
                      แบบฟอร์ม
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 p-2 text-normal">
                <i className="fas fa-clipboard-list"></i> วิทยากร <br />
                <hr />
              </div>
            </div>
            <div className="row">
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('1'),
                }}
              >
                <Link
                  to="./pre-form-1"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอเชิญเป็นวิทยากร (ภายใน)
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('2'),
                }}
              >
                <Link
                  to="./pre-form-2"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอเชิญเป็นวิทยากร (ภายนอก) - กรมควบคุมโรค
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('3'),
                }}
              >
                <Link
                  to="./pre-form-3"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอเชิญเป็นวิทยากร (ภายนอก) - หน่วยงานเทียบเท่ากอง
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('4'),
                }}
              >
                <Link
                  to="./pre-form-4"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอเชิญเป็นวิทยากร (ภายนอก)
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('12'),
                }}
              >
                <Link
                  to="./pre-form-12"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอความอนุเคราะห์วิทยากร (ภายใน) - กรมควบคุมโรค
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('13'),
                }}
              >
                <Link
                  to="./pre-form-13"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอความอนุเคราะห์วิทยากร (ภายใน) - หน่วยงานเทียบเท่ากอง
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('14'),
                }}
              >
                <Link
                  to="./pre-form-14"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอความอนุเคราะห์วิทยากร (ภายนอก) - กรมควบคุมโรค
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('15'),
                }}
              >
                <Link
                  to="./pre-form-15"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอความอนุเคราะห์วิทยากร (ภายนอก) - หน่วยงาน
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-12 p-2"> </div>
            </div>
            <div className="row">
              <div className="col-12 p-2 text-normal">
                <i className="fas fa-clipboard-list"></i> ประชุม <br />
                <hr />
              </div>
            </div>
            <div className="row">
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('5'),
                }}
              >
                <Link
                  to="./pre-form-5"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอเชิญประชุม (ภายใน) - กรมควบคุมโรค
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('6'),
                }}
              >
                <Link
                  to="./pre-form-6"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอเชิญประชุม (ภายใน) - หน่วยงานเทียบเท่ากอง
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('7'),
                }}
              >
                <Link
                  to="./pre-form-7"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอเชิญประชุม (ภายใน-เวียน) - กรมควบคุมโรค
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('8'),
                }}
              >
                <Link
                  to="./pre-form-8"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอเชิญประชุม (ภายใน-เวียน) - หน่วยงานเทียบเท่ากอง
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('9'),
                }}
              >
                <Link
                  to="./pre-form-9"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอเชิญประชุม (ภายนอก) - กรมควบคุมโรค
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('10'),
                }}
              >
                <Link
                  to="./pre-form-10"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอเชิญประชุม (ภายนอก) - หน่วยงาน
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className="col-12 col-sm-12 col-md-4 p-2"
                style={{
                  display: checkVisibleUser('11'),
                }}
              >
                <Link
                  to="./pre-form-11"
                  className="btn btn-ddc-menu-small btn-block elevation-3"
                >
                  <div className="row">
                    <div className="col-3 col-sm-3 col-md-2 p-2">
                      <i className="fas fa-book fa-2x"></i>
                    </div>
                    <div className="col-9 col-sm-9 col-md-10 p-2 text-left mt-1">
                      ขอความอนุเคราะห์ใช้ห้องประชุม
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default AllForm;
