import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import config from 'react-global-configuration';

import "datatables.net-dt/js/dataTables.dataTables.min"
import "datatables.net-bs4/js/dataTables.bootstrap4.min"
import "datatables.net-responsive/js/dataTables.responsive.min"

import $ from 'jquery';

const Documents = () => {

  const [keycloak] = useKeycloak();

  useEffect(() => {
    $.fn.dataTable.ext.errMode = 'none';

    $('#example').DataTable({
      ajax: {
        contentType: 'application/json',
        url : config.get('apiUrl')+'/api/v1/documents/list',
        type: 'POST',
        data: function(d) {
          return JSON.stringify(d);
        },
        headers: {
          Authorization: `bearer ${keycloak.token}`
        }
      },
      processing: true,
      serverSide: true,
      columns: [
        {data: 'documentId', className: 'text-center', responsivePriority: 1, targets: 0},
        {data: 'documentType', className: 'text-center'},
        {data: 'documentGenre', className: 'text-center'},
        {data: 'registerNo', className: 'text-center'},
        {data: 'documentDate', className: 'text-center', responsivePriority: 2, targets: -1}
      ],
      lengthChange: true,
      ordering: true,
      info: true,
      autoWidth: false,
      responsive: true,
      dom: '<"row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>><"datatable-wrap"t><"row"<"col-sm-12 col-md-5"i><"col-sm-12 col-md-7"p>>',
      language: {
        search: "_INPUT_",
        searchPlaceholder: 'ค้นหา...',
        lengthMenu: '_MENU_ รายการ/หน้า',
        sLengthMenu:   "_MENU_ รายการ/หน้า",
        sProcessing:   "กำลังดำเนินการ...",
        sZeroRecords:  "ไม่พบข้อมูล",
        sInfo:         "รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว",
        sInfoEmpty:    "แสดง 0 ถึง 0 จาก 0 แถว",
        sInfoFiltered: "",
        sInfoPostFix:  "",
        sSearch: "",
        sUrl: "",
        oPaginate: {
          sFirst:    "หน้าแรก",
          sPrevious: "ก่อนหน้า",
          sNext:     "ถัดไป",
          sLast:     "หน้าสุดท้าย"
        }
      }
    });

    // Datable Component CSS.
    document.querySelector('div.dataTables_length select').classList.add('datatable-length');
    document.querySelector('div.dataTables_filter input').classList.add('datatable-search');

  }, [keycloak.token]);

  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>

            <div className="content-header">
              <div className="row">
                <div className="col-sm-12">
                  <h1 className="text-light-dark page-heading mt-2">ทะเบียนหนังสือ</h1>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12">
                  <ol className="breadcrumb float-sm-left bg-transparent p-0">
                    <li className="breadcrumb-item text-breadcrumb">
                      <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                      <Link to="./" className="text-breadcrumb">หน้าหลัก</Link>
                    </li>
                    <li className="breadcrumb-item text-breadcrumb-active">ทะเบียนหนังสือ</li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="section-wrapper text-normal">
                  <table id="example" className="table table-striped table-bordered display w-100 margin-bottom-30" >
                    <thead>
                    <tr>
                      <th className="text-center">รหัส</th>
                      <th className="text-center">ประเภท</th>
                      <th className="text-center">ภายนอก/ภายใน</th>
                      <th className="text-center">เลขที่</th>
                      <th className="text-center">วันที่</th>
                    </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>

    </div>
  );
};

export default Documents;
