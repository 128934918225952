import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { mainApi } from '../../HttpClient';
import Highcharts from 'highcharts';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsExportData from 'highcharts/modules/export-data';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import _ from 'lodash';

import moment from 'moment';
import 'moment/locale/th';

highchartsExporting(Highcharts);
highchartsExportData(Highcharts);
highchartsAccessibility(Highcharts);

const ReportChart = () => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'column',
    },
    title: {
      text: 'จำนวนครั้งของการใช้งานระบบ',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      title: {
        text: 'จำนวนครั้ง',
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
      },
    },
    series: [
      {
        name: 'การใช้งานระบบ',
        data: [],
      },
    ],
  });

  const selectOptions = [
    { value: 'hour', label: 'ชั่วโมง' },
    { value: 'day', label: 'วัน' },
    { value: 'month', label: 'เดือน' },
    { value: 'year', label: 'ปี' },
  ];
  const [selectType, setSelectType] = useState(selectOptions[1]);

  useEffect(() => {
    const setHighchartReact = (data) => {
      let categories = [];
      let datas = [];
      let dt = moment();
      dt.locale('th');

      if (selectType.value === 'hour') {
        for (let hour = 0; hour < 24; hour++) {
          dt.set('hour', hour);
          let count = _.find(data, { category: dt.format('HH') });
          datas.push(count ? parseInt(count.value) : 0);
          categories.push(dt.format('HH:00'));
        }
      } else if (selectType.value === 'day') {
        let decDay = 7;
        dt.add(-decDay, 'day');
        for (let i = 0; i < decDay; i++) {
          dt.add(1, 'day');
          let count = _.find(data, { category: dt.format('YYYY-MM-DD') });
          datas.push(count ? parseInt(count.value) : 0);
          categories.push(dt.format('DD MMM'));
        }
      } else if (selectType.value === 'month') {
        for (let month = 0; month < 12; month++) {
          dt.set('month', month);
          let count = _.find(data, { category: dt.format('MM') });
          datas.push(count ? parseInt(count.value) : 0);
          categories.push(dt.format('MMM'));
        }
      } else if (selectType.value === 'year') {
        let decYear = 5;
        dt.add(-decYear, 'year');
        for (let i = 0; i < decYear; i++) {
          dt.add(1, 'year');
          let count = _.find(data, { category: dt.format('YYYY') });
          datas.push(count ? parseInt(count.value) : 0);
          categories.push(dt.format('YYYY'));
        }
      }

      setChartOptions({
        subtitle: {
          text: 'ราย' + selectType.label,
        },
        xAxis: {
          categories,
        },
        series: [
          {
            data: datas,
          },
        ],
      });
    };

    const fetchHighchartReact = async () => {
      const response = await mainApi.get(
        '/api/highcharts/react/' + selectType.value
      );
      setHighchartReact(response.data);
    };

    fetchHighchartReact();
  }, [selectType]);

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: '100vh' }}>
        <section className="content">
          <Container fluid>
            <div className="row">
              <div className="col-12 content-top-padding"></div>
            </div>

            <div className="content-header">
              <div className="row">
                <div className="col-sm-12">
                  <h1 className="text-light-dark page-heading mt-2">
                    รายงานจำนวนครั้งของการใช้งานระบบ
                  </h1>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12">
                  <ol className="breadcrumb float-sm-left bg-transparent p-0">
                    <li className="breadcrumb-item text-breadcrumb">
                      <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                      <Link to="./" className="text-breadcrumb">
                        หน้าหลัก
                      </Link>
                    </li>
                    <li className="breadcrumb-item text-breadcrumb">
                      <Link to="./report" className="text-breadcrumb">
                        รายงาน
                      </Link>
                    </li>
                    <li className="breadcrumb-item text-breadcrumb-active">
                      รายงานจำนวนครั้งของการใช้งานระบบ
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="section-wrapper text-normal">
                  <div className="form-row">
                    <div className="col-sm-1 pt-2">
                      <label>ราย</label>
                    </div>
                    <div className="col-sm-11">
                      <Select
                        defaultValue={selectOptions[1]}
                        options={selectOptions}
                        onChange={(ValueType) => setSelectType(ValueType)}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default ReportChart;
