/* eslint-disable */
import React, {useState, useEffect, useRef} from 'react';
import {Button, Container, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import $ from "jquery";
import {useForm} from "react-hook-form";
import {useKeycloak} from "@react-keycloak/web";
import config from "react-global-configuration";
import { getDateFormat, getDateThaiShortMonthFormat, getDateTimeFormat, getYearThaiShortFormat, isDuedate, isOverDueDate } from "../util/Date";
import Swal from "sweetalert2";
import {mainApi} from "../../HttpClient";
import HistoryBox from "../history/HistoryBox";
import DateInput from "../share/DateInput";
import UploadFiles from "../share/UploadFiles";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {Checkbox} from "@material-ui/core";
import OrgEmpList from "../share/OrgEmpList";
import fileDownload from "js-file-download";
import moment from "moment";
import { useSelector } from "react-redux";

const ArchiveDocument = () => {

  const color2 = '#ffd43b';
  const color3 = '#fd7e14';
  const color4 = '#e03131';

  const { register, handleSubmit, reset  } = useForm();
  const {
    register: registerEdit,
    handleSubmit: handleSubmitEdit,
    errors: errorsEdit,
    setValue: setValueEdit,
  } = useForm();
  const [keycloak] = useKeycloak();
  const [documentDate, setDocumentDate] = useState(new Date());
  const [documentEditDate, setDocumentEditDate] = useState(new Date());
  const [documentEditDuedate, setDocumentEditDuedate] = useState(new Date());
  const [dataProcess, setDataProcess] = useState([]);
  const [documentDetail, setDocumentDetail] = useState({});
  const [documentReferDetail, setDocumentReferDetail] = useState({});
  const [tableSelectData, setTableSelectData] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const [modalSentShow, setModalSentShow] = useState(false);
  const handleModalSentClose = () => setModalSentShow(false);
  const handleModalSentShow = () => setModalSentShow(true);
  const [modalEditShow, setModalEditShow] = useState(false);
  const handleModalEditClose = () => setModalEditShow(false);
  const handleModalEditShow = () => setModalEditShow(true);
  const [modalReferShow, setModalReferShow] = useState(false);
  const handleModalReferClose = () => setModalReferShow(false);
  const handleModalReferShow = () => setModalReferShow(true);
  const [modalCommandShow, setModalCommandShow] = useState(false);
  const handleModalCommandClose = () => setModalCommandShow(false);
  const [modalActionShow, setModalActionShow] = useState(false);
  const handleModalActionClose = () => setModalActionShow(false);
  const [modalAttachFileShow, setModalAttachFileShow] = useState(false);
  const handleModalAttachFileClose = () => setModalAttachFileShow(false);
  const [documentProcess, setDocumentProcess] = useState({});
  const [fileList, setFileList] = useState([]);

  const [isReferLoading, setIsReferLoading] = useState(false);
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const orgEmpListRef = useRef();
  const historyBoxRef = useRef();
  const [referSelections, setReferSelections] = useState([]);
  const [referOptions, setReferOptions] = useState([]);
  const [listUrgencyLevel, setListUrgencyLevel] = useState([]);
  const [listSecretLevel, setListSecretLevel] = useState([]);
  const [isCheckedDueDate, setIsCheckedDueDate] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [organizeId, setOrganizeId] = useState('');
  const [selectUrgencyLevel, setSelectUrgencyLevel] = useState('');
  const [read, setRead] = useState(false);
  const [listYear, setlistYear] = useState([]);

  const [selectYear, setSelectYear] = useState('');
  const [selectPageType, setSelectPageType] = useState('');

  const documentState = useSelector((state) => state.document);

  useEffect(() => {
    const getYear = () => {
      let tmpYear = [];
      let start = moment('2021-01-01');
      const cur_year = moment();
      for (
          let year = moment(cur_year);
          year.isAfter(start);
          year.add(-1, 'year')
      ) {
        year.format('YYYY');
        addYear(year, tmpYear);
      }
      setlistYear(tmpYear);
    };
    getYear();
  }, [keycloak]);

  //Search.
  const handleSearch = () => {

    $('#data-table').DataTable().clear();
    $('#data-table').DataTable().destroy();

    if(selectYear === ''){
      Swal.fire({
        icon: 'error',
        title: 'ข้อมูลไม่ครบ',
        text: 'กรุณาเลือกปี',
      });
    }else if (selectPageType === ''){
      Swal.fire({
        icon: 'error',
        title: 'ข้อมูลไม่ครบ',
        text: 'กรุณาเลือกประเภทหนังสือ',
      });
    }
    
    if(selectPageType === ''){
      setInitialDataTable();
    }else{
      setInitialDataTableAjax();
    }

  };

  const addYear = (currentYr, currentArr) => {
    currentArr.push({
      label: getYearThaiShortFormat(currentYr),
      value: currentYr.format('YYYY'),
    });
  };

  const setInitialDataTable = async () => {

    $('#data-table').DataTable({
      order: [0, 'desc'],
      data: [],
      mark: true,
      columnDefs: [{ width: '100px', targets: 1 }],
      columns: [
        { data: 'documentDate', visible: false },
        { data: 'receiveNo', className: 'text-center', width: '7%' },
        {
          data: 'registerNo',
          className: 'text-center',
          responsivePriority: 1,
          targets: 0,
          width: '10%',
          render: function (data, type, row) {
            let duedate = '';
            if (
                isDuedate(row.documentDuedate) &&
                row.progressStatus !== '4' &&
                row.progressStatus !== '5' &&
                !isOverDueDate(row.documentDuedate)
            ) {
              duedate = `<div class="blink_txt" style="color: ${color4};"><small>แจ้งเตือน 
              ${getDateThaiShortMonthFormat(
                  row.documentDuedate,
                  true
              )}</small></div>`;
            }

            let attachment = '';
            if (row.countAttachment > 0) {
              attachment = `<i class="fas fa-paperclip text-danger mr-1"></i>`;
            }

            let urgencyLevel = '';
            if (row.urgencyLevel === 2) {
              urgencyLevel = `<small class="badge badge-warning mr-1" style="background-color: ${color2};">ด่วน</small>`;
            } else if (row.urgencyLevel === 3) {
              urgencyLevel = `<small class="badge badge-danger mr-1" style="background-color: ${color3};">ด่วนมาก</small>`;
            } else if (row.urgencyLevel === 4) {
              urgencyLevel = `<small class="badge badge-danger mr-1" style="background-color: ${color4};">ด่วนที่สุด</small>`;
            }

            let secretLevel = '';
            if (row.secretLevel === 2) {
              secretLevel = `<small class="badge badge-warning" style="background-color: ${color2};">ลับ</small>`;
            } else if (row.secretLevel === 3) {
              secretLevel = `<small class="badge badge-danger" style="background-color: ${color3};">ลับมาก</small>`;
            } else if (row.secretLevel === 4) {
              secretLevel = `<small class="badge badge-danger" style="background-color: ${color4};">ลับที่สุด</small>`;
            }

            if (duedate || attachment || urgencyLevel || secretLevel) {
              return (
                  data +
                  '<br/>' +
                  attachment +
                  urgencyLevel +
                  secretLevel +
                  duedate
              );
            }
            return data;
          },
        },
        {
          data: 'documentDate',
          width: '80px',
          className: 'text-center',
          orderable: false,
          render: function (data, type, row) {
            return getDateFormat(data);
          },
        },
        {
          className: 'text-center',
          searchable: false,
          orderable: false,
          visible: false,
          render: function (data, type, row) {
            let PageType = '';
            let documentGenre = '';
            if (row.PageType === 'S') {
              PageType = 'หนังสือส่ง';
            } else if (row.PageType === 'W') {
              PageType = 'หนังสือเวียน';
            } else if (row.PageType === 'R') {
              PageType = 'หนังสือรับ';
            }
            if (row.documentGenre === 'IN') {
              documentGenre = ' (ภายใน)';
            } else if (row.documentGenre === 'OUT') {
              documentGenre = ' (ภายนอก)';
            }
            return PageType + documentGenre;
          },
        },
        { data: 'sender', className: 'text-left', width: '10%' },
        // { data: 'processGroup', className: 'text-left', width: '10%' },
        { data: 'receiver', className: 'text-left', width: '10%' },
        {
          data: 'owner',
          className: 'text-left',
          width: '10%',
          render: function (data, type, row) {
            return data + ' ' + (row.ownerTelephone || '');
          },
        },
        {
          data: 'subject',
          className: 'text-left',
          width: '25%',
          responsivePriority: 2,
          targets: -1,
          render: function (data, type, row) {
            if (row.status === '5') {
              return `<a class="show-truncate link-red" href="javascript:void(0)">${data}</a>`;
            } else {
              return `<a class="show-truncate" href="javascript:void(0)">${data}</a>`;
            }
          },
        },
        { data: 'command', className: 'text-center', visible: false },
        {
          data: 'sentDate',
          orderable: false,
          width: '100px',
          className: 'text-center',
          render: function (data, type, row) {
            return getDateTimeFormat(data);
          },
        },
        {
          data: 'receiveDate',
          width: '70px',
          className: 'text-center',
          visible: selectPageType.includes('-inbox') ? true : false,
          render: function (data, type, row) {
            return getDateTimeFormat(data);
          },
        },
        {
          data: 'receiveDateSentBox',
          width: '70px',
          className: 'text-center',
          visible: selectPageType.includes('-outbox') || selectPageType.includes('-circular') ? true : false,
          render: function (data, type, row) {
            return getDateTimeFormat(data);
          },
        },
        {
          data: 'progressStatus',
          className: 'text-center',
          searchable: false,
          orderable: false,
          visible: false,
          render: function (data, type, row) {
            if (data) {
              return `<h6><span class="badge ${getStatusColor(data)}">${
                  row.progressStatusName
              }</span></h6>`;
            }
            return '';
          },
        },
        {
          data: 'categoryId',
          className: 'text-center',
          responsivePriority: 3,
          targets: -2,
          render: function (data, type, row) {
            let name = row.categoryName || 'ไม่มีหมวด';
            let color = row.categoryColor || '';
            let desYear = row.destructionYear || 1;
            return `<input class="btn-category-color" style="background-color: #${color}; text-align: center;" data-toggle="tooltip" data-placement="top" title="${name}" value="${desYear}" readonly/>`;
          },
        },
        {
          data: 'documentGenre',
          width: '70px',
          className: 'text-center',
          render: function (data, type, row) {
            let docTypeBadge = '';
            if (row.documentGenre === 'IN') {
              docTypeBadge = ' <span class="badge badge-pill badge-success"> ภายใน</span>';
            } else if (row.documentGenre === 'OUT') {
              docTypeBadge = ' <span class="badge badge-pill badge-dark"> ภายนอก</span>';
            }
            return docTypeBadge;
          },
        },
      ],
      lengthChange: true,
      ordering: true,
      info: true,
      autoWidth: false,
      responsive: true,
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'ค้นหา...',
        lengthMenu: '_MENU_ รายการ/หน้า',
        sLengthMenu: '_MENU_ รายการ/หน้า',
        sProcessing: 'กำลังดำเนินการ...',
        sZeroRecords: 'ไม่พบข้อมูล',
        sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
        sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
        sInfoFiltered: '',
        sSearch: '',
        sUrl: '',
        oPaginate: {
          sFirst: 'หน้าแรก',
          sPrevious: 'ก่อนหน้า',
          sNext: 'ถัดไป',
          sLast: 'หน้าสุดท้าย',
        },
        processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
      },
    });
  }

  const setInitialDataTableAjax = async () => {
    const { id: documentById } = documentState.documentBy;

    let conditionUrl = '';
    if (selectPageType.includes('-inbox')) {
      conditionUrl = '&receiverId=' + documentById;
    } else if (selectPageType.includes('-outbox') || selectPageType.includes('-circular')) {
      conditionUrl = '&senderId=' + documentById;
    }

    $('#data-table').DataTable({
      searchDelay: 5000,
      order: [0, 'desc'],
      ajax: {
        contentType: 'application/json',
        url:
            config.get('apiUrl') +
            '/api/v3/documents?pageName=' + selectPageType + conditionUrl +
            '&filterYear=' + selectYear +
            '&documentById=' + documentById,
        type: 'POST',
        data: function (d) {
          return JSON.stringify(d);
        },
        headers: {
          Authorization: `bearer ${keycloak.token}`,
        },
      },
      processing: true,
      serverSide: true,
      mark: true,
      columnDefs: [{ width: '100px', targets: 1 }],
      columns: [
        { data: 'documentDate', visible: false },
        {
          title: selectPageType.includes('-inbox') ? 'เลขรับ' : 'เลขส่ง',
          data: selectPageType.includes('-inbox') ? 'receiveNo' : 'sentNo',
          className: 'text-center',
          width: '7%' },
        {
          data: 'registerNo',
          className: 'text-center',
          responsivePriority: 1,
          targets: 0,
          width: '10%',
          render: function (data, type, row) {
            let duedate = '';
            if (
                isDuedate(row.documentDuedate) &&
                row.progressStatus !== '4' &&
                row.progressStatus !== '5' &&
                !isOverDueDate(row.documentDuedate)
            ) {
              duedate = `<div class="blink_txt" style="color: ${color4};"><small>แจ้งเตือน 
              ${getDateThaiShortMonthFormat(
                  row.documentDuedate,
                  true
              )}</small></div>`;
            }

            let attachment = '';
            if (row.countAttachment > 0) {
              attachment = `<i class="fas fa-paperclip text-danger mr-1"></i>`;
            }

            let urgencyLevel = '';
            if (row.urgencyLevel === 2) {
              urgencyLevel = `<small class="badge badge-warning mr-1" style="background-color: ${color2};">ด่วน</small>`;
            } else if (row.urgencyLevel === 3) {
              urgencyLevel = `<small class="badge badge-danger mr-1" style="background-color: ${color3};">ด่วนมาก</small>`;
            } else if (row.urgencyLevel === 4) {
              urgencyLevel = `<small class="badge badge-danger mr-1" style="background-color: ${color4};">ด่วนที่สุด</small>`;
            }

            let secretLevel = '';
            if (row.secretLevel === 2) {
              secretLevel = `<small class="badge badge-warning" style="background-color: ${color2};">ลับ</small>`;
            } else if (row.secretLevel === 3) {
              secretLevel = `<small class="badge badge-danger" style="background-color: ${color3};">ลับมาก</small>`;
            } else if (row.secretLevel === 4) {
              secretLevel = `<small class="badge badge-danger" style="background-color: ${color4};">ลับที่สุด</small>`;
            }

            if (duedate || attachment || urgencyLevel || secretLevel) {
              return (
                  data +
                  '<br/>' +
                  attachment +
                  urgencyLevel +
                  secretLevel +
                  duedate
              );
            }
            return data;
          },
        },
        {
          data: 'documentDate',
          width: '80px',
          className: 'text-center',
          orderable: false,
          render: function (data, type, row) {
            return getDateFormat(data);
          },
        },
        {
          className: 'text-center',
          searchable: false,
          orderable: false,
          visible: false,
          render: function (data, type, row) {
            let PageType = '';
            let documentGenre = '';
            if (row.PageType === 'S') {
              PageType = 'หนังสือส่ง';
            } else if (row.PageType === 'W') {
              PageType = 'หนังสือเวียน';
            } else if (row.PageType === 'R') {
              PageType = 'หนังสือรับ';
            }
            if (row.documentGenre === 'IN') {
              documentGenre = ' (ภายใน)';
            } else if (row.documentGenre === 'OUT') {
              documentGenre = ' (ภายนอก)';
            }
            return PageType + documentGenre;
          },
        },
        { data: 'sender', className: 'text-left', width: '10%' },
        { data: 'receiver', className: 'text-left', width: '10%' },
        {
          data: 'owner',
          className: 'text-left',
          width: '10%',
          render: function (data, type, row) {
            return data + ' ' + (row.ownerTelephone || '');
          },
        },
        {
          data: 'subject',
          className: 'text-left',
          width: '25%',
          responsivePriority: 2,
          targets: -1,
          render: function (data, type, row) {
            if (row.status === '5') {
              return `<a class="show-truncate link-red" href="javascript:void(0)">${data}</a>`;
            } else {
              return `<a class="show-truncate" href="javascript:void(0)">${data}</a>`;
            }
          },
        },
        { data: 'command', className: 'text-center', visible: false },
        {
          data: 'sentDate',
          orderable: false,
          width: '100px',
          className: 'text-center',
          render: function (data, type, row) {
            return getDateTimeFormat(data);
          },
        },
        {
          data: 'receiveDate',
          width: '70px',
          className: 'text-center',
          visible: selectPageType.includes('-inbox') ? true : false,
          render: function (data, type, row) {
            return getDateTimeFormat(data);
          },
        },
        {
          data: 'receiveDateSentBox',
          width: '70px',
          className: 'text-center',
          visible: selectPageType.includes('-outbox') || selectPageType.includes('-circular') ? true : false,
          render: function (data, type, row) {
            return getDateTimeFormat(data);
          },
        },
        {
          data: 'progressStatus',
          className: 'text-center',
          searchable: false,
          orderable: false,
          visible: false,
          render: function (data, type, row) {
            if (data) {
              return `<h6><span class="badge ${getStatusColor(data)}">${
                  row.progressStatusName
              }</span></h6>`;
            }
            return '';
          },
        },
        {
          data: 'categoryId',
          className: 'text-center',
          responsivePriority: 3,
          targets: -2,
          visible: selectPageType.includes('-outbox') ? false : true,
          render: function (data, type, row) {
            let name = row.categoryName || 'ไม่มีหมวด';
            let color = row.categoryColor || '';
            let desYear = row.destructionYear || 1;
            return `<input class="btn-category-color" style="background-color: #${color}; cursor: default; text-align: center;" data-toggle="tooltip" data-placement="top" title="${name}" value="${desYear}" readonly/>`;
          },
        },
        {
          data: 'documentGenre',
          width: '70px',
          className: 'text-center',
          render: function (data, type, row) {
            let docTypeBadge = '';
            if (row.documentGenre === 'IN') {
              docTypeBadge = ' <span class="badge badge-pill badge-success"> ภายใน</span>';
            } else if (row.documentGenre === 'OUT') {
              docTypeBadge = ' <span class="badge badge-pill badge-dark"> ภายนอก</span>';
            }
            return docTypeBadge;
          },
        },
      ],
      rowCallback: function (row, data) {
        $('td', row)
            .eq(6)
            .attr('style', 'cursor: pointer;')
            .on('click', function (e) {
              fetchDocumentProcessById(data);
            });

        $('td', row)
            .find('.btn-category-color')
            .on('click', function (e) {
              setTableSelectData(data);
              // handleModalCategoryShow();
            });

        $('td', row)
            .find('.btn-return-document')
            .on('click', function (e) {
              handleReturnDocument(data.documentId);
            });

        $('td', row)
            .find('.btn-close-document')
            .on('click', function (e) {
              handleCloseDocument(data.documentId);
            });

        $('td', row)
            .find('.btn-cancel-document')
            .on('click', function (e) {
              handleCancelDocument(data.documentId);
            });
      },
      lengthChange: true,
      ordering: true,
      info: true,
      autoWidth: false,
      responsive: true,
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'ค้นหา...',
        lengthMenu: '_MENU_ รายการ/หน้า',
        sLengthMenu: '_MENU_ รายการ/หน้า',
        sProcessing: 'กำลังดำเนินการ...',
        sZeroRecords: 'ไม่พบข้อมูล',
        sInfo: 'รายการ _START_-_END_ ทั้งหมด _TOTAL_ แถว',
        sInfoEmpty: 'แสดง 0 ถึง 0 จาก 0 แถว',
        sInfoFiltered: '',
        sSearch: '',
        sUrl: '',
        oPaginate: {
          sFirst: 'หน้าแรก',
          sPrevious: 'ก่อนหน้า',
          sNext: 'ถัดไป',
          sLast: 'หน้าสุดท้าย',
        },
        processing:
            '<i class="fa fa-spinner fa-spin fa-3x fa-fw text-primary"></i><span class="sr-only">Loading...</span> ',
      },
    });
  }

  const getStatusColor = (statusId) => {
    if (statusId === '0') {
      return 'badge-warning';
    } else if (statusId === '1') {
      return 'badge-warning';
    } else if (statusId === '2') {
      return 'badge-primary';
    } else if (statusId === '3') {
      return 'badge-danger';
    } else if (statusId === '4') {
      return 'badge-success';
    } else if (statusId === '5') {
      return 'badge-danger';
    } else if (statusId === '6') {
      return 'badge-warning';
    }
    return '';
  };

  const fetchDocumentProcessById = async (tableData) => {
    // setClearData(tableData);
    setTableSelectData(tableData);
    setDocumentDate(tableData.documentDate);
    handleModalShow();

    const response = await mainApi.get(
        `/api/v5/document-process/document-id/${tableData.documentId}`
    );

    if (historyBoxRef.current && historyBoxRef.current.dataProps.length === 0) {
      setDataProcess(response.data);
      historyBoxRef.current.setDataProps(response.data);
    }
  };

  const setClearData = (tableData) => {
    if (
        tableData.documentId !== tableSelectData.documentId &&
        tableData.processId !== tableSelectData.processId
    ) {
      reset(tableData);
    }
    // setFileList([]);
    orgEmpListRef.current.clear();
  };

  const handlePrint = async () => {
    setIsPrintLoading(true);
    await mainApi
        .get('/api/report/excel/02/' + tableSelectData.documentId, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(res.data, 'รายงานประวัติเอกสาร.xlsx');
          setIsPrintLoading(false);
        });
  };

  const handleReferSearch = async (query) => {
    setIsReferLoading(true);

    let params = {
      registerNo: query,
    };

    let rs = await mainApi.post('/api/v1/documents/search/register_no', params);

    if (rs.status === 200) {
      setReferOptions(rs.data);
      setIsReferLoading(false);
    } else {
      setIsReferLoading(false);
    }
  };

  const handleDocumentEdit = async (formData) => {
    let params = new FormData();
    params.append('documentId', documentDetail.documentId);
    params.append('documentType', documentDetail.documentType);
    params.append(
        'documentGenre',
        documentDetail.documentGenre === '--'
            ? 'IN'
            : documentDetail.documentGenre
    );
    params.append('registerNo', formData.registerNo || '');
    params.append(
        'documentDate',
        moment(documentEditDate).format('YYYY-MM-DD')
    );
    params.append('urgencyLevel', formData.urgencyLevel);
    params.append('secretLevel', formData.secretLevel);
    params.append('subject', formData.subject || '');
    params.append('senderId', documentState.documentBy.id);
    params.append('sender', documentState.documentBy.name);
    params.append('sentNo', documentDetail.sentNo);
    params.append('receiverId', documentDetail.receiverId || '');
    params.append('receiver', formData.receiver || '');
    params.append('owner', formData.owner || '');
    params.append('ownerTelephone', formData.ownerTelephone || '');
    params.append('remark', formData.remark || '');
    params.append('command', formData.command || '');
    params.append('status', documentDetail.documentStatus);
    // params.append('parentId', documentDetail.processId);
    // params.append(
    //   'documentDuedate',
    //   moment(documentEditDuedate).format('YYYY-MM-DD')
    // );
    if (isCheckedDueDate === true) {
      params.append(
          'documentDuedate',
          moment(documentEditDuedate).format('YYYY-MM-DD')
      );
    } else {
      params.append('documentDuedate', '');
    }
    fileList.forEach((file) => {
      params.append('files', file);
    });
    referSelections.forEach((refer) => {
      params.append('refer', refer.documentId);
    });

    const response = await mainApi.post('/api/book/save', params);

    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        title: 'สำเร็จ',
        text: 'บันทึกสำเร็จ',
        timer: config.get('alertTimer'),
        timerProgressBar: true,
      });
      $('#data-table').DataTable().ajax.reload();
      handleModalEditClose();
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ผิดพลาด',
        text: 'บันทึกไม่สำเร็จ',
      });
    }
  };

  const handleSendSave = async (formData, e) => {
    setIsSend(true);
    let params = new FormData();
    params.append('documentId', tableSelectData.documentId);
    params.append('documentType', tableSelectData.documentType);
    params.append('documentGenre', tableSelectData.documentGenre);
    params.append('registerNo', formData.registerNo || '');
    params.append('documentDate', documentDate);
    params.append('urgencyLevel', tableSelectData.urgencyLevel);
    params.append('secretLevel', tableSelectData.secretLevel);
    params.append('subject', formData.subject || '');
    params.append('senderId', documentState.documentBy.id);
    params.append('sender', documentState.documentBy.name);
    params.append('sentNo', tableSelectData.sentNo || '');
    params.append('receiverId', formData.receiverId || '');
    params.append('receiver', formData.receiver || '');
    params.append('owner', formData.owner || '');
    params.append('ownerTelephone', formData.ownerTelephone || '');
    params.append('refer', tableSelectData.refer || '');
    params.append('remark', tableSelectData.remark || '');
    params.append('command', formData.commandSend || formData.command || '');
    params.append('status', 1);
    // params.append('parentId', tableSelectData.processId);
    fileList.forEach((file) => {
      params.append('files', file);
    });

    if (orgEmpListRef.current.selectList.length > 0) {
      orgEmpListRef.current.selectList.forEach((value) => {
        params.append('insideOrg', value);
      });
    } else {
      orgEmpListRef.current.orgSelectList.forEach((value) => {
        params.append('insideOrg', value);
      });
    }

    let rsSave = await mainApi.post('/api/book/save', params);

    if (rsSave.status === 200) {
      Swal.fire({
        icon: 'success',
        title: 'สำเร็จ',
        text: 'ส่งหนังสือสำเร็จ',
        timer: config.get('alertTimer'),
        timerProgressBar: true,
      });
      setIsSend(false);
      $('#data-table').DataTable().ajax.reload();
      handleModalSentClose();
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ผิดพลาด',
        text: 'ส่งหนังสือไม่สำเร็จ',
      });
    }
    setIsSend(false);
  };

  useEffect( () => {
    $.fn.dataTable.ext.errMode = 'none';

    setInitialDataTable();





    // Datable Component CSS.
    document
        .querySelector('div.dataTables_length select')
        .classList.add('datatable-length');
    document
        .querySelector('div.dataTables_filter input')
        .classList.add('datatable-search');
  }, [keycloak, reset, tableSelectData]);

  return (
      <div>
        <div
            className="content-wrapper text-normal"
            style={{ minHeight: '100vh' }}
        >
          <section className="content">
            <Container fluid>
              <div className="row">
                <div className="col-12 content-top-padding"></div>
              </div>
              <div className="content-header">
                <div className="row">
                  <div className="col-sm-12">
                    <h1 className="text-light-dark page-heading mt-2">
                      ประวัติหนังสือ
                    </h1>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <ol className="breadcrumb float-sm-left bg-transparent p-0">
                      <li className="breadcrumb-item text-breadcrumb">
                        <i className="nav-icon fas fa-home sidebar-fa-size-header pr-2 margin-top-8-force"></i>
                        <Link to="./" className="text-breadcrumb">
                          หน้าหลัก
                        </Link>
                      </li>
                      <li className="breadcrumb-item text-breadcrumb-active">
                        ประวัติหนังสือ
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="table-header-MasOrganization card-header text-dark">
                      ค้นหาประวัติหนังสือ
                    </div>
                    <div className="card-body">
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group row">
                          <div className="col-12 col-md-3 pt-2">
                          <span className="text-color-form align-middle">
                            ปี
                          </span><span className="text-danger"> *</span>
                          </div>

                          <div className="col-12 col-md-4 pt-1">
                            <select
                                className="form-control shadow-none"
                                id="selectYear"
                                name="selectYear"
                                ref={register}
                                defaultValue={''}
                                onChange={(e) => {
                                  setSelectYear(e.target.value);
                                }}
                            >
                              <option value="" tag="">
                                กรุณาเลือก
                              </option>
                              {listYear.map((item) => (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-12 col-md-3 pt-2">
                          <span className="text-color-form align-middle">
                            ประเภทหนังสือ
                          </span><span className="text-danger"> *</span>
                          </div>

                          <div className="col-12 col-md-4 pt-1">
                            <select
                                className="form-control shadow-none"
                                id="selectPageType"
                                name="selectPageType"
                                ref={register}
                                defaultValue={''}
                                onChange={(e) => {
                                  setSelectPageType(e.target.value);
                                }}
                            >
                              <option value="" tag="">
                                กรุณาเลือก
                              </option>
                              <option value="archive-circular" tag="หนังสือเวียน">หนังสือเวียน</option>
                              <option value="archive-inbox" tag="หนังสือรับ">หนังสือรับ</option>
                              <option value="archive-outbox" tag="หนังสือส่ง">หนังสือส่ง</option>
                              <option value="archive-hidden-inbox" tag="หนังสือรับ (ลับ)">หนังสือรับ (ลับ)</option>
                              <option value="archive-hidden-outbox" tag="หนังสือส่ง (ลับ)">หนังสือส่ง (ลับ)</option>
                            </select>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer">
                      <div className="row">
                        <div className="col-sm-12 col-md-2 pt-2">
                          <Button
                              type="submit"
                              id="btn-search-organization"
                              className="btn-color-submit btn-block btn-primary"
                              onClick={handleSearch}
                          >
                            <i className="fas fa-search pr-2"></i>ค้นหา
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="section-wrapper text-normal">
                    <table
                        id="data-table"
                        className="table table-striped table-bordered display w-100 margin-bottom-30"
                    >
                      <thead>
                      <tr>
                        <th className="text-center">ลงวันที่</th>
                        <th className="text-center">เลขรับ</th>
                        <th className="text-center">เลขที่</th>
                        <th className="text-center">ลงวันที่</th>
                        <th className="text-center">ประเภทหนังสือ</th>
                        <th className="text-center">จาก</th>
                        <th className="text-center">เรียน</th>
                        <th className="text-center">เจ้าของเรื่อง</th>
                        <th className="text-center">เรื่อง</th>
                        <th className="text-center">คำสั่งการ</th>
                        <th className="text-center">วันที่ส่ง</th>
                        <th className="text-center" alt="วันที่รับของหนังสือรับ">วันที่รับ</th>
                        <th className="text-center" alt="วันที่รับของหนังสือส่ง">วันที่รับ</th>
                        <th className="text-center">สถานะ</th>
                        <th className="text-center">หมวด</th>
                        <th className="text-center">ประเภท</th>
                      </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </Container>
          </section>
        </div>
        <Modal size="xl" show={modalShow} onHide={handleModalClose}>
          <Modal.Header closeButton className="bg-color">
            <Modal.Title className="nav-text text-shadow-header">
              <i className="fas fa-book mr-2"></i>รายละเอียดเอกสาร
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <div className="row">
                <div className="col-md-12">
                  <div className="section-wrapper text-normal">
                    {tableSelectData.receiveNo && (
                        <div className="row mt-1">
                          <div className="col-12 col-md-3 ">
                            <label className="text-color-form align-middle">
                              เลขทะเบียนรับ
                            </label>
                          </div>
                          <div className="col-12 col-md-9 ">
                            {tableSelectData.receiveNo}
                          </div>
                        </div>
                    )}
                    {tableSelectData.oldNo && (
                        <div className="row mt-1">
                          <div className="col-12 col-md-3 ">
                            <label className="text-color-form align-middle">
                              เลขเดิม
                            </label>
                          </div>
                          <div className="col-12 col-md-9 ">
                            {tableSelectData.oldNo}
                          </div>
                        </div>
                    )}
                    {tableSelectData.registerNo && (
                        <div className="row mt-1">
                          <div className="col-12 col-md-3 ">
                            <label className="text-color-form align-middle">
                              เลขที่
                            </label>
                          </div>
                          <div className="col-12 col-md-9 text-normal">
                            {tableSelectData.registerNo}
                          </div>
                        </div>
                    )}
                    <div className="row mt-1">
                      <div className="col-12 col-md-3">
                        <label className="text-color-form align-middle">
                          ชัันความเร็ว
                        </label>
                      </div>
                      <div className="col-12 col-md-9 text-normal">
                        {tableSelectData.urgencyLevelName}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-12 col-md-3">
                        <label className="text-color-form align-middle">
                          เรื่อง
                        </label>
                      </div>
                      <div className="col-12 col-md-9 text-normal show-truncate">
                        {tableSelectData.subject}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-12 col-md-3 ">
                        <label className="text-color-form align-middle">
                          เรียน
                        </label>
                      </div>
                      <div className="col-12 col-md-9 text-normal">
                        {tableSelectData.receiver}
                      </div>
                    </div>
                    {tableSelectData.refer && (
                        <div className="row mt-1">
                          <div className="col-12 col-md-3 ">
                            <label className="text-color-form align-middle">
                              อ้างถึงหนังสือ
                            </label>
                          </div>
                          <div className="col-12 col-md-9 text-normal">
                            <a href="#section" onClick={handleReferClick}>
                              {tableSelectData.referName}
                            </a>
                          </div>
                        </div>
                    )}
                    {tableSelectData.owner && (
                        <div className="row mt-1">
                          <div className="col-12 col-md-3">
                            <label className="text-color-form align-middle">
                              เจ้าของเรื่อง
                            </label>
                          </div>
                          <div className="col-12 col-md-9 text-normal">
                            {tableSelectData.owner}
                          </div>
                        </div>
                    )}
                    {tableSelectData.ownerTelephone && (
                        <div className="row mt-1">
                          <div className="col-12 col-md-3">
                            <label className="text-color-form align-middle">
                              เบอร์โทรศัพท์
                            </label>
                          </div>
                          <div className="col-12 col-md-9 text-normal">
                            {tableSelectData.ownerTelephone}
                          </div>
                        </div>
                    )}
                    {tableSelectData.command && (
                        <div className="row mt-1">
                          <div className="col-12 col-md-3 ">
                            <label className="text-color-form align-middle">
                              คำสั่งการ (รายละเอียด)
                            </label>
                          </div>
                          <div className="col-12 col-md-9 text-normal">
                            {tableSelectData.command}
                          </div>
                        </div>
                    )}
                    {tableSelectData.remark && (
                        <div className="row mt-1">
                          <div className="col-12 col-md-3 ">
                            <label className="text-color-form align-middle">
                              หมายเหตุ
                            </label>
                          </div>
                          <div className="col-12 col-md-9 text-normal">
                            {tableSelectData.remark}
                          </div>
                        </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8 text-header-x2 ddc-color">
                  <i className="fas fa-paper-plane mr-2"></i>ถึงหน่วยงาน
                </div>
                {/* <div className="d-none d-lg-block col-4 text-header-x2 ddc-color">
                วันที่
              </div> */}
              </div>
              <HistoryBox organizeId={organizeId} viewOnly={true} ref={historyBoxRef} />
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
                type="button"
                className="btn-color-add-small "
                disabled={isPrintLoading}
                onClick={!isPrintLoading ? handlePrint : null}
            >
              <i className="fas fa-print pr-2"></i>
              ดาวน์โหลดประวัติเอกสาร
              {isPrintLoading && (
                  <div
                      className="spinner-border spinner-border-sm ml-2"
                      role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
              )}
            </Button>
            <Button
                type="button"
                className="btn-color-delete-small"
                onClick={handleModalClose}
            >
              <i className="fas fa-ban pr-2"></i>ยกเลิก
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={modalSentShow} onHide={handleModalSentClose}>
          <Modal.Header closeButton className="bg-color">
            <Modal.Title className="nav-text text-shadow-header">
              <i className="fas fa-paper-plane mr-2"></i>ส่งต่อหน่วยงานอื่น
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <div className="row">
                <div className="col-12 col-md-3 pt-2">
                  <label className="text-color-form align-middle">ที่</label>
                </div>
                <div className="col-12 col-md-9 pt-1">
                  <input
                      className="form-control shadow-none"
                      type="text"
                      name="registerNo"
                      ref={register}
                      readOnly
                  />
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-12 col-md-3 pt-2">
                  <label className="text-color-form align-middle">ลงวันที่</label>
                </div>
                <div className="col-12 col-md-9 pt-1">
                  <DateInput
                      value={documentDate}
                      onChange={(date) => setDocumentDate(date)}
                      readOnly
                      disabled
                  />
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-12 col-md-3 pt-2">
                  <label className="text-color-form align-middle">เรื่อง</label>
                </div>
                <div className="col-12 col-md-9 pt-1">
                  <input
                      className="form-control shadow-none"
                      type="text"
                      name="subject"
                      ref={register}
                      readOnly
                  />
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-12 col-md-3 pt-2">
                  <label className="text-color-form align-middle">เรียน</label>
                </div>
                <div className="col-12 col-md-9 pt-1">
                  <input
                      className="form-control shadow-none d-none"
                      type="text"
                      name="receiverId"
                      ref={register}
                  />
                  <input
                      className="form-control shadow-none"
                      type="text"
                      name="receiver"
                      ref={register}
                      readOnly
                  />
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-12 col-md-3 pt-2">
                  <label className="text-color-form align-middle">
                    เจ้าของเรื่อง
                  </label>
                </div>
                <div className="col-12 col-md-9 pt-1">
                  <input
                      className="form-control shadow-none"
                      type="text"
                      name="owner"
                      ref={register}
                      readOnly
                  />
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-12 col-md-3 pt-2">
                  <label className="text-color-form align-middle">
                    เบอร์โทรศัพท์
                  </label>
                </div>
                <div className="col-12 col-md-9 pt-1">
                  <input
                      className="form-control shadow-none"
                      type="text"
                      name="ownerTelephone"
                      ref={register}
                      readOnly
                  />
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-12 col-md-3 pt-2">
                  <label className="text-color-form align-middle">
                    คำสั่งการ (รายละเอียด)
                  </label>
                </div>
                <div className="col-12 col-md-9 pt-1">
                <textarea
                    className="form-control shadow-none"
                    rows="5"
                    name="commandSend"
                    ref={register({ max: 255 })}
                ></textarea>
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-12 col-md-12 pt-1">
                  <UploadFiles fileList={fileList} setFileList={setFileList} />
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
                type="button"
                className="btn-color-submit mr-2"
                disabled={isSend}
                onClick={!isSend ? () => {
                  if (!isSend) {
                    orgEmpListRef.current.clear();
                    orgEmpListRef.current.show();
                  }
                } : null}
            >
              <i className="fas fa-paper-plane mr-2"></i>เลือกหน่วยงาน
              {isSend && (
                  <div
                      className="spinner-border spinner-border-sm ml-2"
                      role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
              )}
            </Button>
            <Button
                type="button"
                className="btn-color-delete-small"
                onClick={handleModalSentClose}
            >
              <i className="fas fa-ban mr-2"></i>ยกเลิก
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal size="xl" show={modalEditShow} onHide={handleModalEditClose}>
          <Modal.Header closeButton className="bg-color">
            <Modal.Title className="nav-text text-shadow-header">
              <i className="fas fa-edit mr-2"></i>แก้ไขเอกสาร
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-3 col-lg-2">
                <span className="text-color-form align-middle">ที่</span>
              </div>
              <div className="col-12 col-md-3 text-normal">
                <input
                    className="form-control shadow-none"
                    type="text"
                    name="registerNo"
                    ref={registerEdit}
                    defaultValue={documentDetail.registerNo}
                    // readOnly
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-3 col-lg-2 pt-2">
                <span className="text-color-form align-middle">ลงวันที่</span>
              </div>
              <div className="col-12 col-md-3 pt-1">
                <DateInput
                    value={documentEditDate}
                    onChange={(date) => setDocumentEditDate(date)}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-3 col-lg-2 d-none">
                <span className="text-color-form align-middle">ความลับ</span>
              </div>
              <div className="col-12 col-md-3 text-normal d-none">
                <select
                    className="form-control shadow-none"
                    name="secretLevel"
                    ref={registerEdit}
                    defaultValue={documentDetail.secretLevel}
                    onChange={(e) =>  {setSelectUrgencyLevel(e.target.value)}}
                    // disabledหนังสือลับ
                >
                  {listSecretLevel.map((item) => {
                    return (
                        <option
                            key={item.secret_level_id}
                            value={item.secret_level_id}
                        >
                          {item.name}
                        </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-12 col-md-3 col-lg-2">
                <span className="text-color-form align-middle">ชั้นความเร็ว</span>
              </div>
              <div className="col-12 col-md-3 text-normal">
                <select
                    className="form-control shadow-none"
                    name="urgencyLevel"
                    ref={registerEdit}
                    defaultValue={documentDetail.urgencyLevel}
                    // disabled
                >
                  {listUrgencyLevel.map((item) => {
                    return (
                        <option
                            key={item.urgency_level_id}
                            value={item.urgency_level_id}
                        >
                          {item.name}
                        </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-3 col-lg-2">
                <span className="text-color-form align-middle">เรื่อง</span>
                <span className="text-danger"> *</span>
              </div>
              <div className="col-12 col-md-9 col-lg-10 text-normal">
                <input
                    className="form-control shadow-none"
                    type="text"
                    name="subject"
                    ref={registerEdit({ required: true })}
                    defaultValue={documentDetail.subject}
                    readOnly={read}
                    // onChange={e => setInputSub(e.target.value)}
                    // onChange={e => setDocumentDetail({...documentDetail, subject: e.target.value})}
                    // value={documentDetail.subject}

                />
                {errorsEdit.subject && (
                    <span className="text-danger">กรุณากรอกข้อมูล</span>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-3 col-lg-2">
                <span className="text-color-form align-middle">เรียน</span>
                <span className="text-danger"> *</span>
              </div>
              <div className="col-12 col-md-9 col-lg-10 text-normal">
                <input
                    className="form-control shadow-none"
                    type="text"
                    name="receiver"
                    ref={registerEdit({ required: true })}
                    defaultValue={documentDetail.receiver}
                />
                {errorsEdit.receiver && (
                    <span className="text-danger">กรุณากรอกข้อมูล</span>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-3 col-lg-2">
              <span className="text-color-form align-middle">
                อ้างถึงหนังสือ (ถ้ามี)
              </span>
              </div>
              <div className="col-12 col-md-9 col-lg-10 text-normal">
                <AsyncTypeahead
                    filterBy={() => true}
                    id="basic-typeahead-single"
                    isLoading={isReferLoading}
                    labelKey="registerNo"
                    onChange={setReferSelections}
                    options={referOptions}
                    placeholder="กรุณาพิมพ์เลขที่หนังสือ..."
                    selected={referSelections}
                    minLength={2}
                    onSearch={handleReferSearch}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-3 col-lg-2">
              <span className="text-color-form align-middle">
                เจ้าของเรื่อง
              </span>
              </div>
              <div className="col-12 col-md-9 col-lg-4 text-normal">
                <input
                    className="form-control shadow-none"
                    type="text"
                    name="owner"
                    ref={registerEdit}
                    defaultValue={documentDetail.owner}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-2 pt-1 text-normal text-center">
              <span className="text-color-form align-middle">
                เบอร์โทรศัพท์
              </span>
              </div>
              <div className="col-12 col-md-9 col-lg-4 text-normal">
                <input
                    className="form-control shadow-none"
                    type="text"
                    name="ownerTelephone"
                    ref={registerEdit({
                      maxLength: {
                        value: 50,
                        message: 'ข้อมูลต้องไม่เกิน 50 ตัวอักษร',
                      },
                    })}
                    defaultValue={documentDetail.ownerTelephone}
                />
                {errorsEdit.ownerTelephone && (
                    <span className="text-danger">
                  {errorsEdit.ownerTelephone.message}
                </span>
                )}
              </div>
            </div>
            <div className="row mt-3" style={{display: "none"}}>
              <div className="col-12 col-md-3 col-lg-2">
              <span className="text-color-form align-middle">
                คำสั่งการ (รายละเอียด)
              </span>
              </div>
              <div className="col-12 col-md-9 col-lg-10 text-normal">
              <textarea
                  className="form-control shadow-none"
                  rows="4"
                  name="command"
                  ref={registerEdit({ max: 255 })}
                  defaultValue={documentDetail.command}
              ></textarea>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-3 col-lg-2">
                <span className="text-color-form align-middle">หมายเหตุ</span>
              </div>
              <div className="col-12 col-md-9 col-lg-10 text-normal">
              <textarea
                  className="form-control shadow-none"
                  rows="4"
                  name="remark"
                  ref={registerEdit({ max: 255 })}
                  defaultValue={documentDetail.remark}
              ></textarea>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-3 col-lg-2">
              <span className="text-color-form align-middle">
                วันที่กำหนดเอกสาร
              </span>
              </div>
              <div className="col-12 col-md-9">
                <div className="">
                  <Checkbox
                      // defaultChecked
                      checked={isCheckedDueDate}
                      color="secondary"
                      onChange={(event) => {
                        setIsCheckedDueDate(event.target.checked);
                      }}
                      name="checkboxDueDate"
                  />
                  <DateInput
                      value={documentEditDuedate}
                      onChange={(date) => setDocumentEditDuedate(date)}
                      disabled={!isCheckedDueDate}
                      readOnly={!isCheckedDueDate}
                      className="ml-50"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
                type="submit"
                variant="contained"
                className="btn-no-border btn-color-submit mr-2"
                onClick={handleSubmitEdit(handleDocumentEdit)}
            >
              <i className="fas fa-save pr-2"></i>บันทึก
            </Button>
            <Button
                type="submit"
                variant="contained"
                className="btn-no-border btn-color-delete-small"
                onClick={handleModalEditClose}
            >
              <i className="fas fa-ban pr-2"></i>
              ยกเลิก
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal size="lg" show={modalReferShow} onHide={handleModalReferClose}>
          <Modal.Header closeButton className="bg-color">
            <Modal.Title className="nav-text text-shadow-header">
              <i className="fas fa-book mr-2"></i>รายละเอียดเอกสารอ้างอิง
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <div className="row">
                <div className="col-md-12">
                  <div className="section-wrapper text-normal">
                    {documentReferDetail.receiveNo && (
                        <div className="row mt-1">
                          <div className="col-12 col-md-3 ">
                            <label className="text-color-form align-middle">
                              เลขทะเบียนรับ
                            </label>
                          </div>
                          <div className="col-12 col-md-9 ">
                            {documentReferDetail.receiveNo}
                          </div>
                        </div>
                    )}
                    {documentReferDetail.oldNo && (
                        <div className="row mt-1">
                          <div className="col-12 col-md-3 ">
                            <label className="text-color-form align-middle">
                              เลขเดิม
                            </label>
                          </div>
                          <div className="col-12 col-md-9 ">
                            {documentReferDetail.oldNo}
                          </div>
                        </div>
                    )}
                    {documentReferDetail.registerNo && (
                        <div className="row mt-1">
                          <div className="col-12 col-md-3 ">
                            <label className="text-color-form align-middle">
                              เลขที่
                            </label>
                          </div>
                          <div className="col-12 col-md-9 text-normal">
                            {documentReferDetail.registerNo}
                          </div>
                        </div>
                    )}
                    <div className="row mt-1">
                      <div className="col-12 col-md-3">
                        <label className="text-color-form align-middle">
                          ชัันความเร็ว
                        </label>
                      </div>
                      <div className="col-12 col-md-9 text-normal">
                        {documentReferDetail.urgencyLevelName}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-12 col-md-3">
                        <label className="text-color-form align-middle">
                          เรื่อง
                        </label>
                      </div>
                      <div className="col-12 col-md-9 text-normal">
                        {documentReferDetail.subject}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-12 col-md-3 ">
                        <label className="text-color-form align-middle">
                          เรียน
                        </label>
                      </div>
                      <div className="col-12 col-md-9 text-normal">
                        {documentReferDetail.receiver}
                      </div>
                    </div>
                    {documentReferDetail.refer && (
                        <div className="row mt-1">
                          <div className="col-12 col-md-3 ">
                            <label className="text-color-form align-middle">
                              อ้างถึงหนังสือ
                            </label>
                          </div>
                          <div className="col-12 col-md-9 text-normal">
                            {documentReferDetail.referName}
                          </div>
                        </div>
                    )}
                    <div className="row mt-1">
                      <div className="col-12 col-md-3">
                        <label className="text-color-form align-middle">
                          เจ้าของเรื่อง
                        </label>
                      </div>
                      <div className="col-12 col-md-9 text-normal">
                        {documentReferDetail.owner}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-12 col-md-3 ">
                        <label className="text-color-form align-middle">
                          คำสั่งการ (รายละเอียด)
                        </label>
                      </div>
                      <div className="col-12 col-md-9 text-normal">
                        {documentReferDetail.command}
                      </div>
                    </div>
                    {documentReferDetail.remark && (
                        <div className="row mt-1">
                          <div className="col-12 col-md-3 ">
                            <label className="text-color-form align-middle">
                              หมายเหตุ
                            </label>
                          </div>
                          <div className="col-12 col-md-9 text-normal">
                            {documentReferDetail.remark}
                          </div>
                        </div>
                    )}
                  </div>
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
                type="button"
                className="btn-color-delete-small"
                onClick={() => {
                  handleModalReferClose();
                  handleModalShow();
                }}
            >
              <i className="fas fa-undo-alt pr-2"></i>ย้อนกลับ
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={modalCommandShow} onHide={handleModalCommandClose}>
          <Modal.Header closeButton className="bg-color">
            <Modal.Title className="nav-text text-shadow-header">
              <i className="fas fa-paper-plane mr-2"></i>เพิ่มคำสั่งการ
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <span className="text-header-x2 ddc-color">คำสั่งการ :</span>
              <div className="row mt-3">
                <div className="col">
                <textarea
                    rows="3"
                    style={{ width: '100%' }}
                    value={documentProcess.command || ''}
                    onChange={(e) => {
                      setDocumentProcess({
                        ...documentProcess,
                        command: e.target.value,
                      });
                    }}
                ></textarea>
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
                type="button"
                className="btn-color-add-small"
                onClick={() => {
                  handleModalCommandClose();
                  handleUpdateCommand();
                }}
            >
              <i className="far fa-save pr-2"></i>บันทึก
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={modalActionShow} onHide={handleModalActionClose}>
          <Modal.Header closeButton className="bg-color">
            <Modal.Title className="nav-text text-shadow-header">
              <i className="fas fa-paper-plane mr-2"></i>เพิ่มการปฏิบัติ
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <span className="text-header-x2 ddc-color">การปฏิบัติ :</span>
              <div className="row mt-3">
                <div className="col">
                <textarea
                    rows="3"
                    style={{ width: '100%' }}
                    value={documentProcess.action || ''}
                    onChange={(e) => {
                      setDocumentProcess({
                        ...documentProcess,
                        action: e.target.value,
                      });
                    }}
                ></textarea>
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
                type="button"
                className="btn-color-add-small"
                onClick={() => {
                  handleModalActionClose();
                  handleUpdateAction();
                }}
            >
              <i className="far fa-save pr-2"></i>บันทึก
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={modalAttachFileShow} onHide={handleModalAttachFileClose}>
          <Modal.Header closeButton className="bg-color">
            <Modal.Title className="nav-text text-shadow-header">
              <i className="fas fa-paper-plane mr-2"></i>เพิ่มแนบไฟล์
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <UploadFiles fileList={fileList} setFileList={setFileList} />
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
                type="button"
                className="btn-color-add-small"
                onClick={() => {
                  handleModalAttachFileClose();
                  handleAddAttachFile();
                }}
            >
              <i className="far fa-save pr-2"></i>บันทึก
            </Button>
          </Modal.Footer>
        </Modal>

        <OrgEmpList onAccept={handleSubmit(handleSendSave)} ref={orgEmpListRef} />
      </div>
  );
};

export default ArchiveDocument;
