import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button, Card, Col, Container, Form, InputGroup, Modal, Nav, Row, Tab } from "react-bootstrap";
import { useForm } from "react-hook-form";
import FroalaEditor from "../share/FroalaEditor";
import UploadFiles from "../share/UploadFiles";
import DateInput from "../share/DateInput";
import { unit } from "./util/Unit";
import DownloadFile from "../share/DownloadFile";
import Swal from "sweetalert2";
import { mainApi } from "../../HttpClient";
import config from "react-global-configuration";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { urgencyLevel } from "./util/UrgencyLevel";
import OrgEmpListAuto from '../share/OrgEmpListAuto';
import { informList } from "./util/InformList";
import CreatableSelect from "react-select/creatable";

const FormAutoEdit = forwardRef(({reloadDataTable}, ref) => {
    const {register, handleSubmit, reset, setValue} = useForm();
    const documentState = useSelector((state) => state.document);

    const [autoFormMetaData, setAutoFormMetaData] = useState({});
    const [content, setContent] = useState('');
    const [content2, setContent2] = useState('');
    const [attachFileList, setAttachFileList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [openTab2, setOpenTab2] = useState(false);
    // const [autoFormDate, setAutoFormDate] = useState(null);
    // const [f2AutoFormDate, setF2AutoFormDate] = useState(null);
    const [autoFormDueDate, setAutoFormDueDate] = useState(null);
    const [f2AutoFormDueDate, setF2AutoFormDueDate] = useState(null);

    const tab1Ref = useRef(null);
    const tab2Ref = useRef(null);

    const [modalShow, setModalShow] = useState(false);
    const close = () => setModalShow(false);
    const show = () => setModalShow(true);

    const [showSelectDepartment, setShowSelectDepartment] = useState(false);
    const orgEmpListAutoRef = useRef();
    const orgEmpListAutoRef2 = useRef();

    const [departmentList, setDepartmentList] = useState([]);
    const [f2DepartmentList, setF2DepartmentList] = useState([]);

    const [departmentNameList, setDepartmentNameList] = useState([]);
    const [f2DepartmentNameList, setF2DepartmentNameList] = useState([]);

    const [listType, setListType] = useState();
    const [f2ListType, setF2ListType] = useState();

    const [referenceList, setReferenceList] = useState([]);
    const [f2ReferenceList, setF2ReferenceList] = useState([]);
    const [f2AttachList, setF2AttachList] = useState([]);
    const [attachList, setAttachList] = useState([]);

    const [prefixInform, setPrefixInform] = useState(informList[0]);
    const [f2PrefixInform, setF2PrefixInform] = useState(informList[0]);

    useImperativeHandle(ref, () => ({
        showModal,
    }));

    const showModal = (data) => {
        show();

        clearInitData();
        setInitData(data);
        setAutoFormMetaData({
            autoFormId: data.autoFormId,
            autoFormName: data.autoFormName,
            autoFormType: data.autoFormType,
            autoFormGenre: data.autoFormGenre,
            formId: data.formId,
            status: data.status,
        });

        reset({...data});

        if (informList.some((item) => item.label === data.prefixInform)) {
            setPrefixInform(informList.find((item) => item.label === data.prefixInform));
        }else {
            setPrefixInform({label: data.prefixInform, value: data.prefixInform});
        }


        if (informList.some((item) => item.label === data.f2PrefixInform)) {
            setF2PrefixInform(informList.find((item) => item.label === data.f2PrefixInform));
        }else {
            setF2PrefixInform({label: data.f2PrefixInform, value: data.f2PrefixInform});
        }

        // setAutoFormDate(data.autoFormDate);
        setAutoFormDueDate(data.autoFormDueDate);
        setContent(data.content);

        // setF2AutoFormDate(data.f2AutoFormDate);
        setF2AutoFormDueDate(data.f2AutoFormDueDate);
        setContent2(data.f2Content);

        setAttachFileList(data.attachFileList || []);
        setFileList([]);

        setOpenTab2(data.createInstead === '0' ? false : true);
    }

    const setInitData = async (data) => {

        mainApi.get('/api/form-auto/sub-detail?documentId=' + data.documentId).then((res) => {
            let data = res.data;
            setDepartmentList(data.departmentList);
            setDepartmentNameList(data.departmentNameList);

            setF2DepartmentList(data.f2DepartmentList);
            setF2DepartmentNameList(data.f2DepartmentNameList);

            setReferenceList(data.referenceList);
            setAttachList(data.attachList);
            setF2ReferenceList(data.f2ReferenceList);
            setF2AttachList(data.f2AttachList);

        });

        if (data.formId === 11 || data.formId === 12 || data.formId === 16) {

            setShowSelectDepartment(true);

        }

        setListType(data.listType);
        setF2ListType(data.f2ListType);
    }

    const clearInitData = async (data) => {
        setShowSelectDepartment(false)
        setListType(null);
        setDepartmentList([]);
        setDepartmentNameList([]);

        setF2ListType(null);
        setF2DepartmentList([]);
        setF2DepartmentNameList([]);

        setReferenceList([]);
        setAttachList([]);
        setF2ReferenceList([]);
        setF2AttachList([]);

        setPrefixInform({});
        setF2PrefixInform({});
    }

    const handleEditDraft = async (data) => {
        let bodyData = {
            autoFormId: autoFormMetaData.autoFormId,
            autoFormName: autoFormMetaData.autoFormName,
            autoFormType: autoFormMetaData.autoFormType,
            autoFormGenre: autoFormMetaData.autoFormGenre,
            formId: autoFormMetaData.formId,
            status: autoFormMetaData.status,

            government: data.government || '',
            tel: data.tel || '',
            fax: data.fax || '',
            autoFormNo: data.autoFormNo || '',
            subject: data.subject || '',
            inform: data.inform || '',
            postScript: data.postScript || '',
            prefixInform: prefixInform.value || '',
            command: data.command || '',
            urgencyLevel: data.urgencyLevel || '',
            governmentFt: data.governmentFt || '',
            telFt: data.telFt || '',
            faxFt: data.faxFt || '',
            emailFt: data.emailFt || '',
            referenceList: referenceList,
            attachList: attachList,
            content: content || '',
            departmentList: departmentList || [],
            listType: listType || '',

            f2Government: data.f2Government || '',
            f2Tel: data.f2Tel || '',
            f2Fax: data.f2Fax || '',
            f2AutoFormNo: data.f2AutoFormNo || '',
            f2Subject: data.f2Subject || '',
            f2Inform: data.f2Inform || '',
            f2PostScript: data.f2PostScript || '',
            f2PrefixInform: f2PrefixInform.value || '',
            f2Command: data.f2Command || '',
            f2GovernmentFt: data.f2GovernmentFt || '',
            f2TelFt: data.f2TelFt || '',
            f2FaxFt: data.f2FaxFt || '',
            f2EmailFt: data.f2EmailFt || '',
            f2ReferenceList: f2ReferenceList,
            f2AttachList: f2AttachList,
            f2Content: content2 || '',
            f2DepartmentList: f2DepartmentList || [],
            f2ListType: f2ListType || '',

            createInstead: data.createInstead,

            documentBy: documentState.documentBy,
        }

        // if (autoFormDate != null) {
        //     bodyData.autoFormDate = moment(autoFormDate).format('YYYY-MM-DD');
        // }

        if (autoFormDueDate != null) {
            bodyData.autoFormDueDate = moment(autoFormDueDate).format('YYYY-MM-DD');
        }

        // if (f2AutoFormDate != null) {
        //     bodyData.f2AutoFormDate = moment(f2AutoFormDate).format('YYYY-MM-DD');
        // }

        if (f2AutoFormDueDate != null) {
            bodyData.f2AutoFormDueDate = moment(f2AutoFormDueDate).format('YYYY-MM-DD');
        }

        let params = new FormData();
        params.append('data', JSON.stringify(bodyData));
        fileList.forEach((file) => {
            params.append('files', file);
        });

        mainApi.post('/api/form-auto/edit', params).then((res) => {
            reloadDataTable();
            Swal.fire({
                icon: 'success',
                title: 'บันทึกข้อมูลสำเร็จ',
                // text: 'เลขที่เอกสาร : ' + res.data.autoFormNo,
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            }).then((result) => {
                if (
                    result.dismiss === Swal.DismissReason.timer ||
                    result.value === true
                ) {
                    close();
                }
            });
        });
    }

    const handleDeleteAttachFile = (index) => {
        Swal.fire({
            title: 'ต้องการลบไฟล์ใช่หรือไม่?',
            text: 'ชื่อไฟล์ : ' + attachFileList[index].realFilename,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'ลบไฟล์!',
            cancelButtonColor: '#3085d6',
            cancelButtonText: 'ยกเลิก',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteAttachFile(index);
            }
        });
    };

    const deleteAttachFile = async (index) => {
        let response = await mainApi.post('/api/v1/document-process/delete-file', attachFileList[index]);

        if (response.status === 200) {
            attachFileList.splice(index, 1);
            setAttachFileList([...attachFileList]);
            Swal.fire({
                icon: 'success',
                title: 'สำเร็จ',
                text: 'ลบไฟล์สำเร็จ',
                timer: config.get('alertTimer'),
                timerProgressBar: true,
            });
        }
    };

    const handleSubmitError = (errors) => {
        let firstKey = Object.keys(errors)[0];
        if (firstKey.indexOf('f2') > -1) {
            tab2Ref.current.click();
        } else {
            tab1Ref.current.click();
        }
    }

    const submitOrg = () => {
        let dataDepartment = getSentList();
        let departmentIdList = dataDepartment.map((n) => n.split(":")[0]);
        let departmentNameList = dataDepartment.map((n) => n.split(":")[1]);
        let dataRadio = getSelectRadio();

        if (parseInt(dataRadio.listType) === 1) {
            // setValue('inform', "แนบท้ายรายชื่อผู้อำนวยการในสังกัดกรมควบคุมโรค");
            setListType(1);
        } else if (parseInt(dataRadio.listType) === 2) {
            // setValue('inform', "แนบท้ายรายชื่อหน่วยงานสังกัดกรมควบคุมโรค");
            setListType(2);
        } else {
            setListType(null);
        }
        setDepartmentList(departmentIdList);
        setDepartmentNameList(departmentNameList);
    }

    const submitOrg2 = () => {
        let dataDepartment = getSentList2();
        let departmentIdList = dataDepartment.map((n) => n.split(":")[0]);
        let departmentNameList = dataDepartment.map((n) => n.split(":")[1]);
        let dataRadio = getSelectRadio2();

        if (parseInt(dataRadio.listType) === 1) {
            // setValue('f2Inform', "แนบท้ายรายชื่อผู้อำนวยการในสังกัดกรมควบคุมโรค");
            setF2ListType(1);
        } else if (parseInt(dataRadio.listType) === 2) {
            // setValue('f2Inform', "แนบท้ายรายชื่อหน่วยงานสังกัดกรมควบคุมโรค");
            setF2ListType(2);
        } else {
            setF2ListType(null);
        }
        setF2DepartmentList(departmentIdList);
        setF2DepartmentNameList(departmentNameList);
    }

    const getSentList = () => {
        return orgEmpListAutoRef.current.getAllSelectList();
    };

    const getSentList2 = () => {
        return orgEmpListAutoRef2.current.getAllSelectList();
    };

    const getSelectRadio = () => {
        return orgEmpListAutoRef.current.getSelectRadio();
    }

    const getSelectRadio2 = () => {
        return orgEmpListAutoRef2.current.getSelectRadio();
    }

    const handleAppendFields = (fieldName, object) => {
        if (fieldName === 'referenceList') {
            setReferenceList([...referenceList, object]);
        }
        if (fieldName === 'f2ReferenceList') {
            setF2ReferenceList([...f2ReferenceList, object]);
        }
        if (fieldName === 'attachList') {
            setAttachList([...attachList, object]);
        }
        if (fieldName === 'f2AttachList') {
            setF2AttachList([...f2AttachList, object]);
        }
    }

    const handleRemoveFields = (fieldName, index) => {
        if (fieldName === 'referenceList') {
            let dataList = [...referenceList];
            dataList.splice(index, 1);
            setReferenceList(dataList);
        }
        if (fieldName === 'f2ReferenceList') {
            let dataList = [...f2ReferenceList];
            dataList.splice(index, 1);
            setF2ReferenceList(dataList);
        }
        if (fieldName === 'attachList') {
            let dataList = [...attachList];
            dataList.splice(index, 1);
            setAttachList(dataList);
        }
        if (fieldName === 'f2AttachList') {
            let dataList = [...f2AttachList];
            dataList.splice(index, 1);
            setF2AttachList(dataList);
        }
    };

    return (
        <Modal size="xl" show={modalShow} onHide={close}>
            <Form className="form-auto" onSubmit={handleSubmit(handleEditDraft, handleSubmitError)}>
                <Modal.Header closeButton className="bg-color">
                    <Modal.Title className="nav-text text-shadow-header">
                        <i className="fas fa-book mr-2"></i>รายละเอียดเอกสาร {autoFormMetaData.autoFormName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Tab.Container defaultActiveKey="tab1">
                            <Nav variant="pills" defaultActiveKey="tab1" className="justify-content-center mb-3 ">
                                <Nav.Item>
                                    <Nav.Link eventKey="tab1" ref={tab1Ref}>เอกสารเสนอผู้บังคับบัญชา</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab2" ref={tab2Ref} disabled={!openTab2}>เอกสารเสนอผู้บังคับบัญชาสูงขึ้น 1 ระดับ</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="tab1">
                                    <Card>
                                        <Card.Header className="form-auto-header">หัวหนังสือราชการ</Card.Header>
                                        <Card.Body>
                                            {autoFormMetaData.formId !== 17 && (
                                                <>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">ส่วนราชการ</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" name="government" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    {autoFormMetaData.autoFormGenre !== "OUT" && (
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">โทร</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="tel" ref={register}/>
                                                            </Col>
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">โทรสาร</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="fax" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    {/*<Row className="mb-3">*/}
                                                    {/*    <Col sm={12} md={2}>*/}
                                                    {/*        <span className="text-color-form align-middle">ที่</span>*/}
                                                    {/*    </Col>*/}
                                                    {/*    <Col sm={12} md={4}>*/}
                                                    {/*        <Form.Control type="text" name="autoFormNo" ref={register}/>*/}
                                                    {/*    </Col>*/}
                                                    {/*    <Col sm={12} md={2}>*/}
                                                    {/*        <span className="text-color-form align-middle">ลงวันที่</span>*/}
                                                    {/*        <span className="text-danger"> *</span>*/}
                                                    {/*    </Col>*/}
                                                    {/*    <Col sm={12} md={4}>*/}
                                                    {/*        <DateInput*/}
                                                    {/*            name="autoFormDate"*/}
                                                    {/*            value={autoFormDate}*/}
                                                    {/*            onChange={(date) => setAutoFormDate(date)}*/}
                                                    {/*            inputRef={register({required: true})}*/}
                                                    {/*        />*/}
                                                    {/*        {errors.autoFormDate && !autoFormDate && (<span className="text-danger">กรุณากรอกวันที่</span>)}*/}
                                                    {/*    </Col>*/}
                                                    {/*</Row>*/}
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">เรื่อง</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" name="subject" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <CreatableSelect
                                                                isClearable
                                                                className="selectInform"
                                                                options={informList}
                                                                onChange={(selected) => {
                                                                    setPrefixInform(selected)
                                                                    if (selected) {
                                                                        setValue("postScript", selected.postScript);
                                                                    }
                                                                    if (selected.value === "ทูล (ชั้นหม่อมเจ้า)" || selected.value === "ทูล (ชั้นพระวรวงศ์เธอ)") {
                                                                        setPrefixInform({value: "ทูล", label: selected.value})
                                                                    }
                                                                }}
                                                                value={prefixInform}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" name="inform" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">คำลงท้าย</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" name="postScript" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    {showSelectDepartment && (
                                                        <>
                                                            <Row className="mb-3">
                                                                <Col sm={12} md={2}>

                                                                </Col>
                                                                <Col>
                                                                    <Button
                                                                        type="button"
                                                                        variant="contained"
                                                                        className="btn-color-submit-small mr-2"
                                                                        onClick={() => {
                                                                            orgEmpListAutoRef.current.setDepartment(departmentList);

                                                                            orgEmpListAutoRef.current.setRadioListType(listType);

                                                                            orgEmpListAutoRef.current.show()
                                                                        }
                                                                        }
                                                                    >
                                                                        <i className="fas fa-paper-plane pr-2"></i>เลือกหน่วยงาน
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={12} md={2}>

                                                                </Col>
                                                                <Col>
                                                                    <div>
                                                                        {departmentNameList.map((data, index) => {
                                                                            return <p className='mb-0' key={data}>- {data}</p>
                                                                        })}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {autoFormMetaData.formId === 17 && (
                                                <>
                                                    {/*<Row className="mb-3">*/}
                                                    {/*    <Col sm={12} md={2}>*/}
                                                    {/*        <span className="text-color-form align-middle">ที่</span>*/}
                                                    {/*    </Col>*/}
                                                    {/*    <Col sm={12} md={4}>*/}
                                                    {/*        <Form.Control type="text" name="autoFormNo" ref={register}/>*/}
                                                    {/*    </Col>*/}
                                                    {/*    <Col sm={12} md={2}>*/}
                                                    {/*        <span className="text-color-form align-middle">ลงวันที่</span>*/}
                                                    {/*        <span className="text-danger"> *</span>*/}
                                                    {/*    </Col>*/}
                                                    {/*    <Col sm={12} md={4}>*/}
                                                    {/*        <DateInput*/}
                                                    {/*            name="autoFormDate"*/}
                                                    {/*            value={autoFormDate}*/}
                                                    {/*            onChange={(date) => setAutoFormDate(date)}*/}
                                                    {/*            inputRef={register({required: true})}*/}
                                                    {/*        />*/}
                                                    {/*        {errors.autoFormDate && !autoFormDate && (<span className="text-danger">กรุณากรอกวันที่</span>)}*/}
                                                    {/*    </Col>*/}
                                                    {/*</Row>*/}
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">เรื่อง</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" name="subject" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">ถึง</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" name="inform" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                            {autoFormMetaData.autoFormGenre === "OUT" && (
                                                <>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">อ้างถึง</span>
                                                            <Button
                                                                type="button"
                                                                className="btn-color-submit-small ml-2 float-right"
                                                                // onClick={() => refAppend({referenceId: 0, detail: ""})}
                                                                onClick={() => handleAppendFields('referenceList', {referenceId: 0, detail: ""})}
                                                            >
                                                                <i className="fas fa-plus"></i>
                                                            </Button>
                                                        </Col>
                                                        <Col sm={12} md={10}>
                                                            {referenceList.length === 0 && (
                                                                <span className="text-color-form align-middle">(ถ้ามี)</span>
                                                            )}
                                                            {referenceList.map((item, index) => (
                                                                <div key={index}>
                                                                    <Row className="mb-1">
                                                                        <Col>
                                                                            <Form.Control
                                                                                type="text"
                                                                                defaultValue={item.referenceId}
                                                                                hidden
                                                                            />
                                                                            <InputGroup className="mb-3">
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    value={item.detail}
                                                                                    onChange={(e) => {
                                                                                        let dataList = referenceList.map((item, i) => i === index ? {
                                                                                            ...item,
                                                                                            detail: e.target.value
                                                                                        } : item);
                                                                                        setReferenceList(dataList);
                                                                                    }}
                                                                                />
                                                                                <Button
                                                                                    type="button"
                                                                                    className="btn-color-delete-small  ml-2"
                                                                                    onClick={() => handleRemoveFields('referenceList', index)}
                                                                                >
                                                                                    ลบ
                                                                                </Button>
                                                                            </InputGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">สิ่งที่ส่งมาด้วย</span>
                                                            <Button
                                                                type="button"
                                                                className="btn-color-submit-small ml-2 float-right"
                                                                onClick={() => handleAppendFields('attachList', {attachId: 0, detail: "", qty: "", unit: "แผ่น"})}
                                                            >
                                                                <i className="fas fa-plus"></i>
                                                            </Button>
                                                        </Col>
                                                        <Col sm={12} md={10}>
                                                            {attachList.length === 0 && (
                                                                <span className="text-color-form align-middle">(ถ้ามี)</span>
                                                            )}
                                                            {attachList.map((item, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <Row className="mb-3">
                                                                            <Col sm={12} md={7}>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    defaultValue={item.attachId}
                                                                                    hidden
                                                                                />
                                                                                <InputGroup>
                                                                                    <InputGroup.Prepend>
                                                                                        <InputGroup.Text>{index + 1}.</InputGroup.Text>
                                                                                    </InputGroup.Prepend>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        value={item.detail}
                                                                                        onChange={(e) => {
                                                                                            let detailDataList = attachList.map((item, i) => i === index ? {
                                                                                                ...item,
                                                                                                detail: e.target.value
                                                                                            } : item);
                                                                                            setAttachList(detailDataList);
                                                                                        }}
                                                                                    />
                                                                                </InputGroup>
                                                                            </Col>
                                                                            <Col sm={12} md={2}>
                                                                                <InputGroup>
                                                                                    <InputGroup.Prepend>
                                                                                        <InputGroup.Text>จำนวน</InputGroup.Text>
                                                                                    </InputGroup.Prepend>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        value={item.qty}
                                                                                        onChange={(e) => {
                                                                                            let qtyDataList = attachList.map((item, i) => i === index ? {
                                                                                                ...item,
                                                                                                qty: e.target.value
                                                                                            } : item);
                                                                                            setAttachList(qtyDataList);
                                                                                        }}
                                                                                    />
                                                                                </InputGroup>
                                                                            </Col>
                                                                            <Col sm={12} md={2}>
                                                                                <select
                                                                                    className="form-control shadow-none"
                                                                                    value={item.unit}
                                                                                    onChange={(e) => {
                                                                                        let unitDataList = attachList.map((item, i) => i === index ? {
                                                                                            ...item,
                                                                                            unit: e.target.value
                                                                                        } : item);
                                                                                        setAttachList(unitDataList);
                                                                                    }}
                                                                                >
                                                                                    {unit.map((item) => {
                                                                                        return (
                                                                                            <option
                                                                                                key={item.id}
                                                                                                value={item.id}>
                                                                                                {item.name}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </Col>
                                                                            <Col sm={12} md={1}>
                                                                                <Button
                                                                                    type="button"
                                                                                    className="btn-color-delete-small ml-1 float-right"
                                                                                    onClick={() => handleRemoveFields('attachList', index)}
                                                                                >
                                                                                    ลบ
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            })}
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </Card.Body>

                                        <Card.Footer className="form-auto-header">ส่วนข้อความ</Card.Footer>
                                        <Card.Body>
                                            <FroalaEditor model={content} onModelChange={setContent}/>
                                        </Card.Body>

                                        {((autoFormMetaData.autoFormGenre !== "OUT") && (autoFormMetaData.formId !== 17)) && (
                                            <>
                                                <Card.Footer className="form-auto-header">ส่วนเสริม</Card.Footer>
                                                <Card.Body>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">ชั้นความเร็ว</span>
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <select className="form-control shadow-none" name="urgencyLevel" ref={register}>
                                                                {urgencyLevel.map((item) => {
                                                                    return (
                                                                        <option
                                                                            key={item.id}
                                                                            value={item.id}>
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">คำสั่งการ</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control as="textarea" rows={3} name="command" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">วันแจ้งเตือน</span>
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <DateInput
                                                                name="autoFormDueDate"
                                                                value={autoFormDueDate}
                                                                onChange={(date) => setAutoFormDueDate(date)}
                                                                inputRef={register}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </>
                                        )}

                                        {(autoFormMetaData.autoFormGenre === "OUT" || autoFormMetaData.formId === 17) && (
                                            <>
                                                <Card.Footer className="form-auto-header">ข้อมูลส่วนที่ 4</Card.Footer>
                                                <Card.Body>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">ส่วนราชการเจ้าของเรื่อง</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" name="governmentFt" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">โทร</span>
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <Form.Control type="text" name="telFt" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">โทรสาร</span>
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <Form.Control type="text" name="faxFt" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">ไปรษณีย์อิเล็กทรอนิกส์</span><br/>
                                                            <span className="text-color-form align-middle">(ถ้ามี)</span>
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <Form.Control type="text" name="emailFt" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>

                                                <Card.Footer className="form-auto-header">ส่วนเสริม</Card.Footer>
                                                <Card.Body>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">ชั้นความเร็ว</span>
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <select className="form-control shadow-none" name="urgencyLevel" ref={register}>
                                                                {urgencyLevel.map((item) => {
                                                                    return (
                                                                        <option
                                                                            key={item.id}
                                                                            value={item.id}>
                                                                            {item.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">คำสั่งการ</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control as="textarea" rows={3} name="command" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">วันแจ้งเตือน</span>
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <DateInput
                                                                name="autoFormDueDate"
                                                                value={autoFormDueDate}
                                                                onChange={(date) => setAutoFormDueDate(date)}
                                                                inputRef={register}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </>
                                        )}

                                        <Card.Footer className="form-auto-header">ส่วนเพิ่มเติม</Card.Footer>
                                        <Card.Body>
                                            <Row className="mb-3">
                                                <Col sm={12} md={3}>
                                                    <span className="text-color-form align-middle">สร้างแบบฟอร์มผ่านหัวหน้ากลุ่ม</span>
                                                </Col>
                                                <Col>
                                                    <Form.Check
                                                        type={"radio"}
                                                        label={"ไม่ต้องการสร้างแบบผ่านหัวหน้ากลุ่ม"}
                                                        name={"createInstead"}
                                                        value={0}
                                                        ref={register}
                                                        defaultChecked
                                                        onChange={() => setOpenTab2(false)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col sm={12} md={3}></Col>
                                                <Col>
                                                    <Form.Check
                                                        type={"radio"}
                                                        label={"ต้องการสร้างแบบฟอร์มผ่านหัวหน้ากลุ่ม (กรุณากรอกข้อมูล)"}
                                                        name={"createInstead"}
                                                        value={1}
                                                        ref={register}
                                                        onChange={() => setOpenTab2(true)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card.Body>

                                        <Card.Footer className="form-auto-header">แนบไฟล์</Card.Footer>
                                        <Card.Body>
                                            {attachFileList.length > 0 && (
                                                <Row>
                                                    <Col>
                                                        <span className="text-color-form align-middle">
                                                            ไฟล์แนบ {attachFileList ? attachFileList.length : 0} ไฟล์
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                            {attachFileList.map((docAttach, docIndex) => {
                                                return (
                                                    <Row key={docIndex}>
                                                        <Col md={2} className="d-none d-sm-block">
                                                            <span className="text-color-form align-middle"></span>
                                                        </Col>
                                                        <Col md={10} className="text-normal">
                                                            <DownloadFile
                                                                filename={docAttach.filename}
                                                                realfilename={docIndex + 1 + '. ' + docAttach.realFilename}
                                                                deleteFile={() => handleDeleteAttachFile(docIndex)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                            <Row className="mt-3 mb-3">
                                                <Col>
                                                    <UploadFiles fileList={fileList} setFileList={setFileList}/>
                                                </Col>
                                            </Row>
                                        </Card.Body>

                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="tab2">
                                    <Card>
                                        <Card.Header className="form-auto-header">หัวหนังสือราชการ</Card.Header>
                                        <Card.Body>
                                            {autoFormMetaData.formId !== 17 && (
                                                <>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">ส่วนราชการ</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" name="f2Government" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    {autoFormMetaData.autoFormGenre !== "OUT" && (
                                                        <Row className="mb-3">
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">โทร</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="f2Tel" ref={register}/>
                                                            </Col>
                                                            <Col sm={12} md={2}>
                                                                <span className="text-color-form align-middle">โทรสาร</span>
                                                            </Col>
                                                            <Col>
                                                                <Form.Control type="text" name="f2Fax" ref={register}/>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    {/*<Row className="mb-3">*/}
                                                    {/*    <Col sm={12} md={2}>*/}
                                                    {/*        <span className="text-color-form align-middle">ที่</span>*/}
                                                    {/*    </Col>*/}
                                                    {/*    <Col sm={12} md={4}>*/}
                                                    {/*        <Form.Control type="text" name="f2AutoFormNo" ref={register}/>*/}
                                                    {/*    </Col>*/}
                                                    {/*    <Col sm={12} md={2}>*/}
                                                    {/*        <span className="text-color-form align-middle">ลงวันที่</span>*/}
                                                    {/*        <span className="text-danger"> *</span>*/}
                                                    {/*    </Col>*/}
                                                    {/*    <Col sm={12} md={4}>*/}
                                                    {/*        <DateInput*/}
                                                    {/*            name="f2AutoFormDate"*/}
                                                    {/*            value={f2AutoFormDate}*/}
                                                    {/*            onChange={(date) => setF2AutoFormDate(date)}*/}
                                                    {/*            inputRef={register({required: openTab2})}*/}
                                                    {/*        />*/}
                                                    {/*        {errors.f2AutoFormDate && !f2AutoFormDate && (<span className="text-danger">กรุณากรอกวันที่</span>)}*/}
                                                    {/*    </Col>*/}
                                                    {/*</Row>*/}
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">เรื่อง</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" name="f2Subject" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <CreatableSelect
                                                                isClearable
                                                                className="selectInform"
                                                                options={informList}
                                                                onChange={(selected) => {
                                                                    setF2PrefixInform(selected)
                                                                    if (selected) {
                                                                        setValue("f2PostScript", selected.postScript);
                                                                    }
                                                                    if (selected.value === "ทูล (ชั้นหม่อมเจ้า)" || selected.value === "ทูล (ชั้นพระวรวงศ์เธอ)") {
                                                                        setF2PrefixInform({value: "ทูล", label: selected.value})
                                                                    }
                                                                }}
                                                                value={f2PrefixInform}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" name="f2Inform" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">คำลงท้าย</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" name="f2PostScript" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    {showSelectDepartment && (
                                                        <>
                                                            <Row className="mb-3">
                                                                <Col sm={12} md={2}>

                                                                </Col>
                                                                <Col>
                                                                    <Button
                                                                        type="button"
                                                                        variant="contained"
                                                                        className="btn-color-submit-small mr-2"
                                                                        onClick={() => {
                                                                            orgEmpListAutoRef2.current.setDepartment(f2DepartmentList);

                                                                            orgEmpListAutoRef2.current.setRadioListType(f2ListType);

                                                                            orgEmpListAutoRef2.current.show()
                                                                        }
                                                                        }
                                                                    >
                                                                        <i className="fas fa-paper-plane pr-2"></i>เลือกหน่วยงาน
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm={12} md={2}>

                                                                </Col>
                                                                <Col>
                                                                    <div>
                                                                        {f2DepartmentNameList.map((data, index) => {
                                                                            return <p className='mb-0' key={data}>- {data}</p>
                                                                        })}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {autoFormMetaData.formId === 17 && (
                                                <>
                                                    {/*<Row className="mb-3">*/}
                                                    {/*    <Col sm={12} md={2}>*/}
                                                    {/*        <span className="text-color-form align-middle">ที่</span>*/}
                                                    {/*    </Col>*/}
                                                    {/*    <Col sm={12} md={4}>*/}
                                                    {/*        <Form.Control type="text" name="f2AutoFormNo" ref={register}/>*/}
                                                    {/*    </Col>*/}
                                                    {/*    <Col sm={12} md={2}>*/}
                                                    {/*        <span className="text-color-form align-middle">ลงวันที่</span>*/}
                                                    {/*        <span className="text-danger"> *</span>*/}
                                                    {/*    </Col>*/}
                                                    {/*    <Col sm={12} md={4}>*/}
                                                    {/*        <DateInput*/}
                                                    {/*            name="f2AutoFormDate"*/}
                                                    {/*            value={f2AutoFormDate}*/}
                                                    {/*            onChange={(date) => setF2AutoFormDate(date)}*/}
                                                    {/*            inputRef={register({required: openTab2})}*/}
                                                    {/*        />*/}
                                                    {/*        {errors.f2AutoFormDate && !f2AutoFormDate && (<span className="text-danger">กรุณากรอกวันที่</span>)}*/}
                                                    {/*    </Col>*/}
                                                    {/*</Row>*/}
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">เรื่อง</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" name="f2Subject" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">ถึง</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" name="f2Inform" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                            {autoFormMetaData.autoFormGenre === "OUT" && (
                                                <>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">อ้างถึง</span>
                                                            <Button
                                                                type="button"
                                                                className="btn-color-submit-small ml-2 float-right"
                                                                onClick={() => handleAppendFields('f2ReferenceList', {referenceId: 0, detail: ""})}
                                                            >
                                                                <i className="fas fa-plus"></i>
                                                            </Button>
                                                        </Col>
                                                        <Col sm={12} md={10}>
                                                            {f2ReferenceList.length === 0 && (
                                                                <span className="text-color-form align-middle">(ถ้ามี)</span>
                                                            )}
                                                            {f2ReferenceList.map((item, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <Row className="mb-1">
                                                                            <Col>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    defaultValue={item.referenceId}
                                                                                    hidden
                                                                                />
                                                                                <InputGroup className="mb-3">
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        value={item.detail}
                                                                                        onChange={(e) => {
                                                                                            let f2ReferenceDataList = f2ReferenceList.map((item, i) => i === index ? {
                                                                                                ...item,
                                                                                                detail: e.target.value
                                                                                            } : item);
                                                                                            setF2ReferenceList(f2ReferenceDataList);
                                                                                        }}
                                                                                    />
                                                                                    <Button
                                                                                        type="button"
                                                                                        className="btn-color-delete-small  ml-2"
                                                                                        onClick={() => handleRemoveFields('f2ReferenceList', index)}
                                                                                    >
                                                                                        ลบ
                                                                                    </Button>
                                                                                </InputGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            })}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">สิ่งที่ส่งมาด้วย</span>
                                                            <Button
                                                                type="button"
                                                                className="btn-color-submit-small ml-2 float-right"
                                                                onClick={() => handleAppendFields('f2AttachList', {attachId: 0, detail: "", qty: "", unit: "แผ่น"})}
                                                            >
                                                                <i className="fas fa-plus"></i>
                                                            </Button>
                                                        </Col>
                                                        <Col sm={12} md={10}>
                                                            {f2AttachList.length === 0 && (
                                                                <span className="text-color-form align-middle">(ถ้ามี)</span>
                                                            )}
                                                            {f2AttachList.map((item, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <Row className="mb-3">
                                                                            <Col sm={12} md={7}>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    defaultValue={item.attachId}
                                                                                    hidden
                                                                                />
                                                                                <InputGroup>
                                                                                    <InputGroup.Prepend>
                                                                                        <InputGroup.Text>{index + 1}.</InputGroup.Text>
                                                                                    </InputGroup.Prepend>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        value={item.detail}
                                                                                        onChange={(e) => {
                                                                                            let F2DetailDataList = f2AttachList.map((item, i) => i === index ? {
                                                                                                ...item,
                                                                                                detail: e.target.value
                                                                                            } : item);
                                                                                            setF2AttachList(F2DetailDataList);
                                                                                        }}
                                                                                    />
                                                                                </InputGroup>
                                                                            </Col>
                                                                            <Col sm={12} md={2}>
                                                                                <InputGroup>
                                                                                    <InputGroup.Prepend>
                                                                                        <InputGroup.Text>จำนวน</InputGroup.Text>
                                                                                    </InputGroup.Prepend>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        value={item.qty}
                                                                                        onChange={(e) => {
                                                                                            let F2QtyDataList = f2AttachList.map((item, i) => i === index ? {
                                                                                                ...item,
                                                                                                qty: e.target.value
                                                                                            } : item);
                                                                                            setF2AttachList(F2QtyDataList);
                                                                                        }}
                                                                                    />
                                                                                </InputGroup>
                                                                            </Col>
                                                                            <Col sm={12} md={2}>
                                                                                <select
                                                                                    className="form-control shadow-none"
                                                                                    value={item.unit}
                                                                                    onChange={(e) => {
                                                                                        let F2UnitDataList = f2AttachList.map((item, i) => i === index ? {
                                                                                            ...item,
                                                                                            unit: e.target.value
                                                                                        } : item);
                                                                                        setF2AttachList(F2UnitDataList);
                                                                                    }}
                                                                                >
                                                                                    {unit.map((item) => {
                                                                                        return (
                                                                                            <option
                                                                                                key={item.id}
                                                                                                value={item.id}>
                                                                                                {item.name}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </Col>
                                                                            <Col sm={12} md={1}>
                                                                                <Button
                                                                                    type="button"
                                                                                    className="btn-color-delete-small ml-2 float-right"
                                                                                    onClick={() => handleRemoveFields('f2AttachList', index)}
                                                                                >
                                                                                    ลบ
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            })}
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </Card.Body>

                                        <Card.Footer className="form-auto-header">ส่วนข้อความ</Card.Footer>
                                        <Card.Body>
                                            <FroalaEditor model={content2} onModelChange={setContent2}/>
                                        </Card.Body>

                                        {((autoFormMetaData.autoFormGenre !== "OUT") && (autoFormMetaData.formId !== 17)) && (
                                            <>
                                                <Card.Footer className="form-auto-header">ส่วนเสริม</Card.Footer>
                                                <Card.Body>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">คำสั่งการ</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control as="textarea" rows={3} name="f2Command" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">วันแจ้งเตือน</span>
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <DateInput
                                                                name="f2AutoFormDueDate"
                                                                value={f2AutoFormDueDate}
                                                                onChange={(date) => setF2AutoFormDueDate(date)}
                                                                inputRef={register}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </>
                                        )}

                                        {(autoFormMetaData.autoFormGenre === "OUT" || autoFormMetaData.formId === 17) && (
                                            <>
                                                <Card.Footer className="form-auto-header">ข้อมูลส่วนที่ 4</Card.Footer>
                                                <Card.Body>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">ส่วนราชการเจ้าของเรื่อง</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text" name="f2GovernmentFt" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">โทร</span>
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <Form.Control type="text" name="f2TelFt" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">โทรสาร</span>
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <Form.Control type="text" name="f2FaxFt" ref={register}/>
                                                        </Col>
                                                    </Row>

                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">ไปรษณีย์อิเล็กทรอนิกส์</span><br/>
                                                            <span className="text-color-form align-middle">(ถ้ามี)</span>
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <Form.Control type="text" name="f2EmailFt" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>

                                                <Card.Footer className="form-auto-header">ส่วนเสริม</Card.Footer>
                                                <Card.Body>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">คำสั่งการ</span>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control as="textarea" rows={3} name="f2Command" ref={register}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col sm={12} md={2}>
                                                            <span className="text-color-form align-middle">วันแจ้งเตือน</span>
                                                        </Col>
                                                        <Col sm={12} md={4}>
                                                            <DateInput
                                                                name="f2AutoFormDueDate"
                                                                value={f2AutoFormDueDate}
                                                                onChange={(date) => setF2AutoFormDueDate(date)}
                                                                inputRef={register}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </>
                                        )}
                                    </Card>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="submit"
                        className="btn-color-add-small mr-2"
                    >
                        <i className="far fa-save pr-2"></i>
                        บันทึก
                    </Button>
                    <Button
                        type="button"
                        className="btn-color-delete-small"
                        onClick={() => close()}
                    >
                        <i className="fas fa-ban pr-2"></i>
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Form>
            <OrgEmpListAuto onAccept={submitOrg} ref={orgEmpListAutoRef}/>
            <OrgEmpListAuto onAccept={submitOrg2} ref={orgEmpListAutoRef2}/>
        </Modal>
    )
});

export default FormAutoEdit;
